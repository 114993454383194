import { ClipboardText } from '@phosphor-icons/react';
import { SmartForms } from './SmartForms';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { PageEditor } from '../Pages/Editor';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  // Add an item called "Forms" to the App Drawer
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("forms")) return [];
    return [
      {
        label: "Forms",
        icon: <ClipboardText className="text-lg" />,
        href: "/forms", // this is the path to the "Forms" gallery. Go there when the user clicks on the item
      },
    ];
  }

  // Add a route for the "Forms" gallery and another for the Smart Forms Dashboard
  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("forms")) return [];
    return [
      {
        path: "/forms",
        element: <SmartForms name="Smart Forms" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Forms" />,
          },
          {
            path: ":campaignId/:objectId",
            element: <PageEditor name="Smart Forms" homeHref="/forms" />,
          },
        ],
      },
    ];
  }
};


export { SmartForms };