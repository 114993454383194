import { BigCheck } from "@/ui/BigCheck"
import Grid from "@/ui/Grid"
import Panel from "@/ui/Panel"
import Select from "@/ui/Select";
import { Switch } from "@/ui/Switch";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

export const SessionSchedule = () => {
    return (
      <Panel title="Session Upcoming Sessions">
        <div className="grid gap-7">
          <Grid cols={2} smCols={1} lgCols={2}>
            <BigCheck
              label="Single Date & Time"
              caption="Schedule one session to occur on a set date and time"
              checked
            />
            <BigCheck
              label="Recurring Times"
              caption="Schedule sessions to occur on a regular, repeating schedule"
            />
          </Grid>
          <Select
            flavor={TextInputFlavors.MODERN}
            label="How Often?"
            items={[
              { label: "Every Day" },
              { label: "Every Week Day" },
              { label: "Top of every hour" },
              { label: "[X] minutes after page visit" },
            ]}
          />
          <Grid cols={3} smCols={1} lgCols={3}>
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`Minutes until next session`}
              name="minutesUntil"
              type={TextInputTypes.NUMBER}
            />
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`# Sessions available`}
              name="numSessions"
              type={TextInputTypes.NUMBER}
            />
            <Switch
                flavor={TextInputFlavors.MODERN}
                title="On Demand Option"
                label="Off"
            />
          </Grid>
          <Grid cols={4} smCols={1} lgCols={4}>
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`Start Time`}
              name="startTime"
              type={TextInputTypes.TIME}
            />
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`End Time`}
              name="endTime"
              type={TextInputTypes.TIME}
            />
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`Start Date`}
              name="startDate"
              type={TextInputTypes.DATE}
            />
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label={`End Date`}
              name="endDate"
              type={TextInputTypes.DATE}
            />
          </Grid>
          <p className="text-black/40 dark:text-white/40">On this date, your page will go live and allow users to register for your webinar</p>
        </div>
      </Panel>
    );
}