import { useState } from 'react';

import { Page, Container } from '../Page';

import { Stepper } from "@/ui/Stepper";
import { BigOptionGroup } from "@/ui/BigOptionGroup";
import { OptionGroup } from "@/ui/OptionGroup";
import { InputGroup } from "@/ui/InputGroup";
import { Radio } from "@/ui/Radio";
import { Checkbox } from "@/ui/Checkbox";
import { Select } from "@/ui/Select";
import { TextInput } from "@/ui/TextInput";
import { SimpleContainer } from "@/ui/SimpleContainer";

import {
	NotificationIcon,
	DashboardIcon,
	AppsIcon,
	ComponentsIcon,
} from "@/ui/Icons";

import {
  TextInputFlavors,
  TextInputTypes,
  TextInputValidationTypes,
} from "@/ui/TextInput/types";

export const Forms: React.FC = () => {

	const basicDropdownItems = [
		{ label: "Action", href: "#" },
		{ label: "Another action", href: "#" },
		{ label: "Something else here", href: "#" },
	];

	const OptionGroupsDemo = () => {
		const [optionGroupSelection, setOptionGroupSelection] = useState("1-1");
		const [bigOptionGroupSelection, setBigOptionGroupSelection] =
			useState("Option 1");

		return (
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
				<SimpleContainer label="Option Group">
					<OptionGroup
						options={["1-1", "2-10", "10-50"]}
						selectedOption={optionGroupSelection}
						onChange={setOptionGroupSelection}
					/>
				</SimpleContainer>

				<SimpleContainer label="Big Option Group">
					<BigOptionGroup
						options={[
							{
								id: "Option 1",
								label: "Option 1",
								caption: "Description for Option 1",
								icon: <DashboardIcon />,
							},
							{
								id: "Option 2",
								label: "Option 2",
								caption: "Description for Option 2",
								icon: <AppsIcon />,
							},
							{
								id: "Option 3",
								label: "Option 3",
								caption: "Description for Option 3",
								icon: <ComponentsIcon />,
							},
						]}
						selectedOption={bigOptionGroupSelection}
						onChange={setBigOptionGroupSelection}
					/>
				</SimpleContainer>
			</div>
		);
	};

	return (
		<Page topbar={{ page: [{ label: "Forms" }] }}>
			<Container>
				<p className="text-lg font-semibold">Forms</p>

				{/* Forms */}
				<a name="forms" />
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Basic Input Text">
						<TextInput
							flavor={TextInputFlavors.MODERN}
							label="User Name"
							value="Stevens"
							placeholder="User name"
						/>
					</SimpleContainer>

					<SimpleContainer label="Email Input">
						<TextInput
							flavor={TextInputFlavors.MODERN}
							type={TextInputTypes.EMAIL}
							label="Email"
							value="Stevens@gmail.com"
							placeholder="Email"
						/>
					</SimpleContainer>

					<SimpleContainer label="Basic Input Text">
						<TextInput placeholder="User Name" required />
					</SimpleContainer>

					<SimpleContainer label="Label Text">
						<TextInput
							label="Choose Username:"
							placeholder="User Name"
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Email Input">
						<TextInput placeholder="email@mail.com" required />
					</SimpleContainer>

					<SimpleContainer label="Password Input">
						<TextInput
							type={TextInputTypes.PASSWORD}
							flavor={TextInputFlavors.CLASSIC}
							placeholder="Enter Password"
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Url Input">
						<TextInput
							type={TextInputTypes.URL}
							flavor={TextInputFlavors.CLASSIC}
							placeholder="https://dummyurl.com"
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Search Input">
						<TextInput
							type={TextInputTypes.SEARCH}
							flavor={TextInputFlavors.CLASSIC}
							placeholder="Search..."
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Telephone Input">
						<TextInput
							type={TextInputTypes.TELEPHONE}
							flavor={TextInputFlavors.CLASSIC}
							placeholder="0-(000)-111-1111"
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Rounded Input">
						<TextInput
							flavor={TextInputFlavors.ROUNDED}
							placeholder="User Name"
							required
						/>
					</SimpleContainer>

					<SimpleContainer label="Select Menu">
						<Select items={basicDropdownItems} />
					</SimpleContainer>

					<SimpleContainer label="Multiselect input">
						<Select items={basicDropdownItems} multiple />
					</SimpleContainer>

					<SimpleContainer label="Checkbox">
						<Checkbox label="Checkbox" checked />
						<Checkbox label="Checkbox" disabled checked />
					</SimpleContainer>

					<SimpleContainer label="Radio">
						<Radio className="cursor-pointer mb-4" label="Radio 1" checked />
						<Radio label="Radio 2" checked disabled />
					</SimpleContainer>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Input Group">
						<InputGroup
							elements={[
								{ type: "text", content: "@", leftRounded: true },
								{
									type: "input",
									placeholder: "Username",
									inputType: "text",
									rightRounded: true,
								},
							]}
						/>

						<InputGroup
							elements={[
								{
									type: "input",
									leftRounded: true,
									placeholder: "Username",
									inputType: "text",
								},
								{ type: "text", content: "@site.com", rightRounded: true },
							]}
						/>

						<InputGroup
							elements={[
								{ type: "text", content: "$", leftRounded: true },
								{ type: "input", placeholder: "Enter Price", inputType: "text" },
								{ type: "text", content: ".00", rightRounded: true },
							]}
						/>
					</SimpleContainer>
					<SimpleContainer label="Other Input">
						<InputGroup
							elements={[
								{
									type: "input",
									leftRounded: true,
									placeholder: "Username",
									inputType: "text",
								},
								{ type: "label", text: "@" },
								{
									type: "input",
									rightRounded: true,
									placeholder: "Server",
									inputType: "text",
								},
							]}
						/>

						<InputGroup
							elements={[
								{ type: "select", options: ["$", "£", "€"] },
								{ type: "input", placeholder: "Price", inputType: "text" },
								{ type: "button", text: "Purchase", rightRounded: true },
							]}
						/>

						<InputGroup
							elements={[
								{
									type: "input",
									leftRounded: true,
									placeholder: "Search...",
									inputType: "text",
								},
								{ type: "button", text: "Dropdown", rightRounded: true },
							]}
						/>
					</SimpleContainer>
					<SimpleContainer label="Icon Input">
						<InputGroup
							elements={[
								{ type: "icon", leftRounded: true, icon: <NotificationIcon /> },
								{
									type: "input",
									rightRounded: true,
									placeholder: "Notification",
									inputType: "text",
								},
							]}
						/>

						<InputGroup
							elements={[
								{
									type: "input",
									leftRounded: true,
									placeholder: "Notification",
									inputType: "text",
								},
								{ type: "icon", rightRounded: true, icon: <NotificationIcon /> },
							]}
						/>
					</SimpleContainer>
					<SimpleContainer label="Button Input">
						<InputGroup
							elements={[
								{ type: "button", text: "Button", leftRounded: true },
								{
									type: "input",
									rounded: true,
									placeholder: "text",
									inputType: "text",
									rightRounded: true,
								},
							]}
						/>

						<InputGroup
							elements={[
								{
									type: "input",
									placeholder: "text",
									inputType: "text",
									leftRounded: true,
									rounded: true,
								},
								{ type: "button", text: "Button", rightRounded: true },
							]}
						/>
					</SimpleContainer>
				</div>

				{/* Form Validation */}
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Input Wrong" display="block">
						<TextInput
							label="User Name"
							validationType={TextInputValidationTypes.ERROR}
							validationMessage="Please fill the Name"
							placeholder="User name"
						/>
					</SimpleContainer>

					<SimpleContainer label="Input Success" display="block">
						<TextInput
							label="User Name"
							validationType={TextInputValidationTypes.SUCCESS}
							validationMessage="Looks Good!"
							value="Stevens"
							placeholder="User name"
						/>
					</SimpleContainer>

					<SimpleContainer label="Email Input" display="block">
						<TextInput
							flavor={TextInputFlavors.CLASSIC}
							validationType={TextInputValidationTypes.ERROR}
							validationMessage="Please fill the Name"
							placeholder="User name"
						/>
					</SimpleContainer>

					<SimpleContainer label="Email Input" display="block">
						<TextInput
							flavor={TextInputFlavors.CLASSIC}
							validationType={TextInputValidationTypes.SUCCESS}
							validationMessage="Looks Good!"
							value="Stevens@gmail.com"
							placeholder="User name"
						/>
					</SimpleContainer>
				</div>

				{/* Option Groups */}
				<a name="option_groups" />
				<OptionGroupsDemo />

				{/* Wizard Steps */}
				<div className="grid grid-cols-1 lg:grid-cols-1 gap-7 mt-7">
					<SimpleContainer label="Wizard Steps" display="block">
						<Stepper numberOfSteps={7} activeStep={2} />
					</SimpleContainer>
				</div>

			</Container>
		</Page>
	);
};
