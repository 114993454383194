import { useState } from "react";
import { FilterBar } from "@/ui/DataView/components/FilterBar/FilterBar";
import TableView from "@/ui/DataView/TableView";
import { DefaultDataProps } from "@/ui/DataView/types";
import { CalendarBlank, Notebook } from "@phosphor-icons/react";

import Modal from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { TextArea } from "@/Login/Onboarding/Onboarding";

import Panel from "@/ui/Panel";
import Select from "@/ui/Select";
import FileInput from "@/ui/CommunicationView/ChatConversation/FileInput";

export const CreateContentPopup = ({
  setIsCreateContentPopupOpen,
  unitName = "Post",
}) => {
  return (
    <Modal
      title={`Create New ${unitName}`}
      onClose={() => setIsCreateContentPopupOpen(false)}
      size={PopupSizes.XXXLARGE}
    >
      {/* <div className="flex flex-col gap-4 p-3"> */}
      <div className="grid md:grid-cols-12 gap-7 px-3 w-full">
        {/* <div className="flex flex-wrap gap-4"> */}
        <div className="flex flex-col md:col-span-8 gap-3">
          <TextInput
            flavor={TextInputFlavors.MODERN}
            label="Enter a title" // value={props.firstName}
            placeholder="Enter a title"
            name="title" // onChange={props.handleProfileChange}
          />
          <TextArea
            flavor={TextInputFlavors.MODERN}
            placeholder="Enter content"
          />
        </div>
        <div className="flex flex-col gap-3 md:col-span-4">
          <Panel title="Post Settings" className="flex flex-col gap-4">
            <p>Post Type</p>
            <Select
              items={[
                { label: "Blog Post" },
                { label: "Facebook Post" },
                { label: "Twitter Post" },
                { label: "Instagram Post" },
              ]}
            />
            <p>Categories</p>
            <Select
              items={[{ label: "Category 1" }, { label: "Category 2" }]}
              multiple
            />
          </Panel>

          <Panel title="Media" className="flex flex-col gap-4">
            {/* <TextInput
              flavor={TextInputFlavors.CLASSIC}
              label="Media (url)" // value={props.firstName}
              placeholder="Enter a url"
              name="url" // onChange={props.handleProfileChange}
            /> */}
            <FileInput />
          </Panel>
        </div>
      </div>
    </Modal>
  );
};

export const ContentDashboardPage = ({
  campaignData = {},
  unitName = "Post",
}) => {
  const [isCreateContentPopupOpen, setIsCreateContentPopupOpen] =
    useState(false);

  const content: DefaultDataProps[] = [...Array(8)].map((_, i) => ({
    id: "header",
    label: "Hello world!",
    icon: (
      <div className="w-6 h-6 p-1 bg-lightpurple-100 flex-none rounded-lg text-black text-center mr-2">
        <Notebook width="16" height="16" />
      </div>
    ),
    data: [
      {
        id: "size",
        label: "Categories",
        fieldType: "string",
        value: "Standard Blog Post",
      },
      {
        id: "type",
        label: "Author",
        fieldType: "string",
        value: "Karina Clark",
      },
      {
        id: "date",
        label: "Publish Date",
        fieldType: "date",
        icon: (
          <CalendarBlank
            width="16"
            height="16"
            className="dark:text-white/40"
          />
        ),
        value: "Today, at 9:40 am",
      },
    ],
  }));

  return (
    <div>
      {isCreateContentPopupOpen && (
        <CreateContentPopup
          setIsCreateContentPopupOpen={setIsCreateContentPopupOpen}
          unitName={unitName}
        />
      )}
      <FilterBar fields={[]} onAddClick={() => console.log("on Add Click")} />
      <TableView
        items={content}
        showFooterPagination
        showMenuItems
        menuItems={[
          { id: "download", label: "Open" },
          { id: "duplicate", label: "Duplicate" },
          { type: "divider" },
          { id: "delete", label: "Delete" },
        ]}
        onItemClick={(item) => setIsCreateContentPopupOpen(true)}
        onMenuItemClick={(item) => setIsCreateContentPopupOpen(true)}
        onNewItemClick={() => console.log("New item clicked")}
      />
    </div>
  );
};
