import Button from "@/ui/Button";
import Panel from "@/ui/Panel";
import { ButtonTypes } from "@/ui/types";
import { DownloadSimple, FilePdf } from "@phosphor-icons/react";
import { UploadItemButton } from "../Media";

export const MediaFiles = ({
  label = "Latest Files",
  uploadSection = false,
}) => {
  return (
    <Panel
      title={<h2 className="text-sm font-semibold mb-4">{label}</h2>}
      withItems
      items={Array.from({ length: 4 }).map((_, i) => ({
        icon: (
          <div className="w-6 h-6 mt-1 p-1 bg-lightpurple-100 flex-none rounded-lg text-black text-center">
            <FilePdf width="16" height="16" />
          </div>
        ),
        title: (
          <p className="text-sm font-normal">Project tech requirements!.pdf</p>
        ),
        description: "5.6 MB / 2 days ago Karina Clark",
        href: "#",
        button: (
          <button
            className="p-1 flex-none hidden group-hover:block"
            type="button"
          >
            <DownloadSimple width="16" height="16" />
          </button>
        ),
      }))}
      footer={
        uploadSection && {
          caption: `Drop files here or upload files`,
          actionComponent: <UploadItemButton type={ButtonTypes.SOFT} />,
        }
      }
    />
  );
};
