import Modal from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import Select from "@/ui/Select";
import { useEffect, useState } from "react";
import { createConnection, createObject, deleteConnection, getCampaign, getCampaigns, getObject, handleCreateNewPageFromTemplate, updateObject } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { traffic } from "@/Apps/Funnels/objects";
import Panel from "@/ui/Panel";
import { saveAnotherFunnelSettings } from "@/Apps/Funnels/CampaignBuilder/Nodes/AnotherFunnel/Properties";

export const EditTrackingLinkModal = ({ editDrawerIcon, campaignId, item = {}, onClose }) => {
  const [name, setName] = useState(item?.name);
  const [campaigns, setCampaigns] = useState([]);
  const [pages, setPages] = useState([]);
  const [thisCampaignsPages, setThisCampaignsPages] = useState([]);

  const [destinationChoice, setDestinationChoice] = useState<"nothing" | "url" | "campaign" | string>("nothing");
  const [destinationURL, setDestinationURL] = useState();

  const [campaignChoice, setCampaignChoice] = useState<number|string>(0);
  const [pageChoice, setPageChoice] = useState<number|string>(0);

  const [trackingSourceType, setTrackingSourceType] = useState();

  const handleSuccess = async () => {
    const token = getSessionToken();
    let obj = item;

    // if it's a brand new object, create it & make its settings the object to perform the following operations on
    if (!Boolean(obj?.id)) {
      // make x a random number between 0 and 500
      const x = Math.floor(Math.random() * 500);
      // const response = await createObject({ name, campaignId, x, y: 0 });
      // console.log({ response });
      // obj = response?.data?.createObject?.response;
      const { object, page } = await handleCreateNewPageFromTemplate({
        position: { x, y: 0 },
        campaignData: { id: campaignId },
        page: {
          ...traffic.find(({ id }) => id === "traffic_source_redirect"),
          name,
        },
      });

      console.log("handleCreateNewPageFromTemplate", { object, page });

      obj = object;
    }
    
    
    // if the destination choice is "nothing" disconnect the object
    if (destinationChoice === "nothing") {
      const response = await updateObject(obj?.id, {
        token,
        name,
        type: "RedirectComponent",
      });
      if (Boolean(obj?.connectTo?.length)) await deleteConnection({ source: obj?.id, target: obj?.connectTo[0]?.id });
      console.log("nothing", { response })
    }

    else if (destinationChoice === "url") {
      // delete any existing connections
      if (Boolean(obj?.connectTo?.length)) await deleteConnection({ source: obj?.id, target: obj?.connectTo[0]?.id });
      
      // update the object to be a redirect component with the chosen url
      const o = traffic.find(({ type }) => type === "RedirectComponent");
      const response = await updateObject(obj?.id, {
        token,
        name,
        type: "RedirectComponent",
        screenshot: o?.screenshot,
        settings: { url: destinationURL },
      });
      console.log("redirect response", { response });

    } else if (destinationChoice === "campaign") {

      // delete any existing connections
      if (Boolean(obj?.connectTo?.length)) await deleteConnection({source: obj?.id, target: obj?.connectTo[0]?.id });

      // update the object to be a funnel component with the chosen campaign and page
      const o = traffic.find(({ type }) => type === "AnotherFunnelComponent");
      const response = await updateObject(obj?.id, {
        token,
        name,
        type: "AnotherFunnelComponent",
        screenshot: o?.screenshot,
        settings: { campaignId: campaignChoice, objectId: pageChoice },
      });

      // update the page with the new settings
      await saveAnotherFunnelSettings({
        // sourceCampaignId: campaignId,
        sourceObject: obj,
        pages,
        targetCampaignId: campaignChoice,
        targetObjectId: pageChoice,
      });

      console.log("another funnel", { response });
    } else {
      // it's a traffic component
      // if the destination choice is an object id connect the object to a new object
      if (Boolean(obj?.connectTo?.length)) await deleteConnection({source: obj?.id, target: obj?.connectTo[0]?.id });
      await createConnection({ source: obj?.id, target: destinationChoice });
      const o = traffic.find(
        ({ type, id }) => type === "TrackingSource" && id === trackingSourceType
      );

      const response = await updateObject(obj?.id, {
        token,
        name,
        type: "TrackingSource",
        screenshot: o?.screenshot,
      });

      console.log("tracking source", { response });
    }

    window.location.reload();
  };

  const getPageObjectsFromCampaign = async (id) => {
    const response = await getCampaign({ token: getSessionToken(), id });
    const pages = response?.objects?.filter(({ type, deleted }) => {
      return (        
        type === "PageComponent" 
        || type == "RedirectComponent"
        || type == "SmartRouteComponent"
        || type == "ABSplitComponent"
        || type == "AnotherFunnelComponent"
      ) && !deleted;
    });
    // const pages = response?.objects?.filter(({ type, deleted }) => !deleted);
    return pages;
    // setPages(pages);
    // console.log(pages);
  };

  // load campaign pages when a campaign is chosen
  useEffect(() => {
    if (campaignChoice === 0) return;

    (async () => {
      const pages = await getPageObjectsFromCampaign(campaignChoice);
      setPages(pages);
    })();

  }, [campaignChoice]);

  useEffect(() => {

    // load campaigns
    (async () => {
      const response = await getCampaigns({ token: getSessionToken(), type: "" });
      setCampaigns(response.filter(({ deleted }) => !deleted));
    })();

    // load campaign pages
    (async () => {
      const pages = await getPageObjectsFromCampaign(campaignId);
      setThisCampaignsPages(pages);
    })();

    // if object is connected to another object, update choices to reflect that
    // if it's a redirect component, just set the destination to the url
    // if it's an "Another Funnel" component, set the destination to the campaign and page
    (async () => {

      if (item?.connectTo?.length) { 
        const response = await getObject(item?.connectTo[0].id);
        const { data } = response;
        const { object } = data;

        // console.log({ object });
        setDestinationChoice(object?.id)
      }

      // if it's a redirect component, just set the destination to the url
      if (item?.type === "RedirectComponent") {
        setDestinationChoice("url");
        setDestinationURL(item?.settings?.url);
      }

      // if it's an "Another Funnel" component, set the destination to the campaign and page
      if (item?.type === "AnotherFunnelComponent") {
        setDestinationChoice("campaign");
        setCampaignChoice(item?.settings?.campaignId);
        setPageChoice(item?.settings?.objectId);
      }

    })();

  }, []);

  return (
    <Modal
      icon={editDrawerIcon}
      title={`${Boolean(item) ? 'Edit' : 'Create'} Tracking Link`}
      size={PopupSizes.XLARGE}
      onClose={onClose}
      onSuccess={handleSuccess}
    >
      <div className="grid md:grid-cols-12 gap-7 px-3 w-full">
        <div className="flex flex-col md:col-span-8 gap-3">
          <TextInput
            flavor={TextInputFlavors.MODERN}
            label="Enter a name"
            placeholder="Enter a name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Select
            label="Choose a destination"
            items={[
              { id: "nothing", label: "Do nothing" },
              { id: "url", label: "Enter a custom url" },
              { id: "campaign", label: "Go to a page in one of my campaigns" },
              { id: "", label: "--------" },
              ...thisCampaignsPages
                .filter(({ id }) => id !== item?.id)
                .map(({ name, id }) => ({ label: name, id })),
            ]}
            onChange={(e) => setDestinationChoice(e.target.value)}
            value={String(destinationChoice)}
            flavor={TextInputFlavors.MODERN}
          />

          {destinationChoice === "campaign" && (
            <Select
              label="Choose a destination campaign"
              // items={[{ label: "Blog Post" }, { label: "Facebook Post" }]}
              items={[
                { label: "Choose Campaign", id: 0 },
                ...campaigns.map(({ name, id }) => ({ label: name, id })),
              ]}
              flavor={TextInputFlavors.MODERN}
              value={campaignChoice}
              onChange={(e) => setCampaignChoice(e.target.value)}
            />
          )}

          {destinationChoice === "campaign" && Boolean(campaignChoice) && (
            <Select
              label="Choose a campaign page"
              items={[
                { label: "Choose Page", id: 0 },
                ...pages.map(({ name, id }) => ({ label: name, id })),
              ]}
              flavor={TextInputFlavors.MODERN}
              value={pageChoice}
              onChange={(e) => setPageChoice(e.target.value)}
            />
          )}

          {destinationChoice === "url" && (
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label="Enter the destination url"
              placeholder="Enter the destination url"
              name="url"
              value={destinationURL}
              onChange={(e) => setDestinationURL(e.target.value)}
            />
          )}
        </div>
        {destinationChoice !== "campaign" && destinationChoice !== "nothing" && destinationChoice !== "url" && (
          <div className="flex flex-col gap-3 md:col-span-4">
            <Panel title="Link Type" className="flex flex-col gap-4">
              <Select
                items={[
                  { label: "Choose a type", id: "" },
                  ...traffic.filter(({ type }) => type === "TrackingSource").map(({ name, id }) => ({ label: name, id }))
                ]}
                value={trackingSourceType}
                onChange={(e) => setTrackingSourceType(e.target.value)}
              />
            </Panel>
          </div>
        )}
      </div>
    </Modal>
  );
};
