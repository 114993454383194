import React, { useEffect } from "react";
import { generatePlansFromPrompt } from "../api/planning";
// import { getSessionToken } from "../api/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/ui/Layout/Loading";
import { cloneCampaign, createCampaign, getCampaign, handleCreateNewPageFromTemplate, updateCampaign, updateObject } from "@/api/campaigns";
import { setActiveTabInLocalStorage } from "@/ui/TabsComponent/TabsComponent";
import { recommendFunnelFromContext } from "@/Apps/Launchpad/FunnelGroup";
import { AI_DOMAIN } from "@/env";
import { useCompletion } from "ai/react";
import { getCatalogItems, getFunnelsInCatalog } from "@/api/catalog";
import { createPage, getNewPageContent, makeSlug } from "@/api/pages";
import { uniqueId } from "lodash";

const getFunnelToClone = async (action, token, complete) => {

  if (action === "pages") {
    return null;
  } else {

    // create a new funnel based on these plans.
    let campaignId = "cjz0ij60j0ful07920stbw0r6"; // id of the "lead capture" campaign template
  
    // determine the funnel to clone
    const allFunnels = await getFunnelsInCatalog();
    const funnelResp = await recommendFunnelFromContext({ token, choices: allFunnels }, complete); 
  
    try {
      const funnelRespConverted = JSON.parse(funnelResp);
      const recommendedFunnels = allFunnels.filter(({ id }) => funnelRespConverted.includes(id));
      // console.log("recommendedFunnels", recommendedFunnels);
      const pickAnyOne = (arr) => arr[Math.floor(Math.random() * arr.length)];
      if (recommendedFunnels.length) campaignId = pickAnyOne(recommendedFunnels)["campaign"]["id"];
      // console.log("Recommended Funnel Chosen", campaignId);
      return campaignId;
    } catch (e) {
      console.log("Couldn't pick a funnel, so defaulting to lead funnel", e);
      return campaignId;    
    }

  }

}

const updatePageObjectsWithAIFlag = async (pageObjects, token) => {
  for (let i = 0; i < pageObjects.length; i++) {
    const object = pageObjects[i];
    console.log("object", object);

    const settings = object?.settings || {};
    console.log("settings", settings);

    const r = await updateObject(object.id, {
      token,
      settings: { ...settings.settings, withAI: true },
    });

    console.log("updateObject Response", r);
  }
}

const getPageContentFromPrompt = async ({ prompt, token }) => {
  const templates = await getCatalogItems();
  const lead_pages = templates.filter(itm => itm.categories === "lead");
  const pickAnyOne = (arr) => arr[Math.floor(Math.random() * arr.length)];
  const content = pickAnyOne(lead_pages)['page']['content']
  return content;
  // return getNewPageContent();
}

// export const Setup = () => {
//   useEffect(() => {
//     (async () => {
//       const res = await getPageContentFromPrompt({ prompt: "", token: "" })
//       console.log(res)
//     })()
//   }, [])
//   return (
//     <div>TEst</div>
//   )
// }

export const Setup = () => {
  // const [prompt, setPrompt] = React.useState(
  //   "My name is Kyle Graham (kjgraham@gmail.com).  Please help me generate a marketing plan for my business.  It's called 10 Minute Pages. (tenminutepages.com) and we're in Leander, TX."
  // );
  const [isLoading, setIsLoading] = React.useState(true);

  const { complete } = useCompletion({ api: `${AI_DOMAIN}/api/completion`, body: { type: "chat" } });

  const { token, prompt, action = "funnels", settings = {} } = useParams();
  const navigate = useNavigate();

  const doSetup = async (prompt) => {
    setIsLoading(true);

    // Generate profile plans based on prompts (calls the agent)
    const plannerProfiles = await generatePlansFromPrompt({ prompt, token});

    // Determine which campaign to base this one off of
    const campaignId = await getFunnelToClone(action, token, complete)

    // Clone a campaign around the selected campaign id
    let newCampaignId;
    if (action === "funnels") {
      const response = await cloneCampaign(campaignId, { token, name: "My First Campaign (Auto Generated)", });
      newCampaignId = response?.data?.cloneCampaign?.response?.id;
    } else if(action === "pages") {
      const name = "My First Page (Auto Generated)";
      const response = await createCampaign({ token, name, type: "page" });
      newCampaignId = response?.id;
      const settings = { name, campaignId: newCampaignId, theme: "lead", type: "lead"}
      const content = await getPageContentFromPrompt({ prompt, token });
      console.log("The content for the page", content);
      // const pageResponse = await createPage({ token, settings, slug: makeSlug(name), content }); // change to template content
      const pageResponse = await handleCreateNewPageFromTemplate({
        position: { x: 0, y: 0 },
        campaignData: { id: newCampaignId },
        page: {
          type: "PageComponent",
          pageType: "lead",
          name,
          slug: makeSlug(name),
          id: uniqueId(),
          content,
        }
      })
      console.log("The response from creating the page", pageResponse);
    }

    // Get the newly created campaign
    const campaignData = await getCampaign({ token, id: newCampaignId });
    const campaignObjects = campaignData?.objects || [];

    // loop through them and update the settings to have a withAI flag
    const pageObjects = campaignObjects.filter(({ type }) => type === "PageComponent");
    await updatePageObjectsWithAIFlag(pageObjects, token)
    

    // Now associate all the profiles we created with the new campaign
    await updateCampaign(newCampaignId, {
      token,
      settings: {
        settings: {
          ...(campaignData?.settings || {}),
          plannerProfiles,
        },
      },
    });

    // set cookie so that visual funnel builder tab is pre-selected
    setActiveTabInLocalStorage(
      `/${action}/${newCampaignId}`,
      action === "funnels" ? "builder" : "editor"
    );

    // open the new funnel (with ai)
    navigate(`/${action}/${newCampaignId}`);
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      const cleanedPrompt = decodeURIComponent(prompt);
      console.log(cleanedPrompt);
      doSetup(cleanedPrompt);
    }
  }, []);

  return (
    <div className="w-full flex-col gap-7 p-5 m-5 text-center flex">
      <h3 className="text-2xl">Please wait while we set up your account...</h3>
      {isLoading && <Loading type="gallery" />}
    </div>
  );
};

export default Setup;
