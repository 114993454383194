import { useEffect, useState } from "react";
import moment from "moment";

import { Panel } from "@/ui/Panel";
import { Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";
import { Button } from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { ListDisplayGroup } from "@/ui/ListDisplayGroup";
import { TabsComponent } from "@/ui/TabsComponent";

import { AddDomainModal, DeleteDomainModal, SSLModal } from "./Popups";
import { getDomainData } from "@/api/domains";
import { getSessionToken } from "@/api/auth";

export const DomainsDashboard = () => {
  const [isSSLModalShowing, setIsSSLModalShowing] = useState(false);
  const [isDeleteDomainModalShowing, setIsDeleteDomainModalShowing] =
    useState(false);
  const [isAddDomainModalShowing, setIsAddDomainModalShowing] = useState(false);

  const [domainData, setDomainData] = useState([]);
  const [activeDomainId, setActiveDomainId] = useState(null);

  const loadDomainData = async () => {
    const token = getSessionToken();
    const response = await getDomainData({ token });

    // console.log(response);
    if (response) {
      // filter out any domains with the extension .tenminutefunnels.com
      const filteredResponse = response.filter(({ name }) => {
        const domainNameSplit = name.split(".");
        const domainNameExtension = domainNameSplit[domainNameSplit.length - 2];
        return domainNameExtension !== "tenminutefunnels";
      });
      setDomainData(filteredResponse);
    }
  };

  useEffect(() => {
    loadDomainData();
  }, []);

  return (
    <Page topBar={{ page: [{ label: "Domain Names" }] }}>
      {isSSLModalShowing && (
        <SSLModal
          activeDomainId={activeDomainId}
          setIsOpen={setIsSSLModalShowing}
        />
      )}
      {isDeleteDomainModalShowing && (
        <DeleteDomainModal
          activeDomainId={activeDomainId}
          setIsOpen={setIsDeleteDomainModalShowing}
        />
      )}
      {isAddDomainModalShowing && (
        <AddDomainModal setIsOpen={setIsAddDomainModalShowing} />
      )}

      <Container>
        <TabsComponent
          tabs={[
            { label: "Profile", value: "profile" },
            { label: "Domain Names", value: "domains" },
            { label: "Integrations", value: "integrations" },
            { label: "Onboarding", value: "onboarding" },
          ]}
          activeTab={"domains"}
          setActiveTab={(val) => {
            window.location.href = `/${val}`;
          }}
          actionSection={false}
        />

        <Panel
          title="My Domain Names"
          actionButton={
            <Button
              type={ButtonTypes.SOFT}
              label="+ Add Domain Name"
              onClick={() => setIsAddDomainModalShowing(true)}
            />
          }
        >
          <ListDisplayGroup
            label="My Domain Names"
            caption="Funnel Settings"
            items={domainData.map(({ id, name, createdAt }) => ({
              id,
              // image: "/assets/images/product-1.png",
              title: name,
              caption: `Added ${moment(createdAt).fromNow()}`,
              status: { caption: `Ready`, type: Variants.PRIMARY },
            }))}
            menuItems={[
              {
                id: "secure_ssl",
                label: "Secure this domain (SSL)",
                href: "#",
                onClick: (id) => {
                  setActiveDomainId(id);
                  setIsSSLModalShowing(true);
                },
              },
              {
                id: "disconnect",
                label: "Disconnect this domain",
                href: "#",
                onClick: (id) => {
                  setActiveDomainId(id);
                  setIsDeleteDomainModalShowing(true);
                },
              },
            ]}
          />
        </Panel>
      </Container>
    </Page>
  );
};
