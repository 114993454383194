export const videos = [
  [
    "Smart Planner - Add Copy to a Page",
    "https://kjgraham.wistia.com/medias/o73m3p9skj",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Planner+-+Add+Copy+to+a+Page.mp4",
  ],
  [
    "Smart Planner - Onboarding",
    "https://kjgraham.wistia.com/medias/ylnub48976",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Planner+-+Onboarding.mp4",
  ],
  [
    "Smart Funnel Engine /Visual Funnel Builder (Content > TY Page)",
    "https://kjgraham.wistia.com/medias/i3p8xj5rhe",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/SmartFunnelEngine+(1).mp4",
  ],
  [
    "Funnel Vision - 1 (Sketch Pad)",
    "https://kjgraham.wistia.com/medias/svnointbs1",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Funnel+Vision+-+1.mp4",
  ],
  [
    "Funnel Vision - 2 (Sketch Pad-upload image)",
    "https://kjgraham.wistia.com/medias/hfhca6holo",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Funnel+Vision+-+2+(Sketch+Pad-upload+image)",
  ],
  [
    "Smart Funnels - Smart Route 1",
    "https://kjgraham.wistia.com/medias/hx6rliims8",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Funnels+-+1.mp4",
  ],
  [
    "Smart Funnels - Smart Route 2",
    "https://kjgraham.wistia.com/medias/jb358r9b9a",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Funnels+-+2.mp4",
  ],
  [
    "One Click AI (Launch with AI)",
    "https://kjgraham.wistia.com/medias/ewrfigdmqn",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/One+Click+AI.mp4",
  ],
  [
    "Smart Copy ",
    "https://kjgraham.wistia.com/medias/a3xlx9kfas",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Copy.mp4",
  ],
  [
    "Infinite Customizability",
    "https://kjgraham.wistia.com/medias/1ka18fbs7h",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Infinite+Customizability.mp4",
  ],
  [
    "Frameworks",
    "https://kjgraham.wistia.com/medias/j9i6faqpnj",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Frameworks.mp4",
  ],
  [
    "Launch OS Workshop (Promo Ending Tuesday)",
    "https://kjgraham.wistia.com/medias/7823d8ggnm",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Launch+OS+Workshop.mp4",
  ],
  [
    "Launch OS Workshop (Social Media) -Promo Ending Fiday, Kyle in cameria during intro and Q&A)",
    "https://kjgraham.wistia.com/medias/6o3f9o1n5v",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Launch+OS+Workshop+(Promo+Ending+Friday).mp4",
  ],
  [
    "Smart Copy Engine",
    "https://kjgraham.wistia.com/medias/7omoxh22hv",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Smart+Copy+Engine.mp4",
  ],
  [
    "Funnel Customization",
    "https://kjgraham.wistia.com/medias/lafbbeepgz",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/Funnel+Customization.mp4",
  ],
  [
    "SneakPeek",
    "https://kjgraham.wistia.com/medias/02bv0o97hw",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/SneakPeek.mp4",
  ],
  [
    "Onboarding Welcome",
    "https://kjgraham.wistia.com/medias/9fvpglpxs1",
    "https://ambid-backup-videos.s3.amazonaws.com/Launch+OS+Videos/LaunchOSOnboardingVideo.mp4",
  ],
];

export const videoLookup = (videoId = "") => {
  const found = videos.find((itm) => itm[1].includes(videoId));
  if (found) {
    return found[2];
  }
};
