import { getTokenInfo } from "@/api/auth";
import { supabase } from "@/db";
import { SupabaseClient } from "@supabase/supabase-js";

/**
 * Retrieves the onboarding data for a user.
 * @param token - The user's authentication token.
 * @returns A Promise that resolves & responds with the onboarding data.
 */
export const getOnboardingData = async (token, groupId, keys) => {
  // get the user id from the token in the local storage
  const { id } = await getTokenInfo(token);
  const userId = id;

  console.log("onboarding fetch", { userId, groupId, keys });

  let query: SupabaseClient<any, "public", any> | any = supabase;
  query = query.from("InformationEntities");
  query = query.select("*");
  query = query.eq("userId", userId);
  if (groupId) query = query.eq("group", groupId);
  if (keys) query = query.in("type", keys);

  const { data, error } = await query;

  // console.log("onboarding fetch", data, { userId, groupId, keys });
  // set the answers state
  if (error) return false;
  
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr.type]: curr.content }),
    {}
  );
};

// groupId = planner campaign id
export const saveOnboardingData = async (token, data, groupId) => {
  const { id } = await getTokenInfo(token);
  const userId = id;

  const entities = Object.entries(data).map(([type, content]) => ({
    userId,
    type,
    content,
    group: groupId,
  }));

  const { error } = await supabase.from("InformationEntities").upsert(entities);
  if (error) return false;

  return true;
}
