export const files = {
  "public/index.html": `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  <body>
    <div id="root"></div>
  </body>
</html>
`,
  "/src/styles.css": `
body {
  font-family: sans-serif;
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: auto;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1 {
  font-size: 1.5rem;
}`,
  "/src/index.js": `
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";

import App from "./App";
export { onListItems } from './App'

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
`,
  "/src/App.js": {
    active: true,
    code: `export default function App() {
  const currentDate = new Date().toLocaleDateString();
  return <h1>Hello Sandpack {currentDate}</h1>
}

export const onListItems = (hook) => {
  if (hook.id === "AppDrawer" && hook.position === "top") {
    return [{
      route: '/hello',
      label: 'Hello from Sandpack!',
    }]
  }
}
`,
  },
};
