import { Page, Container } from '../Page';

import { KPIWidget } from "@/ui/KPIWidget";

import {
	ProjectIcon,
	FinanceIcon,
	ClientsIcon,
} from "@/ui/Icons";

export const Widgets: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Widgets" }] }}>
			<Container>
				<p className="text-lg font-semibold">Widgets</p>

				{/* KPI Widgets */}
				<div className="py-5">

					<div className="grid grid-cols-1 gap-7">
						<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
							<KPIWidget
								color="lightblue"
								label="Views"
								number="721K"
								delta="+11.01%"
								deltaSign="POSITIVE"
							/>
							<KPIWidget
								color="lightpurple"
								label="Visits"
								number="367K"
								delta="+9.15%"
								deltaSign="POSITIVE"
							/>
							<KPIWidget
								color="lightblue"
								label="New Users"
								number="1,156"
								delta="+0.56%"
								deltaSign="POSITIVE"
							/>
							<KPIWidget
								color="lightpurple"
								label="Active Users"
								number="239K"
								delta="-1.48%"
								deltaSign="NEGATIVE"
							/>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-3 gap-7">
							<KPIWidget
								color="lightblue"
								label="Current Projects"
								number="237"
								icon={<ProjectIcon />}
							/>
							<KPIWidget
								color="lightpurple"
								label="Project Finance"
								number="$3,290"
								icon={<FinanceIcon />}
							/>
							<KPIWidget
								color="lightblue"
								label="Our Clients"
								number="49"
								icon={<ClientsIcon />}
							/>
						</div>
					</div>

				</div>
			</Container>
		</Page>
	);
};
