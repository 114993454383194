// Define a functional component called NavSection that takes in a children prop
export const NavSection = ({ children = "" }) => {
  // Return a JSX element that renders an h2 tag with some classes and styles
  return (
    <h2 data-testid="NavSection" className="pl-3 my-2 text-black/60 dark:text-white/40 text-sm">
      {/* Render the children prop inside a span tag */}
      <span>{children}</span>
    </h2>
  );
};
