import { CloudArrowUp, Upload } from "@phosphor-icons/react";
import { FileInputProps } from "../types";

export const FileInput: React.FC<FileInputProps> = ({
  caption = (
    <>
      <span
        className="font-semibold text-black/50
dark:text-white/50"
      >
        Click to browse
      </span>{" "}
      or drag & drop
    </>
  ), // The caption to display next to the file input
  instruction = "JPG or PNG only. Max size: 25 MB", // The instruction to display next to the file input
  icon, // The icon to display next to the file input
  onChange, // The function to call when the selected file(s) change
  accept = [], // The file types to accept
  multiple, // Whether to allow multiple files to be selected
}) => {
  // Function to handle when the selected file(s) change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handleFileChange called");
    if (onChange) {
      // Convert the FileList object to an array and call the onChange function with it
      onChange(Array.from(event.target.files));
    }
  };

  return (
    <div data-testid="FileInput">
      <section className="w-full rounded-3xl">
        <label
          htmlFor="file-input"
          className="flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dashed border-slate-300 bg-black-100 py-16 text-black/50 hover:bg-black/5 dark:border-slate-300/20 dark:bg-black/5 dark:text-white/50 dark:hover:bg-white/5"
        >
          <div className="flex flex-col items-center justify-center">
            {icon ? (
              <div data-testid="icon">{icon}</div>
            ) : (
              <div data-testid="icon">
                <Upload size={42} />
              </div>
            )}
            <p className="mb-2 text-sm">{caption}</p>
            <p className="text-xs">{instruction}</p>
          </div>
          {/* <input id="file-input" type="file" className="hidden" /> */}
          <input
            id="file-input"
            className="hidden"
            type="file"
            onChange={handleFileChange} // Call the handleFileChange function when the selected file(s) change
            accept={accept.join(",")} // Join the file types with commas to set the accept attribute
            multiple={multiple} // Allow multiple files to be selected if the multiple prop is true
          />
        </label>
      </section>
      {/* {icon && <div data-testid="icon">{icon}</div>} */}
    </div>
  );
};

export default FileInput;