import { Link } from "react-router-dom";
import { LogoProps } from "./types";

/**
 * The Logo component displays the application logo and provides a link to the home page.
 */
export const Logo: React.FC<LogoProps> = ({ href = "/", className = "" }) => {
  // The Logo component accepts two optional props: href and className.
  // href is the URL that the logo should link to.
  // className is the CSS class name to apply to the logo element.

  return (
    <div data-testid="Logo" className={`flex p-4 ${className}`}>
      {/* The logo is wrapped in a div with a flex layout and padding. */}
      <Link to={href} className="main-logo flex-1 w-full">
        {/* The logo is displayed as an image wrapped in a Link component. */}
        {/* There are three different images that can be displayed depending on the theme: */}
        {/* - A light logo for the default theme */}
        {/* - A dark logo for the dark theme */}
        {/* - A footer logo for the dark theme */}
        {/* <img
          src="https://medialibdata.s3.amazonaws.com/457/10MinuteFunnels_Logo_RGB.png"
          alt="logo-light"
          className="block dark:hidden"
        />
        <img
          src="https://medialibdata.s3.amazonaws.com/2/tmflogo_footer.png"
          alt="logo-dark"
          className="hidden dark:block"
        /> */}
      </Link>
    </div>
  );
};
