import * as React from "react";
import { LinkSelectorProps } from "./types";
import { GoToUrl } from "@/Apps/Pages/Properties/components/sections/BehaviorsAndActions/subselectors";
// import { GoToUrl } from "@launchos/modules/v2/Properties/components/sections/BehaviorsAndActions/subselectors";

/**
 * A component for collecting the page/url the user wants to link to
 */
const LinkSelector: React.FC<LinkSelectorProps> = ({
  onChange = () => null,
  onBlur = () => null,
  data = [
    { name: "Testing Content", slug: "testing", type: "content" },
    { name: "Testing Launch", slug: "testing", type: "launch" },
  ],
  label,
  value,
}) => {
  return (
    <div data-testid="FormBuilder-Attributes-LinkSelector">
      <GoToUrl
        action={{ payload: { urlPageName: value || label } }}
        updateAction={(action, payload, linkPayload) => {
          console.log({ action, payload, linkPayload });
          onChange(linkPayload.href);
          onBlur(linkPayload.href);
        }}
        data={data}
      />
    </div>
  );
};

export default LinkSelector;
