import FileInput from "@/ui/CommunicationView/ChatConversation/FileInput"
import Panel from "@/ui/Panel"

export const WebinarVideoUpload = ({ campaignData = {} }) => {
  return (
    <Panel title="Webinar Video">
      <FileInput
        instruction="Unlimited files, 5GB total limit"
      />
    </Panel>
  );
};