import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Sandcastle from "@/Apps/Sandcastle";
import Sandbox from "@/Apps/Sandcastle/Sandbox";
import { KitchenSink } from "@/ui/Layout";

import {
  Components,
  Elements,
  Charts,
  Widgets,
  Icons,
  DragDrop,
  Tables,
  Forms,
  Pages,
} from "@/ui/Layout/KitchenSink";

import Login from "@/Login";

import { Profile } from "@/Settings/Profile";
import { DomainsDashboard } from "@/Settings/DomainManagement";
import { Integrations } from "@/Settings/IntegrationManagement/Integrations";
import Launchpad from "@/Apps/Launchpad";

import Test from "@/Test";

import { HookTriggers, ListItemsHooks } from "./plugins/types";
import { triggerAsyncHook } from "./plugins/client";
import Onboarding from "./Login/Onboarding";
import { Scroll } from "./Apps/PFOC/Scroll";
import { AppContext } from "./AppContext";
import { OnboardingVideo } from "./OnboardingVideo";
import Setup from "./Login/Setup";
import { Full } from "./Apps/Pages/Editor/Editor.stories";

const AppRoutesFromPlugins = () => {
  const [routes, setRoutes] = React.useState([]);

  const contextObj = React.useContext(AppContext);

  const loadInAllAppRoutes = async () => {
    const routes = await triggerAsyncHook(
      HookTriggers.onListItems,
      {
        id: ListItemsHooks.ROUTES,
      },
      null,
      null,
      contextObj
    );

    setRoutes(routes);
  };

  React.useEffect(() => {
    loadInAllAppRoutes();
  }, [contextObj]);

  return routes.map(({ path, element, children = [] }, key) => (
    <Route key={key} path={path}>
      <Route index element={element} />
      {children.map(({ path, element }, key) => (
        <Route key={key} path={path} element={element} />
      ))}
    </Route>
  ));
};

export const AppRoutes = () => {
  const [onboardingIsShowing, setOnboardingIsShowing] = React.useState<boolean>(!Boolean(localStorage.getItem('dontShowWelcomeVideo')))
  return (
    <BrowserRouter>
      {onboardingIsShowing && <OnboardingVideo setOnboardingIsShowing={setOnboardingIsShowing} />}
      <Routes>
        <Route path="/setup/:action/:token/:prompt/:settings" element={<Setup />} />
        <Route path="/setup/:action/:token/:prompt" element={<Setup />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/" element={<Navigate to="/funnels" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sandcastle" element={<Sandcastle />} />
        <Route path="/sandcastleIDE" element={<Sandbox />} />
        <Route path="/launchpad">
          <Route index element={<Launchpad type="all" />} />
          <Route path="funnels" element={<Launchpad type="funnels" />} />
          <Route path="pages">
            <Route index element={<Launchpad type="pages" />} />
            <Route path=":id" element={<Launchpad type="pages" />} />
          </Route>
          <Route path="sites">
            <Route index element={<Launchpad type="sites" />} />
            <Route path=":id" element={<Launchpad type="sites" />} />
          </Route>
          <Route path="forms">
            <Route index element={<Launchpad type="forms" />} />
            <Route path=":id" element={<Launchpad type="forms" />} />
          </Route>
          <Route path="apps" element={<Launchpad type="apps" />} />
        </Route>
        {/* <Route path="/kitchenSink" element={<KitchenSink />} /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/domains" element={<DomainsDashboard />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/test" element={<Test />} />
        <Route path="/editor-test" element={<Full />} />
        <Route path="/ui">
          <Route index element={<KitchenSink />} />
          <Route path="components" element={<Components />} />
          <Route path="elements" element={<Elements />} />
          <Route path="charts" element={<Charts />} />
          <Route path="widgets" element={<Widgets />} />
          <Route path="icons" element={<Icons />} />
          <Route path="dragdrop" element={<DragDrop />} />
          <Route path="tables" element={<Tables />} />
          <Route path="forms" element={<Forms />} />
          <Route path="pages" element={<Pages />} />
        </Route>
        <Route path="/pfoc-scroll" element={<Scroll />} />
        {AppRoutesFromPlugins()}
      </Routes>
    </BrowserRouter>
  );
};
