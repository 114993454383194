import { Calout } from "@/ui/Calout";
import { Modal } from "@/ui/Modal";
import { deleteCampaign } from "@/api/campaigns";
import { useParams } from "react-router-dom";
import { getSessionToken } from "@/api/auth";

export const DeleteFunnelModal = ({
  setIsOpen,
  unitName,
  campaignData = {},
}) => {
  const { id } = campaignData || useParams();

  const handleFunnelDelete = async () => {
    const token = getSessionToken();
    const response = await deleteCampaign(id, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Page Deleted");
        setIsOpen(false);
        console.log("Delete funnel response", response);
        // window.location.href = `/funnels`;
        window.location.href = window.location.href.replace(id, "");
      }
    }
  };

  return (
    <Modal
      title={`Are you sure you want to delete this ${unitName.toLowerCase()}?`}
      onClose={() => setIsOpen(false)}
      onSuccess={handleFunnelDelete}
    >
      <Calout title="You won't be able to retrieve this once deleted" />
    </Modal>
  );
};
