import _ from "lodash";
import { Component } from "react";


import { ForwardParams, GoToUrl } from "./Payloads/GoToUrl";
import { ScrollToSection } from "./Payloads/ScrollToSection";
import { SubmitForm } from "./Payloads/SubmitForm";
import { DownloadFile } from "./Payloads/DownloadFile";
import { TriggerWebHook } from "./Payloads/TriggerWebHook";
import { ShowHide } from "./Payloads/ShowHide";
import { CreateTag } from "./Payloads/CreateTag";
import { PopupOptions } from "./Payloads/PopupOptions";
import { TriggerPurchase } from "./Payloads/TriggerPurchase";
import { TriggerUpsell } from "./Payloads/TriggerUpsell";
import { ToggleAddRemoveCart } from "./Payloads/ToggleAddRemoveCart";
import { SwitchTabs } from "./Payloads/SwitchTabs";
import { AddToCalendar } from "./Payloads/AddToCalendar";

import { ActionTypes } from './types'
import { decode } from "@/Apps/Pages/utils";


export class Payload extends Component {
    constructor(props) {
        super(props);
        this.updateAction = this.updateAction.bind(this);
    }

    updateAction(action, payload, newSettings = {}) {
        const { setAction, myKey } = this.props;
        setAction(myKey || 0, { ...action, payload }, action.behavior, newSettings);
    }

    render() {
        const {
          type = ActionTypes.DO_NOTHING,
          action = { payload: {} },
          match = { params: {} },
          getPageQuery = {},
        } = this.props;
        const { campaignId = "", nodeId = "" } = match?.params;

        const pageSettings = getPageQuery?.page?.pageSettings;

        const storedEmailPayload = _.get(pageSettings, "email.payload", false);
        const emailPayload = storedEmailPayload ? JSON.parse(decode(storedEmailPayload)) : false;

        switch (type) {

            case ActionTypes.GO_TO_URL:
                return <GoToUrl action={action} updateAction={this.updateAction} {...this.props} />

            case ActionTypes.TRIGGER_NEXT_NODE:
                return (
                    <ForwardParams action={action} updateAction={this.updateAction} payload={_.get(action, 'payload')} />
                )
            case ActionTypes.SCROLL_TO_SECTION:
                return <ScrollToSection action={action} updateAction={this.updateAction} {...this.props} />

            case ActionTypes.SUBMIT_FORM:
                return <SubmitForm action={action} updateAction={this.updateAction} emailPayload={emailPayload} {...this.props} />

            case ActionTypes.DOWNLOAD_FILE:
                return <DownloadFile action={action} updateAction={this.updateAction} {...this.props} />

            case ActionTypes.TRIGGER_WEBHOOK:
                return <TriggerWebHook action={action} updateAction={this.updateAction} {...this.props} />

            case ActionTypes.OPEN_POPUP:
                return <PopupOptions {...this.props} action={action} updateAction={this.updateAction} />

            case ActionTypes.TRIGGER_PURCHASE:
                return <TriggerPurchase campaignId={campaignId} nodeId={nodeId} />

            case ActionTypes.TRIGGER_UPSELL:
                return <TriggerUpsell campaignId={campaignId} nodeId={nodeId} />

            case ActionTypes.SHOW_AND_HIDE:
                return <ShowHide {...this.props} action={action} updateAction={this.updateAction} />

            case ActionTypes.CREATE_TAG:
                return <CreateTag {...this.props} action={action} updateAction={this.updateAction} />

            case ActionTypes.TOGGLE_ADD_REMOVE_TO_CART:
                return <ToggleAddRemoveCart {...this.props} action={action} updateAction={this.updateAction} />

            case ActionTypes.SWITCH_TABS:
                return <SwitchTabs {...this.props} action={action} updateAction={this.updateAction} />

            case ActionTypes.ADD_TO_CALENDAR:
                return <AddToCalendar {...this.props} action={action} updateAction={this.updateAction} />

            default:
                return null;
        }
    }
}


