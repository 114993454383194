import { FormFieldTypes } from "@/Apps/CRM/types";
import { FieldData } from "@/ui/DataView/types";
import type { Field, RuleType } from "react-querybuilder";
import { defaultOperators } from "react-querybuilder";

export const validator = (r: RuleType) => !!r.value;

export const getPlainEnglishOperators = (
  inputType: FieldData["fieldType"] = "string"
) => {
  const newDefaults = defaultOperators.map(({ name, label }) => {
    switch (name) {
      case "=":
        return { name, label: "is" };
      case "!=":
        return { name, label: "is not" };
      case "<":
        return { name, label: "is less than" };
      case "<=":
        return { name, label: "is less than or equal to" };
      case ">":
        return { name, label: "is greater than" };
      case ">=":
        return { name, label: "is greater than or equal to" };
      default:
        return { name, label };
    }
  });

  // type DefaultOperatorName = '=' | '!=' | '<' | '>' | '<=' | '>=' | 'contains' |
  // 'beginsWith' | 'endsWith' | 'doesNotContain' | 'doesNotBeginWith' | 'doesNotEndWith'
  // | 'null' | 'notNull' | 'in' | 'notIn' | 'between' | 'notBetween';

  if (inputType === "string") {
    return newDefaults.filter(
      ({ name }) =>
        name === "=" ||
        name === "!=" ||
        name === "contains" ||
        name === "doesNotContain" ||
        name === "beginsWith" ||
        name === "endsWith"
    );
  }

  if (inputType === "number") {
    return defaultOperators.filter(
      ({ name }) =>
        name === "=" ||
        name === "!=" ||
        name === "<" ||
        name === "<=" ||
        name === ">" ||
        name === ">=" ||
        name === "between" ||
        name === "notBetween"
    );
  }

  if (inputType === "date") {
    return newDefaults.filter(
      ({ name }) =>
        name === "=" ||
        name === "!=" ||
        name === "<" ||
        name === "<=" ||
        name === ">" ||
        name === ">=" ||
        name === "between" ||
        name === "notBetween"
    );
  }

  if (inputType === "boolean") {
    return newDefaults.filter(({ name }) => name === "=" || name === "!=");
  }

  if (inputType === "enum") {
    return newDefaults.filter(
      ({ name }) =>
        name === "=" || name === "!=" || name === "in" || name === "notIn"
    );
  }

  return newDefaults;
};

export const getFieldType = (field) => {
  const numberFields = ["daysAfterFirstVisit", "daysAfterLastVisit", "numPageVisits"];
  const stringFields = ["url"]; 
  const enumFields = ["tags"];
  const dateFields = ["currentDate", "startDate", "endDate"];
  const booleanFields = ["boolTest"];

  if (numberFields.includes(field)) return "number";
  if (stringFields.includes(field)) return "string";
  if (enumFields.includes(field)) return "enum";
  if (dateFields.includes(field)) return "date";
  if (booleanFields.includes(field)) return "boolean";
};

export const fields: Field[] = [
  {
    name: "currentDate",
    label: "Current Date",
    // placeholder: "Enter a Date",
    inputType: "date",
    operators: getPlainEnglishOperators("date"),
    defaultOperator: ">=",
    validator,
  },
  // {
  //   name: "startDate",
  //   label: "Start Date",
  //   placeholder: "Enter the Start Date",
  //   inputType: "datetime-local",
  //   operators: getPlainEnglishOperators("date"),
  //   validator,
  // },
  // {
  //   name: "endDate",
  //   label: "End Date",
  //   placeholder: "Enter the End Date",
  //   inputType: "datetime-local",
  //   operators: getPlainEnglishOperators("date"),
  //   validator,
  // },
  {
    name: "daysAfterFirstVisit",
    label: "# Days Since First Visit",
    placeholder: "Enter # Days Since First Visit",
    inputType: "number",
    operators: getPlainEnglishOperators("number"),
    defaultOperator: ">=",
    validator,
  },
  {
    name: "daysAfterLastVisit",
    label: "# Days Since Last Visit",
    placeholder: "Enter # Days Since Last Visit",
    inputType: "number",
    operators: getPlainEnglishOperators("number"),
    defaultOperator: ">=",
    validator,
  },
  {
    name: "numPageVisits",
    label: "The # Page Visits",
    placeholder: "Enter the # Page Visits",
    inputType: "number",
    operators: getPlainEnglishOperators("number"),
    defaultOperator: ">=",
    validator,
  },
  {
    name: "url",
    label: "The URL Address",
    placeholder: "Enter the URL",
    defaultOperator: "contains",
    operators: getPlainEnglishOperators(),
    validator,
  },
  // {
  //   name: "test",
  //   label: "Bool Test",
  //   placeholder: "Enter tool Test",
  //   operators: getPlainEnglishOperators("boolean"),
  //   validator,
  //   valueEditorType: "checkbox",
  // },
  // {
  //   name: "form",
  //   label: "The Incoming Form Submission",
  //   placeholder: "Enter the Incoming Form Submission",
  //   operators: getPlainEnglishOperators(),
  //   validator,
  // },
  {
    name: "tags",
    label: "The Visitor Tags",
    placeholder: "Enter the Visitor Tags",
    operators: getPlainEnglishOperators("enum"),
    defaultOperator: "contains",
    validator,
  },
  // {
  //   name: "collection",
  //   label: "The Collection",
  //   placeholder: "Enter the Collection",
  //   operators: getPlainEnglishOperators(),
  //   validator,
  // },
];
