import { Barcode, Receipt, ShoppingCartSimple } from '@phosphor-icons/react';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Products } from "./Products";
import { Offers } from "./Offers";
import { Orders } from './Orders';
import { Dashboard as OfferDashboard } from './Offers/Dashboard';
import { Dashboard as ProductDashboard } from './Products/Dashboard';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("cart")) return [];

    return [
      {
        icon: <Barcode className="text-lg" />,
        label: "Products",
        href: "/products",
      },
      {
        icon: <ShoppingCartSimple className="text-lg" />,
        label: "Offers",
        href: "/offers",
      },
      {
        icon: <Receipt className="text-lg" />,
        label: "Orders",
        href: "/orders",
      },
      // {
      //   label: "Cart",
      //   icon: <ShoppingCartSimple className="text-lg" />,
      //   href: "/cart",
      //   children: [
      //     {
      //       label: "Products",
      //       href: "/cart/products",
      //     },
      //     {
      //       label: "Offers",
      //       href: "/cart/offers",
      //     },
      //     {
      //       label: "Coupons",
      //       href: "/cart/coupons",
      //     },
      //     {
      //       label: "Funnels",
      //       href: "/cart/funnels",
      //     },
      //   ]
      // },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("cart")) return [];

    return [
      {
        label: "Products",
        path: "/products",
        element: <Products name="Products" />,
        children: [
          {
            path: ":id",
            element: <ProductDashboard name="Products" />,
          },
        ],
      },
      {
        label: "Offers",
        path: "/offers",
        element: <Offers name="Offers" />,
        children: [
          {
            path: ":id",
            element: <OfferDashboard name="Offers" />,
          },
        ],
      },
      {
        label: "Orders",
        path: "/orders",
        element: <Orders name="Orders" />,
        // children: [
        //   {
        //     path: ":id",
        //     element: <OrderDashboard name="Orders" />,
        //   },
        // ],
      },
    ];
  }
};