import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { getCampaign } from "@/api/campaigns";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";

import { Settings } from "@/Apps/Dashboards/Settings/Settings";

import { EditorCanvas } from "./Editor/EditorCanvas";
// import EditorCanvas from "@/ui/EditorCanvas";
import { getSessionToken } from "@/api/auth";
import { BrowserWrapper } from "./Editor/BrowserWrapper";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";

import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { getFilterObject } from "@/Apps/CRM/scripts";
import Modal, { Title } from "@/ui/Modal";
import { PopupPosition, PopupSizes } from "@/ui/Modal/types";
import Stepper from "@/ui/Stepper";
import { Loading } from "@/ui/Layout/Loading";
import { AppContext } from "@/AppContext";
import { SITE_DOMAIN } from "@/env";

const UpgradePopup = ({ setIsOpen }) => {
  const token = getSessionToken();

  return (
    <Modal
        title="Please Upgrade to Use This Feature"
        footer={false}
        position={PopupPosition.CENTERED}
        size={PopupSizes.FULLSCREEN}
        onClose={() => setIsOpen(false)}
        childrenStyle={{ padding: 0, margin: 0 }}
      >
        <iframe className="w-full h-full" src={`${SITE_DOMAIN}/upgrade?token=${token}`} />
    </Modal>

  )
}

const CongratsPopup = ({setIsOpen}) => {
  const [step, setStep] = useState(1);
  const steps = [
    {
      "title": "Your Page is Being Generated",
      "media": "",
      "caption": "Wait a moment while our AI generates your initial landing page based on your preferences. This custom starting point will help you create a unique page faster."
    },
    {
      "title": "Choose from Options",
      "media": "",
      "caption": "Browse through AI-generated variations of your page. Click on the option you prefer to select it as your starting point."
    },
    {
      "title": "Double-click to edit",
      "media": "",
      "caption": "To modify any element on your page, simply double-click it. This will open the editing interface where you can change text, images, or styles."
    },
    {
      "title": "Right-click to regenerate",
      "media": "",
      "caption": "If you're not satisfied with an element, right-click it and select 'Regenerate'. Our AI will create new variations for you to choose from."
    },
    {
      "title": "Publish to launch",
      "media": "",
      "caption": "Once you're happy with your landing page, click the 'Publish' button to make it live. You can always come back to edit or update your page later."
    }
  ]
  return (
    <Modal
      title="Welcome to Launch OS"
      footer={false}
      position={PopupPosition.CENTERED}
      onClose={() => setIsOpen(false)}
    >
      <div className="p-3">
        <div className="mx-auto w-4/5 mt-3">
          <Stepper activeStep={step} numberOfSteps={steps.length} />
        </div>

        <Title>{steps[step-1].title}</Title>

        {/* <Loading type="gallery"  /> */}
        <div>
          {steps[step-1].caption}
        </div>
        
        <div className="text-center mb-4">
          {step < steps.length && <Button label="Next ->" type={ButtonTypes.OUTLINED} onClick={() => setStep(step + 1)} />}
          {step === steps.length && <Button label="Finish" type={ButtonTypes.OUTLINED} onClick={() => setIsOpen(false)} />}
        </div>
      </div>
    </Modal>
  );
}

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "editor");
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isCongratsPopupOpen, setIsCongratsPopupOpen] = useState(false);
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const [campaignData, setCampaignData] = useState<{ name?: string }>({});
  const [pageId, setPageId] = useState<string | null>();

  // const pageEditorIframeRef = useRef<HTMLIFrameElement>(null);

  const { id } = useParams();
  const { permissions = [] } = useContext(AppContext);

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();
    console.log("From Pages", { permissions });

    (async () => {
      const data = await getCampaign({ token, id });
      console.log("campaign data", data);
      setCampaignData(data);
      setPageId(data?.objects[0]?.page?.id);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/pages" },
      { label: campaignData.name || "•••" },
    ],
    items: (
      <Button
        label="Publish"
        // onClick={() => setIsPublishPopupOpen(true)}
        onClick={() => {
          if (permissions.includes("freemium")) setIsUpgradePopupOpen(true);
          else setIsPublishPopupOpen(true);
        }}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          unitName="Page"
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
        />
      )}
      {isCongratsPopupOpen && (
        <CongratsPopup
          setIsOpen={setIsCongratsPopupOpen}
        />
      )}
      {isUpgradePopupOpen && (
        <UpgradePopup
          setIsOpen={setIsUpgradePopupOpen}
        />
      )}
      <div className={`p-6 w-full h-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              // { label: "Dashboard", value: "overview" },
              { label: "Editor", value: "editor" },
              { label: "Contacts", value: "contacts" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
        </div>
        {/* {activeTab === "overview" && <div>Your Content</div>} */}
        {activeTab === "editor" &&
          id &&
          pageId !== "" &&
          campaignData?.name && (
            <BrowserWrapper
              url={`https://${campaignData?.domain?.name}/${campaignData?.objects[0].page?.slug}`}
            >
              <EditorCanvas
                // ref={pageEditorIframeRef}
                campaignId={id}
                pageId={pageId}
                toShow="builder"
                token={getSessionToken()}
              />
            </BrowserWrapper>
          )}

        {activeTab === "contacts" && (
          <ContactsGallery
            onCreate={() => {}}
            defaultFilter={getFilterObject({
              field: "hiddenTags",
              operator: "contains",
              value: id,
            })}
          />
        )}
        {activeTab === "settings" && (
          <Settings campaignData={campaignData} unitName="Page" />
        )}
      </div>
    </Page>
  );
};
