import Margin from "../BasicProperties/Margin";
import Padding from "../BasicProperties/Padding";
import Section from "../Section";

const PositionPadding = (props) => (
  <Section
    label="Position & Padding"
    icon="import_export"
    isExpanded={props.isExpanded}
  >
    <Margin {...props} />
    <Padding {...props} />
  </Section>
);

export default PositionPadding;
