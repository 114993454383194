import ReactFlow, {
  Controls,
  ReactFlowProvider,
  applyNodeChanges,
} from "reactflow";
import { useState, useCallback, useEffect, useContext } from "react";
import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import {
  sampleContentData,
  sidebarData,
} from "@/Apps/Pages/Editor/Editor.stories";
import { IPropertyBuilderSettings } from "@/Apps/Pages/Properties/components/generator/types";
import Generator from "@/Apps/Pages/Properties/components/generator";

import { CampaignBuilderProps } from "@/ui/NodeCanvas/types";

import "reactflow/dist/style.css"; // Import the styles
import PageNode from "./PageNode";
import TemplateSelectionGroup from "@/Apps/Pages/Properties/components/sections/Appearance";

const nodeTypes = {
  page: PageNode,
};

export const VisualBuilder: React.FC<CampaignBuilderProps> = ({
  id,
  campaignData = {},
}) => {
  const [nodes, setNodes] = useState([
    {
      id: "1",
      type: "page",
      data: { label: "Hello Visual Builder!", content: sampleContentData },
      position: { x: 0, y: 100 },
    },
    {
      id: "2",
      type: "page",
      data: {
        label: "Hello Visual Builder!",
        content: sampleContentData.slice(1),
      },
      position: { x: 1650, y: 100 },
    },
    // {
    //   id: '3',
    //   type: 'page',
    //   data: { label: 'Hello Visual Builder!' },
    //   position: { x: 3300, y: 100 },
    // },
  ]);

  const [isPropertiesShowing, setIsPropertiesShowing] = useState(true);
  const [propertySections, setPropertySections] =
    useState<IPropertyBuilderSettings>(sidebarData);

  const [activeSettings, setActiveSettings] = useState({});

  // Create a memoized function to get and set activeSettings
  const activeSettingsHandler = useCallback(
    () => ({
      getActiveSettings: () => activeSettings,
      setActiveSettings,
    }),
    []
  );

  const onNodesChange = useCallback(
    (changes: any) =>
      setNodes((nds) => applyNodeChanges(changes, nds) as typeof nds),
    []
  );

  const { updateContent, updateComponent, updateComponentStyle } =
    useEditorActions();
  const updateComponentSettings = updateComponent;

  // Set Nodes from incoming campaign data
  useEffect(() => {
    const { objects } = campaignData;
    setNodes(
      objects
        .filter(({ type }) => type === "PageComponent")
        .map(({ id, page, settings }, key) => ({
          id,
          type: "page",
          data: {
            pageId: id,
            ...page,
            settings,
            activeSettingsHandler, // Pass the handler function instead
          },
          position: { x: key * 1650, y: 100 },
        }))
    );
  }, [campaignData, activeSettingsHandler]); // Add activeSettingsHandler to the dependency array

  return (
    <ReactFlowProvider>
      <div className="w-full h-screen flex relative z-0">
        <div className="pt-5 w-full">
          <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            maxZoom={7}
            minZoom={0.1}
            fitView
          >
            <Controls />
          </ReactFlow>
        </div>

        {isPropertiesShowing && (
          <div className="divide-y right-0 divide-black/10 dark:divide-white/10 border-l border-black/10 dark:border-white/10 h-full ml-2 overflow-y-auto w-[495px] bg-white dark:bg-black/80">
            {/* <button
              onClick={() =>
                setNodes([
                  {
                    id: "1",
                    type: "page",
                    data: {
                      label: "Hello Visual Builder!",
                      content: sampleContentData.slice(
                        Math.floor(Math.random() * 2)
                      ),
                    },
                    position: { x: 0, y: 100 },
                  },
                  {
                    id: "2",
                    type: "page",
                    data: {
                      label: "Hello Visual Builder!",
                      content: sampleContentData.slice(
                        Math.floor(Math.random() * 2)
                      ),
                    },
                    position: { x: 1650, y: 100 },
                  },
                  {
                    id: "3",
                    type: "page",
                    data: {
                      label: "Hello Visual Builder!",
                      content: sampleContentData.slice(
                        Math.floor(Math.random() * 2)
                      ),
                    },
                    position: { x: 3300, y: 100 },
                  },
                ])
              }
            >
              Update
            </button> */}
            {JSON.stringify(activeSettings)}
            {/* <TemplateSelectionGroup filter={{ category: "order" }} /> */}

            <Generator
              data={propertySections}
              settings={activeSettings}
              updateComponentStyle={updateComponentStyle}
              updateContent={updateContent}
              updateComponentSettings={updateComponent}
            />
          </div>
        )}
      </div>
    </ReactFlowProvider>
  );
};
