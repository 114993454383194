import { LoremIpsum } from "lorem-ipsum";
import { ISettings } from "./Editor/types";
import { get, keys, uniq } from "lodash";

import { useRef, useState, useLayoutEffect } from "react";

export const lorem: any = new LoremIpsum({
    sentencesPerParagraph: {
        max: 4,
        min: 3,
    },
    wordsPerSentence: {
        max: 16,
        min: 4,
    },
});

const objectDeepKeys = (obj) =>
    keys(obj)
        .filter((key) => obj[key] instanceof Object)
        .map((key) => objectDeepKeys(obj[key]).map((k) => `${key}.${k}`))
        .reduce((x, y) => x.concat(y), Object.keys(obj));

export const generateFontList: (content: ISettings[]) => string[] = (content) =>
    uniq(
        objectDeepKeys(content)
            .filter((itm) => itm.indexOf(".fontFamily") > -1)
            .map((key) => get(content, key))
    );


export const useDimensions = () => {
    const ref = useRef<HTMLHeadingElement>(null);
    const [dimensions, setDimensions] = useState({});

    const domRectToObject = (rect: DOMRect) => {
        return {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            top: rect.top,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left
        };
    }
    
    useLayoutEffect(() => {
        if (ref.current) {
            // setDimensions(ref.current.getBoundingClientRect().toJSON());
            setDimensions(domRectToObject(ref.current.getBoundingClientRect()));
        }
    }, [ref.current]);

    return [ref, dimensions];
};

export const cancelActions = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    return false;
};

export const encode = (s, k) => btoa(btoa(btoa(s)));
export const decode = (s, k) => atob(atob(atob(s)));