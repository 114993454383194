import { ListDropdownItem } from "@/ui/ListDropdown/types";
import { renderWidget } from "./renderWidget";
import { OverviewProps } from "./types";

export const DashboardPage: React.FC<OverviewProps> = ({
  campaignData = {},
  unitName = "page",
  panelTitle = `Pages in this funnel`,
  id,
  widgets = [
    // "details",
    "kpi",
    // "customize",
    // "share",
    // "contacts",
    {
      type: "pages",
      settings: {
        getDropDownItems: (items: ListDropdownItem[]) => items,
      },
    },
    // {
    //   type: "columns",
    //   settings: { columns: 2, widgets: ["timeline", "media"] },
    // },
    // "spending",
  ],
}) => {
  return (
    <div className={"flex flex-col gap-7 h-full"}>
      {widgets.map((widget, key) => {
        return (
          <span key={key} className="">
            {renderWidget(widget, { campaignData, unitName, panelTitle, id })}
          </span>
        );
      })}
    </div>
  );
};
