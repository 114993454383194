import { Lightbulb } from '@phosphor-icons/react';
import { TrackingCampaigns } from './TrackingCampaigns';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { TrackingLinks } from './widgets/TrackingLinks';
import { KPIBlock } from './widgets/KPIBlock';
import { Dashboard } from './Dashboard';

export const onListItems = (hook: HookObject, payload, _, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("insights")) return [];
    return [
      {
        label: "Tracking",
        icon: <Lightbulb className="text-lg" />,
        href: "/tracking",
        order: 7,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("insights")) return [];
    return [
      {
        path: "/tracking",
        element: <TrackingCampaigns name="Tracking Campaigns" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Tracking Campaigns" />,
          },
        ],
      },
    ];
  }

  if (hook.id === ListItemsHooks.WIDGET) {
    // console.log("WIDGETS!!", hook, payload, permissions);
    if (!permissions.includes("insights")) return [];

    const {
      campaignData,
      // setCampaignData,
      unitName = "Campaign",
      // id,
    } = payload?.settings;

    if (hook.type === "kpi") {
      // return <>Hello WOrld! {JSON.stringify(payload?.settings)}</>
      return <KPIBlock {...payload?.settings} />;
    }

    if (hook.type === "tracking") {
      return <TrackingLinks campaignData={campaignData} unitName={unitName} />;
    }

  }
};


export { TrackingCampaigns };