import { Link } from "react-router-dom";
import { DotsThree } from "@phosphor-icons/react";
import { useState } from "react";
import { ListDropdownProps } from "./types";

// Define a new React functional component called ListDropdown

export const ListDropdown: React.FC<ListDropdownProps> = ({ 
  items = [], 
  element = <DotsThree className="mt-1" />, 
  forId,
  style = {},
  onItemSelect,
}) => {
  // Define a new state variable called isOpen and initialize it to false
  const [isOpen, setIsOpen] = useState(false);

  // Define a new function called toggleDropdown that toggles the isOpen state variable
  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Render the dropdown component
  return (
    <div data-testid="Dropdown" className="dropdown text-right">
      {/* Render a link that toggles the dropdown when clicked */}
      <Link to="#" onClick={toggleDropdown}>
        {element}
      </Link>

      {/* Render the dropdown menu if isOpen is true */}
      {isOpen && (
        <ul className="right-0" style={{ width: 250, ...style }}>
          {/* Map over the items array and render each item as a list item */}
          {items.map((item, key) => {
            const { label, href, type, onClick } = item;

            // If the item type is "divider", render a divider element
            if (type === "divider") {
              return (
                <li role="menuitem"
                  key={key}
                  className="h-px dark:bg-white/5 bg-black/5 block my-1"
                ></li>
              );
            }

            // Otherwise, render a link element with the item label and href
            return (
              <li
                role="menuitem"
                key={key}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onItemSelect && onItemSelect(item);
                  onClick && onClick(forId);
                  setIsOpen(false);
                }}
              >
                {href ? (
                  <Link to={href}>{label}</Link>
                ) : (
                  <div className="px-4 py-2 cursor-pointer">{label}</div>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
