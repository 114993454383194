import { useState } from "react";
import { CheckboxProps } from "./types";

// Define the Checkbox component as a functional component that takes in CheckboxProps as props
export const Checkbox: React.FC<CheckboxProps> = ({ label, disabled = false, checked = false }) => {
  // Declare a state variable isChecked and initialize it with the value of the checked prop
  const [isChecked, setIsChecked] = useState(checked);

  // Render the label and checkbox input elements
  return (
    <label
      // Set the data-testid attribute to "Checkbox"
      data-testid="Checkbox"
      // Add some styles to the label element
      className="flex items-center cursor-pointer mb-4"
    >
      <input
        // Set the type attribute to "checkbox"
        type="checkbox"
        // Add some styles to the input element
        className="form-checkbox"
        // Set the checked attribute to the value of the isChecked state variable
        checked={isChecked}
        // Add an onChange event listener that updates the isChecked state variable when the checkbox is clicked
        onChange={() => !disabled && setIsChecked(!isChecked)}
        // Set the disabled attribute to the value of the disabled prop
        disabled={disabled}
      />
      {/* Render the label text */}
      <span>{label}</span>
    </label>
  );
};