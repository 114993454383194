import { useState } from "react";
import { Avatar } from "@/ui/Avatar/Avatar";
import { Tag } from "./Tag";

interface CardProps {
  title?: string;
  backgroundImage: string;
  price: number;
  description?: string;
  className?: string;
  customStyles?: Record<string, string>;
  tags?: string[];
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  title = "by Ten Minute Funnels",
  backgroundImage,
  price,
  description = "A simple landing page for a SaaS product.",
  className = "",
  customStyles = {},
  tags = [],
  onClick = () => null,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    minHeight: "180px",
    backgroundPosition: isHovered ? "center bottom" : "top",
    marginTop: isHovered ? "-5px" : "0px",
    ...customStyles,
  };

  return (
    <div className="mb-7" onClick={onClick}>
      <div
        className={`bg-white cursor-pointer dark:shadow-3xl dark:bg-white/5 rounded-2xl p-4 mb-3 border border-black/10 dark:border-white/10 ${className}`}
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {price && <Tag key="price">{price}</Tag>}

        {tags.map((tag, key) => (
          <Tag key={key}>{tag.toUpperCase()}</Tag>
        ))}

        {children}
      </div>
      <Avatar
        type="Text"
        src="https://flowbite.com/docs/images/logo.svg"
        label={title}
        caption={description}
      />
    </div>
  );
};
