import Gravatar from "react-gravatar";
import { AvatarProps } from "./types";

// Define Avatar component
export const Avatar: React.FC<AvatarProps> = ({
  label, // Label to display
  caption, // Caption to display
  email, // Email address to use for Gravatar
  type = "Rounded", // Type of avatar to display
  src = "/assets/images/avatar-1", // Source URL for image avatar
  srcList = [], // List of source URLs for group avatars
  extraCount, // Number of extra avatars to display in group avatar
  height = "24", // Height of avatar
  width = "24", // Width of avatar
  color = "bg-indigo-300", // Background color of default avatar
  className = "", // Additional classes to apply to avatar
  style = {}, // Additional styles to apply to avatar
}) => {
  // Determine class for avatar type
  let classType = "rounded-full";
  if (type === "Rounded") classType = "rounded-full";
  if (type === "Square") classType = "rounded-md";

  // Create Gravatar component
  const gr = (
    <Gravatar
      className={`h-${height} w-${width} flex-none ${classType} object-cover`}
      email={email}
    />
  );

  // Render group avatar
  if (type === "Group") {
    return (
      <div className={`flex items-center justify-start -space-x-4 ${className}`} style={style}>
        {srcList.map((src, key) => (
          <img
            key={key}
            src={src}
            className="h-10 w-10 flex-none rounded-full ring-2 ring-white dark:ring-black object-cover"
            alt="avatar"
          />
        ))}
        {/* {Boolean(parseInt(extraCount)) && ( */}
        {extraCount && (
          <span className="flex justify-center items-center w-10 h-10 text-center rounded-full object-cover bg-lightpurple-100 dark:text-black text-xs ring-2 ring-white dark:ring-black">
            {extraCount}
          </span>
        )}
      </div>
    );
  }

  // Render text avatar
  if (type === "Text") {
    return (
      <div
        className={`flex items-center space-x-2 ${className}`}
        style={style}
      >
        {Boolean(email) ? (
          <div className="w-10 h-10 rounded-full">{gr}</div>
        ) : (
          <img className="w-10 h-10 rounded-full" src={src} alt="" />
        )}
        <div className="font-medium">
          <h6 className="text-sm">{label}</h6>
          <p className="text-xs text-black/40 dark:text-white/40">{caption}</p>
        </div>
      </div>
    );
  }

  // Render default avatar
  if (type === "Default" || type === "DefaultSquare") {
    return (
      <div
        role="div"
        className={`h-12 w-12 ${type === "Default" && "rounded-full"} ${
          type === "DefaultSquare" && "rounded-md"
        } flex items-center justify-center font-semibold ${color} text-base uppercase text-black ${className}`}
        style={style}
      >
        {label}
      </div>
    );
  }

  // Render Gravatar or image avatar
  return (
    <>
      {Boolean(email) ? (
        gr
      ) : (
        <img
          src={src}
          className={`h-${height} w-${width} flex-none ${classType} object-cover`}
          alt="avatar"
        />
      )}
    </>
  );
};