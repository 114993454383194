import { Broadcast } from '@phosphor-icons/react';
import { Webinar } from './Webinar';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("webinar")) return [];
    return [
      {
        label: "Webinars",
        icon: <Broadcast className="text-lg" />,
        href: "/webinar",
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("webinar")) return [];
    return [
      {
        path: "/webinar",
        element: <Webinar name="Webinar" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Webinar" />,
          },
        ],
      },
    ];
  }
};


export { Webinar };