import Dropdown from "@/ui/Dropdown";
import { DropDownProps } from "@/ui/Dropdown/types"; 

interface DropdownGroupProps extends DropDownProps {
    label?: string;
    isExpanded?: boolean;
    onClick?: () => void;
    onChange?: () => void;
    children?: JSX.Element | JSX.Element[];
}

export const DropdownGroup: React.FC<DropdownGroupProps> = ({
    label = "Settings",
    isExpanded = true,
    value,
    items = [],
    onChange = () => { },
    children,
}) => {
    return (
        <div className="rounded-lg dark:hover:bg-white/5 hover:bg-black/5 cursor-pointer">
            <div className="flex items-center justify-between p-3">
                <h3 className="text-sm font-medium">{label}</h3>
                <Dropdown
                    label={
                        items.filter((itm) => itm.id === value)[0]?.label || "Choose..."
                    }
                    items={items}
                    onClick={onChange}
                />
            </div>
            {isExpanded && <p className="text-xs text-gray-500 px-3">{children}</p>}
        </div>
    );
};
