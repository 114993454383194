import { CSSProperties, FC } from "react";


export const StatsIcon: FC<{ color: string; style: CSSProperties }> = ({
  color,
  style,
  data = {},
}) => {
  const { visits, pageviews, avg_session_sec, bounce_rate } = data;
  return (
    <div
      style={{ ...style, backgroundColor: color, fontSize: "20pt" }}
      className="p-3 bg-black/5 dark:bg-white/5 text-md rounded-full flex justify-center items-center"
    >
      <div className="text-white">{pageviews || 0}</div>
    </div>
  );
};
