import * as React from "react";
import { debounce } from "lodash";

// import Icon, { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";

import { IconSelectorProps } from "./types";
import style, { LI } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";
import Panel from "@/ui/Panel";

export const treat = (str) =>
  str.replace(/^[a-z]|[A-Z]/g, (v, i) =>
    i === 0 ? v.toUpperCase() : " " + v.toLowerCase()
  );

/**
 * Shows a list of icons that the user can select from.
 *
 * Includes tools to help them search and filter
 */
const IconSelector: React.FC<IconSelectorProps> = ({
  onChange,
  containerStyle,
  isExpanded = true,
  icon = "",
  showNoneSelector = false,
  initialSearchString = "",
}) => {
  const [searchString, setSearchString] =
    React.useState<string>(initialSearchString);
  const [activeIcon, setActiveIcon] = React.useState<string>(icon);
  const [icons, setIcons] = React.useState<string[]>([]);
  const [allIcons, setAllIcons] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(false);
  const [isIconListExpanded, setIsExpanded] =
    React.useState<boolean>(isExpanded);
  const [doDebounce] = React.useState(() =>
    debounce(() => setReload(true), 500)
  );

  React.useEffect(() => {
    if (reload) {
      const results = allIcons.filter(
        (itm) =>
          itm.toUpperCase().indexOf(searchString.toUpperCase()) > -1 ||
          itm.toUpperCase().indexOf(treat(searchString).toUpperCase()) > -1
      );
      setIcons(results);
      setReload(false);
      setLoading(false);
    }
  }, [reload]);

  React.useEffect(() => {
    const all = Object.keys(IconTypes).filter(
      (itm) =>
        itm.indexOf("Outlined") === -1 &&
        itm.indexOf("Rounded") === -1 &&
        itm.indexOf("Sharp") === -1 &&
        itm.indexOf("TwoTone") === -1
    );
    setAllIcons(all);
    setReload(true);
  }, []);

  return (
    <Panel title="Choose an icon">
      <TextInput
        // type="text"
        type={TextInputTypes.TEXT}
        flavor={TextInputFlavors.MODERN}
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
          setLoading(true);
          setIcons([]);
          doDebounce();
        }}
        onClick={() => setIsExpanded(true)}
        placeholder="Search for an icon..."
        style={style.input}
        data-testid="Properties-IconSelector-input"
      />
      <div className="mt-4">
        {loading && (
          <div style={{ padding: 20, textAlign: "center" }}>
            Loading Icons...
          </div>
        )}
        {!loading && (isIconListExpanded || searchString.length) ? (
          <ul
            style={{ ...style.UL, ...containerStyle }}
            data-testid="Properties-IconSelector"
          >
            {showNoneSelector && (
              <li
                key={0}
                className={`
                  inline-block w-[100px] h-[110px] p-2.5 border-2 m-0 
                  text-center overflow-y-hidden overflow-x-hidden cursor-pointer 
                  text-black/70 dark:text-white/70 rounded
                  ${icon === IconTypes.CancelPresentationTwoTone
                    ? 'border-primary-500 bg-gray-200 dark:bg-black/70 border-black dark:border-white/30' 
                    : 'border-transparent hover:bg-gray-200 hover:dark:bg-black/70 hover:border-black/70 hover:dark:border-white/30'}
                `}
                onClick={() => {
                  setActiveIcon(IconTypes.CancelPresentationTwoTone);
                  onChange(IconTypes.CancelPresentationTwoTone);
                }}
              >
                <Icon
                  type={IconTypes.CancelPresentationTwoTone}
                  style={style.iconStyle}
                />
                <p>None</p>
              </li>
            )}
            {icons.map((icon, key) => (
              <li
                key={key}
                className={`
                  inline-block w-[100px] h-[110px] p-2.5 border-2 m-0 
                  text-center overflow-y-hidden overflow-x-hidden cursor-pointer 
                  text-black/70 dark:text-white/70 rounded
                  ${icon === IconTypes[activeIcon] 
                    ? 'border-primary-500 bg-gray-200 dark:bg-black/70 border-black dark:border-white/30' 
                    : 'border-transparent hover:bg-gray-200 hover:dark:bg-black/70 hover:border-black/70 hover:dark:border-white/30'}
                `}
                onClick={() => {
                  setActiveIcon(IconTypes[icon]);
                  onChange(IconTypes[icon]);
                }}
                data-testid="Properties-IconSelector-icon"
              >
                <Icon type={IconTypes[icon]} style={style.iconStyle} />
                <p>{treat(icon)}</p>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </Panel>
  );
};

export default IconSelector;
