import * as React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import { ItemComponentTypes, ItemComponentProps } from "./types";
import theme from "@/ui/theme";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: 18,
    },
  },
  input: {
    border: "none",
  },
}))(InputBase);

export const DefaultItemComponent: React.FC<ItemComponentProps> = ({
  type = ItemComponentTypes.TEXT,
  caption = "",
  isHovering,
  isExpanded,
  containerStyle = {
    position: "absolute",
    marginTop: -41,
    marginLeft: 12,
    // width: 240,
  },
  inputStyle = {
    padding: "2px 10px",
    border: "none",
    width: "100%",
    backgroundColor: "transparent",
    fontSize: "12pt",
  },
  data = [],
  icon,
  placeholder,
  children,
  onAdd = () => null,
  onBlur = () => null,
  onChange = () => null,
  onMouseEnter = () => null,
  onMouseMove = () => null,
  onMouseLeave = () => null,
  toggleExpansion,
}) => {
  const color = isExpanded ? theme.tertiaryColor : "inherit";
  const label =
    caption && caption.length > 25 ? `${caption.substring(0, 22).trim()}...` : caption;

  const styles: { wrap: React.CSSProperties } = {
    wrap: {
      padding: "2px 10px",
      fontWeight: isExpanded ? "bold" : "normal",
      color,
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        style={{
          ...containerStyle,
          cursor: toggleExpansion ? "pointer" : "inherit",
        }}
        // className="w-full p-2.5 m-1 mr-3 border rounded border-black/10 dark:border-white/10 hover:bg-black/5 hover:dark:bg-white/10"
        onClick={toggleExpansion}
      >
        <div style={{ display: "flex" }}>
          <Icon
            type={icon || IconTypes.Cancel}
            style={{ color: isHovering ? "transparent" : color }}
          />

          {type === ItemComponentTypes.INPUT && (
            <input
              type="text"
              style={styles.wrap}
              placeholder={placeholder || "Enter an item"}
              onClick={(e) => e.stopPropagation()}
              onKeyUp={(e) => {
                if (e.which === 13) {
                  onAdd();
                }
              }}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => onBlur(e.target.value)}
              defaultValue={label}
            />
          )}
          
          {type === ItemComponentTypes.TEXT && (
            <span
              style={{
                ...styles.wrap,
                color: isHovering ? theme.primaryColor : color,
                fontWeight: isHovering || isExpanded ? "bold" : "normal",
              }}
            >
              {label}
            </span>
          )}

          {type === ItemComponentTypes.DROPDOWN && (
            <FormControl
              style={{ width: "90%", marginTop: -20, marginLeft: 10 }}
            >
              <InputLabel id="demo-simple-select-label">
                {label || placeholder || "Select an item..."}
              </InputLabel>
              <Select
                // labelId="demo-simple-select-filled-label"
                // id="demo-simple-select-filled"
                // value={age}
                onChange={(e) => {
                  onChange(e.value);
                  onBlur(e.value);
                }}
                style={{ width: "100%" }}
                input={<BootstrapInput />}
              >
                {data.map(({ label, value, icon, image }, key) => (
                  <MenuItem value={value} key={key}>
                    <div style={{ display: "flex" }}>
                      {icon && <Icon type={icon} />}
                      {image && (
                        <img style={{ height: 30 }} src={image} alt="" />
                      )}
                      {label && <div style={{ padding: 8 }}>{label}</div>}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
};
