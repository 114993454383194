import { CircleDashed } from "@phosphor-icons/react";
import { Webhook } from "./Webhook";
import { updateObject } from "@/api/campaigns";
import { Variants } from "@/ui/types";
import { ExternalEvent } from "./ExternalEvent";
import { Email } from "./Email";
import { Wait } from "./Wait";
import { Date } from "./Date";
import { useContext } from "react";
import { AppContext } from "@/AppContext";

const Tag = () => {
  return <div>Properties for Tag</div>;
};

const NewContact = () => {
  const { darkMode } = useContext(AppContext);
  return <div className="items-center flex flex-col p-10">
    <img src={darkMode ? "/assets/images/maintanence-dark.svg" : "/assets/images/maintanence.svg"} />
    <h2 className="text-lg p-5">Contacts added to this workflow will receive this sequence.</h2>
  </div>;
};

export const Properties = ({
  campaignId = "",
  nodeData = {},
  // setNodes,
  // nodes,
  setEditDrawerOpen,
}) => {
  const { data } = nodeData;
  const { id, settings } = data || {};
  const { type } = settings;

  const handleUpdatePayload = async (payload) => {
    await updateObject(nodeData?.id, {
      settings: { ...settings, payload: { ...settings?.payload, ...payload } },
    });

    // setEditDrawerOpen(false);
    window.location.reload();
  }

  // console.log("Properties", { type, nodeData });

  if (type === "send_email") return <Email objectData={nodeData} campaignId={campaignId} setEditDrawerOpen={setEditDrawerOpen} />;
  if (type === "Tag") return <Tag />;
  if (type === "new_contact") return <NewContact />;
  if (type === "external_event") return <ExternalEvent campaignId={campaignId} nodeId={nodeData?.id} />
  if (type === "wait") return <Wait objectData={nodeData} setEditDrawerOpen={setEditDrawerOpen} onSave={handleUpdatePayload} />;
  if (type === "date") return <Date objectData={nodeData} setEditDrawerOpen={setEditDrawerOpen} onSave={handleUpdatePayload} />
  if (type === "trigger_webhook")
    return (
      <Webhook
        data={{ ...nodeData?.data }}
        onClose={() => setEditDrawerOpen(false)}
        onSave={handleUpdatePayload}
      />
    );

  return (
    <div className="text-center p-5 items-center grid grid-flow-row h-[275px]">
      <div className="text-gray-400">
        <CircleDashed className="text-center text-5xl mx-auto mb-3" />
        No Properties Available for this {type} Node.
        <p>Select a different object on the canvas</p>
      </div>
    </div>
  );
};
