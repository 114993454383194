/**
 * Interface for the BigCheck component props
 */
export interface BigCheckProps {
  /**
   * The label for the checkbox
   */
  label: string;

  /**
   * An optional caption for additional information
   */
  caption?: string;

  /**
   * The function to run when the checkbox is clicked
   */
  onClick?: () => void;

  /**
   * The initial checked state of the checkbox
   */
  checked?: boolean;
}

// Define the BigCheck component as a functional component
export const BigCheck: React.FC<BigCheckProps> = ({ label, caption, onClick = () => {}, checked = false }) => {
  return (
    <div
      className="relative"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {/* The label and checkbox container */}
      <label
        className="flex cursor-pointer justify-between items-start gap-4 p-6"
        // onClick={() => {
          // trigger a click on the checkbox when the label is clicked
          // locate the checkbox that is a child of this label and trigger a click on it
          // document.querySelector(`input[type="checkbox"]`).click();
          // onClick();
        // }}
      >
        <div className="relative z-10">
          {/* The label text */}
          <p className="mb-1 font-semibold">{label}</p>
          {/* The caption text */}
          <p className="text-black/40 dark:text-white/40">{caption}</p>
        </div>
        {/* The checkbox input */}
        <input
          type="checkbox"
          className="form-checkbox outline-dark dark:outline-secondary rounded-full relative z-10 bg-transparent peer"
          checked={checked}
          onClick={e => e.stopPropagation()}
        />
        {/* The custom checkbox appearance */}
        <span
          className={`rounded-lg border border-black/10 peer-checked:border-black peer-checked:bg-lightwhite absolute top-0 left-0 z-0 w-full h-full dark:border-white/10 dark:peer-checked:border-lightpurple-200 dark:peer-checked:bg-white/5`} //  ${checked && 'border-black bg-lightwhite dark:border-white/10 dark:border-lightpurple-200 dark:bg-white/5'}
        ></span>
      </label>
    </div>
  );
};