import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  UserIcon,
} from "@heroicons/react/24/outline";


export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
}

export const quickStartQuestions = [
  {
    id: "aboutYou",
    title: "About You",
    description: "Tell us about your brand and the industry you serve.",
    questions: [
      {
        id: "brandStory",
        title: "Brand Story",
        description: "What is your brand story?",
      },
      {
        id: "industryOrMarket",
        title: "Industry or Market",
        description: "What industry or market segment do you (or would like to) serve?",
      }
    ]
  },
  {
    id: "aboutYourProduct",
    title: "About Your Product",
    description: "Tell us about your product or service.",
    questions: [
      {
        id: "productOrService",
        title: "Product or Service",
        description: "What product or service do you offer?",
      },
      {
        id: "description",
        title: "Product Description",
        description: "What product or service do you offer, and how do your clients receive what you offer?",
      },
      {
        id: "features",
        title: "Product Features",
        description: "Tell us about the product you sell and how you deliver it.",
      },
      {
        id: "pricing",
        title: "Pricing",
        description: "What do you currently charge for it?",
      },
      {
        id: "satisfactionGuarantee",
        title: "Satisfaction Guarantee",
        description: "Do you have a satisfaction guarantee or way to alleviate the risk of buying?",
      }
    ]
  },
  {
    id: "aboutBestCustomers",
    title: "About Your Best Customers",
    description: "Tell us about your best customers.",
    questions: [
      {
        id: "demographics",
        title: "Demographics",
        description: "Can you describe their demographic profile?",
      },
      {
        id: "dreamOutcome",
        title: "Dream Outcome",
        description: "Can you describe the key outcomes & results they’ll achieve through your service or product?",
      },
      {
        id: "emotionsAndBeliefs",
        title: "Emotions & Beliefs",
        description: "Now describe core wants, feelings, and beliefs.",
      }
    ]
  },
  {
    id: "marketingPromise",
    title: "Marketing Promise",
    description: "What is your core commitment to your prospects?",
    questions: [
      {
        id: "uniqueSolution",
        title: "Unique Solution",
        description: "Differentiation is key. What makes it not just better, but uniquely different?",
      },
      {
        id: "promise",
        title: "Promise",
        description: "What is your core commitment to your prospects?",
      },
      {
        id: "topBelief",
        title: "Top Belief",
        description: "What’s the #1 idea a prospect who is MOST QUALIFIED for what you’re offering should believe before being introduced to your offer?",
      }
    ]
  },
  {
    id: "whyBelieve",
    title: "Why Believe",
    description: "Why should I believe you?",
    questions: [
      {
        id: "credibility",
        title: "Credibility",
        description: "Why should I believe {credibility}?",
      },
      {
        id: "primaryBelief",
        title: "Primary Belief",
        description: "Why should I believe {primary belief}?",
      },
      {
        id: "differenceFromCompetition",
        title: "Difference From Competition",
        description: "How is {product/solution} different from the competitions?",
      },
      {
        id: "authenticity",
        title: "Authenticity",
        description: "How do I know this is real?",
      },
      {
        id: "trust",
        title: "Trust",
        description: "Why should I trust you?",
      },
      {
        id: "mechanismForOutcome",
        title: "Mechanism For Outcome",
        description: "How does it work to produce {dream outcome}",
      }
    ]
  },
];

export const companyQuestions = [
  {
    id: "companyInfo",
    title: "Basic Information",
    description:
      "Let's gather a quick profile about you & your business.  This will help Launch OS create marketing campaigns around you & your business.",
    questions: [
      {
        id: "companyName",
        title: "Company Name",
        description: "What is the name of your business?",
      },
      {
        id: "fullName",
        title: "Full Name",
        description: "What is your full name?",
      },
      {
        id: "companyEmail",
        title: "Email",
        description:
          "What is the primary email address for you or your business?",
      },
      {
        id: "companyPhone",
        title: "Phone",
        description: "What is your phone number for your business?",
      },
      {
        id: "companyAddress",
        title: "Address",
        description: "What is the primary address for your business?",
      },
      {
        id: "companyUrl",
        title: "URL",
        description: "What is the primary URL for your business?",
      },
    ],
  },
  {
    id: "brandInfo",
    title: "Brand Information",
    description: "Tell us about your brand and the industry you serve.",
    questions: [
      {
        id: "brandStory",
        title: "Brand Story",
        description: "What is your brand story or history of your company?",
        example:
          "Sarah's Sweets started as a passion project in my kitchen, where I experimented with baking different treats. Over time, it grew into a small bakery business specializing in custom cakes and desserts for special occasions.",
      },
      {
        id: "industryOrMarket",
        title: "Niche",
        description:
          "What industry or market segment do you (or would like to) serve?",
        example:
          "We operate in the food and hospitality industry, focusing on providing high-quality baked goods for events and celebrations.",
      },
      {
        id: "businessModel",
        title: "Business Model",
        description:
          "Briefly explain your business model and price points. For example, are you a high ticket coach or a lower cost SaaS? This impacts which funnel models we'll recommend as higher priced services often need longer funnels and more relationship building.",
      },
      {
        id: "products",
        title: "Product(s)",
        description:
          "What product or service do you offer? List each one below.",
        example:
          "We offer custom cakes, cupcakes, cookies, and dessert catering services for weddings, birthdays, and other events.",
      },
      {
        id: "deliveryMechanism",
        title: "Delivery Mechanism",
        description:
          "How do (or will) your customers typically receive your products? Is it through one-on-one service, online orders, or another method?",
        example:
          "Most of our products are ordered through our website or social media channels, and customers can either pick them up in person or have them delivered to their location.",
      },
    ],
  },
  {
    id: "funnelCriteria",
    title: "Funnel Criteria",
    description:
      "Answer these questions to help determine the ideal marketing funnel model for your business situation.",
    questions: [
      {
        id: "businessStage",
        title: "Stage of Business Growth",
        description:
          "What stage of business growth are you (or your next campaign) currently in? Are you in the early research phase, validating your business opportunity, or looking to scale and widen your reach? Explaining where you currently are will help determine the best funnel model for you.",
      },
      {
        id: "awarenessStage",
        title: "Stage of Awareness Targeting",
        description:
          "What level of awareness do your prospects currently have? Do they know they have a problem but not that a solution exists yet, or are they well aware of solutions but not your specific offering? Targeting prospects with higher awareness means shorter, more direct funnels.",
      },
      {
        id: "primaryGoal",
        title: "Primary Goal",
        description:
          "What is the primary goal you want to achieve right now - more leads, direct sales, or improvements in conversion rates or customer lifetime value from current leads? Aligning on the main business goal will shape the ideal funnel model.",
      }
    ],
  },
];

export const icpQuestions = [
  {
    id: "demographics",
    title: "Demographics",
    description:
      "Let's dive into your ideal customer's demographic profile? This is just an initial sketch of your ideal/perfect customer, not the entirety of your target market.",
    questions: [
      {
        id: "ageRange",
        title: "Age Range",
        description: "What is the ideal age range of your customer?",
        example: "25-34",
      },
      {
        id: "gender",
        title: "Gender",
        description: "What is the gender of your ideal customer?",
        example: "mostly female",
      },
      {
        id: "geographicLocation",
        title: "Geographic Location",
        description: "Where are they primarily located?",
        example: "urban areas, major cities, USA",
      },
      {
        id: "maritalStatus",
        title: "Marital Status",
        description: "What is the marital status of your ideal customer?",
        example: "single",
      },
      {
        id: "ethnicity",
        title: "Ethnicity",
        description: "What is their ethnicity?",
        example: "White",
      },
      {
        id: "averageIncome",
        title: "Average Income",
        description:
          "What is the average annual income of your ideal customer?",
        example: "$50,000 - $75,000",
      },
      {
        id: "educationLevel",
        title: "Education Level",
        description: "What is the education level of your ideal customer?",
        example: "college graduate",
      },
      {
        id: "politicalViews",
        title: "Political Views",
        description: "What are their political views?",
        example: "conservative leaning",
      },
    ],
  },
  {
    id: "dreamOutcome",
    title: "Dream Outcome",
    description:
      "Describe the key outcomes & results they’ll achieve through your service or product.",
    questions: [
      {
        id: "dreamOutcome",
        title: "Dream Outcome",
        description:
          "What is the key outcome & result they’ll achieve through your service or product?",
        example: "50% increase in sales",
      },
      {
        id: "otherResults",
        title: "Other Results",
        description:
          "What other results will they achieve because of your service or product?",
        example: "more family time, less stress",
      },
    ],
  },
  {
    id: "emotionProfile",
    title: "Emotional Profile",
    description:
      "Describe core wants, feelings, and beliefs. What do they deeply desire, their fears, their desired feelings after experiencing your product or service?",
    questions: [
      {
        id: "desires",
        title: "Desires",
        description: "What do they deeply desire?",
        example: "to make a positive impact on the environment and society",
      },
      {
        id: "fears",
        title: "Fears",
        description: "What are their fears?",
        example: "contributing to environmental degradation",
      },
      {
        id: "desiredEmotions",
        title: "Desired Emotions",
        description:
          "What are their desired feelings after through your product or service?",
        example: "empowered and fulfilled by their purchasing decisions",
      },
    ],
  },
];

export const competitionQuestions = [];

// Differentiation
// Primary Promise
// The Dream Outcome
// Overcoming Common Objections
export const messagingQuestions = [
  {
    id: "theHook",
    title: "",
    description: "",
    questions: [
      {
        id: "dreamOutcome",
        title: "Most Desired Outcome",
        description:
          "What is the key outcome & result they’ll achieve through your service or product?",
        example: "50% increase in sales",
      },
      {
        id: "promise",
        title: "The Big Promise",
        description: "What is your core commitment to your prospects?",
        example:
          "We promise to deliver high-quality, sustainable products that are good for you and the environment.",
      },
      {
        id: "mechanism",
        title: "Differentiation (Unique Solution)",
        description:
          "What is the main insight or differentiating aspect about the way your solution produces your prospect's most desired result.What makes it not just better, but uniquely different?",
        example:
          "Our products are made from sustainable materials and are 100% biodegradable.",
      },
      {
        id: "coreBelief",
        title: "Core Belief",
        description:
          "What’s the #1 idea a prospect who is MOST QUALIFIED for what you’re offering should believe before being introduced to your offer?",
        example: "The key to achieving {dream outcome} is {unique solution}",
      },
    ],
  },
  {
    id: "commonObjections",
    title: "Overcoming Common Objections",
    description:
      "What are the most common objections or obstacles that would prevent your prospects from believing your core belief, and how can we overcome them?",
    // - Question #1: How is this different from everything else I’ve seen?
    // - Question #2: What’s in it for me?
    // - Question #3: How do I know this is real?
    // - Question #4: What’s holding me back?
    // - Question #5: Who/What is to blame?
    // - Question #6: Why now?
    // - Question #7: Why should I trust you?
    // - Question #8: How does it work?
    // - Question #9: How can I get started?
    // - Question #10: What do I have to lose?
    questions: [
      {
        id: "differentiation",
        title: "This isn't anything new",
        description: "How is this any different from the competitions?",
      },
      {
        id: "whatsInItForMe",
        title: "This isn't for me",
        description: "What’s in it for me?",
      },
      {
        id: "authenticity",
        title: "Is this real?",
        description: "How do I know this is real?",
      },
      {
        id: "whatsHoldingMeBack",
        title: "Will this actually solve my problem?",
        description:
          "Will this actually solve the real problem, or is it just more of the same?",
      },
      {
        id: "whyShouldITrustYou",
        title: "I don't trust you",
        description: "Why should I trust you?",
      },
      {
        id: "howDoesItWork",
        title: "How does it work?",
        description: "But seriously, how is this actually supposed to work?",
      },
      {
        id: "whatDoIHaveToLose",
        title: "What do I have to lose?",
        description: "What am I risking if I don't take action?",
      },
      {
        id: "otherObjections",
        title: "Other Objections",
        description: "What are some other objections or obstacles that would prevent your prospects from believing the idea behind your core belief, and how can we overcome them?",
      },
    ],
  },
];

export const pitchQuestions = [
  {
    id: "pitchQs",
    title: "",
    description: "",
    questions: [
      {
        id: "pitchType",
        title: "What part of the campaign is this for?",
        description: "Specify which campaign component this pitch targets.",
        example:
          "lead capture page, sales page, upsell or one-time offer page, etc.",
      },
      {
        id: "pitchDetails",
        title: "Briefly describe the pitch",
        description: "A concise summary of your sales pitch.",
        example: "explain your pitch in one or two sentences?",
      },
      {
        id: "pitchExamples",
        title: "Share a few plain english examples of your pitch",
        description:
          "Share a few plain English examples of your pitch. How would you explain it to a friend? No need for perfect copy, just keep it simple.",
        example:
          "1. How to [desired outcome] without [biggest concern], 2. Free report reveals how to grow giant, juicy red tomatoes in 7 days!",
      },
    ],
  },
];

export const offerQuestions = [
  {
    id: "offer",
    title: "Irresistible Offer",
    description:
      "Let's define an irresistible offer so compelling that it encourages immediate action from your potential customers.",
    questions: [
      {
        id: "productFeatures",
        title: "Product Features",
        description:
          "Please provide a list of the features and benefits of your product or service. We want to understand what makes it valuable to your customers.",
        example:
          "personalized workout plans, nutritional guidance, access to a private online community",
      },
      {
        id: "premiumsBonuses",
        title: "Premiums & Bonuses",
        description:
          "Are there any additional perks or bonuses you can add to enhance the value of your offer? These are exclusive add-ons that are not normally a part of your core offerings.",
        example:
          "bonus nutrition guides, exclusive workout videos, complimentary one-on-one coaching session, discounted product bundle",
      },
      {
        id: "priceTerms",
        title: "Price & Terms",
        description:
          "How do you currently price your product, and are there any discounts or special offers you'd like to include in your irresistible offer?",
        example: "$99 per month, 20% discount for the first three months",
      },
      {
        id: "riskReversal",
        title: "Risk Reversal",
        description:
          "What guarantees or assurances can you offer to mitigate any concerns your customers may have? This could be a money-back guarantee, double money-back guarantee, or other forms of risk reversal.",
        example: "30-day money-back guarantee, work for free if not satisfied",
      },
      {
        id: "reasonToRespondNow",
        title: "Reason to Respond Now",
        description:
          "What urgency or scarcity factors can we incorporate to encourage immediate action from potential customers?",
        example: "limited-time offer, available only to the first 50 sign-ups",
      },
      {
        id: "reasonWhy",
        title: "Reason why you're making such a strong offer",
        description:
          "What's the reason you're making such a strong offer? Is it a special occasion, a new product launch, or something else?",
        example: "Black Friday sale, new product launch, customer appreciation event",
      },
    ],
  },
];

export const assetQuestions = [];

export const allQuestions = {
  quickStart: quickStartQuestions,
  company: companyQuestions,
  customer: icpQuestions,
  competition: competitionQuestions,
  messaging: messagingQuestions,
  offer: offerQuestions,
  asset: assetQuestions,
  pitch: pitchQuestions,
}

export const questionnaire = {
  aboutYou: {
    brandStory:
      "What is your brand story (e.g. tell me the history and evolution of your company)?",
    industryOrMarket:
      "What industry or market segment do you (or would like to) serve?",
  },
  aboutYourProduct: {
    productOrService: "[Product] or [Service] or [None Yet]?",
    description:
      "What product or service do you offer, and how do your clients receive what you offer (e.g. one-on-one service, an online course, a physical product, etc.)",
    features:
      "Tell me about the product you sell and how you deliver it. List out the BEST features (or useful facts) about it that make it such a good product.",
    pricing: "What do you currently charge for it?",
    satisfactionGuarantee:
      "Do you have a satisfaction guarantee or way to alleviate the risk of buying? What is it?",
  },
  aboutBestCustomers: {
    demographics:
      "Think about your best (or ideal) customer.  Can you describe their demographic profile? Please consider factors like their age range, gender, geographic location, marital status, ethnicity, political views, level of education, and average income. Remember, this is just an initial sketch of your ideal prospect, not the entirety of your target market.",
    dreamOutcome:
      "Think about your best (or ideal) customer. Can you describe the key outcomes & results they’ll achieve through your service or product?",
    emotionsAndBeliefs:
      "Now describe core wants, feelings, and beliefs. What do they deeply desire, their fears, their desired feelings after through your product or service?",
  },
  marketingPromise: {
    uniqueSolution:
      "Differentiation is key. Think about the main insight or differentiating feature you want your prospects to instantly grasp about you. What makes it not just better, but uniquely different?",
    promise:
      "What is your core commitment to your prospects? What is the ultimate promise or solution that your product offers to your prospects?",
    topBelief:
      "What’s the #1 idea a prospect who is MOST QUALIFIED for what you’re offering should believe before being introduced to your offer. Based on your previous answers, I came up with this as your primary belief. Is this accurate? Please adjust it below. The key to achieving {dream outcome} is {unique solution}",
  },
  whyBelieve: {
    credibility: "Why should I believe {credibility}",
    primaryBelief: "Why should I believe {primary belief}",
    differenceFromCompetition:
      "How is {product/solution} different from the competitions?",
    authenticity: "How do I know this is real?",
    trust: "Why should I trust you?",
    mechanismForOutcome: "How does it work to produce {dream outcome}",
  },
};

// A list of market research profiles that the user can create
export const blueprintProfiles = [
  {
    id: "company",
    title: "Company Profile",
    description: "Create a profile for you or a client.",
    icon: BuildingOffice2Icon,
    background: "bg-pink-500",
  },
  {
    id: "customer",
    title: "Ideal Customer Profile",
    description: "Create a profile of your ideal customer (avatar).",
    icon: UserIcon,
    background: "bg-yellow-500",
  },
  {
    id: "competition",
    title: "Competition Profile",
    description: "Create a profile of your competition.",
    icon: BriefcaseIcon,
    background: "bg-green-500",
    tag: "Upcoming",
    disabled: true,
  },
  {
    id: "messaging",
    title: "Messaging Profile",
    description: "Create a profile for your messaging strategy.",
    icon: ChatBubbleLeftRightIcon,
    background: "bg-blue-500",
  },
  {
    id: "pitch",
    title: "Sales Pitch",
    description: "Create a sales pitch for various parts of a campaign.",
    icon: ChatBubbleBottomCenterTextIcon,
    background: "bg-cyan-500",
  },
  {
    id: "offer",
    title: "Offer Profile",
    description: "Create an irresistible offer profile.",
    icon: BanknotesIcon,
    background: "bg-indigo-500",
  },
  {
    id: "asset",
    title: "Asset Catalog",
    description: "Create a catalog of your marketing assets.",
    icon: DocumentDuplicateIcon,
    background: "bg-purple-500",
    tag: "Upcoming",
    disabled: true,
  },
];
