import { useState } from "react";

import {
  DeltaUpIcon,
  DeltaDownIcon,
  ProjectIcon,
  FinanceIcon,
  ClientsIcon,
  RightCarrotIcon,
  HomeIcon,
  FlashIcon,
  PrimaryIcon,
  SuccessIcon,
  WarningIcon,
  DangerIcon,
  DownCarrotIcon,
  // the rest
  SidebarToggleIcon,
  FavoriteIcon,
  SearchIcon,
  MicrophoneIcon,
  DarkToggleIcon,
  LightToggleIcon,
  NotificationIcon,
  SignoutIcon,
  SupportIcon,
  MessagesIcon,
  SettingsIcon,
  ProfileIcon,
  DashboardIcon,
  AppsIcon,
  ComponentsIcon,
  ElementsIcon,
  ChartsIcon,
  WidgetsIcon,
  FontIcon,
  DragDropIcon,
  TableIcon,
  FormsIcon,
  UsersIcon,
  PagesIcon,
  AuthenticationIcon,
  DocumentationIcon,
  CloseButtonIcon,
  PrimaryAlertIcon,
  WarningAlertIcon,
  DangerAlertIcon,
} from "./Icons";
import {
  Status,
  ButtonTypes,
  Variants,
  DropDownTypes,
} from "./types";

import {
  PopupPosition,
  PopupSizes
} from "@/ui/Modal/types";

import {
  TextInputFlavors,
  TextInputTypes,
  TextInputValidationTypes,
} from "@/ui/TextInput/types";


import { AllAlerts } from "./Alert";
// import { Badges } from "./Badges";
import AccountInfoPage from "./Layout/KitchenSink/AccountInfoPage";

import { Avatar } from "./Avatar/Avatar";
import { Badge } from "./Badge";
import { KPIWidget } from "./KPIWidget";
import { Group } from "./Group";
import { SimpleContainer } from "./SimpleContainer";
import { Breadcrumbs } from "./Breadcrumbs";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { TextInput } from "./TextInput";
import { Select } from "./Select";
import { Checkbox } from "./Checkbox";
import { Radio } from "./Radio";
import { Modal } from "./Modal";
import { InputGroup } from "./InputGroup";
import { OptionGroup } from "./OptionGroup";
import { BigOptionGroup } from "./BigOptionGroup";
import { Stepper } from "./Stepper";
import { Card } from "./Card";

export const ShowAllTheBadges = () => {
  return (
    <>
      <Group label="Basic Badges">
        <div className="flex flex-wrap items-center gap-4">
          <Badge type="basic" color="default" text="Default" />
          <Badge type="basic" color="primary" text="Primary" />
          <Badge type="basic" color="secondary" text="Secondary" />
          <Badge type="basic" color="info" text="Info" />
          <Badge type="basic" color="success" text="Success" />
          <Badge type="basic" color="warning" text="Warning" />
          <Badge type="basic" color="error" text="Error" />
        </div>
      </Group>

      <Group label="Rounded Badge">
        <div className="flex flex-wrap items-center gap-4">
          <Badge type="rounded" color="default" text="Default" />
          <Badge type="rounded" color="primary" text="Primary" />
          <Badge type="rounded" color="secondary" text="Secondary" />
          <Badge type="rounded" color="info" text="Info" />
          <Badge type="rounded" color="success" text="Success" />
          <Badge type="rounded" color="warning" text="Warning" />
          <Badge type="rounded" color="error" text="Error" />
        </div>
      </Group>

      <Group label="Glow Badge">
        <div className="flex flex-wrap items-center gap-4">
          <Badge type="glow" color="default" text="Default" />
          <Badge type="glow" color="primary" text="Primary" />
          <Badge type="glow" color="secondary" text="Secondary" />
          <Badge type="glow" color="info" text="Info" />
          <Badge type="glow" color="success" text="Success" />
          <Badge type="glow" color="warning" text="Warning" />
          <Badge type="glow" color="error" text="Error" />
        </div>
      </Group>

      <Group label="Badge With Dots">
        <div className="flex flex-wrap items-center gap-4">
          <Badge type="dots" color="default" text="Default" />
          <Badge type="dots" color="primary" text="Primary" />
          <Badge type="dots" color="secondary" text="Secondary" />
          <Badge type="dots" color="info" text="Info" />
          <Badge type="dots" color="success" text="Success" />
          <Badge type="dots" color="warning" text="Warning" />
          <Badge type="dots" color="error" text="Error" />
        </div>
      </Group>
    </>
  );
};

const AllTheModals = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState(PopupSizes.MEDIUM);
  const [position, setPosition] = useState(PopupPosition.DEFAULT);

  return (
    <SimpleContainer label="Modals">
      {isOpen && (
        <Modal
          title="Basic"
          setIsOpen={setIsOpen}
          size={size}
          position={position}
        >
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </Modal>
      )}

      <Button
        label="Launch Modal (Default)"
        onClick={() => {
          setPosition(PopupPosition.DEFAULT);
          setIsOpen(true);
        }}
      />
      <Button
        label="Launch Modal (Centered)"
        onClick={() => {
          setPosition(PopupPosition.CENTERED);
          setIsOpen(true);
        }}
      />

      <Button
        label="Launch Modal (Small)"
        onClick={() => {
          setSize(PopupSizes.SMALL);
          setIsOpen(true);
        }}
      />
      <Button
        label="Launch Modal (Medium)"
        onClick={() => {
          setSize(PopupSizes.MEDIUM);
          setIsOpen(true);
        }}
      />
      <Button
        label="Launch Modal (Large)"
        onClick={() => {
          setSize(PopupSizes.LARGE);
          setIsOpen(true);
        }}
      />
      <Button
        label="Launch Modal (Extra Large)"
        onClick={() => {
          setSize(PopupSizes.XLARGE);
          setIsOpen(true);
        }}
      />
    </SimpleContainer>
  );
};

export const OptionGroupsDemo = () => {
  const [optionGroupSelection, setOptionGroupSelection] = useState("1-1");
  const [bigOptionGroupSelection, setBigOptionGroupSelection] =
    useState("Option 1");

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
      <SimpleContainer label="Option Group">
        <OptionGroup
          options={["1-1", "2-10", "10-50"]}
          selectedOption={optionGroupSelection}
          onChange={setOptionGroupSelection}
        />
      </SimpleContainer>

      <SimpleContainer label="Big Option Group">
        <BigOptionGroup
          options={[
            {
              id: "Option 1",
              label: "Option 1",
              caption: "Description for Option 1",
              icon: <DashboardIcon />,
            },
            {
              id: "Option 2",
              label: "Option 2",
              caption: "Description for Option 2",
              icon: <AppsIcon />,
            },
            {
              id: "Option 3",
              label: "Option 3",
              caption: "Description for Option 3",
              icon: <ComponentsIcon />,
            },
          ]}
          selectedOption={bigOptionGroupSelection}
          onChange={setBigOptionGroupSelection}
        />
      </SimpleContainer>
    </div>
  );
};

export const MainContent = () => {
  const image = [
    <img
      className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
      src="/assets/images/dropbox.png"
      alt=""
    />,
    <img
      className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
      src="/assets/images/badoo.png"
      alt=""
    />,
    <img
      className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
      src="/assets/images/invision.png"
      alt=""
    />,
  ];

  const logo = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
        fill="currentcolor"
      />
    </svg>
  );

  const breadCrumbItems = [
    { label: "Home" },
    { label: "Elements" },
    { label: "Breadcrumb" },
  ];

  const basicDropdownItems = [
    { label: "Action", href: "#" },
    { label: "Another action", href: "#" },
    { label: "Something else here", href: "#" },
  ];

  return (
    <>
      {/* Main Content */}

      {/* KPI Widgets */}
      <a name="kpis" />
      <div className="grid grid-cols-1 gap-7">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
          <KPIWidget
            color="lightblue"
            label="Views"
            number="721K"
            delta="+11.01%"
            deltaSign="POSITIVE"
          />
          <KPIWidget
            color="lightpurple"
            label="Visits"
            number="367K"
            delta="+9.15%"
            deltaSign="POSITIVE"
          />
          <KPIWidget
            color="lightblue"
            label="New Users"
            number="1,156"
            delta="+0.56%"
            deltaSign="POSITIVE"
          />
          <KPIWidget
            color="lightpurple"
            label="Active Users"
            number="239K"
            delta="-1.48%"
            deltaSign="NEGATIVE"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-7">
          <KPIWidget
            color="lightblue"
            label="Current Projects"
            number="237"
            icon={<ProjectIcon />}
          />
          <KPIWidget
            color="lightpurple"
            label="Project Finance"
            number="$3,290"
            icon={<FinanceIcon />}
          />
          <KPIWidget
            color="lightblue"
            label="Our Clients"
            number="49"
            icon={<ClientsIcon />}
          />
        </div>
      </div>

      {/* Project Label */}
      <a name="projects" />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-7">
        <Card
          type="Task"
          label="Poster illustration design"
          caption="Due Date: Nov 10, 2022"
          image={image[0]}
          status={Status.INPROGRESS}
          sharedWith={logo}
          taskDetails={{
            completedNum: 13,
            totalNum: 49,
          }}
        />
        <Card
          type="Task"
          label="Coffee detail page - Main Page"
          caption="Due Date: June 20, 2022"
          image={image[1]}
          status={Status.PENDING}
          sharedWith={logo}
          taskDetails={{
            completedNum: 56,
            totalNum: 56,
          }}
        />
        <Card
          type="Task"
          label="Drinking bottle graphics"
          caption="Due Date: Nov 10, 2022"
          image={image[2]}
          status={Status.COMPLETED}
          sharedWith={logo}
          taskDetails={{
            completedNum: 16,
            totalNum: 65,
          }}
        />
      </div>

      {/* Avatars */}
      <a name="avatars" />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Rounded Avatar">
          <Avatar
            type="Rounded"
            src="/assets/images/avatar-1.png"
            height="24"
            width="24"
          />
          <Avatar
            type="Rounded"
            src="/assets/images/avatar-1.png"
            height="20"
            width="20"
          />
          <Avatar
            type="Rounded"
            src="/assets/images/avatar-1.png"
            height="16"
            width="16"
          />
          <Avatar
            type="Rounded"
            src="/assets/images/avatar-1.png"
            height="12"
            width="12"
          />
          <Avatar
            type="Rounded"
            src="/assets/images/avatar-1.png"
            height="10"
            width="10"
          />
        </SimpleContainer>
        <SimpleContainer label="Square Avatar">
          <Avatar
            type="Square"
            src="/assets/images/avatar-1.png"
            height="24"
            width="24"
          />
          <Avatar
            type="Square"
            src="/assets/images/avatar-1.png"
            height="20"
            width="20"
          />
          <Avatar
            type="Square"
            src="/assets/images/avatar-1.png"
            height="16"
            width="16"
          />
          <Avatar
            type="Square"
            src="/assets/images/avatar-1.png"
            height="12"
            width="12"
          />
          <Avatar
            type="Square"
            src="/assets/images/avatar-1.png"
            height="10"
            width="10"
          />
        </SimpleContainer>

        <SimpleContainer label="Avatar With Dots">
          <Avatar
            type="Dots"
            src="/assets/images/avatar-1.png"
            height="24"
            width="24"
          />
          <Avatar
            type="Dots"
            src="/assets/images/avatar-1.png"
            height="20"
            width="20"
          />
          <Avatar
            type="Dots"
            src="/assets/images/avatar-1.png"
            height="16"
            width="16"
          />
          <Avatar
            type="Dots"
            src="/assets/images/avatar-1.png"
            height="12"
            width="12"
          />
          <Avatar
            type="Dots"
            src="/assets/images/avatar-1.png"
            height="10"
            width="10"
          />
        </SimpleContainer>

        <SimpleContainer label="Avatar group">
          <Avatar
            type="Group"
            srcList={[
              "/assets/images/avatar-2.png",
              "/assets/images/avatar-3.png",
              "/assets/images/avatar-5.png",
              "/assets/images/avatar-8.png",
            ]}
            extraCount="99"
            height="24"
            width="24"
          />
        </SimpleContainer>

        <SimpleContainer label="Avatar with text">
          <Avatar
            type="Text"
            src="/assets/images/avatar-1.png"
            label="David Jackson"
            caption="Frontend Developer"
          />
        </SimpleContainer>

        <SimpleContainer label="Initial Avatar">
          <Avatar type="Default" label="KG" color="bg-indigo-300" />
          <Avatar type="Default" label="KG" color="bg-lightpurple-200" />
          <Avatar type="Default" label="KG" color="bg-lightblue-200" />
          <Avatar type="Default" label="KG" color="bg-lightgreen-100" />
          <Avatar type="Default" label="KG" color="bg-lightyellow" />
          <Avatar type="Default" label="KG" color="bg-lightred" />
        </SimpleContainer>

        <SimpleContainer label="Square Initial Avatar">
          <Avatar type="DefaultSquare" label="KG" color="bg-indigo-300" />
          <Avatar type="DefaultSquare" label="KG" color="bg-lightpurple-200" />
          <Avatar type="DefaultSquare" label="KG" color="bg-lightblue-200" />
          <Avatar type="DefaultSquare" label="KG" color="bg-lightgreen-100" />
          <Avatar type="DefaultSquare" label="KG" color="bg-lightyellow" />
          <Avatar type="DefaultSquare" label="KG" color="bg-lightred" />
        </SimpleContainer>
      </div>

      {/* Breadcrumbs */}
      <a name="breadcrumbs" />
      <div className="grid grid-cols-1 gap-7">
        <SimpleContainer label="Breadcrumb">
          <Breadcrumbs items={breadCrumbItems} type="Arrow" />
        </SimpleContainer>

        <SimpleContainer label="Separators Breadcrum">
          <Breadcrumbs items={breadCrumbItems} type="Pipe" />
          <Breadcrumbs items={breadCrumbItems} type="Slash" />
        </SimpleContainer>

        <SimpleContainer label="Breadcrumb With Icon">
          <Breadcrumbs
            items={[
              { icon: <HomeIcon /> },
              { label: "Elements", icon: <FlashIcon /> },
              { label: "Calendar " },
            ]}
            type="Icon"
          />
        </SimpleContainer>
      </div>

      {/* Buttons */}
      <a name="buttons" />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Buttons">
          <Button variant={Variants.PRIMARY} label="Primary" />
          <Button variant={Variants.SECONDARY} label="Secondary" />
          <Button variant={Variants.SUCCESS} label="Success" />
          <Button variant={Variants.INFO} label="Info" />
          <Button variant={Variants.WARNING} label="Warning" />
          <Button variant={Variants.DANGER} label="Danger" />
        </SimpleContainer>
        <SimpleContainer label="Rounded Button">
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.PRIMARY}
            label="Primary"
          />
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.SECONDARY}
            label="Secondary"
          />
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.SUCCESS}
            label="Success"
          />
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.INFO}
            label="Info"
          />
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.WARNING}
            label="Warning"
          />
          <Button
            type={ButtonTypes.ROUNDED}
            variant={Variants.DANGER}
            label="Danger"
          />
        </SimpleContainer>
        <SimpleContainer label="Outlined Button">
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.PRIMARY}
            label="Primary"
          />
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.SECONDARY}
            label="Secondary"
          />
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.SUCCESS}
            label="Success"
          />
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.INFO}
            label="Info"
          />
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.WARNING}
            label="Warning"
          />
          <Button
            type={ButtonTypes.OUTLINED}
            variant={Variants.DANGER}
            label="Danger"
          />
        </SimpleContainer>
        <SimpleContainer label="Disabled Button">
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.PRIMARY}
            label="Primary"
          />
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.SECONDARY}
            label="Secondary"
          />
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.SUCCESS}
            label="Success"
          />
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.INFO}
            label="Info"
          />
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.WARNING}
            label="Warning"
          />
          <Button
            type={ButtonTypes.DISABLED}
            variant={Variants.DANGER}
            label="Danger"
          />
        </SimpleContainer>
        <SimpleContainer label="Buttons with Label">
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.PRIMARY}
            label="Primary"
          />
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.SECONDARY}
            label="Secondary"
          />
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.SUCCESS}
            label="Success"
          />
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.INFO}
            label="Info"
          />
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.WARNING}
            label="Warning"
          />
          <Button
            type={ButtonTypes.WITH_LABEL}
            variant={Variants.DANGER}
            label="Danger"
          />
        </SimpleContainer>
        <SimpleContainer label="Button with Leading Icon">
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.PRIMARY}
            label="Primary"
          />
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.SECONDARY}
            label="Secondary"
          />
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.SUCCESS}
            label="Success"
          />
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.INFO}
            label="Info"
          />
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.WARNING}
            label="Warning"
          />
          <Button
            type={ButtonTypes.WITH_ICON}
            variant={Variants.DANGER}
            label="Danger"
          />
        </SimpleContainer>
        <SimpleContainer label="Icon Buttons">
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.PRIMARY} />
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.SECONDARY} />
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.SUCCESS} />
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.INFO} />
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.WARNING} />
          <Button type={ButtonTypes.ICON_BUTTON} variant={Variants.DANGER} />
        </SimpleContainer>
      </div>

      {/* Dropdowns */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Basic Dropdown">
          <Dropdown type={DropDownTypes.BASIC} items={basicDropdownItems} />
          <Dropdown
            withBorder
            type={DropDownTypes.BASIC}
            items={basicDropdownItems}
          />
        </SimpleContainer>
        <SimpleContainer label="Variant Dropdown">N/A</SimpleContainer>
        <SimpleContainer label="Split Button Dropdowns">N/A</SimpleContainer>
        <SimpleContainer label="Icon with Dropdowns">N/A</SimpleContainer>
        <SimpleContainer label="Sizes Dropdown">N/A</SimpleContainer>
        <SimpleContainer label="Dropdown With icons">N/A</SimpleContainer>
      </div>

      {/* Forms */}
      <a name="forms" />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Basic Input Text">
          <TextInput
            flavor={TextInputFlavors.MODERN}
            label="User Name"
            value="Stevens"
            placeholder="User name"
          />
        </SimpleContainer>

        <SimpleContainer label="Email Input">
          <TextInput
            flavor={TextInputFlavors.MODERN}
            type={TextInputTypes.EMAIL}
            label="Email"
            value="Stevens@gmail.com"
            placeholder="Email"
          />
        </SimpleContainer>

        <SimpleContainer label="Basic Input Text">
          <TextInput placeholder="User Name" required />
        </SimpleContainer>

        <SimpleContainer label="Label Text">
          <TextInput
            label="Choose Username:"
            placeholder="User Name"
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Email Input">
          <TextInput placeholder="email@mail.com" required />
        </SimpleContainer>

        <SimpleContainer label="Password Input">
          <TextInput
            type={TextInputTypes.PASSWORD}
            flavor={TextInputFlavors.CLASSIC}
            placeholder="Enter Password"
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Url Input">
          <TextInput
            type={TextInputTypes.URL}
            flavor={TextInputFlavors.CLASSIC}
            placeholder="https://dummyurl.com"
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Search Input">
          <TextInput
            type={TextInputTypes.SEARCH}
            flavor={TextInputFlavors.CLASSIC}
            placeholder="Search..."
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Telephone Input">
          <TextInput
            type={TextInputTypes.TELEPHONE}
            flavor={TextInputFlavors.CLASSIC}
            placeholder="0-(000)-111-1111"
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Rounded Input">
          <TextInput
            flavor={TextInputFlavors.ROUNDED}
            placeholder="User Name"
            required
          />
        </SimpleContainer>

        <SimpleContainer label="Select Menu">
          <Select items={basicDropdownItems} />
        </SimpleContainer>

        <SimpleContainer label="Multiselect input">
          <Select items={basicDropdownItems} multiple />
        </SimpleContainer>

        <SimpleContainer label="Checkbox">
          <Checkbox label="Checkbox" checked />
          <Checkbox label="Checkbox" disabled checked />
        </SimpleContainer>

        <SimpleContainer label="Radio">
          <Radio className="cursor-pointer mb-4" label="Radio 1" checked />
          <Radio label="Radio 2" checked disabled />
        </SimpleContainer>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Input Group">
          <InputGroup
            elements={[
              { type: "text", content: "@", leftRounded: true },
              {
                type: "input",
                placeholder: "Username",
                inputType: "text",
                rightRounded: true,
              },
            ]}
          />

          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: "Username",
                inputType: "text",
              },
              { type: "text", content: "@site.com", rightRounded: true },
            ]}
          />

          <InputGroup
            elements={[
              { type: "text", content: "$", leftRounded: true },
              { type: "input", placeholder: "Enter Price", inputType: "text" },
              { type: "text", content: ".00", rightRounded: true },
            ]}
          />
        </SimpleContainer>
        <SimpleContainer label="Other Input">
          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: "Username",
                inputType: "text",
              },
              { type: "label", text: "@" },
              {
                type: "input",
                rightRounded: true,
                placeholder: "Server",
                inputType: "text",
              },
            ]}
          />

          <InputGroup
            elements={[
              { type: "select", options: ["$", "£", "€"] },
              { type: "input", placeholder: "Price", inputType: "text" },
              { type: "button", text: "Purchase", rightRounded: true },
            ]}
          />

          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: "Search...",
                inputType: "text",
              },
              { type: "button", text: "Dropdown", rightRounded: true },
            ]}
          />
        </SimpleContainer>
        <SimpleContainer label="Icon Input">
          <InputGroup
            elements={[
              { type: "icon", leftRounded: true, icon: <NotificationIcon /> },
              {
                type: "input",
                rightRounded: true,
                placeholder: "Notification",
                inputType: "text",
              },
            ]}
          />

          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: "Notification",
                inputType: "text",
              },
              { type: "icon", rightRounded: true, icon: <NotificationIcon /> },
            ]}
          />
        </SimpleContainer>
        <SimpleContainer label="Button Input">
          <InputGroup
            elements={[
              { type: "button", text: "Button", leftRounded: true },
              {
                type: "input",
                rounded: true,
                placeholder: "text",
                inputType: "text",
                rightRounded: true,
              },
            ]}
          />

          <InputGroup
            elements={[
              {
                type: "input",
                placeholder: "text",
                inputType: "text",
                leftRounded: true,
                rounded: true,
              },
              { type: "button", text: "Button", rightRounded: true },
            ]}
          />
        </SimpleContainer>
      </div>

      {/* Form Validation */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Input Wrong" display="block">
          <TextInput
            label="User Name"
            validationType={TextInputValidationTypes.ERROR}
            validationMessage="Please fill the Name"
            placeholder="User name"
          />
        </SimpleContainer>

        <SimpleContainer label="Input Success" display="block">
          <TextInput
            label="User Name"
            validationType={TextInputValidationTypes.SUCCESS}
            validationMessage="Looks Good!"
            value="Stevens"
            placeholder="User name"
          />
        </SimpleContainer>

        <SimpleContainer label="Email Input" display="block">
          <TextInput
            flavor={TextInputFlavors.CLASSIC}
            validationType={TextInputValidationTypes.ERROR}
            validationMessage="Please fill the Name"
            placeholder="User name"
          />
        </SimpleContainer>

        <SimpleContainer label="Email Input" display="block">
          <TextInput
            flavor={TextInputFlavors.CLASSIC}
            validationType={TextInputValidationTypes.SUCCESS}
            validationMessage="Looks Good!"
            value="Stevens@gmail.com"
            placeholder="User name"
          />
        </SimpleContainer>
      </div>

      {/* Option Groups */}
      <a name="option_groups" />
      <OptionGroupsDemo />

      {/* Icons & Modals */}
      <a name="icons" />
      <a name="modals" />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Icons">
          <div>
            <SidebarToggleIcon />
            SidebarToggleIcon
          </div>
          <div>
            <FavoriteIcon />
            FavoriteIcon
          </div>
          <div>
            <SearchIcon />
            SearchIcon
          </div>
          <div>
            <MicrophoneIcon />
            MicrophoneIcon
          </div>
          <div>
            <DarkToggleIcon />
            DarkToggleIcon
          </div>
          <div>
            <LightToggleIcon />
            LightToggleIcon
          </div>
          <div>
            <NotificationIcon />
            NotificationIcon
          </div>
          <div>
            <DownCarrotIcon />
            DownCarrotIcon
          </div>
          <div>
            <SignoutIcon />
            SignoutIcon
          </div>
          <div>
            <SupportIcon />
            SupportIcon
          </div>
          <div>
            <MessagesIcon />
            MessagesIcon
          </div>
          <div>
            <SettingsIcon />
            SettingsIcon
          </div>
          <div>
            <ProfileIcon />
            ProfileIcon
          </div>
          <div>
            <DashboardIcon />
            DashboardIcon
          </div>
          <div>
            <AppsIcon />
            AppsIcon
          </div>
          <div>
            <ComponentsIcon />
            ComponentsIcon
          </div>
          <div>
            <ElementsIcon />
            ElementsIcon
          </div>
          <div>
            <ChartsIcon />
            ChartsIcon
          </div>
          <div>
            <WidgetsIcon />
            WidgetsIcon
          </div>
          <div>
            <FontIcon />
            FontIcon
          </div>
          <div>
            <DragDropIcon />
            DragDropIcon
          </div>
          <div>
            <TableIcon />
            TableIcon
          </div>
          <div>
            <FormsIcon />
            FormsIcon
          </div>
          <div>
            <UsersIcon />
            UsersIcon
          </div>
          <div>
            <PagesIcon />
            PagesIcon
          </div>
          <div>
            <AuthenticationIcon />
            AuthenticationIcon
          </div>
          <div>
            <DocumentationIcon />
            DocumentationIcon
          </div>
          <div>
            <RightCarrotIcon />
            RightCarrotIcon
          </div>
          <div>
            <DeltaUpIcon />
            DeltaUpIcon
          </div>
          <div>
            <DeltaDownIcon />
            DeltaDownIcon
          </div>
          <div>
            <ProjectIcon />
            ProjectIcon
          </div>
          <div>
            <FinanceIcon />
            FinanceIcon
          </div>
          <div>
            <ClientsIcon />
            ClientsIcon
          </div>
          <div>
            <HomeIcon />
            HomeIcon
          </div>
          <div>
            <FlashIcon />
            FlashIcon
          </div>
          <div>
            <PrimaryIcon />
            PrimaryIcon
          </div>
          <div>
            <SuccessIcon />
            SuccessIcon
          </div>
          <div>
            <WarningIcon />
            WarningIcon
          </div>
          <div>
            <DangerIcon />
            DangerIcon
          </div>
          <div>
            <CloseButtonIcon />
            CloseButtonIcon
          </div>
        </SimpleContainer>
        <AllTheModals />
      </div>

      {/* Alert Badges */}
      <a name="alerts" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
        <AllAlerts />
      </div>
      {/* Alert Examples */}

      {/* Badge Examples */}
      <a name="badges" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
        <ShowAllTheBadges />
      </div>

      {/* Alert Examples */}
      <a name="alert_examples" />
      <div className="border col-span-1 lg:col-span-2 border-black/10 mt-7 dark:border-white/10 p-5 rounded-md">
        <div className="mb-5">
          <p className="text-sm font-semibold">Alert Examples</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-5">
          <div className="relative text-center rounded border-2 border-indigo-400 bg-indigo-100 px-6 py-3 text-indigo-600">
            <PrimaryAlertIcon />
            <h3 className="text-lg font-semibold">Primary</h3>
            <p className="mt-1 mb-4 text-black/50">A simple Primary alert</p>
            <button
              type="button"
              className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
            >
              <svg
                className="w-4 h-4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
                  fill="currentcolor"
                />
                <path
                  d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
                  fill="currentcolor"
                />
              </svg>
            </button>
          </div>
          <div className="relative text-center rounded border-2 border-lightgreen-100/80 bg-lightgreen-100/20 px-6 py-3 text-green-800">
            <SuccessAlertIcon />
            <h3 className="text-lg font-semibold">Success</h3>
            <p className="mt-1 mb-4 text-black/50">A simple success alert</p>
            <button
              type="button"
              className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
            >
              <svg
                className="w-4 h-4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
                  fill="currentcolor"
                />
                <path
                  d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
                  fill="currentcolor"
                />
              </svg>
            </button>
          </div>
          <div className="relative text-center rounded border-2 border-lightyellow/80 bg-lightyellow/20 px-6 py-3 text-yellow-600">
            <WarningAlertIcon />
            <h3 className="text-lg font-semibold">Warning</h3>
            <p className="mt-1 mb-4 text-black/50">A simple warning alert</p>
            <button
              type="button"
              className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
            >
              <svg
                className="w-4 h-4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
                  fill="currentcolor"
                />
                <path
                  d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
                  fill="currentcolor"
                />
              </svg>
            </button>
          </div>
          <div className="relative text-center rounded border-2 border-lightred/80 bg-lightred/20 px-6 py-3 text-red-600">
            <DangerAlertIcon />
            <h3 className="text-lg font-semibold">Danger</h3>
            <p className="mt-1 mb-4 text-black/50">A simple danger alert</p>
            <button
              type="button"
              className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
            >
              <svg
                className="w-4 h-4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
                  fill="currentcolor"
                />
                <path
                  d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
                  fill="currentcolor"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Wizard Steps */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
        <SimpleContainer label="Wizard Steps" display="block">
          <Stepper />
        </SimpleContainer>
      </div>

      {/* Account Info Page */}
      <a name="account_info" />
      <div className="mt-7">
        <SimpleContainer label="Account Info Page">
          <AccountInfoPage />
        </SimpleContainer>
      </div>
    </>
  );
};
