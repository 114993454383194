import React, { Component } from "react";
import styled from "styled-components";
import WebFont from "webfontloader";

import style from "../style";
import fonts from "./fonts";
import { convertFromRem, convertToRem } from "../../MobileResponsiveToggle/style";
import Grid, { Cell } from "@/ui/_old/Grid";
import ListItem from "@/ui/_old/ListItem";
import Menu from "@/ui/_old/Menu";
import ColorPicker from "../components/groups/ColorPicker";
import LineHeight from "../BasicProperties/LineHeight";
import Section from "../Section";
import Panel from "@/ui/Panel";

const fontSizes = [
  8,
  10,
  11,
  12,
  14,
  16,
  18,
  20,
  24,
  26,
  32,
  36,
  42,
  48,
  56,
  64,
  72,
  100,
  150,
  200,
  400,
];

class Typography extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fonts,
      isFontFamilyOpen: false,
      isFontSizeOpen: false,
    };

    this.onChange = this.onChange.bind(this);
    this.fontFamilyScrollRef = React.createRef();
    this.fontSizeScrollRef = React.createRef();
  }

  onChange(settings, font, dbUpdate = true, wait = 500) {
    const id = this.props.settings.id;
    // alert(font);

    if (font) {
      WebFont.load({
        google: {
          families: [font],
        },
        loading: () => {
          console.log("Loading...", font);
        },
        active: () => {
          this.setState({ isFontFamilyOpen: false });
          console.log({ settings });
          this.props.updateComponentStyle(id, settings, true, 0);
        },
      });
    } else {
      this.props.updateComponentStyle(id, settings, dbUpdate, wait);
    }
  }

  render() {
    const { showFonts, showColors, showLineHeight, settings } = this.props;
    const { fonts, isFontSizeOpen, isFontFamilyOpen } = this.state;

    const currentFontFamily = this.props.settings?.properties?.fontFamily;
    const currentFontSize = convertFromRem(this.props.settings?.properties?.fontSize);

    const bgImg = "https://s3.amazonaws.com/sandcastleassets/images/fonts.gif";

    const FontLi = styled.div`
      height: 30px;
      cursor: pointer;
      &:hover {
        border: 1px solid #999;
        border-radius: 3;
      }
    `;

    return (
      <Section label="Typography" icon="text_format" {...this.props}>
        <div className="flex flex-col gap-4">
          {showFonts ? (
            <fieldset
              // style={{ ...style.fieldset, paddingLeft: 15 }}
              // className="p-3"
              title="Choose your font..."
            >
              {/* <legend>Choose your font...</legend> */}
              <Panel title="Choose a font...">
                <div className="flex gap-4">
                  <Menu
                    label={
                      currentFontFamily || `${this.props.type} Font` || "Font"
                    }
                    style={{
                      width: "100%",
                      // maxWidth: 300,
                      display: "inline-block",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState(
                        (prevState) => ({
                          isFontFamilyOpen: !prevState.isFontFamilyOpen,
                        }),
                        () => {
                          // scroll to the position of the item
                          const pos = fonts.findIndex((font, key) => {
                            const fontFamily = font.slice(0, font.search(":"));

                            return fontFamily === currentFontFamily;
                          });

                          if (pos > -1) {
                            this.fontFamilyScrollRef.current.scrollBy(
                              0,
                              30 * parseInt(pos, 10)
                            );
                          }
                        }
                      )
                    }
                    visible={isFontFamilyOpen}
                  >
                    <div
                      ref={this.fontFamilyScrollRef}
                      style={{ overflowY: "auto", height: 250, maxHeight: 250, width: 300 }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${bgImg})`,
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {fonts.map((font, key) => {
                          const fontFamily = font.slice(0, font.search(":"));
                          return (
                            <FontLi
                              key={key}
                              title={fontFamily}
                              onClick={() => this.onChange({ fontFamily }, font)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Menu>
                  <Menu
                    label={currentFontSize || "Size"}
                    style={{
                      display: "inline-block",
                      width: 100,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState(
                        (prevState) => ({
                          isFontSizeOpen: !prevState.isFontSizeOpen,
                        }),
                        () => {
                          // scroll to the position of the item
                          const pos = fontSizes.findIndex((fontSize) => {
                            return fontSize === currentFontSize;
                          });

                          if (pos > -1) {
                            this.fontSizeScrollRef.current.scrollBy(
                              0,
                              40 * parseInt(pos, 10)
                            );
                          }
                        }
                      )
                    }
                    visible={isFontSizeOpen}
                  >
                    <div
                      ref={this.fontSizeScrollRef}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {fontSizes.map((fontSize, key) => (
                        <ListItem
                          key={key}
                          style={{ height: 40 }}
                          onClick={() => this.onChange({ fontSize: convertToRem(fontSize) }, false)}
                        >
                          {fontSize}
                        </ListItem>
                      ))}
                    </div>
                  </Menu>
                </div>
              </Panel>
            </fieldset>
          ) : null}
          {showColors ? (
            <Panel title="Choose a color...">
              <ColorPicker
                colorExpanded={false}
                {...this.props}
                onChange={(e, dbUpdate = true, wait = 500) =>
                  this.onChange({ color: e.hex }, false, dbUpdate, wait)
                }
                color={settings.properties?.color}
              />
            </Panel>
          ) : null}
          {showLineHeight ? <LineHeight {...this.props} /> : null}
        </div>
      </Section>
    );
  }
}

Typography.defaultProps = {
  showFonts: true,
  showColors: true,
  showLineHeight: false,
};

export default Typography;
