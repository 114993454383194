import { IntegrationAccountList } from "@/Settings/IntegrationManagement/ConnectedAccounts";
import Calout from "@/ui/Calout";
import Group from "@/ui/Group";
import { SettingsIcon } from "@/ui/Icons"
import Modal, { Title } from "@/ui/Modal"
import { PopupSizes } from "@/ui/Modal/types"
import Select from "@/ui/Select";
import Stepper from "@/ui/Stepper";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { CheckCircle } from "@phosphor-icons/react";
import { useState } from "react";

const ChooseIntegration = ({type="email"}) => {
  return (
    <div className="flex flex-col items-center">
      <Title>
        What {type} provider should we use to send new{" "}
        {type === "email" ? "leads" : "orders"} that come from this campaign?
      </Title>
      <Group label={`Search for an ${type} provider`}>
        <div className="overflow-y-auto h-96 w-full">
          <IntegrationAccountList />
        </div>
      </Group>
    </div>
  );
}

const ChooseCampaign = () => {
  return (
    <div className="flex flex-col items-center p-7">
      <Title>
        When there is a new lead, which campaign should we send their contact
        information to?
      </Title>
      <Select
        flavor={TextInputFlavors.MODERN}
        label="Click here to choose a campaign..."
        items={[{ label: "Campaign 1" }, { label: "Campaign 2" }]}
        onChange={() => null}
        className="w-full"
      />
    </div>
  );
}

const Success = ({ type = "email"}) => {
  return (
    <div className="flex flex-col items-center p-8">
      <div className="flex flex-col items-center px-5">
        <CheckCircle className="w-20 h-20" />
        <Title>
          Success! We've connected your {type} provider and campaign together.
        </Title>
        <Calout
          title={
            type === "email"
              ? "After publishing this campaign, any information submitted on any page in this campaign will be sent to the corresponding campaign in your Convert Kit account."
              : "After publishing this page, any purchases made from this campaign, will be recorded as a new order in your Stripe account."
          }
          // caption="In rare cases it can take up to 48 hours."
        />
      </div>
    </div>
  );
}

export const CampaignSettingsModal = ({ setIsOpen, campaignId }) => {
  const [step, setStep] = useState("Success");
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Modal
      title="Campaign Settings"
      onClose={() => setIsOpen(false)}
      size={PopupSizes.XLARGE}
      icon={<SettingsIcon />}
    >
      <div className="mx-auto w-4/5 mt-3">
        <Stepper activeStep={activeStep} numberOfSteps={3} />
      </div>
      <div className="items-center mb-7">
        {step === "ChooseIntegration" && <ChooseIntegration />}
        {step === "ChooseCampaign" && <ChooseCampaign />}
        {step === "Success" && <Success />}
      </div>
    </Modal>
  );
}

export const PaymentSettingsModal = ({ setIsOpen, campaignId }) => {
  const [step, setStep] = useState("Success");
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Modal
      title="Payment Settings"
      onClose={() => setIsOpen(false)}
      size={PopupSizes.XLARGE}
      icon={<SettingsIcon />}
    >
      <div className="mx-auto w-4/5 mt-3">
        <Stepper activeStep={activeStep} numberOfSteps={2} />
      </div>
      <div className="items-center mb-7">
        {step === "ChooseIntegration" && <ChooseIntegration type="payment" />}
        {step === "Success" && <Success type="payment" />}
      </div>
    </Modal>
  );
}