import Button from "@/ui/Button";
import { Panel, PanelTitle } from "@/ui/Panel/Panel";
import { Switch } from "@/ui/Switch";
import { ButtonTypes } from "@/ui/types";
import { useState } from "react";
import { getImageFromType } from "../utils";
import { PageEditorModal } from "../../Pages/Editor/PageEditorModal";

const ItemPanel = ({
  isEnabled,
  setIsEnabled,
  setIsPageEditorModalOpen,
  title,
  image,
  caption,
}) => {
  return (
    <Panel
      title={
        <PanelTitle // caption="Optin Page"
          caption={title} // description="Craft the perfect optin page for your campaign."
          img={image}
          description={caption}
          showLearnMore={false}
        />
      } // isExpanded={isExpanded[0]}
      isExpanded={false}
      actionButton={
        <div className="flex gap-4" onClick={(e) => e.stopPropagation()}>
          {isEnabled && (
            <Button
              label="Configure"
              type={ButtonTypes.SOFT}
              onClick={() => setIsPageEditorModalOpen(true)}
            />
          )}

          <Switch
            htmlFor="enable"
            checked={isEnabled}
            onChange={() => setIsEnabled(!isEnabled)}
          />
        </div>
      } // onToggle={() => setIsPageEditorModalOpen(true)}
      className="pb-4 cursor-pointer hover:bg-black/5 dark:hover:bg-transparent/10"
    />
  );
};

export const Customize = ({ campaignData = {} }) => {
  const [isExpanded, setIsExpanded] = useState([false, false, false]);
  const [isPageEditorModalOpen, setIsPageEditorModalOpen] = useState(false);
  const [activePageId, setActivePageId] = useState("cljywyjt04thr0811tdbv4gff");
  const [isChecked, setIsChecked] = useState(false);

  const { id } = campaignData;

  const toggleExpansion = (index) => {
    const newIsExpanded = isExpanded.map((item, i) => {
      if (i === index) {
        return !item;
      }
      return item;
    });
    setIsExpanded(newIsExpanded);
  };

  // Just Non Deleted Pages, shown in order
  const pagesInCampaign = campaignData?.objects
    ?.filter(({ deleted, type }) => !deleted && type === "PageComponent")
    .sort((a: { x: any }, b: { x: any }) => (a.x || 0) - (b.x || 0)) // order by [x]
    .map(({ id, name, type, page }) => ({
      id: page.id,
      title: name,
      caption: `/${page?.slug}`,
      image:
        getImageFromType({ type, subType: page?.type }) ||
        "https://flowbite.com/docs/images/logo.svg",
      // imageStyle: getImageFromType({ type, subType: page?.type })
      //   ? {}
      //   : {
      //       padding: "5px 15px",
      //       backgroundColor: darkMode ? "#323232" : "#f5f5f5",
      //     },
      // status: { caption: "In Progress", type: Variants.PRIMARY },
      // onClick: () => {
      //   navigate(
      //     `/${
      //       unitName !== "page" ? getLaunchPadLink(unitName) : "funnels"
      //     }/${campaignId}/${id}`
      //   );
      // },
    })) || [];

  return (
    <div className="grid grid-flow-row divide-y divide-black/10 dark:divide-white/10 gap-4">
      {isPageEditorModalOpen && (
        <PageEditorModal
          setIsOpen={setIsPageEditorModalOpen}
          campaignId={id}
          pageId={activePageId}
        />
      )}

      {pagesInCampaign.map(({ id, title, caption, image }, key) => (
        <ItemPanel
          key={key}
          setIsPageEditorModalOpen={(f) => {
            setIsPageEditorModalOpen(f);
            setActivePageId(id);
          }}
          isEnabled={isChecked}
          setIsEnabled={setIsChecked}
          title={title}
          caption={caption}
          image={image}
        />
      ))}

      {/* <Panel
        title={
          <PanelTitle
            caption="Optin Multiplier Page"
            description="Ask your subscribers to refer their friends."
            showLearnMore={false}
          />
        }
        isExpanded={false}
        actionButton={<Switch />}
        className="pb-4 cursor-pointer"
      />
      <Panel
        title={
          <PanelTitle
            caption="Thank You / Portal"
            description="Redirect or thank your subscribers for their referral."
            showLearnMore={false}
          />
        }
        isExpanded={false}
        actionButton={<Switch />}
        className="pb-4 cursor-pointer"
      /> */}

      {/* <hr /> */}

      {/* User Stats */}
      <Panel
        title={
          <PanelTitle
            caption="User Stats"
            description="Display your user stats on your optin page."
            showLearnMore={false}
          />
        }
        isExpanded={false}
        actionButton={<Switch />}
        className="pb-4 cursor-pointer"
      />

      {/* Leaderboard Page */}
      <Panel
        title={
          <PanelTitle
            caption="Leaderboard Page"
            description="Display your leaderboard on your optin page."
            showLearnMore={false}
          />
        }
        isExpanded={false}
        actionButton={<Switch />}
        className="pb-4 cursor-pointer"
      />
    </div>
  );
};
