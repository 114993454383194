import * as React from 'react';
import { get } from 'lodash';

import { ShadowStyles, ShadowProps } from './types'

import style from "../../style";

import { doColorConversion } from '../../components/groups/ColorPicker/scripts';
import ButtonSliderGroup from '../../BuildingBlocks/ButtonSliderGroup';
import ColorPicker from '../../components/groups/ColorPicker';
import FancySelector, { Icon } from '../../BuildingBlocks/FancySelector';

import { ButtonSlider } from '../../BuildingBlocks/ButtonSliderGroup/ButtonSlider';

export const createBoxShadow = ({
    shadowStyle = ShadowStyles.OUTSIDE,
    shadowColor = 'rgb(155,155,155,1)',
    shadowOffset = 0,
    shadowHorizontalOffset = false,
    shadowVerticalOffset = false,
    shadowRadius = 0,
    shadowBlur = 0,
}) => {
    if (shadowStyle === ShadowStyles.NONE) return '';

    return `${shadowHorizontalOffset || shadowOffset
        }px ${shadowVerticalOffset || shadowOffset
        }px ${shadowBlur
        }px ${shadowRadius
        }px ${shadowColor
        } ${shadowStyle === ShadowStyles.INSIDE ? 'inset' : ''
        }`;
}

export const Shadow: React.FC<ShadowProps> = (props) => {

    const {
        settings = { properties: {} },
        hideShadowStyle = false,
        hideShadowColor = false,
        hideShadowOffset = false,
        hideShadowHorizontalOffset = false,
        hideShadowVerticalOffset = false,
        hideShadowRadius = false,
        hideShadowBlur = false,
    } = props;

    const { properties = {} } = settings;

    const {
        shadowStyle = ShadowStyles.NONE,
        shadowColor = "rgb(155,155,155,1)",
        shadowOffset = 0,
        shadowHorizontalOffset = 0,
        shadowVerticalOffset = 0,
        shadowRadius = 0,
        shadowBlur = 0,
    } = properties;

    const [horizontalOffsetSliderValue, setHorizontalOffsetSliderValue] = React.useState<number>(shadowHorizontalOffset || shadowOffset || 0);
    const [verticalOffsetSliderValue, setVerticalOffsetSliderValue] = React.useState<number>(shadowVerticalOffset || shadowOffset || 0);

    const changeShadowStyle = (id) => {
        const { onChange, settings } = props;
        const boxShadow = createBoxShadow({ ...properties, shadowStyle: id })
        onChange({ shadowStyle: id, boxShadow });
    }

    const changeShadowColor = (color) => {
        const { onChange } = props;
        let rgb = get(color, "rgb", doColorConversion(color.hex, "rgb"));
        const shadowColor = `rgb(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`; // we use rgb() vs rgba() bc alpha still works and hex converter (for input field) won't convert to hex otherwise

        const boxShadow = createBoxShadow({ ...properties, shadowColor })
        onChange({ shadowColor, boxShadow });
        // console.log(shadowColor)
    }

    const changeVerticalShadowOffset = (value) => {
        const { onChange } = props;

        const offset = value === "LESS" ? parseInt(shadowVerticalOffset || shadowOffset) - 5 : parseInt(shadowVerticalOffset || shadowOffset) + 5;
        const newValue = Number.isInteger(value) ? value : offset;
        // console.log({ offset, newValue, value })
        setVerticalOffsetSliderValue(newValue);
        const boxShadow = createBoxShadow({ ...properties, shadowVerticalOffset: newValue })
        onChange({ shadowVerticalOffset: newValue, boxShadow }) // convert to rem
    }

    const changeHorizontalShadowOffset = (value) => {
        const { onChange } = props;
        const offset = value === "LESS" ? parseInt(shadowHorizontalOffset || shadowOffset) - 5 : parseInt(shadowHorizontalOffset || shadowOffset) + 5;
        const newValue = Number.isInteger(value) ? value : offset;
        // console.log({ offset, newValue, value })
        setHorizontalOffsetSliderValue(newValue);
        const boxShadow = createBoxShadow({ ...properties, shadowHorizontalOffset: newValue })
        onChange({ shadowHorizontalOffset: newValue, boxShadow }) // convert to rem
    }

    const changeShadowRadius = (value) => {
        const { onChange } = props;
        const offset = value === "LESS" ? parseInt(shadowRadius) - 2 : parseInt(shadowRadius) + 2;
        const newValue = Number.isInteger(value) ? value : offset;

        const boxShadow = createBoxShadow({ ...properties, shadowRadius: newValue })
        onChange({ shadowRadius: newValue, boxShadow }); // convert to rem
    }

    const changeShadowBlur = (value) => {
        const { onChange } = props;
        const offset = value === "LESS" ? parseInt(shadowBlur) - 2 : parseInt(shadowBlur) + 2;
        const newValue = Number.isInteger(value) ? value : offset;

        const boxShadow = createBoxShadow({ ...properties, shadowBlur: newValue })
        onChange({ shadowBlur: newValue, boxShadow }); // convert to rem
    }

    const shadowStyles = [
        {
            id: ShadowStyles.NONE,
            component: (props) => (
                <Icon
                    {...props}
                    icon="do_not_disturb"
                    highlighted={shadowStyle === ShadowStyles.NONE}
                    caption="None"
                />
            ),
        },
        {
            id: ShadowStyles.OUTSIDE,
            component: (props) => (
                <Icon
                    {...props}
                    icon="filter_none"
                    highlighted={shadowStyle === ShadowStyles.OUTSIDE}
                    caption="Outside"
                />
            ),
        },
        {
            id: ShadowStyles.INSIDE,
            component: (props) => (
                <Icon
                    {...props}
                    icon="border_style"
                    highlighted={shadowStyle === ShadowStyles.INSIDE}
                    caption="Inside"
                />
            ),
        },
    ];

    const minValue = -50;
    const maxValue = 50;

    return (
        <div>
            {!hideShadowStyle ? (
                <div className="p-3">
                    <FancySelector items={shadowStyles} onChange={changeShadowStyle} />
                </div>
                // <fieldset style={{ ...style.fieldset, padding: 15 }}>
                //     <legend>Style</legend>
                //     <FancySelector items={shadowStyles} onChange={changeShadowStyle} />
                // </fieldset>
            ) : null}

            {(!hideShadowColor && shadowStyle !== ShadowStyles.NONE) ? (
                <ColorPicker
                    {...props}
                    text="Color"
                    colorExpanded={false}
                    color={shadowColor}
                    //   onInstantChange={changeShadowColor}
                    onChange={changeShadowColor}
                    showAlpha
                />
            ) : null}

            {(!hideShadowBlur && shadowStyle !== ShadowStyles.NONE) ? (
                <ButtonSliderGroup
                    onChange={changeShadowBlur}
                    text="Blurriness"
                    button1={{ icon: "remove", value: "LESS" }}
                    button2={{ icon: "add", value: "MORE" }}
                    minValue={0}
                    maxValue={100}
                    value={shadowBlur || 0}
                    sliderContainerStyle={{ width: 250 }}
                />
            ) : null}

            {(!hideShadowOffset && shadowStyle !== ShadowStyles.NONE) ? (
                <>
                    <fieldset
                        style={{ ...style.fieldset, padding: 15 }}
                        data-testid="ButtonSliderGroup"
                    >
                        <legend>Offset</legend>
                        {!hideShadowHorizontalOffset && (<ButtonSlider
                            label="LEFT"
                            button1={{ icon: "remove", value: "LESS" }}
                            button2={{ icon: "add", value: "MORE" }}
                            doManualUpdate={() => {
                                const newValue = prompt(
                                    `Enter a number (${parseInt(minValue, 10)} - ${parseInt(maxValue, 10)})`,
                                    parseInt(horizontalOffsetSliderValue, 10)
                                );
                                changeHorizontalShadowOffset(parseInt(newValue));
                            }}
                            doChange={(e, v) => changeHorizontalShadowOffset(v)}
                            doFinalChange={(e, v) => changeHorizontalShadowOffset(v)}
                            sliderValue={horizontalOffsetSliderValue}
                            minValue={minValue}
                            maxValue={maxValue}
                            sliderContainerStyle={{ width: 200 }}
                        />)}

                        {!hideShadowVerticalOffset && (<ButtonSlider
                            label="TOP"
                            button1={{ icon: "remove", value: "LESS" }}
                            button2={{ icon: "add", value: "MORE" }}
                            doManualUpdate={() => {
                                const newValue = prompt(
                                    `Enter a number (${parseInt(minValue, 10)} - ${parseInt(maxValue, 10)})`,
                                    parseInt(verticalOffsetSliderValue, 10)
                                );
                                changeVerticalShadowOffset(parseInt(newValue));
                            }}
                            doChange={(e, v) => changeVerticalShadowOffset(v)}
                            doFinalChange={(e, v) => changeVerticalShadowOffset(v)}
                            sliderValue={verticalOffsetSliderValue}
                            minValue={minValue}
                            maxValue={maxValue}
                            sliderContainerStyle={{ width: 200 }}
                        />)}
                    </fieldset>
                </>
            ) : null}

            {(!hideShadowRadius && shadowStyle !== ShadowStyles.NONE) ? (
                <ButtonSliderGroup
                    onChange={changeShadowRadius}
                    text="Size"
                    button1={{ icon: "remove", value: "LESS" }}
                    button2={{ icon: "add", value: "MORE" }}
                    minValue={0}
                    maxValue={100}
                    value={shadowRadius || 0}
                    sliderContainerStyle={{ width: 250 }}
                />
            ) : null}
        </div>
    )
}

export default Shadow;