import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Panel, { PanelItem } from "@/ui/Panel";

export const NextDestinationView = ({ id }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  // const { navigate } = useNavigate();

  useEffect(() => {
    // console.log("NextDestinationView");
    (async () => {
      // fetch details from http://localhost:8100/next/{id}
      const resp = await fetch(`https://pfoc-server-1165efc8841d.herokuapp.com/next/${id}`);
      const data = await resp.json();
      setData(data);
      console.log("next destination data", data);
      setLoading(false);
    })();
  }, []);

  const { churchName, churchLocation, pastorName } = data;


  return (
    <Panel
      title={<h3 className="text-sm font-semibold">Next Destination</h3>}
      // className="mt-4"
    >
      {loading ? (
        <p className="py-4">Loading...</p>
      ) : (
        <PanelItem
          title={`${churchName} in ${churchLocation}`}
          description={`Pastor ${pastorName}`}
          buttonLabel="Open Church Record"
          onClick={() => {
            window.location.href = `/crm/contacts/${data?.id}`;
          }} />
      )}
    </Panel>
  );
};
