import { useEffect, useState } from "react";

import { Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
// import { ShieldIcon } from "@/ui/Icons";
import {
  APISettingsModal,
  CustomWebHookModal,
  DeleteIntegrationModal,
} from "./Popups";
import { getUserIntegrations } from "../../api/integrations";
import { getSessionToken } from "@/api/auth";
import { ConnectedAccounts } from "./ConnectedAccounts";
import { CustomWebHooks } from "./CustomWebHooks";

export const Integrations = () => {
  const [isIntegrationsPopupShowing, setIntegrationsPopupIsShowing] =
    useState(false);
  const [isCustomWebHookModalShowing, setIsCustomWebHookModalShowing] =
    useState(false);
  const [isDeleteIntegrationModalShowing, setIsDeleteIntegrationModalShowing] =
    useState(false);

  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [activeIntegrationId, setActiveIntegrationId] = useState(null);
  const [activeProviderId, setActiveProviderId] = useState(null);

  const loadIntegrations = async () => {
    const token = getSessionToken();
    const response = await getUserIntegrations({ token });
    setActiveIntegrations(response);
  };

  useEffect(() => {
    loadIntegrations();
  }, []);

  return (
    <Page topBar={{ page: [{ label: "Integrations" }] }}>
      {isIntegrationsPopupShowing && (
        <APISettingsModal
          activeProviderId={activeProviderId}
          activeIntegrationId={activeIntegrationId}
          setIsOpen={setIntegrationsPopupIsShowing}
        />
      )}

      {isCustomWebHookModalShowing && (
        <CustomWebHookModal
          activeIntegrationId={activeIntegrationId}
          setIsOpen={setIsCustomWebHookModalShowing}
        />
      )}

      {isDeleteIntegrationModalShowing && (
        <DeleteIntegrationModal
          activeIntegrationId={activeIntegrationId}
          setIsOpen={setIsDeleteIntegrationModalShowing}
        />
      )}

      <Container>
        <TabsComponent
          tabs={[
            { label: "Profile", value: "profile" },
            { label: "Domain Names", value: "domains" },
            { label: "Integrations", value: "integrations" },
            { label: "Onboarding", value: "onboarding" },
          ]}
          activeTab={"integrations"}
          setActiveTab={(val) => {
            window.location.href = `/${val}`;
          }}
          actionSection={false}
        />

        <div className="grid grid-cols-1 gap-5">
          <ConnectedAccounts
            setIntegrationsPopupIsShowing={setIntegrationsPopupIsShowing}
            showIntegrationsPopup={(id, providerId) => {
              console.log({ id, providerId });
              if (providerId) setActiveProviderId(providerId);
              setActiveIntegrationId(id);
              setIntegrationsPopupIsShowing(true);
            }}
            activeIntegrations={activeIntegrations}
            setActiveIntegrations={setActiveIntegrations}
            activeIntegrationId={activeIntegrationId}
            setActiveIntegrationId={setActiveIntegrationId}
            setActiveProviderId={setActiveProviderId}
            setIsDeleteIntegrationModalShowing={
              setIsDeleteIntegrationModalShowing
            }
          />

          <CustomWebHooks
            setIsCustomWebHookModalShowing={setIsCustomWebHookModalShowing}
            activeIntegrations={activeIntegrations}
            setActiveIntegrations={setActiveIntegrations}
            activeIntegrationId={activeIntegrationId}
            setActiveIntegrationId={setActiveIntegrationId}
            setIsDeleteIntegrationModalShowing={
              setIsDeleteIntegrationModalShowing
            }
          />
        </div>
      </Container>
    </Page>
  );
};
