import { ChatConversationProps } from "./types";
import PromptMessageInput from "./PromptMessageInput";
import PromptMessageContainer from "./PromptMessageContainer";
import { Sparkle } from "@phosphor-icons/react";
import { useState } from "react";
// import FileInput from "./FileInput";
// import SearchInput from "./SearchInput";

export const ChatConversation: React.FC<ChatConversationProps> = ({
  containerType = "Minimal", // default container type is "Minimal"
  inputSettings = {}, // default prompt input settings is an empty object
  containerSettings = {}, // default prompt container settings is an empty object
  fileInputSettings = {}, // default file input settings is an empty object
  searchInputSettings = {}, // default search input settings is an empty object
  onPromptSubmit = () => {}, // default onPromptSubmit function is an empty function
  onFileSubmit = () => {}, // default onFileSubmit function is an empty function
}) => {
  const { messages = [] } = containerSettings;

  return (
    <div data-testid="ChatConversation" className={`${containerType} h-full`}>
      {/* The heading for the chat conversation */}
      {/* <h2>ChatConversation</h2> */}

      {/* The prompt message container component */}
      <div
        className="overflow-y-auto"
        style={{
          height: Boolean(inputSettings?.suggestions?.length)
            ? `calc(100vh - 185px)`
            : `calc(100vh - 145px)`,
        }}
        id="chat-conversation-container"
      >
        {/* empty state */}
        {messages.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <Sparkle
              size={64}
              className="rounded-full bg-white text-black p-3"
            />
            <p className="text-gray-400 text-2xl p-4">
              How can I help you today?
            </p>
          </div>
        )}

        {/* Render the prompt message container */}
        {messages.length > 0 && (
          <PromptMessageContainer {...containerSettings} />
        )}
      </div>

      {/* The file input component */}
      {/* <FileInput
        data-testid="FileInput" // test id for the file input component
        onChange={onFileSubmit} // function to be called when file is submitted
        {...fileInputSettings} // spread operator to pass file input settings as props
      /> */}

      {/* The prompt message input component */}
      <div style={{ marginTop: 18 }}>
        <form id="chat-form" onSubmit={inputSettings.onSubmit}>
          <div className="py-0">
            {/* <input 
              value={inputSettings.value} 
              onChange={inputSettings.onChange} 
              className="w-full max-w-md border border-gray-300 rounded mb-8 shadow-xl p-2 dark:text-black" 
            /> */}
            <PromptMessageInput
              data-testid="PromptMessageInput" // test id for the prompt message input component
              // onChange={onPromptSubmit} // function to be called when prompt message is submitted
              // onChange={(e) => {
              //   console.log(e.target.value);
              //   setPromptValue(e.target.value);
              // }}
              // onSuggestionClick={(str: string) => {
              //   console.log(str);
              //   setPromptValue(str);
              //   // submit the form using the provided str
              //   // (
              //   //   document.getElementById("chat-form") as HTMLFormElement
              //   // )?.submit();
              // }}
              {...inputSettings} // spread operator to pass prompt input settings as props
              // value={promptValue}
            />
          </div>
        </form>
      </div>
      {/* The search input component */}
      {/* <SearchInput
        data-testid="SearchInput" // test id for the search input component
        {...searchInputSettings} // spread operator to pass search input settings as props
      /> */}
    </div>
  );
};

export default ChatConversation;
