import FileInput from "@/ui/CommunicationView/ChatConversation/FileInput";
import Panel from "@/ui/Panel";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

export const ProductDetails = ({
  data = {},
  campaignData = {},
  unitName = "Product",
  onChange = () => {},
}) => {
  const { price, media } = data;
  return (
    <Panel title={`Pricing & Media`}>
      {/* Price */}
      <TextInput
        flavor={TextInputFlavors.MODERN}
        label={`${unitName} Price`}
        value={price}
        placeholder={`${unitName} Price`}
        name="price"
        type={TextInputTypes.TEXT}
        // onChange={onChange}
      />

      {/* Media */}
      <p className="font-semibold py-3 mt-3">Media</p>
      <FileInput />

      <p className="font-semibold py-3 mt-3">Files</p>
      <FileInput 
        instruction="Unlimited files, 5GB total limit"
      />
    </Panel>
  );
};