import { Page, Container } from '../Page';

import {
	ShowAllTheBadges,
} from "@/ui/all";
import { Dropdown } from "@/ui/Dropdown";
import { Button } from "@/ui/Button";
import { Breadcrumbs } from "@/ui/Breadcrumbs";
import { SimpleContainer } from "@/ui/SimpleContainer";
import { Avatar } from "@/ui/Avatar/Avatar";

import { Pagination } from "@/ui/Pagination";

import {
	HomeIcon,
	FlashIcon,
	SuccessAlertIcon,
	WarningAlertIcon,
	DangerAlertIcon,
	PrimaryAlertIcon,
} from "@/ui/Icons";

import {
	ButtonTypes,
	Variants,
	DropDownTypes,
} from "@/ui/types";

import { AllAlerts } from "@/ui/Alert";
// import { Badges } from "@/ui/Badges";

export const Elements: React.FC = () => {

	const breadCrumbItems = [
		{ label: "Home" },
		{ label: "Elements" },
		{ label: "Breadcrumb" },
	];

	const basicDropdownItems = [
		{ label: "Action", href: "#" },
		{ label: "Another action", href: "#" },
		{ label: "Something else here", href: "#" },
	];

	return (
		<Page topbar={{ page: [{ label: "Elements" }] }}>
			<Container>
				<p className="text-lg font-semibold">Elements</p>

				{/* Avatars */}
				<a name="avatars" />
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Rounded Avatar">
						<Avatar
							type="Rounded"
							src="/assets/images/avatar-1.png"
							height="24"
							width="24"
						/>
						<Avatar
							type="Rounded"
							src="/assets/images/avatar-1.png"
							height="20"
							width="20"
						/>
						<Avatar
							type="Rounded"
							src="/assets/images/avatar-1.png"
							height="16"
							width="16"
						/>
						<Avatar
							type="Rounded"
							src="/assets/images/avatar-1.png"
							height="12"
							width="12"
						/>
						<Avatar
							type="Rounded"
							src="/assets/images/avatar-1.png"
							height="10"
							width="10"
						/>
					</SimpleContainer>
					<SimpleContainer label="Square Avatar">
						<Avatar
							type="Square"
							src="/assets/images/avatar-1.png"
							height="24"
							width="24"
						/>
						<Avatar
							type="Square"
							src="/assets/images/avatar-1.png"
							height="20"
							width="20"
						/>
						<Avatar
							type="Square"
							src="/assets/images/avatar-1.png"
							height="16"
							width="16"
						/>
						<Avatar
							type="Square"
							src="/assets/images/avatar-1.png"
							height="12"
							width="12"
						/>
						<Avatar
							type="Square"
							src="/assets/images/avatar-1.png"
							height="10"
							width="10"
						/>
					</SimpleContainer>

					<SimpleContainer label="Avatar With Dots">
						<Avatar
							type="Dots"
							src="/assets/images/avatar-1.png"
							height="24"
							width="24"
						/>
						<Avatar
							type="Dots"
							src="/assets/images/avatar-1.png"
							height="20"
							width="20"
						/>
						<Avatar
							type="Dots"
							src="/assets/images/avatar-1.png"
							height="16"
							width="16"
						/>
						<Avatar
							type="Dots"
							src="/assets/images/avatar-1.png"
							height="12"
							width="12"
						/>
						<Avatar
							type="Dots"
							src="/assets/images/avatar-1.png"
							height="10"
							width="10"
						/>
					</SimpleContainer>

					<SimpleContainer label="Avatar group">
						<Avatar
							type="Group"
							srcList={[
								"/assets/images/avatar-2.png",
								"/assets/images/avatar-3.png",
								"/assets/images/avatar-5.png",
								"/assets/images/avatar-8.png",
							]}
							extraCount="99"
							height="24"
							width="24"
						/>
					</SimpleContainer>

					<SimpleContainer label="Avatar with text">
						<Avatar
							type="Text"
							src="/assets/images/avatar-1.png"
							label="David Jackson"
							caption="Frontend Developer"
						/>
					</SimpleContainer>

					<SimpleContainer label="Initial Avatar">
						<Avatar type="Default" label="KG" color="bg-indigo-300" />
						<Avatar type="Default" label="KG" color="bg-lightpurple-200" />
						<Avatar type="Default" label="KG" color="bg-lightblue-200" />
						<Avatar type="Default" label="KG" color="bg-lightgreen-100" />
						<Avatar type="Default" label="KG" color="bg-lightyellow" />
						<Avatar type="Default" label="KG" color="bg-lightred" />
					</SimpleContainer>

					<SimpleContainer label="Square Initial Avatar">
						<Avatar type="DefaultSquare" label="KG" color="bg-indigo-300" />
						<Avatar type="DefaultSquare" label="KG" color="bg-lightpurple-200" />
						<Avatar type="DefaultSquare" label="KG" color="bg-lightblue-200" />
						<Avatar type="DefaultSquare" label="KG" color="bg-lightgreen-100" />
						<Avatar type="DefaultSquare" label="KG" color="bg-lightyellow" />
						<Avatar type="DefaultSquare" label="KG" color="bg-lightred" />
					</SimpleContainer>
				</div>



				{/* Breadcrumbs */}
				<a name="breadcrumbs" />
				<div className="grid grid-cols-1 gap-7">
					<SimpleContainer label="Breadcrumb">
						<Breadcrumbs items={breadCrumbItems} type="Arrow" />
					</SimpleContainer>

					<SimpleContainer label="Separators Breadcrum">
						<Breadcrumbs items={breadCrumbItems} type="Pipe" />
						<Breadcrumbs items={breadCrumbItems} type="Slash" />
					</SimpleContainer>

					<SimpleContainer label="Breadcrumb With Icon">
						<Breadcrumbs
							items={[
								{ icon: <HomeIcon /> },
								{ label: "Elements", icon: <FlashIcon /> },
								{ label: "Calendar " },
							]}
							type="Icon"
						/>
					</SimpleContainer>
				</div>

				{/* Buttons */}
				<a name="buttons" />
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Buttons">
						<Button variant={Variants.PRIMARY} label="Primary" />
						<Button variant={Variants.SECONDARY} label="Secondary" />
						<Button variant={Variants.SUCCESS} label="Success" />
						<Button variant={Variants.INFO} label="Info" />
						<Button variant={Variants.WARNING} label="Warning" />
						<Button variant={Variants.DANGER} label="Danger" />
					</SimpleContainer>
					<SimpleContainer label="Rounded Button">
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.PRIMARY}
							label="Primary"
						/>
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.SECONDARY}
							label="Secondary"
						/>
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.SUCCESS}
							label="Success"
						/>
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.INFO}
							label="Info"
						/>
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.WARNING}
							label="Warning"
						/>
						<Button
							type={ButtonTypes.ROUNDED}
							variant={Variants.DANGER}
							label="Danger"
						/>
					</SimpleContainer>
					<SimpleContainer label="Outlined Button">
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.PRIMARY}
							label="Primary"
						/>
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.SECONDARY}
							label="Secondary"
						/>
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.SUCCESS}
							label="Success"
						/>
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.INFO}
							label="Info"
						/>
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.WARNING}
							label="Warning"
						/>
						<Button
							type={ButtonTypes.OUTLINED}
							variant={Variants.DANGER}
							label="Danger"
						/>
					</SimpleContainer>
					<SimpleContainer label="Disabled Button">
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.PRIMARY}
							label="Primary"
						/>
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.SECONDARY}
							label="Secondary"
						/>
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.SUCCESS}
							label="Success"
						/>
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.INFO}
							label="Info"
						/>
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.WARNING}
							label="Warning"
						/>
						<Button
							type={ButtonTypes.DISABLED}
							variant={Variants.DANGER}
							label="Danger"
						/>
					</SimpleContainer>
					<SimpleContainer label="Buttons with Label">
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.PRIMARY}
							label="Primary"
						/>
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.SECONDARY}
							label="Secondary"
						/>
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.SUCCESS}
							label="Success"
						/>
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.INFO}
							label="Info"
						/>
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.WARNING}
							label="Warning"
						/>
						<Button
							type={ButtonTypes.WITH_LABEL}
							variant={Variants.DANGER}
							label="Danger"
						/>
					</SimpleContainer>
					<SimpleContainer label="Button with Leading Icon">
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.PRIMARY}
							label="Primary"
						/>
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.SECONDARY}
							label="Secondary"
						/>
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.SUCCESS}
							label="Success"
						/>
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.INFO}
							label="Info"
						/>
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.WARNING}
							label="Warning"
						/>
						<Button
							type={ButtonTypes.WITH_ICON}
							variant={Variants.DANGER}
							label="Danger"
						/>
					</SimpleContainer>
					<SimpleContainer label="Icon Buttons">
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.PRIMARY} />
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.SECONDARY} />
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.SUCCESS} />
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.INFO} />
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.WARNING} />
						<Button type={ButtonTypes.ICON_BUTTON} variant={Variants.DANGER} />
					</SimpleContainer>
				</div>

				{/* Dropdowns */}
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Basic Dropdown">
						<Dropdown type={DropDownTypes.BASIC} items={basicDropdownItems} />
						<Dropdown
							withBorder
							type={DropDownTypes.BASIC}
							items={basicDropdownItems}
						/>
					</SimpleContainer>
					<SimpleContainer label="Variant Dropdown">N/A</SimpleContainer>
					<SimpleContainer label="Split Button Dropdowns">N/A</SimpleContainer>
					<SimpleContainer label="Icon with Dropdowns">N/A</SimpleContainer>
					<SimpleContainer label="Sizes Dropdown">N/A</SimpleContainer>
					<SimpleContainer label="Dropdown With icons">N/A</SimpleContainer>
				</div>

				{/* Alert Badges */}
				<a name="alerts" />
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
					<AllAlerts />
				</div>
				{/* Alert Examples */}

				{/* Badge Examples */}
				<a name="badges" />
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
					<ShowAllTheBadges />
				</div>

				{/* Alert Examples */}
				<a name="alert_examples" />
				<div className="border col-span-1 lg:col-span-2 border-black/10 mt-7 dark:border-white/10 p-5 rounded-md">
					<div className="mb-5">
						<p className="text-sm font-semibold">Alert Examples</p>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-5">
						<div className="relative text-center rounded border-2 border-indigo-400 bg-indigo-100 px-6 py-3 text-indigo-600">
							<PrimaryAlertIcon />
							<h3 className="text-lg font-semibold">Primary</h3>
							<p className="mt-1 mb-4 text-black/50">A simple Primary alert</p>
							<button
								type="button"
								className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
							>
								<svg
									className="w-4 h-4"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
										fill="currentcolor"
									/>
									<path
										d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
										fill="currentcolor"
									/>
								</svg>
							</button>
						</div>
						<div className="relative text-center rounded border-2 border-lightgreen-100/80 bg-lightgreen-100/20 px-6 py-3 text-green-800">
							<SuccessAlertIcon />
							<h3 className="text-lg font-semibold">Success</h3>
							<p className="mt-1 mb-4 text-black/50">A simple success alert</p>
							<button
								type="button"
								className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
							>
								<svg
									className="w-4 h-4"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
										fill="currentcolor"
									/>
									<path
										d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
										fill="currentcolor"
									/>
								</svg>
							</button>
						</div>
						<div className="relative text-center rounded border-2 border-lightyellow/80 bg-lightyellow/20 px-6 py-3 text-yellow-600">
							<WarningAlertIcon />
							<h3 className="text-lg font-semibold">Warning</h3>
							<p className="mt-1 mb-4 text-black/50">A simple warning alert</p>
							<button
								type="button"
								className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
							>
								<svg
									className="w-4 h-4"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
										fill="currentcolor"
									/>
									<path
										d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
										fill="currentcolor"
									/>
								</svg>
							</button>
						</div>
						<div className="relative text-center rounded border-2 border-lightred/80 bg-lightred/20 px-6 py-3 text-red-600">
							<DangerAlertIcon />
							<h3 className="text-lg font-semibold">Danger</h3>
							<p className="mt-1 mb-4 text-black/50">A simple danger alert</p>
							<button
								type="button"
								className="absolute top-2 right-2 hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
							>
								<svg
									className="w-4 h-4"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M24.2929 6.29289L6.29289 24.2929C6.10536 24.4804 6 24.7348 6 25C6 25.2652 6.10536 25.5196 6.29289 25.7071C6.48043 25.8946 6.73478 26 7 26C7.26522 26 7.51957 25.8946 7.70711 25.7071L25.7071 7.70711C25.8946 7.51957 26 7.26522 26 7C26 6.73478 25.8946 6.48043 25.7071 6.29289C25.5196 6.10536 25.2652 6 25 6C24.7348 6 24.4804 6.10536 24.2929 6.29289Z"
										fill="currentcolor"
									/>
									<path
										d="M7.70711 6.29289C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711L24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25C26 24.7348 25.8946 24.4804 25.7071 24.2929L7.70711 6.29289Z"
										fill="currentcolor"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>

				{/* Pagination Examples */}
				<a name="pagination" />
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Default Pagination" display="block">
						<Pagination
							showPrevNextButtons
							showFirstLastButtons
							currentPage={2}
							totalPages={3}
							fill="outline"
							onPageChange={console.log}
						/>

						<Pagination
							showPrevNextButtons
							currentPage={3}
							totalPages={5}
							gap={0}
							fill="outline"
							onPageChange={console.log}
						/>
					</SimpleContainer>

					<SimpleContainer label="Solid Pagination" display="block">
						<Pagination
							showFirstLastButtons
							showPrevNextButtons
							currentPage={2}
							totalPages={3}
							fill="solid"
							onPageChange={console.log}
						/>

						<Pagination
							showPrevNextButtons
							currentPage={3}
							totalPages={5}
							gap={0}
							fill="solid"
							onPageChange={console.log}
						/>
					</SimpleContainer>

					<SimpleContainer label="Pagination with Icons" display="block">
						<Pagination
							showFirstLastButtons
							showPrevNextButtons
							currentPage={2}
							totalPages={3}
							showIcons
							fill="solid"
							onPageChange={console.log}
						/>
						<Pagination
							showPrevNextButtons
							currentPage={2}
							totalPages={3}
							gap={0}
							showIcons
							fill="solid"
							onPageChange={console.log}
						/>
					</SimpleContainer>

					<SimpleContainer label="Pagination with Icons and Rounded" display="block">
						<Pagination
							showFirstLastButtons
							showPrevNextButtons
							currentPage={2}
							totalPages={3}
							showIcons
							circles
							fill="solid"
							onPageChange={console.log}
						/>
						<Pagination
							showPrevNextButtons
							currentPage={2}
							totalPages={3}
							gap={0}
							showIcons
							rounded
							fill="solid"
							onPageChange={console.log}
						/>
					</SimpleContainer>
				</div>

			</Container>
		</Page>
	);
};
