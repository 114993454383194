import { TextInputFlavors } from "../TextInput/types";

interface SwitchProps {
  htmlFor?: string;
  checked?: boolean;
  onChange?: () => void;
}

export const Switch: React.FC<SwitchProps> = ({
  htmlFor,
  checked = false,
  label = "",
  title = "",
  flavor = TextInputFlavors.CLASSIC,
  onChange = () => null,
}) => {

  let containerClass = `togglebutton inline-block`;
  if (flavor === TextInputFlavors.MODERN) containerClass = `relative rounded-lg px-5 py-4 w-full bg-white dark:bg-white/5 border border-black/10 dark:border-white/10`;

  const switchContent = (
    <label htmlFor={htmlFor} className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          id={htmlFor}
          className="sr-only"
          onChange={onChange}
          checked={checked}
        />
        <div className="block band bg-black/20 dark:bg-white/20 w-7 h-4 rounded-full"></div>
        <div className="dot absolute left-[1.5px] top-[2px] bg-white dark:bg-black w-3 h-3 rounded-full transition"></div>
      </div>
      <div className="ml-1.5 text-black dark:text-white">{label}</div>
    </label>
  );

  return (
    <div className={containerClass}>
      {flavor === TextInputFlavors.MODERN ? (
        <>
          <p className="text-xs text-black/40 dark:text-white/40 mb-1.5">
            {title}
          </p>
          <div className="togglebutton inline-block">{switchContent}</div>
        </>
      ) : (
        switchContent
      )}
    </div>
  );
};
