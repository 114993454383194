// import { Calout } from "@/ui/Alerts";
import { useEffect, useState } from "react";
import { Panel } from "@/ui/Panel";
import { Stepper } from "@/ui/Stepper";
import { Dropdown } from "@/ui/Dropdown";
import { DropDownTypes } from "@/ui/types";

import { createPage, getNewPageContent, makeSlug, updatePage } from "@/api/pages";
import { getCampaign, getCampaigns, updateObject } from "@/api/campaigns";
import { FooterButtons } from "@/Settings/DomainManagement/Popups";
// import { Calout } from "@/ui/Calout";
import { CheckCircle } from "@phosphor-icons/react";
import { getSessionToken } from "@/api/auth";

export const generatePageScript = (url) => `
  <script type="text/javascript">
    window.location.href = "/${url}";
  </script>
`;

export const saveAnotherFunnelSettings = async ({ /*sourceCampaignId,*/ sourceObject, pages, targetCampaignId, targetObjectId }) => {

  const currentPageSettings = sourceObject?.page?.pageSettings || {};
  const url = pages.find(({ id }) => id === targetObjectId)?.page?.slug || "";

  const pageId = pages.find(({ id }) => id === targetObjectId)?.page?.id;
  // console.log("The URL", generatePageScript(url), { sourceObject, pageId });

  const token = getSessionToken();
  const pageSettings = {
    ...currentPageSettings,
    tracking: {
      ...currentPageSettings?.tracking,
      head: generatePageScript(url),
    },
  };

  // update the page with the new settings
  // let pageResponse;
  // if (!pageId) {
  //   pageResponse = await createPage({
  //     token,
  //     settings: {
  //       name: sourceObject?.name,
  //       campaignId: sourceCampaignId,
  //       slug: makeSlug(sourceObject?.name),
  //       content: getNewPageContent(),
  //       pageSettings,
  //     },
  //   });
  // } else {
    const pageResponse = await updatePage(pageId, { token, settings: { pageSettings } });
  // }

  console.log({ pageResponse });

  // now update the node data with the new settings
  const objSettings = { url, campaignId: targetCampaignId, objectId: targetObjectId };
  const nodeResponse = await updateObject(sourceObject?.id, { token, settings: objSettings });
  return { nodeResponse, objSettings, pageSettings };

};

export const Properties = ({
  campaignId,
  nodeData,
  nodes,
  setNodes,
  setEditDrawerOpen,
}) => {
  // const [url, setUrl] = useState(nodeData?.data?.settings?.url || "");
  const [activeStep, setActiveStep] = useState("funnel");
  const [funnels, setFunnels] = useState([]);
  const [selectedFunnelId, setSelectedFunnelId] = useState(
    nodeData?.data?.settings?.campaignId || null
  );
  const [pages, setPages] = useState([]);
  const [selectedObjectId, setSelectedObjectId] = useState(
    nodeData?.data?.settings?.objectId || null
  );  

  const saveSettings = async () => {
    // save the settings
    
    // const currentPageSettings = nodeData?.data?.page?.pageSettings || {};
    // const url =
    //   pages.find(({ id }) => id === selectedObjectId)?.page?.slug || "";
    // const objectId = selectedObjectId;
    // const pageId = pages.find(({ id }) => id === selectedObjectId)?.page?.id;
    // console.log("The URL", generatePageScript(url), { nodeData, pageId });

    // const token = getSessionToken();
    // const pageSettings = {
    //   ...currentPageSettings,
    //   tracking: {
    //     ...currentPageSettings?.tracking,
    //     head: generatePageScript(url),
    //   },
    // };

    // // update the page with the new settings
    // const pageResponse = await updatePage(pageId, {
    //   token,
    //   settings: { pageSettings },
    // });
    // console.log({ pageResponse });

    // // now update the node data with the new settings

    // const objSettings = {
    //   url,
    //   campaignId: selectedFunnelId,
    //   objectId,
    // };

    // const nodeResponse = await updateObject(nodeData?.id, {
    //   token,
    //   settings: objSettings,
    // });

    const { objSettings, pageSettings } = await saveAnotherFunnelSettings({
      sourceCampaignId: campaignId,
      sourceObject: nodeData.data,
      pages,
      targetObjectId: selectedObjectId,
      targetCampaignId: selectedFunnelId,
    });

    // console.log({ nodeResponse });

    // const newNodes = nodes.map((node) => {
    //   if (node.id === selectedFunnelId) {
    //     return {
    //       ...node,
    //       settings: {
    //         ...node.settings,
    //         ...objSettings,
    //       },
    //       data: {
    //         ...node.data,
    //         page: {
    //           ...node.data.page,
    //           pageSettings,
    //         },
    //       },
    //     };
    //   }
    //   return node;
    // });

    // setNodes(newNodes);

    // alert("Your changes have been saved");
    // setEditDrawerOpen(false);
    // window.location.reload();
  };

  const getActiveStepNumber = () => {
    if (activeStep === "funnel") {
      return 1;
    }
    if (activeStep === "page") {
      return 2;
    }
    if (activeStep === "success") {
      return 3;
    }
  };

  // get the list of campaigns from the API using the provided campaignId
  const loadFunnels = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token });
    if (response) {
      const funnels = response?.filter(({ deleted }) => !deleted);
      setFunnels(funnels);
    }
  };

  const loadPages = async () => {
    // get the pages for the selected funnel
    const token = getSessionToken();
    const response = await getCampaign({ token, id: selectedFunnelId });
    if (response) {
      const pagesOnly = response?.objects?.filter(
        ({ deleted, type }) => !deleted && type === "PageComponent"
      );
      console.log({ pagesOnly });
      setPages(pagesOnly);
    }
  };

  const handleBackButtonClick = () => {
    if (activeStep === "page") {
      setActiveStep("funnel");
    } else if (activeStep === "success") {
      setActiveStep("page");
    }
  };

  const handleNextButtonClick = () => {
    if (activeStep === "funnel") {
      if (selectedFunnelId) {
        setActiveStep("page");
      } else {
        alert("Please select a funnel");
      }
    } else if (activeStep === "page") {
      if (selectedObjectId) {
        setActiveStep("success");
      } else {
        alert("Please select a page");
      }
    } else if (activeStep === "success") {
      saveSettings();
    }
  };

  useEffect(() => {
    loadFunnels();
    console.log({ nodeData });
  }, []);

  useEffect(() => {
    // load the pages for the selected funnel
    if (selectedFunnelId) {
      loadPages();
    }
  }, [selectedFunnelId]);

  return (
    <div className="grid grid-flow-row gap-7">
      <div className="mx-auto w-4/5 mt-3">
        <Stepper activeStep={getActiveStepNumber()} numberOfSteps={3} />
      </div>

      {activeStep === "funnel" && (
        <Panel title="Select the funnel that you would like this object to link to...">
          <div className="grid grid-flow-row gap-7">
            <Dropdown
              // make the label be the name of the selected funnel (pulled using the selectedFunnelId & the funnels array)
              label={
                funnels.find(({ id }) => id === selectedFunnelId)?.name ||
                `Select a funnel`
              }
              items={funnels.map(({ id, name }) => ({ id, label: name }))}
              type={DropDownTypes.BASIC}
              onClick={(key) => {
                // console.log(id);
                setSelectedFunnelId(funnels[key]?.id);
                console.log({ selectedFunnelId: funnels[key]?.id });
              }}
            />
          </div>
        </Panel>
      )}

      {activeStep === "page" && (
        <Panel title="Now choose the page in that funnel that you would like this object to link to...">
          <div className="grid grid-flow-row gap-7">
            <Dropdown
              // make the label be the name of the selected page (pulled using the selectedObjectId & the pages array)
              label={
                pages.find(({ id }) => id === selectedObjectId)?.name ||
                `Select a page`
              }
              items={pages.map(({ id, name }) => ({ id, label: name }))}
              type={DropDownTypes.BASIC}
              onClick={(key) => {
                const objId = pages[key]?.id;
                setSelectedObjectId(objId);
                console.log({ selectedObjectId: objId });
              }}
            />
          </div>
        </Panel>
      )}

      {activeStep === "success" && (
        <div className="grid grid-flow-row gap-7 text-center">
          <CheckCircle
            className="mx-auto dark:text-white/40 text-green-600"
            size={120}
          />
          <h2 className="text-lg">Your Funnel Has Been Updated</h2>
          {/* {selectedObjectId} */}
          <p className="text-sm dark:text-white/40">
            Press [Save Settings] to save your
            <br />
            changes and close this window.
          </p>
        </div>
      )}

      <FooterButtons
        position="center"
        showBack={activeStep !== "funnel"}
        showNext={activeStep !== "success"}
        showSave={activeStep === "success"}
        showCancel
        onCancel={() => setEditDrawerOpen(false)}
        onBack={handleBackButtonClick}
        onNext={handleNextButtonClick}
        onSave={saveSettings}
      />
    </div>
  );
};
