import React from "react";
import { PromptMessageContainerProps, MessageType } from "../types";
import { ThumbsDown, ThumbsUp } from "@phosphor-icons/react";
import Markdown from "react-markdown";

export const PromptMessageContainer: React.FC<PromptMessageContainerProps> = ({
  type = "Minimal",
  messages = [],
  withFeedbackButtons = false,
  feedbackButtons = [
    {
      // label: "Yes",
      onClick: () => {},
      icon: <ThumbsUp size={24} className="p-1" />,
      // tooltip: "Yes",
    },
    {
      // label: "No",
      onClick: () => {},
      icon: <ThumbsDown size={24} className="p-1" />,
      // tooltip: "No",
    },
  ],
  withIcon = true,
}) => {
  // Render a single message bubble
  const renderMessageCaption = (message: MessageType, index: number) => {
    const { caption, from, type, icon } = message;
    const messageCaptionTestId = "message-caption";
    const messageIconTestId = "message-icon";

    const isFromUser = from === "User";

    return (
      <div
        key={index}
        className={`
        ${
          isFromUser
            ? "flex flex-row my-1 px-4 py-8 sm:px-6 bg-lightwhite dark:bg-white/5"
            : "flex my-1 px-4 py-8 sm:px-6"
        }
      `}
      >
        {/* Render the message icon */}
        {withIcon && icon && (
          <div data-testid={messageIconTestId}>
            {typeof icon === "string" ? (
              <img
                src={icon}
                className="mr-2 flex h-8 w-8 rounded-full sm:mt-4"
              />
            ) : (
              icon
            )}
          </div>
        )}

        {/* Render the message caption */}
        <div>
          {caption && (
            <div
              data-testid={messageCaptionTestId}
              className="flex max-w-3xl items-center"
            >
              <div
                style={{ whiteSpace: "pre-wrap" }}
                className={`whitespace-pre-wrap ${
                  isFromUser ? "" : "max-w-3xl"
                }`}
              >
                {/* Render the message text */}
                <Markdown
                  children={caption}
                  components={{
                    h1: ({ node, ...props }) => <h1 {...props} className="text-2xl font-semibold" />,
                    h2: ({ node, ...props }) => <h2 {...props} className="text-xl font-semibold" />,
                    h3: ({ node, ...props }) => <h3 {...props} className="text-lg font-semibold" />,
                    ul: ({ node, ...props }) => <ul {...props} className="!list-decimal list-inside" />,
                    ol: ({ node, ...props }) => <ol {...props} className="!list-decimal list-inside" />,
                    li: ({ node, ...props }) => <li {...props} className="" />,
                    a: ({ node, ...props }) => <a {...props} className="font-bold underline" />,
                    p: ({ node, ...props }) => <span {...props} className="inline-block" />,
                  }}
                />
                {/* {type === "text" ? (
                  <Markdown>{caption}</Markdown>
                ) : (
                  // <span dangerouslySetInnerHTML={{ __html: caption }} />
                  <div>
                    <Markdown>{caption}</Markdown>
                  </div>
                )} */}
              </div>
            </div>
          )}
          {withFeedbackButtons && !isFromUser && <div className="mt-4 gap-3 flex">{renderFeedbackButtons(caption)}</div>}
        </div>
      </div>
    );
  };

  // Render a single message caption
  const renderMessageBubble = (message: MessageType, index: number) => {
    const { caption, from } = message;
    const messageBubbleTestId = "message-bubble";
    // const messageIconTestId = "message-icon";

    const isFromUser = from === "User";

    return (
      <div
        key={index}
        data-testid={messageBubbleTestId}
        className={`${
          isFromUser
            ? `place-self-end text-right`
            : `place-self-start text-left`
        }`}
      >
        {/* Render the message icon */}
        {/* {withIcon && message.icon && (
          <div data-testid={messageIconTestId}>{message.icon}</div>
        )} */}

        {caption && (
          <div
            className={`${
              isFromUser
                ? "bg-lightpurple-300 text-white p-5 rounded-2xl rounded-br-none"
                : "bg-lightwhite dark:bg-white/5 p-5 rounded-2xl rounded-bl-none"
            }`}
          >
            {caption}
          </div>
        )}

        {withFeedbackButtons && !isFromUser && <div className="mt-4 gap-3 flex">{renderFeedbackButtons(caption)}</div>}
        
      </div>
    );
  };

  // Render the feedback buttons
  const renderFeedbackButtons = (caption = "") => {
    // return <div>TESTING!</div>
    return feedbackButtons.map((button, index) => {
      const { label, onClick, className = "", icon, tooltip } = button;
      const feedbackButtonTestId = "feedback-button";

      return (
        <button
          key={index}
          data-testid={feedbackButtonTestId}
          onClick={() => onClick(caption)}
          title={tooltip}
          className={className}
        >
          {icon && icon(caption)}
          {label}
        </button>
      );
    });
  };

  // Render the prompt message container
  return (
    <div data-testid="PromptMessageContainer">
      {/* Render the message bubbles or captions based on the type prop. */}
      {type === "Minimal" ? (
        <div className="flex-1 overflow-y-auto text-sm leading-6 text-slate-900 dark:bg-black dark:text-slate-300 sm:text-base sm:leading-7">
          {messages.map(renderMessageCaption)}
        </div>
      ) : (
        // Bubbles
        <div className="max-w-4xl mx-auto space-y-7 grid grid-cols-1">
          {messages.map(renderMessageBubble)}
        </div>
      )}
      {/* Render the feedback buttons if withFeedbackButtons prop is true */}
      {/* {withFeedbackButtons && renderFeedbackButtons()} */}
    </div>
  );
};

export default PromptMessageContainer;
