import { Link } from "react-router-dom";
import { Panel } from "@/ui/Panel";
import { ButtonTypes } from "@/ui/types";
import { Button } from "@/ui/Button";
import { Calout } from "@/ui/Calout";
import moment from "moment";
import { ListDropdown } from "@/ui/ListDropdown";
import { DotsThree } from "@phosphor-icons/react";


export const CustomWebHooks = ({
  setIsCustomWebHookModalShowing = () => null, activeIntegrations = [], setActiveIntegrations = () => null,
  // activeIntegrationId = "",
  setActiveIntegrationId = (activeIntegrationId: string | null) => null, setIsDeleteIntegrationModalShowing = () => null,
}) => {
  return (
    <Panel
      title="Custom Webhooks"
      actionButton={<Button
        onClick={() => {
          setActiveIntegrationId(null);
          setIsCustomWebHookModalShowing(true);
        }}
        type={ButtonTypes.SOFT}
        label="+ Add Custom Webhook" />}
    >
      <Calout
        title="Create your own custom webhook"
        // icon={<ShieldIcon />}
        caption={<span>
          This is a great way to integrate with any third party service that
          we don't have a direct integration with. &nbsp;
          <Link to="#" className="text-lightpurple-300">
            Learn More
          </Link>
        </span>} />

      <div className="grid grid-flow-row divide-y divide-black/10 dark:divide-white/10 mt-3">
        {activeIntegrations
          .filter(({ type }) => type === "webhook")
          .map((integration, index) => {
            // const isActive = Boolean(integration);
            return (
              <div key={index} className="flex items-center gap-4 py-4">
                <img
                  src={integration?.setting?.icon}
                  alt="images"
                  className="flex-none w-8 h-8" />
                <div className="flex-1 flex items-center justify-between gap-4">
                  <div>
                    <p className="text-sm font-semibold">
                      {integration?.setting?.name}
                    </p>
                    <p className="text-xs text-black/40 dark:text-white/40">
                      Created {moment(integration?.createdAt).fromNow()}
                    </p>
                  </div>
                  <div className="togglebutton inline-block flex gap-3">
                    <Link
                      to="#"
                      className="text-xs text-black/40 dark:text-white/40 underline"
                      onClick={() => {
                        setActiveIntegrationId(integration?.id);
                        setIsCustomWebHookModalShowing(true);
                      }}
                    >
                      Edit Settings
                    </Link>
                    <ListDropdown
                      elements={<DotsThree />}
                      items={[
                        {
                          label: "Edit Settings",
                          onClick: () => {
                            setActiveIntegrationId(integration?.id);
                            setIsCustomWebHookModalShowing(true);
                          },
                        },
                        {
                          label: "Delete",
                          onClick: () => {
                            setActiveIntegrationId(integration?.id);
                            setIsDeleteIntegrationModalShowing(true);
                          },
                        },
                      ]} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Panel>
  );
};
