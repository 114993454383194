import { DotsThree } from "@phosphor-icons/react";
import { CardProps } from "./types";
import { Link } from "react-router-dom";
import TaskBar from "../TaskBar";
import { Status } from "../types";
import { Badge } from "../Badge";
import ListDropdown from "../ListDropdown";
import Grid from "../Grid";

const StatusBadgeSection = (props) => {
  return (
    <div className="flex items-center justify-between gap-2 text-xs mb-3">
      <img
        className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover"
        src="/assets/images/avatar-16.png"
        alt=""
      />
      <Badge type="dots" color="primary" text={props.status} />
      <div className={`flex items-center text-xs text-[${props.color}]`}>
        {props.sharedWith}
        <p>{props.status}</p>
      </div>
    </div>
  );
};

/**
 * Show's content & actions about a single item (e.g. a campaign, a person, etc.)
 * @returns React.FC
 */
export const Card: React.FC<CardProps> = ({
  type = "Object",
  label,
  caption,
  image,
  href,
  taskDetails,
  sharedWith,
  status,
  tags,
  withActionMenu = false,
  actionMenuItems = [],
  actionMenuItemElement,
  children,
}) => {

  const cardClass = `card-${type.toLowerCase()} bg-lightwhite dark:bg-white/5 rounded-2xl p-6 hover:bg-black/5 dark:hover:bg-transparent/20`;

  const CardContent = ({ children }) => {
    let color = "#8A8CD9";
    if (status == Status.INPROGRESS) color = "#8A8CD9";
    if (status == Status.COMPLETED) color = "#4AA785";
    if (status == Status.PENDING) color = "#59A8D4";

    return (
      <div className="card-content">
        <div className="flex gap-4 mb-4">
          {/* Render the tags if they exist */}

          {Boolean(tags) && (
            <ul className="card-tags">
              {tags.map((tag, index) => (
                <li key={index} className="card-tag" data-testid="Card-tag">
                  {tag}
                </li>
              ))}
            </ul>
          )}

          <div className="card-header flex-1">
            <div style={{ display: "flex" }}>
              {label && (
                <p
                  data-testid="card-item-label"
                  className="mb-1 mr-4 text-sm font-semibold"
                >
                  {label}
                </p>
              )}
              {Boolean(actionMenuItems.length) && withActionMenu && (
                // {/* Render the item dropdown menu */}
                <ListDropdown
                  items={actionMenuItems}
                  element={actionMenuItemElement}
                  // forId={id}
                />
              )}
            </div>
            {Boolean(caption) && (
              <p className="text-xs text-black/40 dark:text-white/40">
                {caption}
              </p>
            )}
          </div>
          {/* Render the image if it exists */}
          {image && (
            <div className="card-image">
              {/* If the image is a string, render an img tag with the src and alt attributes */}
              {typeof image === "string" ? (
                <img data-testid="card-item-image" src={image} alt={label} />
              ) : (
                image
              )}
            </div>
          )}
        </div>

        {/* Render the status badge */}
        {/* <StatusBadgeSection color={color} status={status} sharedWith={sharedWith} /> */}

        {/* Render the task bar if task details are provided */}
        {Boolean(taskDetails) && type == "Task" ? (
          <TaskBar {...taskDetails} status={status} />
        ) : (
          <>
            {Boolean(status) && (
              <div
                className={`card-status card-status-${status.type}`}
                data-testid="Card-Status"
              >
                <div>
                  {/* {status?.caption} */}

                  {/* Render the sharedWith div if it exists */}
                  {Boolean(sharedWith) && <div>{sharedWith}</div>}
                  {/* Render the status badge */}
                  {/* <StatusBadgeSection
                    color={color}
                    status={status}
                    sharedWith={sharedWith}
                  /> */}
                </div>
              </div>
            )}
          </>
        )}

        {children}
      </div>
    );
  };

  if (href) {
    return (
      <Link data-testid="Card" to={href} className={`card ${cardClass}`}>
        <CardContent>{children}</CardContent>
      </Link>
    );
  } else
    return (
      <div data-testid="Card" className={`card ${cardClass}`}>
        <CardContent>{children}</CardContent>
      </div>
    );
};

export default Card;
