import { Browsers } from '@phosphor-icons/react';
import { Pages } from './Pages';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("pages")) return [];
    return [
      {
        label: "Pages",
        icon: <Browsers className="text-lg" />,
        href: "/pages",
        order: 3,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("pages")) return [];
    return [
      {
        path: "/pages",
        element: <Pages name="Pages" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Pages" />,
          },
        ],
      },
    ];
  }
};


export { Pages };