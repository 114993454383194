import React from "react";
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { PaginationProps } from "./types";

export const Pagination: React.FC<PaginationProps> = ({
  currentPage = 2, totalPages = 4, gap = 1, showFirstLastButtons, showPrevNextButtons, showPageNumbers = true, showIcons = false, onPageChange = () => null, fill = "solid", rounded = false, // to do
  circles = false, // to do
  className,
}) => {
  let cn = "";

  const getClassName = (index) => {

    // If the current page is not the same as the index + 1, set the class name to the following:
    if (currentPage !== index + 1) {
      cn = `flex justify-center px-3.5 py-2 rounded transition text-black/60 hover:text-black border border-black/10 hover:border-black dark:text-white/60 dark:hover:text-white dark:border-white/10 dark:hover:border-white`;

      // If the fill prop is set to 'solid', add the 'bg-black/10' or 'dark:bg-white/10' class to the class name
      if (fill === 'solid') cn += ' bg-black/10 dark:bg-white/10';
    } else {

      // If the current page is the same as the index + 1, set the class name to the following:
      cn = `flex justify-center px-3.5 py-2 rounded transition text-white border border-black dark:text-black dark:border-white bg-black dark:bg-white`;
    }

    // If the circles prop is set to true, add the 'rounded-full' class to the class name
    if (circles) cn += ' rounded-full';

    return cn;
  };

  return (
    <div
      data-testid="ContactList-Pagination"
      className={`flex flex-col items-center justify-center gap-3 ${className}`}
    >
      {/* First and Last buttons */}
      <ul className={`inline-flex items-center gap-${gap} m-auto mb-4`}>
        {showFirstLastButtons && (
          <li>
            <button
              type="button"
              className={`${getClassName(-1)}`}
              onClick={() => onPageChange(1)}
            >
              {showIcons ? <CaretDoubleLeft size={20} /> : "First"}
            </button>
          </li>
        )}

        {/* Previous and Next buttons */}
        {showPrevNextButtons && (
          <li>
            <button
              type="button"
              className={getClassName(-1)}
              onClick={() => onPageChange(currentPage - 1)}
            >
              {showIcons ? <CaretLeft size={20} /> : "Prev"}
            </button>
          </li>
        )}

        {/* Page numbers */}
        {showPageNumbers &&
          Array.from({ length: totalPages }, (_, index) => {
            return (
              <li key={index}>
                <button
                  type="button"
                  className={getClassName(index)}
                  onClick={() => onPageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            );
          })}

        {/* Previous and Next buttons */}
        {showPrevNextButtons && (
          <li>
            <button
              type="button"
              className={getClassName(-1)}
              onClick={() => onPageChange(currentPage + 1)}
            >
              {showIcons ? <CaretRight size={20} /> : "Next"}
            </button>
          </li>
        )}

        {/* First and Last buttons */}
        {showFirstLastButtons && (
          <li>
            <button
              type="button"
              className={getClassName(-1)}
              onClick={() => onPageChange(totalPages)}
            >
              {showIcons ? <CaretDoubleRight size={20} /> : "Last"}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
