import { content } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";

import { workflowNodes } from "@/Apps/Workflows/nodes/workflowNodes";

export const contentNodes = ({ position, campaignData, nodes, setNodes }) => [
  {
    label: "Content",
    items: content.map((page) => {
      return {
        label: getNodeMenuLabelComponent(page.name),
        icon: getNodeIconComponent({
          Icon: page?.icon,
          color: page?.iconColor,
          iconStyle: { fontSize: "18pt" },
          containerStyle: { padding: "5px 10px" },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: {
              ...page,
              settings: { type: page?.name },
            },
            setNodes,
            nodes,
          });
        },
      };
    }),
  },
  ...workflowNodes({ position, campaignData, nodes, setNodes }),
];
