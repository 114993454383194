import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link, useNavigate } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { AddPageModal } from "../Funnels/Popups/AddPageModal";
import { CreateNewCampaign } from "./CreateNewCampaign";

export const TrackingCampaigns = ({ name = "" }) => {
  const [linkCampaigns, setLinkCampaigns] = useState([]);
  const [createCampaignIsShowing, setCreateCampaignIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // get the linkCampaigns from the server
  const loadTrackingCampaigns = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "tracking" });
    // const response = await getCampaigns({ token, type: "campaign" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((campaign: any) => {
          return {
            id: campaign.id,
            label: campaign.name,
            caption: `Last Updated: ${moment(campaign.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setLinkCampaigns(seqs);
    }
    setIsLoading(false);
  };

  // load the linkCampaigns when the page loads
  useEffect(() => {
    loadTrackingCampaigns();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Button
            onClick={() => setCreateCampaignIsShowing(true)}
            label="+ Create a Campaign"
            type={ButtonTypes.OUTLINED}
          />
        ),
      }}
    >
      {createCampaignIsShowing && (
        <CreateNewCampaign onClose={() => setCreateCampaignIsShowing(false)} />
      )}
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(linkCampaigns.length) && (
          <EmptyState
            title="No Tracking Campaigns Yet"
            description="Get started by creating a new campaign."
            showImage={false}
          >
            <Link to="#">
              <Button label="Create a Campaign" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(linkCampaigns.length) && (
          <Grid>
            {/* Can we change to Gallery View - let's me toggle views */}
            {linkCampaigns.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/tracking/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};

