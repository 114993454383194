import React from "react";
import { Link } from "react-router-dom";
import { TabsComponentProps } from "./types";

export const getActiveTabFromLocalStorage = () => {
  return localStorage.getItem('activeTabs') ? JSON.parse(localStorage.getItem('activeTabs'))[window.location.pathname] : null;
}

export const setActiveTabInLocalStorage = (pathname = window.location.pathname, tab) => {
  const activeTabs = localStorage.getItem("activeTabs")
    ? JSON.parse(localStorage.getItem("activeTabs"))
    : {};
  const updatedTabs = { ...activeTabs, [pathname]: tab };
  localStorage.setItem("activeTabs", JSON.stringify(updatedTabs));
};


// Define the TabsComponent functional component
export const TabsComponent: React.FC<TabsComponentProps> = ({
  actionSection = true,
  tabs = [],
  activeTab,
  className = "mb-5",
  storeActiveTab = true,
  setActiveTab = () => null,
}) => {
  // Define the handleTabClick function to set the active tab
  const handleTabClick = (tab) => {
    // console.log("handleTabClick", tab, activeTab);
    if (tab !== activeTab) {
      setActiveTab(tab);

      // store the active tab in cookies for this url
      if (storeActiveTab) {
        setActiveTabInLocalStorage(window.location.pathname, tab);
        // const activeTabs = localStorage.getItem("activeTabs")
        //   ? JSON.parse(localStorage.getItem("activeTabs"))
        //   : {};
        // const updatedTabs = { ...activeTabs, [window.location.pathname]: tab };
        // localStorage.setItem("activeTabs", JSON.stringify(updatedTabs));
      }
    }
  };

  // Define the Tab component to render each tab
  const Tab = ({ children, active, onClick }) => {
    return (
      <button
        data-testid="TabItem"
        onClick={onClick}
        className={`border-b-2 mx-2 my-1 font-normal ${
          active
            ? "active border-black text-black dark:border-lightpurple-200 dark:text-lightpurple-200 font-semibold"
            : "border-transparent text-black/40 hover:text-black dark:text-white/40 dark:hover:text-lightpurple-200 hover:font-semibold"
        }`}
      >
        {children}
      </button>
    );
  };

  // Render the TabsComponent
  return (
    <div
      data-testid="TabsComponent"
      className={`grid grid-cols-1 md:grid-cols-3 justify-between gap-4 items-center ${className}`}
    >
      {/* Render the tabs list */}
      <div className="tabs-list md:col-span-3 flex text-sm flex-nowrap overflow-auto">
        {" "}
        {/* used to be md:col-span-2 */}
        {tabs.map((tab, key) => (
          <Tab
            key={key}
            onClick={() => handleTabClick(tab.value)}
            active={activeTab === tab.value}
          >
            {tab.label}
          </Tab>
        ))}
      </div>

      {/* Render the action section */}
      {actionSection && (
        <div className="flex gap-2 justify-center md:justify-end flex-none items-center">
          {/* Render the "Add User" button */}
          <button className="flex items-center gap-1 py-1 px-2 text-black/40 hover:text-black dark:text-white/40 dark:hover:text-white transition-all duration-300">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 3.5C8.5 3.22386 8.27614 3 8 3C7.72386 3 7.5 3.22386 7.5 3.5V7.5H3.5C3.22386 7.5 3 7.72386 3 8C3 8.27614 3.22386 8.5 3.5 8.5H7.5V12.5C7.5 12.7761 7.72386 13 8 13C8.27614 13 8.5 12.7761 8.5 12.5V8.5H12.5C12.7761 8.5 13 8.27614 13 8C13 7.72386 12.7761 7.5 12.5 7.5H8.5V3.5Z"
                fill="currentcolor"
              />
            </svg>
            <p>Add User</p>
          </button>
          {/* Render the "Add Target" button */}
          <button className="py-1 px-2 text-black/40 hover:text-black  dark:text-white/40 dark:hover:text-white transition-all duration-300">
            Add Target
          </button>
          {/* Render the dropdown */}
          <div className="dropdown">
            <button className="p-1 text-black/40 hover:text-black dark:text-white/40 dark:hover:text-white transition-all duration-300">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 8.75C5.69036 8.75 6.25 9.30964 6.25 10C6.25 10.6904 5.69036 11.25 5 11.25C4.30964 11.25 3.75 10.6904 3.75 10C3.75 9.30964 4.30964 8.75 5 8.75Z"
                  fill="currentcolor"
                />
                <path
                  d="M10 8.75C10.6904 8.75 11.25 9.30964 11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10C8.75 9.30964 9.30964 8.75 10 8.75Z"
                  fill="currentcolor"
                />
                <path
                  d="M16.25 10C16.25 9.30964 15.6904 8.75 15 8.75C14.3096 8.75 13.75 9.30964 13.75 10C13.75 10.6904 14.3096 11.25 15 11.25C15.6904 11.25 16.25 10.6904 16.25 10Z"
                  fill="currentcolor"
                />
              </svg>
            </button>
            {/* Render the dropdown options */}
            <ul
              className="right-0 whitespace-nowrap transition duration-300ms"
              onClick={() => handleTabClick("dropdown")}
            >
              <li>
                <Link to="#">Weekly</Link>
              </li>
              <li>
                <Link to="#">Monthly</Link>
              </li>
              <li>
                <Link to="#">Yearly</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
