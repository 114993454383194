import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";

import style from "@/Apps/Pages/Properties/style";

import { NiceMenu } from "../Helpers/NiceMenu";
import { AppContext } from "@/AppContext";
import Grid, { Cell } from "@/ui/_old/Grid";
import ListItem from "@/ui/_old/ListItem";
import { Switch } from "@material-ui/core";
import HelpButton from "@/ui/_old/HelpButton";
import { pages } from "@/Apps/Funnels/objects";

const objects = { pages };

export const ForwardParams = (props) => {
    return (<div style={{
        marginTop: 10
    }}>
        <FormControlLabel control={<Switch // style={{ fontSize: '8pt' }}
            // size="small"
            name="forwardParams" onChange={e => {
                props.updateAction(props.action, {
                    ...props.action.payload,
                    forwardParams: e.target.checked
                });
            }} checked={Boolean(_.get(props.action, 'payload.forwardParams', false))} />} label="Forward Params (optional)" />
        <HelpButton style={{
            position: 'relative',
            top: 5,
            display: 'inline-block'
        }} tooltip="When checked, ALL URL parameters will be passed to the destination URL. ---- For example: url.com/page?param1&param2 when clicked will route to destination.com?param1&param" />
    </div>);
}

export const GoToUrl = (props) => {
    const { action, updateAction } = props;
    const { payload } = action;

    // console.log({ payload });

    return (
        <AppContext.Consumer>
            {({ app }) => (
                <>
                    {/* // show dropdown menu of pages, with option to enter url */}
                    <NiceMenu
                        style={{ marginTop: 10 }}
                        label={_.get(payload, "urlPageName", "Choose a page to link to...")}
                    >
                        <ListItem
                            icon="cancel"
                            onClick={() =>
                                updateAction(action, { ...action.payload, url: "", urlPageName: "Then: Do Nothing" })
                            }
                        >Do Nothing</ListItem>

                        <ListItem
                            icon="link"
                            onClick={() => {
                                const urlPrompt = window.prompt(
                                    "Enter the url you want to link to",
                                    _.get(payload, "url", "http://")
                                );

                                if (urlPrompt) {
                                    const url = urlPrompt.indexOf("http") > -1
                                        ? urlPrompt
                                        : `http://${urlPrompt}`;

                                    updateAction(
                                        action,
                                        {
                                            ...payload,
                                            url,
                                            urlPageName: url,
                                            useTag: false,
                                        },
                                        { href: url }
                                    );
                                }
                            }}
                        >
                            Enter a Custom URL
                        </ListItem>


                        <ListItem
                            icon="skip_next"
                            onClick={() => {
                                updateAction(
                                    action,
                                    {
                                        ...payload,
                                        url: "{next_page}",
                                        urlPageName: "Go to the next page",
                                        useTag: false,
                                    },
                                    { href: "{next_page}" }
                                );
                            }}
                        >
                            Go to the next page
                        </ListItem>

                        <div style={style.divider} />
                        {_.filter(
                            _.get(props, "getCampaignQuery.campaign.objects", []),
                            ({ deleted }) => !deleted).map(
                                (page, key) => {
                                    
                                    const thumbnail = _.head(
                                        _.filter(
                                            _.get(objects, "pages", []),
                                            (itm) => itm.pageType === _.get(page, 'page.type')
                                        )
                                    );

                                    return (
                                        <ListItem
                                            key={key}
                                            onClick={() => {
                                                updateAction(
                                                    action,
                                                    {
                                                        ...payload,
                                                        url: `/${page.page.slug}/`,
                                                        urlPageName: page.name,
                                                        useTag: false,
                                                    },
                                                    {
                                                        href: `/${page.page.slug}/`,
                                                    }
                                                );
                                            }}
                                        >
                                            <Grid>
                                                <Cell
                                                    style={{ width: 50, height: 45, textAlign: "center" }}
                                                >
                                                    <img
                                                        alt="Screenshot"
                                                        src={_.get(thumbnail, "screenshot", app.logoIcon)}
                                                        style={{
                                                            height: "100%",
                                                            marginTop: 5,
                                                            border: "5px solid transparent",
                                                        }} />
                                                </Cell>
                                                <Cell>
                                                    <div style={{ fontSize: 14, padding: "20px 10px" }}>
                                                        {page.name.length > 23
                                                            ? `${page.name.substring(0, 20)}...`
                                                            : page.name}
                                                    </div>
                                                </Cell>
                                            </Grid>
                                        </ListItem>
                                    );
                                }
                            )}
                    </NiceMenu>
                    <ForwardParams action={action} updateAction={updateAction} payload={payload} />
                </>
            )}
        </AppContext.Consumer>
    );
};
