import Calout from "@/ui/Calout";
import { Panel, PanelItem } from "@/ui/Panel";
import {
  Copy,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";

export const ShareURL = ({ unitName = "funnel" }) => {
  return (
    <Panel title={`Share this ${unitName}`} className="flex-row grid gap-4">
      <PanelItem
        title={
          <p>
            {/* Your Referral Link */}
            Your Link for Sharing
            <br />
            <div className="border border-black/10 dark:border-white/10 rounded-lg p-3 my-2 font-normal flex justify-between items-center bg-white/5">
              https://themeforest.com/reffer/?refid=example
              <Copy />
            </div>
            {/* <TextInput
              className="w-full"
              // flavor={TextInputFlavors.MODERN}
              value="https://themeforest.com/reffer/?refid=example"
            /> */}
          </p>
        }
        description="Plan your blog post by choosing a topic, creating an outline conduct research, and checking facts."
        // button={<Copy />}
      />
      <div className="border-t border-black/10 dark:border-white/10" />
      <PanelItem
        title="How to use Referral Program"
        description="Use images to enhance your post, improve its flow, add humor and explain complex topics."
        // button={<Link to="#">Get Started</Link>}
        buttonLabel="Get Started"
      />
      <div className="mt-4">
        <Calout
          icon={<CurrencyCircleDollar />}
          title="Withdraw Your Money to a Bank Account"
          caption="Withdraw money securily to your bank account. Commision is $25 per transaction under $50,000"
        />
      </div>
    </Panel>
  );
};
