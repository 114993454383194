import { handleCreateNewPageFromTemplate } from "@/api/campaigns";
import {
  getNodeMenuLabelComponent,
} from "../../Funnels/CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "@/Apps/Funnels/CampaignBuilder/Node/NodeIcon";

import { workflow } from "../../Funnels/objects";

export const workflowNodes = ({ position, campaignData, integrations = [], setNodes, nodes }) => {
  
  // console.log({ integrations })
  const webhooks = integrations.filter(({ type }) => type === "webhook");

  const webhookItems = webhooks.map((itm) => {
    const { id, setting } = itm;
    return {
      label: getNodeMenuLabelComponent(setting.name),
      icon: getNodeIconComponent({
        imageSrc: setting?.icon,
        // color: setting?.iconColor,
        // iconStyle: { fontSize: "18pt" },
        imageStyle: { width: 25, maxHeight: 25 },
        containerStyle: { padding: "5px 10px" },
      }),
      onClick: () => {
        handleCreateNewPageFromTemplate({
          position,
          campaignData,
          page: {
            // ...setting,
            type: "Webhook",
            id,
            ...setting,
            screenshot: setting?.icon,
            settings: { type: "trigger_webhook", id, screenshot: setting?.icon },
          },
          setNodes,
          nodes,
        });
      },
    };
  });

  const triggerItems = workflow
    .filter(({ type }) => type === "Trigger")
    .map((itm) => {
      return {
        label: getNodeMenuLabelComponent(itm.name),
        icon: getNodeIconComponent({
          Icon: itm?.icon,
          color: itm?.iconColor,
          iconStyle: { fontSize: "18pt" },
          containerStyle: { padding: "5px 10px" },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: {
              ...itm,
              settings: { type: itm.id },
            },
            setNodes,
            nodes,
          });
        },
      };
    });

  const taskItems = workflow
    .filter(({ type }) => type === "Task")
    .map((itm) => {
      return {
        label: getNodeMenuLabelComponent(itm.name),
        icon: getNodeIconComponent({
          Icon: itm?.icon,
          color: itm?.iconColor,
          iconStyle: { fontSize: "18pt" },
          containerStyle: { padding: "5px 10px" },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: {
              ...itm,
              settings: { type: itm.id },
            },
            setNodes,
            nodes,
          });
        },
      };
    });

  return [
    {
      label: "Triggers",
      items: triggerItems,
    },
    {
      label: "Tasks",
      items: [...taskItems, ...webhookItems],
    },
  ];};
