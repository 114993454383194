import { ProgressBarProps } from "../types";

export const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  number,
  max,
  showAsPercent = true,
  className = "",
  color = "",  
}) => {
  const percent = max ? (number / max) * 100 : number;

  return (
    <div
      data-testid="ProgressBar"
      className={`progress-bar w-full bg-[#000]/5 dark:bg-white/20 rounded-lg overflow-hidden ${className}`}
    >
      <div
        data-testid="ProgressBar-Bar"
        className="bg-lightpurple-200 whitespace-nowrap text-center px-1.5 text-lg font-semibold text-black"
        style={{ width: `${percent}%`, backgroundColor: color }}
      >
        {label && (
          <>
            <span>{label}</span> <span> /</span>
          </>
        )}{" "}
        {showAsPercent ? <span>{percent}%</span> : <span>{number}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
