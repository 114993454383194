import Modal from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { useState } from "react";
import { BrowserWrapper } from "./BrowserWrapper";
import { EditorCanvas, useEditorActions } from "./EditorCanvas";
import { getSessionToken } from "@/api/auth";
import { renderWidget } from "@/Apps/Dashboards/renderWidget";
import { Widget } from "@/Apps/Dashboards/types";

import TabsComponent from "@/ui/TabsComponent";

import SectionWrapper from "@/Apps/Pages/Properties/components/sections/SectionWrapper";
import SwitchGroup from "@/Apps/Pages/Properties/components/groups/SwitchGroup";
import DropdownGroup from "@/Apps/Pages/Properties/components/groups/DropdownGroup";
import TemplateSelectionGroup from "@/Apps/Pages/Properties/components/sections/Appearance";

interface PageEditorModalProps {
  setIsOpen: (isOpen: boolean) => void;
  campaignId: string;
  pageId: string;
  widgets?: Widget[];
}

export const PageEditorModal: React.FC<PageEditorModalProps> = ({
  setIsOpen,
  campaignId,
  pageId,
  widgets = [],
}) => {
  const { updateContent, updateComponent } = useEditorActions();
  const [isChecked, setIsChecked] = useState(false);
  const [isChatChecked, setIsChatChecked] = useState(false);
  const [isShareChecked, setIsShareChecked] = useState(false);

  const Tabs = (
    <TabsComponent
      tabs={[
        { label: "General", value: "general" },
        { label: "Store", value: "store" },
        { label: "Checkout", value: "checkout" },
        { label: "Overlay", value: "overlay" },
        { label: "Portal", value: "portal" },
        { label: "Email", value: "email" },
      ]}
      activeTab="store"
      setActiveTab={() => { }}
      actionSection={false}
      className="p-0"
    />
  );

  return (
    <Modal
      title={<p className="px-3">Page Editor</p>}
      tabs={Tabs}
      size={PopupSizes.FULLSCREEN}
      onClose={() => setIsOpen(false)}
      footer={false}
      childrenStyle={{ padding: 0 }}
    >
      <div className="w-full h-full flex">
        {/* Editor */}
        <div className="p-5 h-full w-full">
          <BrowserWrapper>
            <EditorCanvas
              campaignId={campaignId}
              pageId={pageId}
              // toShow={toShow}
              toShow="builder"
              // token={localStorage.getItem("token")}
              token={getSessionToken()}
            />
          </BrowserWrapper>
        </div>
        {/* Properties */}
        <div className="divide-y divide-black/10 dark:divide-white/10 border-l border-black/10 dark:border-white/10 h-full ml-2 overflow-y-auto">
          <SwitchGroup
            label="Enable this page"
            isChecked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
            className="p-5"
          >
            <p>Display this page or hide it and redirect to your website.</p>
          </SwitchGroup>

          {/* Template */}
          <TemplateSelectionGroup filter={{ category: "order" }} />

          <SectionWrapper label="Settings" isExpanded={false}>
            {/* Show Chatbot & Settings */}
            <SwitchGroup
              label="Enable Chat"
              caption="Show the chat widget on this page"
              isChecked={isChatChecked}
              onClick={() => setIsChatChecked(!isChatChecked)}
              isExpanded={isChatChecked}
            >
              <p className="py-3">Chat specific settings here</p>
            </SwitchGroup>

            {/* Wildfire Share Buttons */}
            <SwitchGroup
              label="Enable Share Buttons"
              caption="Show the share buttons on this page"
              isChecked={isShareChecked}
              onClick={() => setIsShareChecked(!isShareChecked)}
              isExpanded={isShareChecked}
            >
              <SwitchGroup
                isExpanded={false}
                label="Enable Facebook Sharing"
                className="py-0"
              />
              <SwitchGroup
                isExpanded={false}
                label="Enable Twitter Sharing"
                className="py-0"
              />
              <SwitchGroup
                isExpanded={false}
                label="Enable LinkedIn Sharing"
                className="py-0"
              />
              <SwitchGroup
                isExpanded={false}
                label="Enable Pinterest Sharing"
                className="py-0"
              />
              <SwitchGroup
                isExpanded={false}
                label="Enable Email Sharing"
                className="py-0"
              />
            </SwitchGroup>

            <DropdownGroup
              label="Display Type"
              value="page"
              items={[
                { id: "page", label: "Page" },
                { id: "popup", label: "Popup" },
                { id: "slide-in", label: "Slide-in" },
                { id: "bar", label: "Bar" },
                { id: "full-screen", label: "Full Screen" },
              ]}
            />
          </SectionWrapper>

          {/* {Array.from({ length: 4 }).map((_, i) => (
            <SectionWrapper label={`Test ${i + 1}`} isExpanded={false} />
          ))} */}

          {/* Render Widgets */}
          {widgets.map((widget, key) => {
            return (
              <span key={key} className="">
                {renderWidget(widget, {
                  campaignId,
                  pageId,
                  updateContent,
                  updateComponent,
                })}
              </span>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
