import { useContext } from "react";
import { AppContext } from "@/AppContext";
import { files } from "./project";

import {
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  // SandpackTranspiledCode,
  // SandpackFileExplorer,
  SandpackPreview,
  useSandpack,
  // useTranspiledCode,
} from "@codesandbox/sandpack-react";

import { Page, Topbar, LeftSidebar, RightSidebar, Footer } from "@/ui/Layout";

import { Button } from "@/ui/Button";

import { Variants } from "@/ui/types";
import { SANDBOX_DOMAIN } from "@/env";

const SandPackActionBar = () => {
  const { sandpack } = useSandpack();

  const bundleCode = async () => {
    const object = {
      projectId: "testProjectId",
      files: sandpack.files,
    };

    try {
      const resp = await fetch(`${SANDBOX_DOMAIN}/processFiles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(object),
      });

      // check if the HTTP response status denotes success
      if (!resp.ok) {
        throw new Error("HTTP error, status = " + resp.status);
      }

      const jsonResponse = await resp.json();

      // check if jsonResponse has error, if so throw it
      if (jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }

      console.log("Response received successfully", jsonResponse);

      // refresh the page so that the plugin reloads
      window.location.href = "/sandcastleIDE";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Button variant={Variants.SECONDARY} label="Deploy" onClick={bundleCode} />
  );
};

export const Sandbox = () => {
  const { darkMode } = useContext(AppContext);
  const sandpackHeight = { height: "88vh" };

  const ideOptions = {
    entry: "/src/index.js",
    dependencies: {
      react: "^18.0.0",
      "react-dom": "^18.0.0",
      "react-scripts": "^5.0.0",
    },
    devDependencies: {
      "@babel/core": "^7.22.1",
      "@babel/preset-env": "^7.22.4",
      "@babel/preset-react": "^7.22.3",
      "babel-loader": "^9.1.2",
      webpack: "^5.86.0",
      "webpack-cli": "^5.1.4",
      "css-loader": "^6.8.1",
      "style-loader": "^3.3.3",
    },
  };

  const page = [
    { label: "Sandcastle", href: "/sandcastle" },
    { label: "Project Name" },
  ];

  return (
    <Page topBar={{ page /*items: <SandPackActionBar />*/ }}>
      <SandpackProvider
        files={files}
        customSetup={ideOptions}
        theme={darkMode ? "dark" : "light"}
      >
        <SandpackLayout
          style={{
            borderRadius: 0,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          {/* <SandpackFileExplorer style={sandpackHeight} /> */}
          <SandpackCodeEditor style={sandpackHeight} closableTabs showTabs />
          <SandpackPreview style={sandpackHeight} />
        </SandpackLayout>
      </SandpackProvider>
    </Page>
  );

  return (
    <>
      {/* Start Sidebar */}
      <LeftSidebar />
      {/* End Sidebar */}

      <div className="main-content flex-1">
        <SandpackProvider
          files={files}
          customSetup={ideOptions}
          theme={darkMode ? "dark" : "light"}
        >
          <Topbar page={page} items={<SandPackActionBar />} />

          {/* Start Content */}
          {/* <div className="h-[calc(100vh-73px)] overflow-y-auto overflow-x-hidden p-0"> */}

          <SandpackLayout
            style={{
              borderRadius: 0,
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          >
            {/* <SandpackFileExplorer style={sandpackHeight} /> */}
            <SandpackCodeEditor style={sandpackHeight} closableTabs showTabs />
            <SandpackPreview style={sandpackHeight} />
          </SandpackLayout>

          <Footer />
          {/* </div> */}
          {/* End Content */}
        </SandpackProvider>
      </div>

      {/* Start Right Sidebar */}
      <RightSidebar />
      {/* End Right Sidebar */}
    </>
  );
};

export default Sandbox;
