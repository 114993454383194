import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { useState } from "react";
import { TableView } from "@/ui/DataView/TableView";
import { items } from "@/ui/DataView/TableView/TableView.stories";
import { DefaultDataProps } from "@/ui/DataView/types";
import { Loading } from "@/ui/Layout/Loading";
import { EmptyState } from "@/ui/Layout";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

export const Orders = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "all");
  const [orderData, setOrderData] = useState<DefaultDataProps[]>(items);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Page topBar={{ page: [{ label: name }] }}>
      <div className={`p-6 w-full h-full`}>
        <div className="tabs flex flex-col h-full">
          <TabsComponent
            tabs={[
              { label: "All Orders", value: "all" },
              // { label: "Images", value: "images" },
              // { label: "Videos", value: "videos" },
              // { label: "Documents", value: "documents" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />

          {isLoading && <Loading type="list" />}
          {!isLoading && !Boolean(orderData.length) && (
            <EmptyState
              title="No Orders Yet"
              description="New orders will appear here."
              showImage={false}
            />
          )}

          {!isLoading && Boolean(orderData.length) && activeTab === "all" && <TableView items={orderData} />}
          {!isLoading && Boolean(orderData.length) && activeTab === "images" && <div>Images</div>}
          {!isLoading && Boolean(orderData.length) && activeTab === "videos" && <div>Videos</div>}
          {!isLoading && Boolean(orderData.length) && activeTab === "documents" && <div>Documents</div>}
        </div>
      </div>
    </Page>
  );
};
