import { Media } from './Media';
import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Files } from '@phosphor-icons/react';
import { MediaView } from "./MediaView";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("media")) return [];
    return [
      {
        route: "/media",
        icon: <Files className="text-lg" />,
        label: "Media",
        href: "/media",
        order: 4,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("media")) return [];
    return [
      {
        path: "/media",
        element: <Media name="Media" />,
        children: [
          {
            path: ":id",
            element: <MediaView name="Media" />,
          },
        ],
      },
    ];
  }
};

export { Media };