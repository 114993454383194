import * as React from "react";
import { useState } from "react";
import { get } from 'lodash';

// import Typography from "@launchos/modules/editor/Builder/Properties/Sections/Typography";
import Typography from "@/Apps/Pages/Properties/Sections/Typography";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import { Icon } from "@/Apps/Pages/WebComponents/v2/Icon/live"
// import { Icon } from "@launchos/plugins/webcomponents/v2";
// import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";

import { CaptionProps } from "./types";
import style from "./style";
import TextInput from "@/ui/TextInput";

/**
 * Creates a caption group for gathering input data
 */
const Caption: React.FC<CaptionProps> = (props) => {
  const {
    value,
    placeholder,
    text,
    onChange,
    onKeyUp,
    help,
    showTypography = false,
    setCanDrag = (value) => false,
  } = props;

  const [caption, setCaption] = useState<string>(value);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <fieldset
        data-testid="Properties-Caption"
        style={style.fieldset}
        title={text}
      >
        <div className="flex">
          <legend className="py-2 px-3 w-1/3 text-black/50 dark:text-white/50">
            {text || "Caption"}
          </legend>
          <div className="w-2/3 flex">
            <TextInput
              placeholder={placeholder}
              value={caption}
              // ref={(el) => (this.input = el)}
              onMouseDown={(e) => {
                setCanDrag(false);
                e.stopPropagation();
              }}
              onMouseUp={(e) => {
                setCanDrag(true);
                e.stopPropagation();
              }}
              onChange={(e) => {
                setCaption(e.target.value);
              }}
              // onKeyUp={e => { e.preventDefault(); e.stopPropagation(); return false; }}
              onKeyUp={(e) => {
                onKeyUp(caption);
              }}
              onBlur={(e) => {
                setCanDrag(true);
                onChange(e.target.value);
              }}
              style={{
                padding: 10,
                width: "100%",
                border: "none",
                fontSize: 14,
              }}
            />
            <div
              onClick={() => {
                setCaption("");
                onChange("");
              }}
              style={{
                zoom: 0.65,
                cursor: "pointer",
                padding: 16,
                color: "#CCC",
              }}
            >
              <Icon type={IconTypes.Cancel} />
            </div>
          </div>
        </div>
      </fieldset>

      {help ? (
        <a
          target="_blank"
          style={{
            textDecoration: "underline",
            textAlign: "center",
            color: "#666",
            fontSize: "10pt",
          }}
          href={get(help, "url")}
        >
          {get(help, "text")}
        </a>
      ) : null}

      {showTypography && (
        <Typography showColors={false} hideLabel {...props} type="Caption" />
      )}
    </div>
  );
};

export default Caption;
