import {
  useState,
  useEffect,
  useCallback,
  useContext,
  ChangeEvent,
  FC,
} from "react";

import { Handle, Position } from "reactflow";

import { AppContext } from "@/AppContext";
import { changeObjectName } from "@/api/campaigns";

import { NodeIcon } from "./NodeIcon";

import { getIconData } from "../../utils";
import { NodeProps } from "./types";
import { labelStyle } from "./styles";
import { BrowserWrapper } from "@/Apps/Pages/Editor/BrowserWrapper";
import { EditorCanvas } from "@/Apps/Pages/Editor/EditorCanvas";
import { getSessionToken } from "@/api/auth";
import { PageEditor } from "@/Apps/Pages/Editor";

export const Node: FC<NodeProps> = ({
  id,
  data,
  sourcePosition,
  targetPosition,
  selected,
}) => {
  const [label, setLabel] = useState(data.label);
  const { darkMode } = useContext(AppContext);
  const { showStats, stats, direction, type, viewType, campaignId } = data;

  const onBlur = useCallback((evt: ChangeEvent<HTMLTextAreaElement>) => {
    changeObjectName(id, evt.target.value);
  }, []);

  const { Icon, imageSrc, color, size } = getIconData(data, darkMode);
  const { width, height } = size;

  useEffect(() => {
    // update the label if the name changes
    if (data.label !== data.name) setLabel(data.name);
    console.log({ width, height });
  }, [data.name]);

  if (viewType === "artboard" && type === "PageComponent") {

    const height = 400;
    const width = 300;

    const { page } = data;
    // const { slug, type } = page;
    const pageId = page?.id;

    return (
      <div
        // className="relative h-full w-full overflow-hidden pointer-events-none p-0 rounded-lg bg-white dark:bg-black/10 border border-black/10 dark:border-white/10 appearance-none"
        className="relative h-full w-full overflow-hidden shadow-sm"
        style={{
          height,
          width,
          pointerEvents: "auto" // the pointerEvents style is to allow the user to interact with the iframe
        }}
      >
        <Handle type="target" position={targetPosition} />
        <div className="relative w-full h-full">
          <div
            className="absolute origin-top-left select-none overflow-hidden"
            style={{
              width: width * 3,
              height: height * 3,
              transform: `scale(${1 / 3})`,
            }}
          >
            {/* <BrowserWrapper url={label} selected={selected}>
              <EditorCanvas
                campaignId={campaignId}
                pageId={pageId}
                toShow="builder"
                token={getSessionToken()}
                // getDropDownItems={handleGetDropDownItems}
              />
            </BrowserWrapper>           */}
            <PageEditor
              campaignId={campaignId}
              objectId={id}
              selected={selected}
              withChrome={false}
            />
          </div>
        </div>
        <Handle type="source" position={sourcePosition} />
      </div>
    );
  }

  if (direction === "vertical") {
    return (
      <div className={`p-3 rounded-lg ${selected ? 'bg-lightwhite dark:bg-white/20' : 'bg-white dark:bg-white/5'} border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer`}>
        <Handle type="target" position={targetPosition} />
        <div className="flex">
          <NodeIcon
            Icon={Icon}
            imageSrc={imageSrc}
            color={color}
            // selected={selected}
            iconStyle={{ fontSize: "36pt", padding: "5px 8px" }}
            imageStyle={{ maxHeight: 70, height: "100%", padding: 5 }}
            stats={stats}
            showStats={showStats}
          />
          <textarea
            // style={labelStyle}
            className="w-full bg-transparent border-none overflow-hidden resize-none outline-none text-xs leading-[1.3]"
            placeholder="Text..."
            id="text"
            name="text"
            onMouseDown={(e: React.MouseEvent<HTMLTextAreaElement>) => { e.preventDefault(); e.stopPropagation() }}
            onDragStart={(e: React.DragEvent<HTMLTextAreaElement>) => { e.preventDefault(); e.stopPropagation() }}
            onDrag={(e: React.DragEvent<HTMLTextAreaElement>) => { e.preventDefault(); e.stopPropagation() }}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setLabel(e.target.value)}
            onDoubleClick={(e: React.MouseEvent<HTMLTextAreaElement>) => e.stopPropagation()}
            onBlur={onBlur}
            value={label}
            rows={Math.ceil(label.length / 15)}
          />
        </div>
        <Handle type="source" position={sourcePosition} />
      </div>
    );
  }

  return (
    <div style={{ width, height }}>
      <Handle type="target" position={targetPosition} />
        <NodeIcon
          Icon={Icon}
          imageSrc={imageSrc}
          color={color}
          selected={selected}
          imageStyle={{ maxHeight: 70, height: '100%', padding: 5 }}
          stats={stats}
          showStats={showStats}
        />
        <textarea
          style={labelStyle}
          placeholder="Text..."
          id="text"
          name="text"
          onMouseDown={(e: React.MouseEvent<HTMLTextAreaElement>) => e.stopPropagation()}
          onDragStart={(e: React.DragEvent<HTMLTextAreaElement>) => e.stopPropagation()}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setLabel(e.target.value)}
          onDoubleClick={(e: React.MouseEvent<HTMLTextAreaElement>) => e.stopPropagation()}
          onBlur={onBlur}
          value={label}
          rows={Math.ceil(label.length / 10)}
        />
      <Handle type="source" position={sourcePosition} />
    </div>
  );
};

export const getNodeMenuLabelComponent = (str: string) => <div className="p-2">{str}</div>;

export default Node;
