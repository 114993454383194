import * as React from "react";
// import { Caption, Typography } from "@launchos/modules/editor/Builder/Properties";

import { Tabs, Tab } from "@material-ui/core";
import { get } from "lodash";
import { Caption, Typography } from "@/Apps/Pages/Properties";

export interface CaptionSectionProps {
  updateComponentSettings: (
    id: string,
    properties: any,
    dbUpdate?: boolean,
    forceUpdate?: number
  ) => any;

  updateComponentStyle: (
    id: string,
    properties: any,
    dbUpdate?: boolean,
    forceUpdate?: number
  ) => any;

  settings: any;
}

export const CaptionSection: React.FC<CaptionSectionProps> = (props) => {
  const { updateComponentSettings, updateComponentStyle, settings } = props;
  const [value, setValue] = React.useState<number>(0);

  const switchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={switchTab}>
        <Tab label="First Line" value={0} />
        <Tab label="Second Line" value={1} />
      </Tabs>
      {value === 0 && (
        <div style={{ padding: 20 }}>
          <Caption
            text="Line 1 Text"
            value={get(settings, "caption1", "")}
            placeholder="Enter a label for the first line of the button"
            onKeyUp={(caption1) => {
              updateComponentSettings(settings.id, {
                ...settings,
                caption1,
              }, false, true);
            }}
            onChange={(caption1) => {
              updateComponentSettings(settings.id, {
                ...settings,
                caption1,
              });
            }}
          />
          {get(settings, 'caption1', '').length ? (
            <Typography
              {...props}
              updateComponentStyle={(id, properties, dbUpdate, forceUpdate) => {
                // console.log({ id, properties, dbUpdate, forceUpdate });
                updateComponentSettings(
                  id,
                  {
                    ...settings,
                    caption1style: {
                      ...get(settings, "caption1style", {}),
                      ...properties,
                    },
                  },
                  dbUpdate,
                  forceUpdate
                );
              }}
              settings={{
                ...settings,
                properties: {
                  ...settings.properties,
                  ...settings.caption1style,
                },
              }}
              type="Line 1"
              hideLabel
              isExpanded
            // showLineHeight
            />
          ) : null}
        </div>
      )}

      {value === 1 && (
        <div style={{ padding: 20 }}>
          <Caption
            text="Line 2 Text"
            value={get(settings, "caption2", "")}
            placeholder="Enter a label for the second line of the button"
            onKeyUp={(caption2) => {
              updateComponentSettings(settings.id, {
                ...settings,
                caption2,
              }, false, true);
            }}
            onChange={(caption2) => {
              updateComponentSettings(settings.id, {
                ...settings,
                caption2,
              });
            }}
          />
          {get(settings, 'caption2', '').length ? (
            <Typography
              {...props}
              updateComponentStyle={(id, properties, dbUpdate, forceUpdate) => {
                // console.log({ id, properties, dbUpdate, forceUpdate });
                updateComponentSettings(
                  id,
                  {
                    ...settings,
                    caption2style: {
                      ...get(settings, "caption2style", {}),
                      ...properties,
                    },
                  },
                  dbUpdate,
                  forceUpdate
                );
              }}
              settings={{
                ...settings,
                properties: {
                  ...settings.properties,
                  ...settings.caption2style,
                },
              }}
              type="Line 2"
              hideLabel
              isExpanded
            // showLineHeight
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
