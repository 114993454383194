import { Widget } from "../Dashboards/types";

export const pfocWidgets: Widget[] = [
  "contactTitle",
  {
    type: "columns",
    settings: {
      columns: 2,
      widgets: [
        {
          type: "group",
          settings: {
            widgets: [
              "contactInfo",
              "contactNotes",
              "pfocContactType",
              "pfocNextDestination",
            ],
          },
        },
        {
          type: "group",
          settings: {
            widgets: ["pfocQRCode", "pfocScrollStatus", "pfocChurchInfo"],
          },
        },
      ],
    },
  },
];

export default pfocWidgets;