import { useContext, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/javascript/javascript";

import { Calout } from "@/ui/Calout";

import { Panel } from "@/ui/Panel";
import { TabsComponent } from "@/ui/TabsComponent";
import { Modal } from "@/ui/Modal";
import { TextInput } from "@/ui/TextInput";
import { Dropdown } from "@/ui/Dropdown";
import { PopupPosition, PopupSizes } from "@/ui/Modal/types";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

import integrationProviders from "./integrationProviders.json";
import {
  createUserIntegration,
  deleteUserIntegration,
  getUserIntegrationSettings,
  updateUserIntegrationSettings,
} from "../../api/integrations";
import { LinkSimpleHorizontal, WebhooksLogo } from "@phosphor-icons/react";

import { isJsonString } from "@/utils";
import { AppContext } from "@/AppContext";
import { SmallInfoIcon } from "@/ui/Icons";

import { getSessionToken } from "@/api/auth";

const Title = ({ children }) => (
  <div className="p-5 mb-5 text-2xl text-center">{children}</div>
);

export const APISettingsModal = ({
  setIsOpen,
  activeProviderId,
  activeIntegrationId,
}) => {
  // const activeIntegration = integrationProviders.find(integration => integration.id === activeIntegrationId);
  const [integrationSettings, setIntegrationSettings] = useState({});
  const [providerSettings, setProviderSettings] = useState({});

  // get integration settings from api
  const loadIntegrationSettings = async () => {
    if (activeIntegrationId) {
      const token = getSessionToken();
      const response = await getUserIntegrationSettings(activeIntegrationId, {
        token,
      });
      const ps = integrationProviders.find(
        (integration) => integration.id === response.type
      );
      setProviderSettings(ps);
      setIntegrationSettings(response);
      console.log({ response });
    } else {
      const ps = integrationProviders.find(
        (integration) => integration.id === activeProviderId
      );
      setProviderSettings(ps);
      setIntegrationSettings({ type: ps.id, setting: {} });
    }
  };

  const handleUpdateUserIntegrationSettings = async () => {
    const token = getSessionToken();
    const sanitizedIntegrationSettings = {
      type: integrationSettings?.type,
      setting: integrationSettings?.setting,
    };

    // update or create integration
    if (activeIntegrationId) {
      const response = await updateUserIntegrationSettings(
        activeIntegrationId,
        { token, settings: sanitizedIntegrationSettings }
      );
      console.log({ response });
    } else {
      const response = await createUserIntegration({
        token,
        settings: sanitizedIntegrationSettings,
      });
      console.log({ response });
      // reload
      window.location.reload();
    }

    setIsOpen(false);
  };

  useEffect(() => {
    loadIntegrationSettings();
  }, []);

  return (
    <Modal
      icon={
        <img src={providerSettings?.img} className="h-8 w-8" alt="images" />
      }
      title={`${providerSettings?.caption} Settings`}
      size={PopupSizes.LARGE}
      onClose={() => setIsOpen(false)}
      onSuccess={handleUpdateUserIntegrationSettings}
    >
      <Panel title="API Settings">
        <div className="grid grid-flow-row gap-7">
          {providerSettings?.fields?.map((field, index) => {
            return (
              <TextInput
                key={index}
                value={integrationSettings?.setting?.[field.name]}
                label={field.caption}
                type={
                  field.name === "password"
                    ? TextInputTypes.PASSWORD
                    : TextInputTypes.TEXT
                }
                flavor={TextInputFlavors.MODERN}
                onChange={(e) =>
                  setIntegrationSettings({
                    ...integrationSettings,
                    setting: {
                      ...integrationSettings.setting,
                      [field.name]: e.target.value,
                    },
                  })
                }
              />
            );
          })}
        </div>
      </Panel>
      <div className="mb-5" />
      <Calout
        icon={<LinkSimpleHorizontal />}
        title={
          <a
            className="underline text-lightpurple-300"
            href={providerSettings?.support?.url}
            target="_blank"
          >
            {providerSettings?.support?.title}
          </a>
        }
      />
    </Modal>
  );
};

export const CustomWebHookModal = ({ setIsOpen, activeIntegrationId }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "required");
  // const { hideWebHookEditor, integration, createIntegration, updateIntegration, allIntegrationQuery } = props;
  const [variables, setVariables] = useState({
    name: "",
    url: "",
    icon: "",
    body: "{}",
    settings: "{}",
    method: "POST",
  });
  const { darkMode } = useContext(AppContext);

  const handleSave = async () => {
    // make sure valid json
    if (!isJsonString(variables.body) || !isJsonString(variables.settings)) {
      alert("Please use valid JSON in Field Mapping and User Field tabs");
    } else {
      if (activeIntegrationId) {
        const response = await updateUserIntegrationSettings(
          activeIntegrationId,
          {
            token: localStorage.token,
            settings: {
              setting: variables,
              type: "webhook",
            },
          }
        );
        console.log("Update webhook response", response);
        setIsOpen(false);
        // reload
        window.location.reload();
      } else {
        const response = await createUserIntegration({
          token: localStorage.token,
          settings: {
            setting: variables,
            type: "webhook",
          },
        });
        console.log("Create webhook response", response);
        setIsOpen(false);
        // reload
        window.location.reload();
      }
    }
  };
  // get integration settings from api
  const loadIntegrationSettings = async () => {
    const token = getSessionToken();
    if (activeIntegrationId) {
      const response = await getUserIntegrationSettings(activeIntegrationId, {
        token,
      });
      setVariables(response?.setting);
      console.log({ response });
    }
  };

  useEffect(() => {
    loadIntegrationSettings();
  }, []);

  return (
    <Modal
      title="Add a Custom Webhook"
      size={PopupSizes.LARGE}
      onClose={() => setIsOpen(false)}
      icon={<WebhooksLogo />}
      onSuccess={handleSave}
    >
      <Calout
        icon={<SmallInfoIcon />}
        title="Set up a webhook profile to notify a custom server of new leads or sales. Then select it in campaign settings after opening the editor."
      />

      <div className="mb-5" />

      <TabsComponent
        tabs={[
          { label: "Required", value: "required" },
          { label: "Field Mapping", value: "mapping" },
          { label: "User Fields", value: "fields" },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        actionSection={false}
      />

      <div className="p-2">
        {activeTab === "required" && (
          <div className="grid gap-5">
            <TextInput
              label="Name of Integration"
              flavor={TextInputFlavors.MODERN}
              value={variables?.name}
              onChange={(e) =>
                setVariables({ ...variables, name: e.target.value })
              }
            />
            <TextInput
              label="Icon URL"
              flavor={TextInputFlavors.MODERN}
              value={variables?.icon}
              onChange={(e) =>
                setVariables({ ...variables, icon: e.target.value })
              }
            />
            <TextInput
              label="Webhook URL"
              flavor={TextInputFlavors.MODERN}
              value={variables?.url}
              onChange={(e) =>
                setVariables({ ...variables, url: e.target.value })
              }
            />
            <Dropdown
              label={variables?.method}
              items={[
                {
                  label: "POST",
                  onClick: () => setVariables({ ...variables, method: "POST" }),
                },
                {
                  label: "GET",
                  onClick: () => setVariables({ ...variables, method: "GET" }),
                },
              ]}
            />
          </div>
        )}

        {activeTab === "mapping" && (
          <div className="grid border border-black/10 dark:border-white/5">
            <CodeMirror
              className="cdmirror"
              options={{
                mode: { name: "javascript", json: true },
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={variables?.body}
              onChange={(editor) => {
                setVariables({ ...variables, body: editor });
              }}
              autoFocus
            />
          </div>
        )}

        {activeTab === "fields" && (
          <div className="grid border border-black/10 dark:border-white/5">
            <CodeMirror
              className="cdmirror"
              options={{
                mode: { name: "javascript", json: true },
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={variables?.settings}
              onChange={(editor) => {
                setVariables({ ...variables, settings: editor });
              }}
              autoFocus
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export const DeleteIntegrationModal = ({ activeIntegrationId, setIsOpen }) => {
  const handleIntegrationDelete = async () => {
    const token = getSessionToken();
    const response = await deleteUserIntegration(activeIntegrationId, {
      token,
    });
    if (response) {
      setIsOpen(false);
      window.location.reload();
    } else {
      alert("Error deleting integration");
    }
  };

  return (
    <Modal
      title="Delete this integration?"
      onClose={() => setIsOpen(false)}
      onSuccess={handleIntegrationDelete}
    >
      <Title>Are you sure you want to delete this integration?</Title>

      <Calout title="Note: Any connections to this integration will be disconnected." />
    </Modal>
  );
};
