import React from "react";
import { RightCarrotIcon } from "../Icons";
import { Link } from "react-router-dom";
import { BreadcrumbsProps } from "./types";

// Define the Breadcrumbs component as a React functional component that takes in BreadcrumbsProps as props.
export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items = [], type = "Arrow" }) => {
  // Set the default separator to be a right carrot icon.
  let separator = <span data-testid="Breadcrumbs-Separator"><RightCarrotIcon /></span>;

  // If the type prop is "Pipe", change the separator to a pipe character.
  if (type === "Pipe")
    separator = <span data-testid="Breadcrumbs-Separator" className="text-xs font-light"> | </span>;
  // If the type prop is "Slash", change the separator to a slash character.
  if (type === "Slash")
    separator = <span data-testid="Breadcrumbs-Separator" className="text-xs font-light"> / </span>;

  // Render the Breadcrumbs component as an unordered list with a test ID and flexbox styling.
  return (
    <ul
      data-testid="Breadcrumbs"
      className="flex flex-wrap items-center space-x-2"
    >
      {/* Map over the items prop and render each breadcrumb item as a list item. */}
      {items.map(({ label, href = "#", icon }, key) => (
        <li key={key} className="flex items-center space-x-2">
          {/* If this is not the last breadcrumb item, render it as a link with the separator. */}
          {key < items.length - 1 ? (
            <>
              <Link
                className="flex items-center space-x-1.5 text-black/40 dark:text-white/40 transition-colors hover:text-black dark:hover:text-white"
                to={href}
              >
                {icon}
                <span>{label}</span>
              </Link>
              {separator}
            </>
          ) : (
            // If this is the last breadcrumb item, render it as plain text.
            <span>{label}</span>
          )}
        </li>
      ))}
    </ul>
  );
};