export const questionnaire = {
  aboutYou: {
    brandStory:
      "What is your brand story (e.g. tell me the history and evolution of your company)?",
    industryOrMarket:
      "What industry or market segment do you (or would like to) serve?",
  },
  aboutYourProduct: {
    productOrService: "[Product] or [Service] or [None Yet]?",
    description:
      "What product or service do you offer, and how do your clients receive what you offer (e.g. one-on-one service, an online course, a physical product, etc.)",
    features:
      "Tell me about the product you sell and how you deliver it. List out the BEST features (or useful facts) about it that make it such a good product.",
    pricing: "What do you currently charge for it?",
    satisfactionGuarantee:
      "Do you have a satisfaction guarantee or way to alleviate the risk of buying? What is it?",
  },
  aboutBestCustomers: {
    demographics:
      "Think about your best (or ideal) customer.  Can you describe their demographic profile? Please consider factors like their age range, gender, geographic location, marital status, ethnicity, political views, level of education, and average income. Remember, this is just an initial sketch of your ideal prospect, not the entirety of your target market.",
    dreamOutcome:
      "Think about your best (or ideal) customer. Can you describe the key outcomes & results they’ll achieve through your service or product?",
    emotionsAndBeliefs:
      "Now describe core wants, feelings, and beliefs. What do they deeply desire, their fears, their desired feelings after through your product or service?",
  },
  marketingPromise: {
    uniqueSolution:
      "Differentiation is key. Think about the main insight or differentiating feature you want your prospects to instantly grasp about you. What makes it not just better, but uniquely different?",
    promise:
      "What is your core commitment to your prospects? What is the ultimate promise or solution that your product offers to your prospects?",
    topBelief:
      "What’s the #1 idea a prospect who is MOST QUALIFIED for what you’re offering should believe before being introduced to your offer. Based on your previous answers, I came up with this as your primary belief. Is this accurate? Please adjust it below. The key to achieving {dream outcome} is {unique solution}",
  },
  whyBelieve: {
    credibility: "Why should I believe {credibility}",
    primaryBelief: "Why should I believe {primary belief}",
    differenceFromCompetition:
      "How is {product/solution} different from the competitions?",
    authenticity: "How do I know this is real?",
    trust: "Why should I trust you?",
    mechanismForOutcome: "How does it work to produce {dream outcome}",
  },
};
