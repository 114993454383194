export enum ActionTypes {
    DO_NOTHING = "DO_NOTHING",
    GO_TO_URL = "GO_TO_URL",
    DOWNLOAD_FILE = "DOWNLOAD_FILE",
    OPEN_POPUP = "OPEN_POPUP",
    HIDE_POPUP = "HIDE_POPUP",
    SUBMIT_FORM = "SUBMIT_FORM",
    TRIGGER_PURCHASE = "TRIGGER_PURCHASE",
    TRIGGER_UPSELL = "TRIGGER_UPSELL",
    ADD_TO_CART = "ADD_TO_CART",
    REMOVE_FROM_CART = "REMOVE_FROM_CART",
    TOGGLE_ADD_REMOVE_TO_CART = "TOGGLE_ADD_REMOVE_TO_CART",
    SHOW_AND_HIDE = "SHOW_AND_HIDE",
    CREATE_TAG = "CREATE_TAG",
    TRIGGER_NEXT_NODE = "TRIGGER_NEXT_NODE",
    SWITCH_TABS = "SWITCH_TABS",
    SWITCH_CAROUSEL_ITEM = "SWITCH_CAROUSEL_ITEM",
    TRIGGER_WEBHOOK = "TRIGGER_WEBHOOK",
    SCROLL_TO_SECTION = "SCROLL_TO_SECTION",
    ADD_TO_CALENDAR = "ADD_TO_CALENDAR",


    TRIGGER_BOLTPAY = "TRIGGER_BOLTPAY",

}

export enum ActionBehaviors {
    CLICK = "click",
}

export interface IAction {
    behavior?: ActionBehaviors;
    type: ActionTypes;
    name: string;
    payload: unknown;
}
