import * as React from "react";

interface UploadGroupProps {
  /**
   * ...all of the section props
   */
  sectionProps?: any;

  /**
   * (DropZone props)
   */
  dropZoneSettings?: Boolean;

  /**
   * Whether or not to show the dropzone with upload and url selection buttons or just the dropzone alone
   */
  showURLPicker?: Boolean;

  /**
   * This is the label for the upload button.  Used to inform the user what it's uploading (e.g. [UPLOAD VIDEO] if text=[video])
   */
  text?: string;

  /**
   * The style to apply to the container just inside the <Section /> object
   */
  style?: object;

  /**
   * The current url that is being used for the object (e.g. an image or video).
   */
  url?: string;

  /**
   * Triggered after the upload occurs. Passes in a preview url for an instant view. And then the actual url once the upload is complete
   */
  onChange?: () => void;
}

/**
 * A section for uploading content.  Through drag/drop, clicking an upload button, or entering in a url
 */
const UploadGroup: React.FC<UploadGroupProps> = ({ children }) => {
  return <div>{children}</div>;
};

export default UploadGroup;
