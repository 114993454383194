import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "@/Apps/Funnels/Popups/PublishCampaignModal";

import { DashboardPage } from "@/Apps/Dashboards";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "details");
  const [productData, setProductData] = useState<{ name?: string }>({
    name: "",
  });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  // const [activePageId, setActivePageId] = useState(null);
  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setProductData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/products" },
      { label: productData.name || "" },
    ],
    items: (
      <Button
        label="</> Get Code"
        onClick={() => setIsPublishPopupOpen(true)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          unitName="Product"
        />
      )}
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Product Details", value: "details" },
              { label: "Advanced", value: "advanced" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "details" && (
            <Settings
              campaignData={productData}
              unitName="Product"
              widgets={["name", "delete"]}
              // need a way to add thinks like price, media, description, etc.
            />
          )}
          {activeTab === "advanced" && (
            <DashboardPage
              campaignData={productData}
              unitName="product"
              widgets={["share"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
