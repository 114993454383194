import { get } from "lodash";
import React from "react";

import {
  convertToRem,
  convertFromRem,
} from "@/Apps/Pages/MobileResponsiveToggle/style";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { WidthProps } from "./types";
import { Minus, Plus } from "@phosphor-icons/react";
import { ButtonSliderGroup, W } from "../BuildingBlocks/ButtonSliderGroup/ButtonSliderGroup";

/**
 * Creates a Button Slider Group to Change the Components Width
 */
const Width: React.FC<WidthProps> = ({
  updateComponentStyle = () => null,
  onChange = () => null,
  settings = {},
  objCoordinates = {},
  parentCoordinates = {},
  minValue = 10,
  maxValue = 2000,
  widthLabel = "Width",
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
}) => {
  let maxCheckValue = false;
  const width = get(settings, "properties.width", 0);
  const maxWidth = get(settings, "properties.maxWidth", 0);

  const { type } = getMobileState();
  const convertedWidthValue = convertFromRem(width, type);
  const convertedMaxWidthValue = convertFromRem(maxWidth, type);
  let value = convertedWidthValue;

  // set value to max width value if width is 100%
  if (convertedWidthValue === "100%" && convertedMaxWidthValue)
    value = convertedMaxWidthValue;

  // set value to the width if maxWidth is 100%
  if (convertedMaxWidthValue === "100%" && width) value = convertedWidthValue;

  // turn on the max check box if width is 100% (and maxWidth has a non-numeric value)
  if (
    convertedWidthValue === "100%" &&
    (!convertedMaxWidthValue || convertedMaxWidthValue === "inherit")
  )
    maxCheckValue = true;

  // turn on the max check box if maxWidth is 100% (and width has a non-numeric value)
  if (
    convertedMaxWidthValue === "100%" &&
    (!convertedWidthValue || convertedWidthValue === "inherit")
  )
    maxCheckValue = true;

  // turn on the max check box if both width and maxWidth are 100%
  if (convertedWidthValue === "100%" && convertedMaxWidthValue === "100%")
    maxCheckValue = true;

  const handleChange = (newValue, shouldDbUpdate = true, wait = 100) => {
    const newWidth = value === "100%" ? objCoordinates.width : value;

    let widthOffset: number | string = "auto";
    if (newValue === "SHRINK") widthOffset = Number(newWidth) - 10;
    else if (newValue === "GROW") widthOffset = Number(newWidth) + 10;

    const finalValue = Number.isInteger(newValue) ? newValue : widthOffset;

    const convertedValue = useRem ? convertToRem(finalValue, type) : finalValue;

    const newStyle = {
      width: convertedValue,
      maxWidth: "100%",
    };

    if (newValue === "100%")
      updateComponentStyle(
        settings.id,
        { width: "100%", maxWidth: "100%" },
        shouldDbUpdate,
        wait
      );
    else {
      updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);
    }

    onChange();
  };

  return (
    <div data-testid="Properties-Width">
      <ButtonSliderGroup
        onChange={handleChange}
        text={widthLabel}
        button1={{ icon: <Minus />, value: "SHRINK" }}
        button2={{ icon: <Plus />, value: "GROW" }}
        minValue={minValue}
        maxValue={parentCoordinates.width || maxValue}
        value={value || objCoordinates.width || 0}
        showMaxCheck
        maxCheckValue={Number(maxCheckValue)}
      />
    </div>
  );
};

export default Width;
