import React from "react";
import {
  OptionProps,
  OptionGroupProps
} from "./types";

// Option component
// This component represents an individual option in an option group.
export const Option: React.FC<OptionProps> = ({ label, checked, onChange }) => {
  return (
    <div data-testid="Option" className="relative budget-input">
      {/* Label for the option */}
      <label className="flex cursor-pointer rounded-lg border border-black/10 dark:border-white/10 bg-white dark:bg-white/5 justify-between items-start gap-4 py-2.5 px-2 text-center">
        {/* Option label */}
        <h3 className="font-semibold relative z-10 mx-auto">{label}</h3>
        {/* Radio button for the option */}
        <input
          name="teamsize"
          type="radio"
          className="form-checkbox relative hidden z-10 peer"
          checked={checked}
          onChange={onChange}
        />
        {/* Span for the radio button */}
        <span className="rounded-lg border border-black/10 dark:border-white/10 peer-checked:border-black dark:peer-checked:border-lightpurple-200 peer-checked:bg-lightwhite dark:peer-checked:bg-white/5 absolute top-0 left-0 z-0 w-full h-full"></span>
      </label>
    </div>
  );
};

// OptionGroup component
// This component represents a group of options.
export const OptionGroup: React.FC<OptionGroupProps> = ({
  options = [], selectedOption, onChange,
}) => {
  return (
    // <div className="grid grid-cols-4 gap-4 mb-2">
    <div data-testid="OptionGroup" className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-2">
      {/* Map over the options array and render an Option component for each option */}
      {options.map((option) => (
        <Option
          key={option}
          label={option}
          checked={selectedOption === option}
          onChange={() => onChange(option)} />
      ))}
    </div>
  );
};