import { DefaultDataProps } from "@/ui/DataView/types";

const labelMap = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  address1: "Address",
  city: "City",
  state: "State",
  zip: "Zipcode",
  phone: "Phone",
  churchName: "Church Name",
  churchLocation: "Church Location",
  pastorName: "Pastor Name",
  notes: "Notes",
  inQueue: "In Queue",
  scrollReceived: "Scroll Was Received",
  scrollSent: "Scroll Was Sent",
};

const getDataType = (value, id) => {
  // console.log("checking value type for", id, value, typeof value);
  if (id === "notes") return "longtext";

  if (value === "") return "string";
  if (value === false) return "boolean";
  if (typeof value === "boolean") return "boolean";
  if (typeof value === "number") return "number";
  if (typeof value === "string") return "string";
  if (value === null) return "string";
  if (typeof value === "object") return "object";
  return "string";
};

export const mapContactResponseToView = (data): DefaultDataProps => {
  return {
    id: data.id,
    label: data.title,
    // banner: "https://via.placeholder.com/150",
    data: Object.keys(data).map((key) => {
      return {
        id: key,
        label: labelMap[key] || key,
        value: data[key],
        fieldType: getDataType(data[key], key),
      };
    }),
  };
};

export const getFilterObject = ({ field, operator, value }) => {
  return {
    operator: "and",
    rules: [
      {
        field,
        operator,
        value,
      },
    ],
  };
};
