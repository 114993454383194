import { traffic } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";

export const destinationNodes = ({
  position,
  campaignData,
  nodes,
  setNodes,
}) => [
  {
    label: "Destinations",
    // add destination options for Smart Route, Route to URL, Another Funnel, and AB Split Testing
    items: [
      {
        label: getNodeMenuLabelComponent("Route to URL"),
        icon: getNodeIconComponent({
          imageSrc:
            "https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png",
          imageStyle: { maxHeight: 35 },
          containerStyle: { padding: 0 },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: traffic.find(({ id }) => id === "traffic_source_redirect"),
            setNodes,
            nodes,
          });
        },
      },
      {
        label: getNodeMenuLabelComponent("Smart Router"),
        icon: getNodeIconComponent({
          imageSrc:
            "https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_conditionalredirect.png",
          imageStyle: { maxHeight: 35 },
          containerStyle: { padding: 0 },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: traffic.find((obj) => obj.type === "SmartRouteComponent"),
            setNodes,
            nodes,
          });
        },
      },
      {
        label: getNodeMenuLabelComponent("Another Funnel"),
        icon: getNodeIconComponent({
          imageSrc:
            "https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_anotherfunnel.png",
          imageStyle: { maxHeight: 35 },
          containerStyle: { padding: 0 },
        }),
        onClick: () =>
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: traffic.find((obj) => obj.type === "AnotherFunnelComponent"),
            setNodes,
            nodes,
          }),
      },
      {
        label: getNodeMenuLabelComponent("AB Split Testing"),
        icon: getNodeIconComponent({
          imageSrc:
            "https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_splittesting.png",
          imageStyle: { maxHeight: 35 },
          containerStyle: { padding: 0 },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page: traffic.find((obj) => obj.type === "ABSplitComponent"),
            setNodes,
            nodes,
          });
        },
      },
    ],
  },
];
