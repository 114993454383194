import { traffic } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";
import { getIconData } from "../utils";

export const trackingNodes = ({ position, campaignData, nodes, setNodes }) => [
  {
    label: "Traffic Source",
    items: traffic.map((page) => {
      const { Icon, imageSrc, color } = getIconData(
        { ...page, page: { type: page?.pageType } },
        false
      );
      return {
        label: getNodeMenuLabelComponent(page.name),
        icon: getNodeIconComponent({
          Icon,
          imageSrc,
          imageStyle: { maxHeight: 35 },
          color,
          iconStyle: { fontSize: "10pt" },
          containerStyle: { padding: 0 },
        }),
        onClick: () => {
          handleCreateNewPageFromTemplate({
            position,
            campaignData,
            page,
            setNodes,
            nodes,
          });
        },
      };
    }),
  },
];
