import _, { get } from "lodash";
import * as React from "react";
// import {
//   DropdownMenu as DropDownMenuMD,
//   FontIcon,
//   MenuButton as MenuButtonMD,
// } from "react-md";
// import { Cell, Grid, Button } from "..";

import MenuMUI from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon'

import { FixedPositions } from "../types";
import { MenuProps, MenuButtonProps } from "./Menu.d";
import menuStyle from "./style";
import Grid, { Cell } from "../Grid";
import Button from "../Button";

/**
 * This component let's you set up a button that displays a menu list when clicked
 * @param {object} props The props
 * @returns {function} The component
 */
const MenuButton: React.FC<MenuButtonProps> = ({
  position = FixedPositions.BOTTOM_LEFT,
  id,
  icon,
  children,
  style = {},
  // onClick = () => null,
  className,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null)
  }

  return (
    <div data-testid="Components-MenuButton">
      <Button
        icon
        style={style}
        onClick={handleClick}
        className={className}
      >
        {icon}
      </Button>
      <MenuMUI anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </MenuMUI>
    </div>
  )
};

/**
 * The Menu controlled component is used to display a list of children in the List component once the visible prop is true.
 * @param param0
 */
const Menu: React.FC<MenuProps> = ({
  position = FixedPositions.BOTTOM_LEFT,
  className = "",
  id,
  children,
  style = {},
  label,
  icon = "keyboard_arrow_down",
  onClick = () => null,
  visible,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null)
  }

  return (
    // <div data-testid="Components-Menu" style={style}>
    <div
      data-testid="Components-Menu"
      style={style}
      // className={`relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-black/10 dark:border-white/10 ${className}`}
      className={`
        form-multiselect !bg-none py-1 px-3 w-full text-black dark:text-white border 
        border-black/10 dark:border-white/10 rounded-lg placeholder:text-black/20
        dark:placeholder:text-white/20 focus:border-black dark:focus:border-white/10 
        focus:ring-0 focus:shadow-none hover:bg-black/10 hover:dark:bg-white/10 ${className}
      `}
    >
      {label ? (
        <Grid justify="space-between" onClick={handleClick}>
          <Cell
            align="center"
            style={{
              ...menuStyle.cell,
              textAlign: get(style, "textAlign", "left"),
            }}
          >
            {label}
          </Cell>

          {icon && (
            <Cell align="center" style={menuStyle.icon}>
              <Icon className="arrdropdown">{icon}</Icon>
            </Cell>
          )}
        </Grid>
      ) : (
        <Icon className="arrdropdown" onClick={handleClick}>
          {icon}
        </Icon>
      )}

      <MenuMUI
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </MenuMUI>
    </div>
  );
};

export default Menu;
export { MenuButton };
