import { ContactGroup } from "./ContactGroup";
import { ContactWidgetProps } from "./types";

export const ContactInfo: React.FC<ContactWidgetProps> = ({ id }) => {
  const contactFields = ["firstName", "lastName", "email", "address1", "city", "state", "zip", "phone"];

  return (
    <ContactGroup id={id} title="Contact Information" fields={contactFields} />
  );
};
