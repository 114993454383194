import { Link } from "react-router-dom";
import { Panel } from "@/ui/Panel";
import { Calout } from "@/ui/Calout";
import { integrationProviders } from ".";
import { ListDropdown } from "@/ui/ListDropdown";
import { DotsThree } from "@phosphor-icons/react";
import { Switch } from "@/ui/Switch";

export const IntegrationAccountList = ({
  setIntegrationsPopupIsShowing = () => null,
  activeIntegrations = [],
  showIntegrationsPopup = () => null,
  setActiveIntegrationId = () => null,
  setActiveProviderId = () => null,
  setIsDeleteIntegrationModalShowing = () => null,
}) => {
  return (
    <div className="grid grid-flow-row divide-y divide-black/10 dark:divide-white/10 mt-3">
      {integrationProviders.map((provider, index) => {
        const activeIntegrationSettings = activeIntegrations.find(
          (integration) => integration.type === provider.id
        );
        const isActive = Boolean(activeIntegrationSettings);
        return (
          <div key={index} className="flex items-center gap-4 py-4">
            {/* {JSON.stringify(provider)} */}
            <img
              src={provider.img}
              alt="images"
              className="flex-none w-8 h-8"
            />
            <div className="flex-1 flex items-center justify-between gap-4">
              <div>
                <p className="text-sm font-semibold">{provider.caption}</p>
                <p className="text-xs text-black/40 dark:text-white/40">
                  {provider.description}{" "}
                  <a
                    href={provider.support.url}
                    target="_blank"
                    className="text-lightpurple-300"
                  >
                    Learn More
                  </a>
                </p>
              </div>
              <div className="togglebutton inline-block flex gap-3">
                {isActive ? (
                  <>
                    <Link
                      to="#"
                      className="text-xs text-black/40 dark:text-white/40 underline"
                      onClick={() => {
                        // setActiveIntegrationId(activeIntegrationSettings.id);
                        console.log("activeIntegrationSettings", activeIntegrationSettings);
                        // setTimeout(() => setIntegrationsPopupIsShowing(true), 2500);
                        showIntegrationsPopup(activeIntegrationSettings.id);
                      }}
                    >
                      Edit Settings
                    </Link>
                    <ListDropdown
                      elements={<DotsThree />}
                      items={[
                        {
                          label: "Edit Settings",
                          onClick: () => {
                            // setActiveIntegrationId(activeIntegrationSettings?.id);
                            // setIntegrationsPopupIsShowing(true);
                            showIntegrationsPopup(activeIntegrationSettings.id);
                          },
                        },
                        {
                          label: "Delete",
                          onClick: () => {
                            setActiveIntegrationId(
                              activeIntegrationSettings?.id
                            );
                            setIsDeleteIntegrationModalShowing(true);
                          },
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Switch
                    htmlFor={`toggle${provider.id}`}
                    checked={isActive} // onChange={e => { if (e.target.checked) setIntegrationsPopupIsShowing(true) }}
                    onChange={(e) => {
                      // setActiveIntegrationId(null);
                      // setActiveProviderId(provider.id);
                      // setIntegrationsPopupIsShowing(true);
                      // console.log("provider.id", provider.id);
                        showIntegrationsPopup(null, provider.id);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const ConnectedAccounts = ({
  setIntegrationsPopupIsShowing = () => null,
  activeIntegrations = [],
  showIntegrationsPopup = () => null,
  setActiveIntegrationId = () => null,
  setActiveProviderId = () => null,
  setIsDeleteIntegrationModalShowing = () => null,
}) => {
  return (
    <Panel title="My Integrations">
      <Calout
        title="Activate the integrations you want to use"
        // icon={<ShieldIcon />}
        caption={
          <span>
            These integrations will be where your leads and sales go in your
            funnels. &nbsp;
            <Link to="#" className="text-lightpurple-300">
              Learn More
            </Link>
          </span>
        }
      />
      <IntegrationAccountList
        setIntegrationsPopupIsShowing={setIntegrationsPopupIsShowing}
        activeIntegrations={activeIntegrations}
        setActiveIntegrationId={setActiveIntegrationId}
        setActiveProviderId={setActiveProviderId}
        setIsDeleteIntegrationModalShowing={setIsDeleteIntegrationModalShowing}
        showIntegrationsPopup={showIntegrationsPopup}
      />
    </Panel>
  );
};
