import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign } from "@/api/campaigns";

// import { Contacts } from "@/Apps/Dashboards/Contacts";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { CampaignBuilder } from "@/Apps/Funnels";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";
import { ContactsGallery } from "../CRM/ContactsGallery";
import { getFilterObject } from "../CRM/scripts";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [campaignData, setCampaignData] = useState<{ name?: string }>({ name });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const { id } = useParams();

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setCampaignData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: "Smart Forms", href: "/forms" },
      { label: campaignData.name || "" },
    ],
    items: (
      <Button
        label="Publish Sequence"
        onClick={() => setIsPublishPopupOpen(true)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          unitName="Sequence"
        />
      )}
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Dashboard", value: "overview" },
              { label: "Sequence Builder", value: "builder" },
              // { label: "Responses", value: "responses" },
              { label: "Contacts", value: "contacts" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "overview" && (
            <DashboardPage
              campaignData={campaignData}
              widgets={[
                {
                  type: "kpi",
                  settings: { items: ["views", "visitors", "leads"] },
                },
                {
                  type: "pages",
                  settings: {
                    getDropDownItems: (defaultItems) => {
                      // Rename the default items
                      const editItemKey = defaultItems.findIndex(({ id }) => id === "edit"); // prettier-ignore
                      const publishItemKey = defaultItems.findIndex(({ id }) => id === "publish"); // prettier-ignore
                      const deleteItemKey = defaultItems.findIndex(({ id }) => id === "delete"); // prettier-ignore

                      // Rename the default items
                      defaultItems[editItemKey].label = "Edit Step"; // prettier-ignore
                      defaultItems[publishItemKey].label = "View Published Step"; // prettier-ignore
                      defaultItems[deleteItemKey].label = "Delete Step"; // prettier-ignore

                      // Find the key of hte first divider
                      const firstDividerKey = defaultItems.findIndex(({ type }) => type === "divider"); // prettier-ignore

                      // Remove the homepage and 404 page from the dropdown
                      return defaultItems.filter(
                        ({ id }, key) =>
                          id !== "homepage" &&
                          id !== "404" &&
                          key !== firstDividerKey
                      );
                    },
                  },
                },
              ]}
              unitName="step"
              panelTitle="Steps in this sequence"
            />
          )}
          {activeTab === "builder" && (
            <div className="h-[calc(100vh-174px)]">
              <CampaignBuilder id={id} campaignData={campaignData} />
            </div>
          )}
          {activeTab === "responses" && <div>Responses</div>}
          {activeTab === "contacts" && (
            <ContactsGallery
              onCreate={() => {}}
              defaultFilter={getFilterObject({
                field: "hiddenTags",
                operator: "contains",
                value: id,
              })}
            />
          )}
          {activeTab === "settings" && (
            <Settings campaignData={campaignData} unitName="Sequence" />
          )}
        </div>
      </div>
    </Page>
  );
};
