import ReactGA from "react-ga4";

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isSystemDarkMode = () =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const syncSystemDarkMode = (setDarkMode) => {
  const runColorMode = (fn) => {
    if (!window.matchMedia) return;
    const query = window.matchMedia("(prefers-color-scheme: dark)");
    fn(query.matches);
    query.addEventListener("change", (event) => fn(event.matches));
  };

  runColorMode((isSystemDarkMode) => {
    setDarkMode(isSystemDarkMode);
  });
};

export const getTotalByKey = (dataArray, key) => {
  // Initialize the total sum to 0
  let total = 0;

  // Loop through each object in the array
  dataArray.forEach((item) => {
    // Add the value associated with the key to the total sum
    // We use the key parameter to dynamically access the property
    total += item[key] || 0; // The || 0 ensures that undefined values are treated as 0
  });

  // Return the total sum
  return total;
};

export const initGA = (measurementId) => {
  ReactGA.initialize(measurementId);
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const styleClean = (str) => str.replace(/ /g, "");