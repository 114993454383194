import React from "react";
import {
  TextInputFlavors,
  TextInputProps,
  TextInputValidationTypes,
} from "@/ui/TextInput/types";

export const TextArea: React.FC<TextInputProps> = ({
  label, // The label for the input
  value = "", // The value of the input
  placeholder = "", // The placeholder text for the input
  required = false, // Whether the input is required or not
  flavor = TextInputFlavors.CLASSIC, // The flavor of the input
  name = "", // The name of the input
  validationType = TextInputValidationTypes.NONE, // The validation type of the input
  validationMessage, // The validation message for the input
  className = "", // The class name for the input
  onChange = () => null, // The onChange event handler for the input
  onKeyUp = () => null, // The onKeyUp event handler for the input
}) => {
  let inputClass = "form-input"; // The class name for the input element
  let containerClass = ""; // The class name for the container element
  let labelClass = ""; // The class name for the label element
  let validationMessageClass = ""; // The class name for the validation message element

  if (flavor === TextInputFlavors.DEFAULT) {
    // If the flavor is DEFAULT, set the container and input class names accordingly
    labelClass = "font-semibold";
    inputClass = "form-input mt-3";
  }

  if (flavor === TextInputFlavors.MODERN) {
    // If the flavor is MODERN, set the container and label class names accordingly
    containerClass =
      "relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer";
    labelClass = "block text-xs text-black/40 dark:text-white/40 mb-1";
  }

  if (flavor === TextInputFlavors.CLASSIC) {
    // If the flavor is CLASSIC, set the input class name accordingly
    if (label) {
      inputClass =
        "form-input mt-2 py-2.5 px-4 w-full text-black dark:text-white border border-black/10 dark:border-white/10 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-black dark:focus:border-white/10 focus:ring-0 focus:shadow-none;";
    } else {
      inputClass =
        "form-input py-2.5 px-4 w-full text-black dark:text-white border border-black/10 dark:border-white/10 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-black dark:focus:border-white/10 focus:ring-0 focus:shadow-none;";
    }
  }

  if (flavor === TextInputFlavors.ROUNDED) {
    // If the flavor is ROUNDED, set the input class name accordingly
    inputClass =
      "form-input !rounded-full py-2.5 px-4 w-full text-black dark:text-white border border-black/10 dark:border-white/10 placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-black dark:focus:border-white focus:ring-0 focus:shadow-none;";
  }

  // Validation Mode
  if (validationType !== TextInputValidationTypes.NONE) {
    // If the validation type is not NONE, set the container, label, input, and validation message class names accordingly
    if (validationType === TextInputValidationTypes.SUCCESS) {
      containerClass =
        "relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-green-600";
      labelClass = "block text-xs text-green-600 mb-1";
      inputClass = "form-input text-green-600";
      validationMessageClass = "text-green-600 mt-1";
    } else if (validationType === TextInputValidationTypes.ERROR) {
      containerClass =
        "relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-lightred";
      labelClass = "block text-xs text-lightred mb-1";
      inputClass = "form-input text-lightred";
      validationMessageClass = "text-lightred mt-1";
    }

    // // If the flavor is CLASSIC, set the container class name accordingly
    // if (flavor === TextInputFlavors.CLASSIC) {
    //   // inputClass += " bg-transparent"
    //   // inputClass = "form-input py-2.5 px-4 w-full text-green-600 dark:text-green-600 border border-green-600 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-green-600 focus:ring-0 focus:shadow-none;"
    //   // containerClass += " bg-transparent";
    //   // containerClass = ""
    //   containerClass =
    //     "relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-green-600";
    //   // labelClass = "form-input py-2.5 px-4 w-full text-green-600 dark:text-green-600 border border-green-600 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-green-600 focus:ring-0 focus:shadow-none;"
    // }
  }

  return (
    <div
      data-testid="TextArea"
      className={`relative ${containerClass} ${className}`}
    >
      {/* <div className="relative"> */}
      {Boolean(label) && (
        <label
          htmlFor="description"
          // className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2"
          className={labelClass}
        >
          {label}
        </label>
      )}
      <textarea
        id="description"
        // className="block rounded-lg px-5 pb-4 pt-[38px] w-full text-black dark:text-white bg-white dark:bg-white/5 border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer"
        className={inputClass}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        defaultValue={value}
        required={required}
        name={name}
        style={{ resize: "vertical", overflow: "auto" }}
      />

      {validationType !== TextInputValidationTypes.NONE && (
        <p className={validationMessageClass}>{validationMessage}</p>
      )}
    </div>
  );

  return (
    <>
      <div data-testid="TextArea" className={`${containerClass} ${className}`}>
        {Boolean(label) && <label className={labelClass}>{label}</label>}
        <input
          type={type}
          onChange={onChange}
          onKeyUp={onKeyUp}
          value={value}
          placeholder={placeholder}
          className={inputClass}
          required={required}
          name={name} />
      </div>
      {validationType !== TextInputValidationTypes.NONE && (
        <p className={validationMessageClass}>{validationMessage}</p>
      )}
    </>
  );
};