import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ArrowUUpLeft, ArrowUUpRight, Eye, FrameCorners, Sparkle } from "@phosphor-icons/react";
import { useEditorActions } from "./EditorCanvas";
import UndoRedoButtons from "../UndoRedoButtons";
import { DEFAULT_PAGE_ID } from "./types";


export const BrowserWrapper = ({
  children,
  withUrl = true,
  withPreview = true,
  withUndoRedo = false,
  withFullScreen = true,
  withCustomButtons = false,
  customButtonIcons = [],
  selected = false,
  url = "•••",
}: {
  withUrl?: boolean;
  withPreview?: boolean;
  withUndoRedo?: boolean;
  withFullScreen?: boolean;
  withCustomButtons?: boolean;
  customButtonIcons?: any;
  selected?: boolean;
  url?: string;
  children: React.ReactNode;
}) => {
  const { doUndoRedo } = useEditorActions();
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle} className="w-full h-full">
      <div
        className={`rounded-md overflow-hidden shadow-3xl h-full w-full border border-black/20 ${
          selected ? "bg-white/90" : "bg-white"
        } relative`}
      >
        <div className="p-3 bg-white dark:bg-white/10 border-b border-black/10">
          {/* Three circles to represent browser chrome buttons */}
          <div className="flex absolute space-x-1 py-px">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
            {withUndoRedo && (
              <UndoRedoButtons doUndoRedo={doUndoRedo} pageId={DEFAULT_PAGE_ID} />
            )}
          </div>
          {/* Show the url in the middle */}
          {withUrl && (
            <div className="text-center text-xs text-gray-500">
              <a href={url} target="_blank">
                <span className="p-1 rounded bg-black/5 px-7 rounded-lg hover:text-underline">
                  {url}
                </span>
              </a>
            </div>
          )}

          {(withPreview || withFullScreen || withCustomButtons) && (
            <div className="absolute right-3 top-2 py-px gap-0 flex">
              {/* Show custom buttons */}
              {withCustomButtons && customButtonIcons && customButtonIcons.map(({icon, onClick}) => (
                <button
                  onClick={onClick}
                  className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                >
                  {icon}
                </button>
              ))}


              {/* {withCustomButtons && (
                <button
                  onClick={() => customButtonFunction()}
                  className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                >
                  <Sparkle />
                </button>
              )} */}

              {/* Show a preview button to the right */}
              {withPreview && (
                <a href={url} target="_blank">
                  <button className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500">
                    <Eye />
                  </button>
                </a>
              )}

              {/* Show a fullscreen button to the right */}
              {withFullScreen && (
                <button
                  onClick={handle.active ? handle.exit : handle.enter}
                  className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                >
                  <FrameCorners />
                </button>
              )}
            </div>
          )}
        </div>
        <div className="h-full w-full">{children}</div>
      </div>
    </FullScreen>
  );
};
