import Height from "../BasicProperties/Height";
import Width from "../BasicProperties/Width";
import Section from "../Section";

const WidthHeight = (props) => (
  <Section label="Size" icon="photo_size_select_large" {...props}>
    {props.showWidth ? <Width {...props} /> : null}
    {props.showHeight ? <Height {...props} /> : null}
  </Section>
);

WidthHeight.defaultProps = {
  showWidth: true,
  showHeight: true,
};

export default WidthHeight;
