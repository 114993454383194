export interface ITheme {
    default: any;
    darkMode: any;
    lightMode: any;
}
export const themeSettings: ITheme = {
    default: {
        primaryColor: "#060606",
        secondaryColor: "#1baa10",
        highlightColor: "#f05a1f",
        lsHighlightColor: "#2096D3",
        darkTextColor: "#060606",
        lightTextColor: "#FFFFFF",
        heroImage: "background.png",
        avatar:
            "https://scontent-mia3-2.xx.fbcdn.net/v/t1.0-9/83336313_2530980476999415_5189690747049213952_o.jpg?_nc_cat=1&_nc_sid=85a577&_nc_ohc=vbqCtn5e_HoAX90N-Oz&_nc_ht=scontent-mia3-2.xx&oh=4ad24c763d18af44eacfb4eb9539004e&oe=5E991086",
        logo: "logos/bradlea.png",
        company: "Brad Lea LLC",
    },
    darkMode: {
        // primaryColor: "#060606",
    },
    lightMode: {},
};