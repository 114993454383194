import { Page, Container } from '../Page'
import { MainContent } from "../../all";

export const KitchenSink: React.FC = () => {

	return (
		<Page topBar={{ page: [{ label: "Kitchen Sink" }] }}>
			<Container>
				<MainContent />
			</Container>
		</Page>
	)
}

export default KitchenSink;