import { TourProvider } from "@reactour/tour";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { get, has } from "lodash";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout";
import { Button } from "@/ui/Button";
import { TabsComponent } from "@/ui/TabsComponent";
import { ButtonTypes, Variants } from "@/ui/types";
import { AppContext } from "@/AppContext";

import { getCampaign } from "@/api/campaigns";
import CampaignBuilder from "./CampaignBuilder";

import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { PublishCampaignModal } from "./Popups/PublishCampaignModal";
// import { Contacts } from "@/Apps/Dashboards/Contacts";
import { getSessionToken } from "@/api/auth";
import { Loading } from "@/ui/Layout/Loading";
import { ContactsGallery } from "../CRM/ContactsGallery";
import { getFilterObject } from "../CRM/scripts";
import { getOnboardingData } from "@/api/entities";
import { Sketchpad } from "../Sketchpad/Sketchpad";
import ListDropdown from "@/ui/ListDropdown";
import { DownCarrotIcon } from "@/ui/Icons";
import { FunnelVisionModal } from "./Popups/FunnelVisionModal";
import VisualBuilder from "./VisualBuilder";

import BeautifulDropdown from "@/ui/BeautifulDropdown";
import { blueprintProfiles as items } from "@/Apps/Planning/data";
import MobileResponsiveToggle from "../Pages/MobileResponsiveToggle";
import UndoRedoButtons from "../Pages/UndoRedoButtons";
import { DEFAULT_PAGE_ID } from "../Pages/Editor/types";

export const FunnelDashboard = ({ name }) => {
  // Define state variables
  const [activeTab, setActiveTab] = useState(
    getActiveTabFromLocalStorage() || "overview"
  );
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isFunnelVisionPopupOpen, setIsFunnelVisionPopupOpen] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [isShowingStats, setIsShowingStats] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Get the funnel ID from the URL
  const { id } = useParams();

  // Get the dark mode setting from the app context
  const { darkMode, permissions } = useContext(AppContext);

  const loadEverything = async () => {
    const token = getSessionToken();
    const data = await getCampaign({ token, id });
    setCampaignData(data);

    // Get the profile data
    const plannerProfiles = campaignData?.settings?.plannerProfiles || [];
    let profileDataArray = [];
    for (let i = 0; i < plannerProfiles.length; i++) {
      const resp = await getOnboardingData(token, plannerProfiles[i], null);
      profileDataArray.push(resp);
    }
    // flatten the array
    const profileDataFlat = profileDataArray.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});

    setProfileData(profileDataFlat);
    setIsLoading(false);
  };

  // Define the function to handle tab changes
  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    if (tab === "overview" || tab === "builder" || tab === "sketchpad") {
      setIsLoading(true);
      await loadEverything(); // reload the data when the tab changes
    }
  };

  // handle campaign generation
  // const handleCampaignGeneration = async () => {

  // };

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    loadEverything();

    // get hash from url and set active tab
    const hash = window.location.hash;
    if (hash) {
      const tab = hash.replace("#", "");
      setActiveTab(tab);
    }
  }, []);

  let withPadding = true;
  if (activeTab === "canvas") withPadding = false;

  // Render the component
  return (
    <Page
      topBar={{
        page: [
          { label: name, href: "/funnels" },
          {
            label: get(campaignData, "name", "•••"),
            href: `/funnels/${id}`,
            context: {
              campaignId: id,
              profileData,
            },
          },
        ],
        // leftItems: (
        //   <>
        //     {activeTab === "canvas" && (
        //       <>
        //         <BeautifulDropdown
        //           label="+ Add Item"
        //           footer={false}
        //           items={items}
        //           onSelect={console.log}
        //         />
        //       </>
        //     )}
        //   </>
        // ),
        items: (
          <>
            {activeTab === "canvas" && (
              <>
                <UndoRedoButtons doUndoRedo={() => {}} pageId={DEFAULT_PAGE_ID} />
                <MobileResponsiveToggle />
              </>
            )}
            {activeTab === "builder" && (
              <>
                <Button
                  label={isShowingStats ? "Hide Stats" : "Show Stats"}
                  onClick={() => setIsShowingStats(!isShowingStats)}
                  type={ButtonTypes.SOFT}
                  className="bg-transparent"
                  // variant={Variants.SECONDARY}
                />
                {/* <Button
                  onClick={() => {
                    const proceed = window.confirm(
                      "This will generate new content for this campaign.  Are you sure you want to proceed?"
                    );
                    if (proceed) handleCampaignGeneration();
                  }}
                  label="Regenerate Funnel"
                  variant={Variants.INFO}
                /> */}
              </>
            )}
            {/* <Button
              label="Publish"
              onClick={() => setIsPublishPopupOpen(true)}
              type={ButtonTypes.OUTLINED}
              variant={Variants.INFO}
            /> */}
            <ListDropdown
              element={
                <Button
                  label={
                    <div className="flex gap-1">
                      <span className="">Actions</span>
                      <DownCarrotIcon className="pt-1" />
                    </div>
                  }
                  type={ButtonTypes.OUTLINED}
                  variant={Variants.INFO}
                />
              }
              items={[
                {
                  id: "view",
                  label: "Publish this Campaign",
                  onClick: () => setIsPublishPopupOpen(true),
                },
                { id: "divider", type: "divider" },
                {
                  id: "view",
                  label: "Upload Funnel Sketch",
                  onClick: () => setIsFunnelVisionPopupOpen(true),
                  // onClick: () => openPublishedPage({ campaignData, pageData }),
                },
              ]}
            />
          </>
        ),
      }}
    >
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
        />
      )}

      {/* Render the funnel vision modal if it's set to open */}
      {isFunnelVisionPopupOpen && (
        <FunnelVisionModal
          setIsOpen={setIsFunnelVisionPopupOpen}
          campaignData={campaignData}
        />
      )}

      {/* Render the dashboard tabs */}
      <div
        className={`w-full h-full ${withPadding && "p-6"}`}
        style={{
          ...(darkMode &&
          (activeTab === "builder" ||
            activeTab === "sketchpad" ||
            activeTab === "canvas")
            ? { backgroundColor: "rgb(22 22 22 / 0.8)" }
            : {}),
          ...(!darkMode &&
          (activeTab === "builder" ||
            activeTab === "sketchpad" ||
            activeTab === "canvas")
            ? { backgroundColor: "rgb(28 28 28 / 0.04)" }
            : {}),
        }}
      >
        <div className="tabs flex flex-col h-full">
          <div className={!withPadding ? "absolute z-10 p-6" : undefined}>
            <TabsComponent
              tabs={[
                { label: "Dashboard", value: "overview" },
                { label: "Sketch Pad", value: "sketchpad" },
                { label: "Visual Funnel Builder", value: "builder" },
                // { label: "Visual Builder", value: "canvas" },
                // { label: "Smart Canvas", value: "canvas" },
                { label: "Contacts", value: "contacts" },
                // { label: "Media", value: "media" },
                // { label: "Insights", value: "insights" },
                { label: "Settings", value: "settings" },
              ]}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              actionSection={false}
            />
          </div>

          {/* Render the appropriate tab content based on the active tab */}
          {!has(campaignData, "id") || isLoading ? (
            <Loading type="gallery" />
          ) : (
            <>
              {activeTab === "overview" && (
                <DashboardPage
                  campaignData={campaignData}
                  widgets={[
                    "kpi",
                    ...(permissions.includes("insights")
                      ? [
                          {
                            type: "columns",
                            settings: { widgets: ["pages", "tracking"] },
                          },
                        ]
                      : ["pages"]),
                  ]}
                />
              )}
              {activeTab === "builder" && (
                <div className="h-[calc(100vh-174px)]">
                  <TourProvider
                    steps={[
                      {
                        selector: ".first-step",
                        content: "This is my first Step",
                      },
                      // ...
                    ]}
                  >
                    <CampaignBuilder
                      id={id}
                      campaignData={campaignData}
                      showStats={isShowingStats}
                      // type="artboard"
                    />
                  </TourProvider>
                </div>
              )}
              {activeTab === "settings" && (
                <Settings
                  campaignData={campaignData}
                  setCampaignData={setCampaignData}
                />
              )}
              {activeTab === "sketchpad" && (
                <div className="h-full">
                  <Sketchpad
                    campaignData={campaignData}
                    setCampaignData={setCampaignData}
                  />
                </div>
              )}
              {activeTab === "canvas" && (
                <VisualBuilder id={id} campaignData={campaignData} />
              )}
              {activeTab === "contacts" && (
                <ContactsGallery
                  onCreate={() => {}}
                  defaultFilter={getFilterObject({
                    field: "hiddenTags",
                    operator: "contains",
                    value: id,
                  })}
                />
              )}
              {activeTab === "media" && <div>Media</div>}
              {activeTab === "insights" && <div>Insights</div>}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};
