import Dropdown from "@/ui/Dropdown";
import Grid from "@/ui/Grid";
import Panel from "@/ui/Panel";
import Select from "@/ui/Select";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

export const OfferDetails = ({
  data = {},
  campaignData = {},
  unitName = "Offer",
  onChange = () => {},
}) => {
  const { price, startDate, endDate } = data;
  return (
    <Panel title={`Pricing & Schedule`}>
      {/* Schedule */}
      <Grid className="mb-7" cols={2} smCols={1} lgCols={2}>
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} Start Date`}
          value={startDate}
          name="startDate"
          type={TextInputTypes.DATE}
          // onChange={onChange}
        />
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} End Date`}
          value={endDate}
          name="endDate"
          type={TextInputTypes.DATE}
          // onChange={onChange}
        />
      </Grid>

      {/* Product Choice */}
      <Select
        label="Product Choice"
        flavor={TextInputFlavors.MODERN}
        items={[{ label: "Product Name 1" }, { label: "Product Name 2" }]}
        className="mb-7"
      />

      {/* Price */}
      <TextInput
        flavor={TextInputFlavors.MODERN}
        label={`${unitName} Price`}
        value={price}
        placeholder={`${unitName} Price`}
        name="price"
        type={TextInputTypes.TEXT}
        // onChange={onChange}
      />
    </Panel>
  );
};