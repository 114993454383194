import { Dropdown } from "@/ui/Dropdown";
import { Button } from "@/ui/Button";
import { ButtonTypes, DropDownTypes } from "@/ui/types";
import { Panel } from "@/ui/Panel";
import { Calout } from "@/ui/Calout";
import { ShieldIcon } from "@/ui/Icons";
import { CampaignDomainNameProps } from "../types";
import { useEffect, useState } from "react";
import { AddDomainModal } from "@/Settings/DomainManagement/Popups";
import { getSessionToken } from "@/api/auth";
import { updateCampaign } from "@/api/campaigns";
import { getDomainData } from "@/api/domains";

export const CampaignDomainName: React.FC<CampaignDomainNameProps> = ({
  // campaignDomainId = "",
  unitName = "Funnel",
  // domainNameList = [],
  campaignData = {},
  setCampaignData = () => {},
  // setIsDomainPopupOpen = () => null,
  // setCampaignDomainId = () => null,
}) => {
  const [isDomainPopupOpen, setIsDomainPopupOpen] = useState(false);
  const [campaignDomainId, setCampaignDomainId] = useState();
  const [domainNameList, setDomainNameList] = useState([]);

  useEffect(() => {
    const updateCampaignDomain = async () => {
      const token = getSessionToken();
      if (!token) return;
      const response = await updateCampaign(campaignData?.id, {
        token,
        settings: { domainId: campaignDomainId },
      });
      console.log("response", response, campaignData);

      // Update the campaign data with the new domain
      const domainData = domainNameList.find(
        ({ id }) => id === campaignDomainId
      );

      setCampaignData({
        ...campaignData,
        domain: domainData,
      });
    };

    if (campaignData?.id) updateCampaignDomain();
  }, [campaignDomainId]);

  const loadDomainNameList = async () => {
    const token = getSessionToken();
    if (!token) return;
    const response = await getDomainData({ token });
    console.log("response", response);
    // console.log('campaignDomainId', campaignData?.domain?.id);
    setDomainNameList(response);
    setCampaignDomainId(campaignData?.domain?.id);
  };

  useEffect(() => {
    loadDomainNameList();
  }, []);

  const allDomains = domainNameList.map(({ name, id }) => ({
    label: name,
    id,
  }));

  return (
    <>
      {Boolean(isDomainPopupOpen) && (
        <AddDomainModal
          campaignId={campaignData?.id}
          setIsOpen={setIsDomainPopupOpen}
        />
      )}
      <Panel
        title={`${unitName} domain name`}
        actionButton={
          <Button
            onClick={() => setIsDomainPopupOpen(true)}
            type={ButtonTypes.SOFT}
            label="+ Add a new Domain"
          />
        }
      >
        <Calout
          icon={<ShieldIcon />}
          title={`Choose the Domain Name you want to use for this ${unitName.toLowerCase()}.`}
        />

        <div className="mb-5" />

        {Boolean(allDomains.length) && (
          <Dropdown
            label={
              allDomains.find((domain) => domain.id === campaignDomainId)
                ?.label || "Select a domain"
            }
            onClick={(k) => setCampaignDomainId(allDomains[k]?.id)}
            type={DropDownTypes.BASIC}
            items={allDomains}
          />
        )}
      </Panel>
    </>
  );
};
