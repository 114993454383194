import { ContactWidgetProps } from "@/Apps/CRM/widgets/types";
import GoogleMap from "../GoogleMap";
import { ContactGroup } from "@/Apps/CRM/widgets/ContactGroup";

export const ChurchInfo: React.FC<ContactWidgetProps> = ({ id }) => {
  const churchFields = ["churchName", "churchLocation", "pastorName"];

  return (
    <ContactGroup id={id} title="Church Information" fields={churchFields}>
      {(item) => {
        return (
          <div style={{ height: 300 }}>
            <GoogleMap
              addresses={[
                {
                  address: item?.data?.filter(
                    ({ id }) => id === "churchLocation"
                  )[0].value,
                },
              ]}
            />
          </div>
        );
      }}
    </ContactGroup>
  );
};