import React from "react";
import { BigOptionProps } from "../types";

export const BigOption: React.FC<BigOptionProps> = ({
  label,
  className = "",
  caption,
  icon,
  checked = false,
  disabled = false,
  onChange,
  value,
}) => {
  const handleClick = () => {
    if (!disabled && onChange) {
      onChange(!checked);
    }
  };

  return (
    <label
      className={`big-option ${className}`}
      data-testid="BigOption"
      htmlFor={`big-option__checkbox-${value}`}
    >
      {label && (
        <div className="big-option__label" data-testid="BigOptionLabel">
          {label}
        </div>
      )}
      {caption && (
        <div className="big-option__caption" data-testid="BigOptionCaption">
          {caption}
        </div>
      )}
      {icon && (
        <div className="big-option__icon" data-testid="BigOptionIcon">
          {icon}
        </div>
      )}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleClick}
        value={value}
        id={`big-option__checkbox-${value}`}
        data-testid={value}
        className={`big-option__checkbox ${
          disabled ? "big-option__checkbox--disabled" : ""
        }`}
      />
    </label>
  );
};

export default BigOption;
