import { CSSProperties } from "react";
// import { isNumber, keys } from "lodash";
import styled from "styled-components";

import theme from "@/ui/theme";

import { MobileState } from "./types";

const isFromIframe = window.location !== window.parent.location;

export const getDefaultFontSize: (type: MobileState) => number = (type) => {
  switch (type) {
    case MobileState.FULLSCREEN:
      return 16;
    case MobileState.DESKTOP:
      return 14;
    case MobileState.TABLET:
      return 12;
    case MobileState.SMARTPHONE:
      return 10;
    default:
      return 14;
  }
};

// export const convertPropertiesToEms = (
//   properties = {},
//   type = MobileState.DESKTOP
// ) => {
//   let newProperties: React.CSSProperties = properties;
//   const divisor = getDefaultFontSize(type);

//   keys(properties).forEach((k) => {
//     const val = properties[k]; //.replace('px', '');

//     switch (k) {
//       case "padding":
//         newProperties.padding = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       case "width":
//         newProperties.width = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       case "maxWidth":
//         newProperties.maxWidth = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       case "height":
//         newProperties.height = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       case "marginTop":
//         newProperties.marginTop = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       case "lineHeight":
//         newProperties.lineHeight = isNumber(val) ? `${val / divisor}rem` : val;
//         break;
//       default:
//         newProperties[k] = properties[k];
//         break;
//     }
//   });

//   // console.log({ properties, newProperties });

//   return newProperties;
// };

export const convertToRem: (
  value: string | number,
  mobileState: MobileState
) => string = (value, mobileState) => {
  const fontSize = getDefaultFontSize(mobileState);
  // is px or rem?
  const isUsingRem = String(value).indexOf("rem") > -1;

  // if already using rem, just return it unchanged
  if (isUsingRem || value === "100%") return String(value);
  // if not using rem (using px) calculate & return the rem version
  else return `${Number(value) / fontSize}rem`;
};

export const convertFromRem: (
  value: string | number,
  mobileState: MobileState
) => string | number = (value, mobileState) => {
  const fontSize = getDefaultFontSize(mobileState);

  // is px or rem?
  const isUsingRem = String(value).indexOf("rem") > -1;

  // if not using rem, just return it unchanged
  if (!isUsingRem || value === "100%") return value;
  // if using rem right now, convert it to px
  else return Number(String(value).replace("rem", "")) * fontSize;
};


export const BtnWrapper = styled.span`
  cursor: pointer;
  color: #666;
  display: inline-block;
  background-color: ${(props: { isActive: boolean }) =>
    props.isActive ? "#DDD" : "inherit"};
  &:hover {
    background-color: ${(props: { isActive: boolean }) =>
      props.isActive ? "#DDD" : "#EEE"};
  }
`;

export const style: {
  container: CSSProperties;
  deviceList: CSSProperties;
  fullscreen: CSSProperties;
  desktop: CSSProperties;
  tablet: CSSProperties;
  smartphone: CSSProperties;
  rotateIcon: CSSProperties;
  icon: CSSProperties;
} = {
  container: {
    position: "fixed",
    display: "inline-block",
    right: 20,
    bottom: 10,
    zIndex: 300,
    zoom: 0.8,
  },
  deviceList: {
    border: "1px solid #DDD",
    background: "white",
    display: "inline-block",
    borderRadius: 6,
  },
  fullscreen: {
    width: "100%",
    minHeight: "100vh",
    margin: 0,
    marginTop: isFromIframe ? 0 : 57,
  },
  desktop: {
    maxWidth: 1000,
    minHeight: "70vh",
    margin: `${isFromIframe ? "20px" : "80px"} auto`,
    overflow: "hidden",
  },
  tablet: {
    width: 768,
    backgroundColor: theme.darkColor,
    paddingTop: 65,
    paddingBottom: 65,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 50,
    height: 1024,
  },
  smartphone: {
    width: 411,
    backgroundColor: theme.darkColor,
    paddingTop: 65,
    paddingBottom: 65,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 50,
    height: 731,
  },
  icon: {
    cursor: "pointer",
    color: "#666",
  },
  rotateIcon: { display: "inline-block", width: 30 },
};
