import { useContext, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { getCampaign, updateCampaign } from "@/api/campaigns";
import { FileJs } from "@phosphor-icons/react";
import { TabsComponent } from "@/ui/TabsComponent";
import { AppContext } from "@/AppContext";
import { getSessionToken } from "@/api/auth";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import { Loading } from "@/ui/Layout/Loading";

export const CampaignTrackingCodesModal = ({ setIsOpen, campaignId }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "head");
  const { darkMode } = useContext(AppContext);
  const [variables, setVariables] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleCampaignTrackingCodesSave = async () => {
    // call the api to save the campaign tracking codes
    const token = getSessionToken();

    let sT = {};
    if (variables?.head) {
      sT = { ...sT, head: variables?.head };
    }
    if (variables?.body) {
      sT = { ...sT, body: variables?.body };
    }
    if (variables?.footer) {
      sT = { ...sT, footer: variables?.footer };
    }

    const scriptTags = JSON.stringify(sT);

    const settings = {
      scriptTags,
    };

    const response = await updateCampaign(campaignId, { token, settings });

    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Campaign Tracking Codes Saved");
        setIsOpen(false);
        console.log(response);
      }
    }
  };

  // load in the campaign tracking codes from the api if there are any
  const loadCampaignTrackingCodes = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    const response = await getCampaign({ token, id: campaignId });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        console.log("Campaign Tracking Codes", response);
        const { scriptTags } = response;
        if (scriptTags) {
          const sT = JSON.parse(scriptTags);
          console.log("sT", sT);

          let sv = variables;

          if (sT?.head) {
            sv = { ...sv, head: sT?.head };
          }
          if (sT?.body) {
            sv = { ...sv, body: sT?.body };
          }
          if (sT?.bodyEnd) {
            sv = { ...sv, footer: sT?.bodyEnd };
          }

          console.log("sv", sv);

          setVariables(sv);
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCampaignTrackingCodes();
  }, []);

  return (
    <Modal
      title="Campaign Tracking Codes"
      onClose={() => setIsOpen(false)}
      onSuccess={handleCampaignTrackingCodesSave}
      icon={<FileJs />}
      size={PopupSizes.LARGE}
    >
      <Title>
        Enter any custom scripts you want placed on each page of this campaign
      </Title>

      <TabsComponent
        tabs={[
          { label: "Head", value: "head" },
          { label: "Body", value: "body" },
          { label: "Footer", value: "footer" },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        actionSection={false}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid border border-black/10 dark:border-white/5">
          {activeTab === "head" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.head ||
                '<script type="text/javascript">\n\n\t// Replace with your head code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, head: editor });
              }}
              autoFocus
            />
          )}
          {activeTab === "body" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.body ||
                '<script type="text/javascript">\n\n\t// Replace with your body code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, body: editor });
              }}
              autoFocus
            />
          )}
          {activeTab === "footer" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.footer ||
                '<script type="text/javascript">\n\n\t// Replace with your footer code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, footer: editor });
              }}
              autoFocus
            />
          )}
        </div>
      )}
    </Modal>
  );
};
