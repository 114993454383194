import { Link } from "react-router-dom";
import { AppContext } from "@/AppContext";

import { GoToUrl } from './GoToUrl'
import style from "@/Apps/Pages/Properties/style";

export const SubmitForm = (props) => {
    const { action, updateAction, match, emailPayload } = props;
    const { campaignId, nodeId } = match.params;

    return (
        <AppContext.Consumer>
            {({ app }) => (
                <>
                    <GoToUrl action={action} updateAction={updateAction} {...props} />
                    <Link to={`/campaign/${campaignId}/${nodeId}/email/${app.showProviderStep && !_.has(emailPayload, "campaignId") ? "provider" : "campaign"}`}>
                        <span style={style.linkStyle}>Open Campaign Settings</span>
                    </Link>
                </>
            )}
        </AppContext.Consumer>
    )
}