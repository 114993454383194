import { useEffect, useState } from "react";
import { Calout } from "@/ui/Calout";
import { Modal, Title } from "@/ui/Modal";
import { TextInput } from "@/ui/TextInput";
import { createPage, getNewPageContent, makeSlug } from "@/api/pages";
import { useNavigate } from "react-router-dom";
import { getSessionToken, getTokenInfo } from "@/api/auth";
import { ComponentTypes, ISettings } from "@/Apps/Pages/Editor/types";
import { cloneCampaign, createCampaign, getCampaign } from "@/api/campaigns";
import { PopupSizes } from "@/ui/Modal/types";
import { TextInputFlavors } from "@/ui/TextInput/types";

interface AddPageModalProps {
  setIsOpen: (isOpen: boolean) => void;
  pageType?: string;
  pageTheme?: string;
  pageContent?: ISettings[];
  campaignId: string;
  unitName?: string;
  campaignAction?: "clone" | "create" | false | null; // before doing the page action, should we clone the provided campaign, create a new (empty) one, or do nothing
  pageAction?: "add" | "replace"; // should we add the new page to the campaign or replace one of the pages (TODO: implement replace)
  onSuccess?: (data: { campaignId: string; pageId: string }) => void;
}

export const AddPageModal: React.FC<AddPageModalProps> = ({
  setIsOpen,
  pageType = "blank",
  pageTheme = "blank",
  pageContent = getNewPageContent(),
  campaignId,
  campaignAction,
  onSuccess,
  unitName = "page",
}) => {
  const [pageName, setPageName] = useState<string>("");
  const [pageDomain, setPageDomain] = useState<string>("•••");

  const navigate = useNavigate();

  const handleAddPage = async () => {
    let useThisCampaignId = campaignId;
    // const emptyCampaignId = ""; // this is the id of the empty campaign that we will clone to create a new campaign

    if (campaignAction) {
      if (campaignAction === "clone") {
        // clone the provided campaign before adding a new page to it
        const response = await cloneCampaign(campaignId, {
          token: getSessionToken(),
          name: pageName,
        });
        useThisCampaignId = response?.data?.cloneCampaign?.response?.id;
        useThisCampaignId = response.id;
      }
      if (campaignAction === "create") {
        // clone an empty campaign before adding a new page to it
        const response = await createCampaign({
          token: getSessionToken(),
          name: pageName,
          type: unitName,
        });

        useThisCampaignId = response?.id;
        console.log("response", response);
      }
    }

    // call the api to add a new page
    const pageSettings = {
      token: getSessionToken(),
      settings: {
        name: pageName,
        campaignId: useThisCampaignId, // the newly created page will be added to this campaign
        // action: pageAction, // tells the api to either add the new page to the campaign or replace one of the pages
        theme: pageType,
        type: pageTheme,
        slug: makeSlug(pageName),
        content: pageContent,
      },
    };

    console.log("pageSettings", pageSettings);

    const response = await createPage(pageSettings);

    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Page Added");
        setIsOpen(false);
        console.log({ response });
        // load the page editor with the new page
        if (Boolean(onSuccess))
          onSuccess({ campaignId: useThisCampaignId, pageId: response.id });
        else navigate(`/funnels/${campaignId}/${response.id}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const token = getSessionToken();
      const { defaultDomain, subdomain } = await getTokenInfo(token);
      if (campaignId) {
        const { domain } = await getCampaign({ id: campaignId, token });
        setPageDomain(domain.name);
      } else {
        setPageDomain(`${subdomain}.${defaultDomain}`);
      }
    })();
  }, []);

  return (
    <Modal
      title={`Create a ${unitName}`}
      onClose={() => setIsOpen(false)}
      onSuccess={handleAddPage}
      size={PopupSizes.LARGE}
    >
      <div className="px-5">
        <Title>What would you like to name this {unitName}?</Title>

        <div className="grid grid-flow-row gap-7 mb-7">
          <TextInput
            // label={`What would you like to name this ${unitName}`}
            placeholder={`Enter ${unitName} name here`}
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            flavor={TextInputFlavors.MODERN}
          />
        </div>
        <div className="mb-5" />
        <Calout title={`https://${pageDomain}/${makeSlug(pageName)}`} />
      </div>
    </Modal>
  );
};
