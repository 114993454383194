import { BigCheck } from "@/ui/BigCheck";
import Grid from "@/ui/Grid";
import Panel from "@/ui/Panel";
import { PaypalLogo } from "@phosphor-icons/react";

export const SelectPaymentTypes = ({ campaignData = {} }) => {
  return (
    <Panel title="Payment Types Supported">
      <p className="text-sm text-gray-500">
        Choose the payment types you want to support.
      </p>
      <Grid cols={3} smCols={1} lgCols={3} className="mt-7">
        <BigCheck
          label="PayPal"
          caption="Choose this option if you want to support PayPal payments"
        />
        <BigCheck
          label="Wise"
          caption="Choose this option if you want to support Wise payments"
        />
        <BigCheck
          label="Check / Bank Wire"
          caption="Choose this option if you want to support Check / Bank Wire payments"
        />
      </Grid>      
    </Panel>
  );
};
