import { StepperProps } from "./types";

/**
 * A stepper component that displays a series of steps.
 * @param activeStep The index of the active step.
 * @param numberOfSteps The total number of steps in the stepper.
 */
export const Stepper: React.FC<StepperProps> = ({ activeStep = 1, numberOfSteps = 4 }) => (
  // The outer container for the stepper.
  <div data-testid="Stepper" className="flex gap-2 mb-3">
    {/* Generate an array of divs to represent each step. */}
    {Array.from({ length: numberOfSteps }, (_, index) => {
      // Determine the color of the current step based on whether it is active or not.
      let colorClass = (index + 1) === activeStep
        ? "bg-lightpurple-300"
        : "bg-black/10 dark:bg-white/10";
      // Render a div to represent the current step.
      return <div data-testid="Stepper-Step" key={index} className={`h-1 ${colorClass} w-full`} />;
    })}
  </div>
);