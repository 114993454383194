import { Link } from "react-router-dom";
import { Button } from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

export const BigBanner = ({
  title = "Choose Your Plan",
  caption = "Simple pricing. No hidden fees. Advanced features for you business.",
  // backgroundImage = "../images/pricing-bg.svg",
  backgroundImage = "../images/pricing-bg.svg",
  children,
}) => {
  return (
    <div
      className={`text-center mb-7 bg-[url(${backgroundImage})] bg-cover rounded-2xl py-16 bg-black border border-black/10 dark:border-white/10 `} // bg-black dark:bg-white/5*
    >
      <h1 className="text-5xl font-semibold text-white mb-2">{title}</h1>
      <p className="text-xs text-white mb-7">{caption}</p>
      {/* <p className="text-xs dark:text-black/30 text-white/30 mb-7">{caption}</p> */}
      {children}
    </div>
  );
};
export const BannerButton = ({ label, href = "#" }) => {
  return (
    <Link to={href}>
      <Button
        className="w-full"
        style={{ backgroundColor: "rgb(66,66,66,0.9)" }}
        label={label}
        type={ButtonTypes.SOFT}
      />
    </Link>
  );
};
