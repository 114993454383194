import { getImageFromType } from "@/Apps/Funnels/utils";
import { Avatar } from "@/ui/Avatar/Avatar";

// import { EditDrawer } from "@/Apps/Funnels/CampaignBuilder/EditDrawer";

import Button from "@/ui/Button";
import ListDisplayGroup from "@/ui/ListDisplayGroup";
import Panel from "@/ui/Panel";
import { ButtonTypes } from "@/ui/types";
import { useEffect, useState } from "react";
import KPISimpleGroup from "@/ui/TitleCard/KPISimpleGroup";
import { EditTrackingLinkModal } from "./EditTrackingLinkModal";
import { deleteObject } from "@/api/campaigns";

export const TrackingLinks: React.FC<{ campaignData: any }> = ({
  campaignData = {},
}) => {
  const [items, setItems] = useState([]);
  const [editDrawerIsShowing, setEditDrawerIsShowing] = useState(false);
  const [editDrawerIcon, setEditDrawerIcon] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    console.log(campaignData);
    const { objects = [] } = campaignData;
    // const trackingLinks = objects?.filter(
    //   ({ type }) => type === "RedirectComponent"
    // );
    setItems(objects.filter(({ deleted }) => !deleted));
    // console.log({ trackingLinks });
  }, [campaignData]);

  const handleEditLinkClick = (item) => {
    console.log({ item });
    setEditDrawerIcon(item?.image);
    setEditDrawerIsShowing(true);
    setActiveItem(item);
  };

  const handleAddTrackingLinkClick = () => {
    setEditDrawerIcon(null);
    setActiveItem(null);
    setEditDrawerIsShowing(true);
  };   
  
  const handleItemDelete = async (objectId) => {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (confirm) {
      await deleteObject(objectId);
      window.location.reload();
    }
  };

  return (
    <>
      {editDrawerIsShowing && (
        <EditTrackingLinkModal
          editDrawerIcon={editDrawerIcon}
          campaignId={campaignData?.id}
          item={activeItem}
          onClose={() => setEditDrawerIsShowing(false)}
        />
        // <EditDrawer
        //   icon={editDrawerIcon}
        //   title={`Edit Tracking Link`}
        //   activenodeType="TrackingSource"
        //   campaignId={campaignData?.id}
        //   editDrawerOpen={editDrawerIsShowing}
        //   setEditDrawerOpen={setEditDrawerIsShowing}
        //   viewAs="popup"
        //   // viewAs="drawer"
        // />
      )}

      <Panel
        title="Tracking Links"
        // withItems
        // items={[{ title: "All", href: "/insights/tracking-links" }]}
        actionButton={
          <Button
            onClick={handleAddTrackingLinkClick}
            type={ButtonTypes.SOFT}
            label="+ Create Tracking Link"
          />
        }
      >
        <ListDisplayGroup
          footer={{
            caption: `Click to add a new tracking link`,
            actionComponent: (
              <Button
                onClick={handleAddTrackingLinkClick}
                type={ButtonTypes.SOFT}
                label={`+ Add Tracking Link`}
              />
            ),
          }}
          items={items
            .filter(
              ({ type }) =>
                type === "RedirectComponent"
                || type === "TrackingSource" 
                || type === "AnotherFunnelComponent"
            )
            .map((item) => {
              const {
                name,
                description = "",
                connectTo = [],
                page = {},
                screenshot = "",
                type,
                id,
              } = item;
              // const { slug } = page;
              const slug = page?.slug || "•••";

              // get the ids of the connected items
              const connectedIds = connectTo.map(({ id }) => id);

              // get the screenshot
              const scrnsht =
                getImageFromType({ type, subType: page?.type }) || screenshot;

              // now look up the screenshot for the connected id
              const screenshot2obj = items.find(({ id }) =>
                connectedIds.includes(id)
              );
              const scrnsht2 =
                getImageFromType({
                  type: screenshot2obj?.type,
                  subType: screenshot2obj?.page?.type,
                }) || screenshot2obj?.screenshot;

              let srcList = [scrnsht];
              if (scrnsht2) srcList.push(scrnsht2);

              const image = (
                <Avatar
                  label="John Doe"
                  type="Group"
                  srcList={srcList}
                  {...(srcList.length === 1 && { extraCount: "🔗" })}
                />
              );

              const hrefItems = [
                // { label: "Number Format", number: 1234567.89, format: "number" },
                // {
                //   label: "Clicks",
                //   number: 12345,
                //   format: "number",
                // },
                // { label: "EPC", number: 1.21, format: "currency" },
                // { label: "Conversions", number: 0.012, format: "percentage" },
              ];

              return {
                id,
                title: name,
                caption: `/${slug}`,
                // icon: <img src={screenshot} alt="" className="w-12 h-12" />,
                image,
                // href: `/insights/tracking-links/${id}`,
                actions: (
                  <span style={{ zoom: 0.75 }}>
                    <KPISimpleGroup items={hrefItems} />
                  </span>
                ),
                onClick: () => handleEditLinkClick({ image, ...item }), // open popup
              };
            })}
          menuItems={[
            // {
            //   label: "Edit Link",
            //   onClick: (item) => {
            //     handleEditLinkClick(item);
            //   },
            // },
            { label: "Copy URL to Clipboard", onClick: () => alert("edit") },
            { type: "divider" },
            { label: "Delete", onClick: handleItemDelete },
          ]}
        />
      </Panel>
    </>
  );
};
