import { pages } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { makeSlug } from "../../../api/pages";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";
import { getIconData } from "../utils";

export const pageNodes = ({ position, campaignData, nodes, setNodes }) => [
  {
    label: "New Page",
    items: pages
      .filter(({ type }) => type === "PageComponent")
      .map((page) => {
        const { Icon, imageSrc, color } = getIconData(
          { ...page, page: { type: page?.pageType } },
          false
        );

        return {
          label: getNodeMenuLabelComponent(page.name),
          icon: getNodeIconComponent({
            Icon,
            imageSrc,
            color,
            iconStyle: { fontSize: "10pt", width: 45, height: 35, padding: 5 },
          }),
          onClick: () =>
            handleCreateNewPageFromTemplate({
              position,
              campaignData,
              page: {
                ...page,
                slug: `${makeSlug(page.name)}-${Math.random()
                  .toString(36)
                  .substr(2, 2)}`,
              },
              nodes,
              setNodes,
            }),
        };
      }),
  },
];
