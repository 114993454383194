import { useEffect, useState } from "react";
import { RuleGroupType } from "react-querybuilder";

import { BigCheck } from "@/ui/BigCheck";
import Panel from "@/ui/Panel";
import { CalendarDot, Clock } from "@phosphor-icons/react";

type ScheduleType = {
  type: "draft" | "now" | "later",
  options: { sendDate?: Date, sendTime?: Date },
}

export const BroadcastOptions = ({ options = {}, onChange = ({ schedule }) => {} }) => {
  const [query, setQuery] = useState<RuleGroupType>();
  const [sendSchedule, setSendSchedule] = useState<ScheduleType>(options?.schedule);

  const handleSetQuery = (query) => {
    setQuery(query);
    // onChange(query);
  };

  return (
    <div className="flex flex-col gap-3">
      {/* <Panel title="Send this email from...">
        <Dropdown
          label="From: "
          items={[{ label: "Now" }, { label: "Later" }]}
        />
      </Panel>
      <Panel
        title="Send this email to..."
        actionButton={<p className="font-bold">422 people</p>}
      >
        <LogicBuilder fields={[]} query={query} setQuery={handleSetQuery} />
      </Panel> */}
      <Panel title="Send this email when...">
        {/* <Dropdown
          label="Send now"
          // items={[{ label: "Now" }]}
          items={[
            { id: "draft", label: "Draft" },
            { id: "now", label: "Send Now" },
            { id: "later", label: "Send Later" },
          ]}
          type={DropDownTypes.BASIC}
          onClick={console.log}
        /> */}
        <SendSchedule
          schedule={sendSchedule}
          onChange={(schedule) => {
            setSendSchedule(schedule);
            onChange({ schedule });
          }}
        />
        {/* {JSON.stringify(sendSchedule)} */}
      </Panel>
    </div>
  );
};

export const DatePicker = ({ name, label = "date", placeholder = "date", value, onChange = () => {} }) => {
  return (
    <div className="relative">
      <div className="relative">
        <div
          htmlFor={name}
          className="absolute inset-y-0 left-0 flex items-center pt-[20px] pl-4 pointer-events-none"
        >
          <CalendarDot className="text-gray-400 text-lg m-1" />
        </div>
        <input
          type="date"
          value={value}
          id={name}
          className="block rounded-lg px-10 pb-4 pt-[38px] w-full text-sm text-black dark:text-white dark:bg-white/5 bg-white border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer"
          placeholder="pick a date"
          onChange={onChange}
        />
      </div>
      <label className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2">
        {label || placeholder}
      </label>
    </div>
  );
}

export const TimePicker = ({ name, label = "time", placeholder = "time", value, onChange = () => {} }) => {
  return (
    <div className="relative">
      <div className="relative">
        <div
          htmlFor={name}
          className="absolute inset-y-0 left-0 flex items-center pt-[20px] pl-4 pointer-events-none"
        >
          <Clock className="text-gray-400 text-lg m-1" />
        </div>
        <input
          type="time"
          value={value}
          id={name}
          className="block rounded-lg px-10 pb-4 pt-[38px] w-full text-sm text-black dark:text-white dark:bg-white/5 bg-white border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer"
          placeholder="pick a time"
          onChange={onChange}
        />
      </div>
      <label className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2">
        {label || placeholder}
      </label>
    </div>
  );
}

const SendSchedule = ({
  schedule = { type: "now", options: {} },
  onChange = ({ type, options }) => null,
}) => {
  const [options, setOptions] = useState(schedule.options);
  const [items, setItems] = useState([
    {
      id: "draft",
      label: "Don't Send Yet (Draft)",
      caption: "Save this email as a draft to send later.",
      checked: schedule.type === "draft",
    },
    {
      id: "now",
      label: "Send Now",
      caption: "Send the email immediately.",
      checked: schedule.type === "now",
    },
    {
      id: "later",
      label: "Send Later",
      caption: "Schedule the email to be sent at a specific time.",
      checked: schedule.type === "later",
    },
  ]);

  const handleDateChange = e => {
    const newOptions = { ...options, sendDate: e.target.value };
    setOptions(newOptions);
    onChange({ type: "later", options: newOptions })
  };

  const handleTimeChange = (e) => {
    const newOptions = { ...options, sendTime: e.target.value };
    setOptions(newOptions);
    onChange({ type: "later", options: newOptions });
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-7">
        {items.map(({ id, label, caption = "", checked }) => (
          <BigCheck
            label={label}
            caption={caption}
            onClick={() => {
              let newItems = items.map((itm) => {
                if (itm.id === id) return { ...itm, checked: true };
                else return { ...itm, checked: false };
              });

              setItems(newItems);

              onChange({ type: id, options });
            }}
            checked={checked}
          />
        ))}
      </div>
      {items.find(({ id }) => id === "later").checked && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
          <DatePicker
            name="send-date"
            label="Send Date"
            onChange={handleDateChange}
            value={schedule.options?.sendDate}
            />
          <TimePicker
            name="send-time"
            label="Send Time"
            onChange={handleTimeChange}
            value={schedule.options?.sendTime}
          />
        </div>
      )}
    </div>
  );
};
