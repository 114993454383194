import { BrowserWrapper } from "./BrowserWrapper";
import { EditorCanvas, EditorTypes, useEditorActions } from "./EditorCanvas";
import { BODY_ID, ComponentTypes } from "./types";
import SwitchGroup from "../Properties/components/groups/SwitchGroup";
import { useState } from "react";
import { Generator } from "../Properties/components/generator/Generator";
import { updateSection } from "../Properties/components/generator";

import { SectionTypes } from "../Properties/types";
// import { BrowserRouter as Router } from "react-router-dom";
import { X } from "@phosphor-icons/react";
import { IPropertyBuilderSettings } from "../Properties/components/generator/types";
import { style } from "../WebComponents/v2/Button/style";
import {
  ActionBehaviors,
  ActionTypes,
} from "../Properties/BuildingBlocks/Interactions/types";

import textSettings from "../WebComponents/v2/Text/settings";
import buttonSettings from "../WebComponents/v2/Button/settings";
import navigationSettings from "../WebComponents/v2/Navigation/settings";

import { IconTypes } from "../WebComponents/v2/Icon/types";
import { CaptionSection } from "../WebComponents/v2/Button/CaptionSection";
import { ColorPickerSection } from "../WebComponents/v2/Button/ColorPickerSection";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { LinkLayoutStyles } from "../WebComponents/v2/Navigation/types";
import { LinkBuilder } from "../WebComponents/v2/Navigation/Properties/LinkBuilder";
import Layout from "../Properties/components/sections/Layout";

export default {
  title: "Modules/Editor/Classic Editor (v1)",
  excludeStories: /.*Data$/,
};

export const mobileStateData = {
  desktop: {
    type: "desktop",
    dimensions: {
      maxWidth: 1000,
      minHeight: "70vh",
      margin: "80px auto",
      overflow: "hidden",
    },
  },
  fullscreen: {
    type: "fullscreen",
    dimensions: {
      width: "100%",
      height: "100vh",
      marginTop: 57,
    },
  },
};

export const sampleContentData = [
  {
    parent: "u5j1r64m5rf",
    id: "bj51e4dfmym",
    ...navigationSettings.default,
  },
  // {
  //   parent: "u5j1r64m5rf",
  //   id: "bj51e45wmym",
  //   properties: {
  //     // marginTop: 50,
  //     marginTop: 0,
  //     color: "#95a5a6",
  //     textAlign: "center",
  //     fontFamily: "Advent Pro",
  //     fontSize: 36,
  //     padding: 5,
  //     display: "inline-block",
  //   },
  //   type: "Paragraph",
  //   html: "<p style='color:red;'>Replace this content with your own!</p>",
  // },
  {
    parent: "u5j1r64m5rf",
    id: "hm3iuax13pd",
    type: ComponentTypes.VIDEO,
    src: {
      mp4: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
      ogg: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
    },
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: 450,
    },
  },
  // {
  //   parent: "u5j1r64m5rf",
  //   id: "fa3sdbsas3",
  //   type: ComponentTypes.PICTURE,
  //   src: "https://assets-pages.s3.amazonaws.com/drop_img-02.jpg",
  //   properties: {
  //     textAlign: "center",
  //     display: "inline-block",
  //     width: "100%",
  //   },
  // },
  // {
  //   parent: "u5j1r64m5rf",
  //   id: "hm3iurc13pd",
  //   properties: {
  //     marginTop: 2,
  //     color: "#95a5a6",
  //     textAlign: "center",
  //     fontFamily: "Advent Pro",
  //     fontSize: 20,
  //     padding: 5,
  //     mobile: {
  //       desktop: {
  //         marginTop: 2,
  //         color: "#95a5a6",
  //         textAlign: "center",
  //         fontFamily: "Advent Pro",
  //         fontSize: 20,
  //       },
  //     },
  //     display: "inline-block",
  //     width: 350,
  //   },
  //   type: "Paragraph",
  //   html: "<p>(Just select me and hit your keyboard's [DELETE] key)</p>",
  // },
  {
    id: "hm3iurc1dsd",
    type: ComponentTypes.BUTTON,
    properties: style.BUTTON.properties,
    // properties: { display: "inline-block" },
    // hoverStyle: { backgroundColor: theme.highlightColor },
    hoverStyle: {},
    caption1: "Add to Cart",
    caption1style: style.BUTTON.caption1,
    // caption2: "Thank You For The Discount!",
    caption2style: style.BUTTON.caption2,
    parent: "u5j1r64m5rf",
    actions: [
      {
        type: ActionTypes.TRIGGER_NEXT_NODE,
        name: "Go to the next page",
        payload: {
          url: "{next_page}",
        },
        behavior: ActionBehaviors.CLICK,
      },
    ],
  },
  {
    parent: 2540,
    id: "u5j1r64m5rf",
    properties: {
      width: "100%",
      display: "flex",
      backgroundImage: "",
      mobile: {
        desktop: {
          padding: 100,
        },
      },
      padding: 100,
    },
    type: "Container",
    canHaveChildren: true,
  },
  {
    parent: false,
    state: "normal",
    id: BODY_ID,
    properties: {
      backgroundColor: "#ffffff",
      backgroundImage: "none",
      height: "100%",
      backgroundAttachment: "scroll",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "repeat-y",
      mobile: {
        desktop: {
          backgroundColor: "#ffffff",
          backgroundImage: "none",
          backgroundAttachment: "scroll",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "repeat-y",
        },
      },
    },
    type: ComponentTypes.BODY,
    canHaveChildren: true,
  },
];

export const Default = () => {
  const campaignId = "clzj3e1he7wiu0811cpbqq07l";
  const pageId = "clzj3fo3f7wkw0811l2ae5bw7";
  const token = "ZWM0MTI2YjAtNjk2OS0xMWVmLTk3NDktMjlmNzI3YzMxMjdi";
  return (
    <div>
      <p className="py-5">Please update the token...</p>
      <div className="h-[calc(100vh-73px)]">
        <BrowserWrapper url="•••">
          <EditorCanvas
            campaignId={campaignId}
            pageId={pageId}
            toShow="builder"
            token={token}
            // getDropDownItems={handleGetDropDownItems}
            onLoad={async () => {
              console.log("The editor has loaded");
            }}
          />
        </BrowserWrapper>
      </div>
    </div>
  );
};

export const Pure = () => {
  return (
    <div className="h-[calc(100vh-73px)]">
      <BrowserWrapper url="•••">
        <EditorCanvas
          variant={EditorTypes.PURE}
          content={sampleContentData}
          controlled
          // onChange={(data) => {
          //   console.log("Editor Changed", data);
          // }}
        />
      </BrowserWrapper>
    </div>
  );
};

export const sidebarData = {
  main: {
    tabs: ["Basic", "Advanced"],
    sections: [
      {
        tab: "Basic",
        type: SectionTypes.POSITIONPADDING,
        settings: {},
      },
    ],
  },
};

const sections = [
  {
    tab: "Basic",
    type: SectionTypes.UPLOADGROUP,
    settings: {},
  },
  {
    tab: "Basic",
    type: SectionTypes.TYPOGRAPHY,
    settings: {},
  },
  {
    tab: "Basic",
    type: SectionTypes.POSITIONPADDING,
  },
  {
    tab: "Basic",
    type: SectionTypes.ALIGNMENT,
    settings: { textAlign: true },
  },
  {
    tab: "Advanced",
    type: SectionTypes.BORDERSSHADOW,
    settings: {},
  },
  {
    tab: "Advanced",
    type: SectionTypes.ACTIONS,
    caption: "Text Actions",
  },
  {
    tab: "Advanced",
    type: SectionTypes.BACKGROUND,
    settings: {},
  },
  { tab: "Advanced", type: SectionTypes.LISTSORTABLE, settings: {} },
];

export const Full = () => {
  // const [isChecked, setIsChecked] = useState(false);
  const [isPropertiesShowing, setIsPropertiesShowing] = useState(false);
  const [propertySections, setPropertySections] =
    useState<IPropertyBuilderSettings>(sidebarData);
  const [activeSettings, setActiveSettings] = useState({});

  const { updateContent, updateComponent, updateComponentStyle } =
    useEditorActions();

  const updateComponentSettings = updateComponent;
  const props = {
    updateContent,
    updateComponent,
    updateComponentStyle,
    updateComponentSettings: updateComponent,
    settings: activeSettings,
  };

  const handleShowProperties = ({ id, type }) => {
    setIsPropertiesShowing(true);

    // look up & assign settings
    console.log("Showing Properties for object", { id, type });
    if (
      type === ComponentTypes.TEXT ||
      type === ComponentTypes.PARAGRAPH ||
      type === ComponentTypes.CONTAINER ||
      type === ComponentTypes.VIDEO
    ) {
      setPropertySections({
        main: {
          tabs: ["Basic", "Advanced"],
          sections,
        },
      });
    }

    if (type === ComponentTypes.BUTTON) {
      const { sections = [] } = buttonSettings?.properties?.main;

      // Custom Properties
      const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
        onChange: (e) => {
          console.log(e);
          const settings = activeSettings;
          updateComponentSettings(
            settings.id,
            { ...settings, icon: IconTypes[e] },
            true,
            0
          );
        },
      });

      const withCaption = updateSection(withIcon, "custom_caption", {
        component: <CaptionSection {...props} />,
      });

      const withColorPicker = updateSection(
        withCaption,
        "custom_color_picker",
        {
          component: <ColorPickerSection {...props} />,
        }
      );

      const updatedProperties = {
        ...buttonSettings?.properties,
        main: {
          ...buttonSettings?.properties?.main,
          sections: withColorPicker,
        },
      };

      setPropertySections(updatedProperties);
    }

    if (type === ComponentTypes.NAVIGATION) {
      const linkBuilderSettings = navigationSettings;
      const { properties } = linkBuilderSettings;
      const { sections } = properties.main;

      const settings = activeSettings;

      const handleSettingsChange = () => {};

      // const withLinkLayout = updateSection(sections, "LinkLayout", {
      //   component: (
      //     <fieldset style={fieldset}>
      //       <legend>Layout Style</legend>
      //       <RadioGroup
      //         // aria-label="linkDisplay"
      //         // name="linkDisplay"
      //         value={settings.layoutStyle}
      //         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      //           handleSettingsChange(settings.id, {
      //             ...settings,
      //             layoutStyle: e.target.value as LinkLayoutStyles,
      //           });
      //         }}
      //       >
      //         <div style={{ display: "flex" }}>
      //           <FormControlLabel
      //             value={LinkLayoutStyles.HORIZONTAL}
      //             control={<Radio />}
      //             label="Horizontal"
      //           />
      //           <FormControlLabel
      //             value={LinkLayoutStyles.VERTICAL}
      //             control={<Radio />}
      //             label="Vertical"
      //           />
      //         </div>
      //       </RadioGroup>
      //     </fieldset>
      //   ),
      // });

      const withLinkBuilder = updateSection(sections, "LinkBuilderSettings", {
        component: (
          <LinkBuilder
            {...settings}
            // links={linkBuilderSettings.default.data.map((object) => ({
            //   type: object.page.type,
            //   slug: object.page.slug,
            //   name: object.name,
            // }))}
            data={[
              {
                id: "155",
                caption: "Home",
                // linkDisplayType: "Text",
              },
              {
                id: "156",
                caption: "Features",
                // linkDisplayType: "Text",
              },
              {
                id: "157",
                caption: "Blog",
                // linkDisplayType: "Text",
              },
              {
                id: "158",
                caption: "About Us",
                // linkDisplayType: "Text",
              },
              {
                id: "159",
                caption: "Contact Us",
                // linkDisplayType: "Text",
              },
            ]}
            onChange={(data) =>
              handleSettingsChange(settings.id, { ...settings, data: {} })
            }
          />
        ),
      });

      const updatedProperties = {
        ...properties,
        main: { ...properties.main, sections: withLinkBuilder },
      };

      setPropertySections(updatedProperties);
    }
  };

  return (
    <div className="h-[100vh] w-full flex">
      {/* Editor */}
      <div className="p-5 h-full w-full">
        <BrowserWrapper url="•••">
          <EditorCanvas
            variant={EditorTypes.PURE}
            content={sampleContentData}
            onShowProperties={handleShowProperties}
            onItemSelect={setActiveSettings}
            onChange={(content) => {
              // current id
              const { id } = activeSettings;
              const activeObject = content.find((item) => item.id === id);
              setActiveSettings(activeObject);
            }}
          />
        </BrowserWrapper>
      </div>

      {/* Properties */}
      {isPropertiesShowing && (
        <div className="divide-y divide-black/10 dark:divide-white/10 border-l border-black/10 dark:border-white/10 h-full ml-2 overflow-y-auto w-[495px]">
          <div
            className="text-right float-right p-3 cursor-pointer"
            onClick={() => setIsPropertiesShowing(false)}
          >
            <X />
          </div>

          {/* <SwitchGroup
            label="Enable this page"
            isChecked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
            className="p-5"
          >
            <p>Display this page or hide it and redirect to your website.</p>
          </SwitchGroup> */}

          <Layout />

          {/* <Router> */}
          <Generator
            data={propertySections}
            settings={activeSettings}
            updateComponentStyle={updateComponentStyle}
            updateContent={updateContent}
            updateComponentSettings={updateComponent}
          />
          {/* </Router> */}
        </div>
      )}
    </div>
  );
};
