import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { getSessionToken } from "@/api/auth";
import { getCampaign } from "@/api/campaigns";
import { get } from "lodash";
import { Settings } from "../Dashboards/Settings";
// import { Questions } from "@/Login/Onboarding/Onboarding";
import { Questionaire } from "./Questionaire";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(
    getActiveTabFromLocalStorage() || "details"
  );
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setCampaignData(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Page
      topBar={{
        page: [
          { label: name, href: "/planner" },
          { label: get(campaignData, "name", "•••"), href: `/planner/${id}` },
        ],
      }}
    >
      <div className={`p-6 w-full`}>
        {loading ? <div>Loading...</div> : (
          <div className="tabs flex flex-col">
            <TabsComponent
              tabs={[
                { label: "Details", value: "details" },
                { label: "Settings", value: "settings" },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actionSection={false}
            />

            {activeTab === "details" && <Questionaire type={campaignData?.settings?.type} groupId={campaignData?.id} />}

            {activeTab === "settings" && (
              <Settings
                campaignData={campaignData}
                setCampaignData={setCampaignData}
                unitName="Profile"
                widgets={["name", "delete"]}
              />
            )}
          </div>          
        )}
      </div>
    </Page>
  );
};
