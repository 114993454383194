// import { useLocation } from "react-router-dom";
import Modal from "./ui/Modal";
import { PopupSizes } from "./ui/Modal/types";
import { VideoPlayer } from "./ui/VideoPlayer";

export const OnboardingVideo = ({ setOnboardingIsShowing = () => false }) => {
  // const location = useLocation();
  // if (location.pathname === '/login') return null;
  // console.log("Current Location", location.pathname)
  if (window.location.pathname === "/login") return null;

  return (
    <Modal
      title={`Welcome to Launch OS!`}
      onClose={() => {
        setOnboardingIsShowing(false);
        localStorage.setItem("dontShowWelcomeVideo", "true");
      }}
      onSuccess={() => {
        setOnboardingIsShowing(false);
        localStorage.setItem("dontShowWelcomeVideo", "true");
      }}
      size={PopupSizes.XXXLARGE}
    >
      <div className="p-3">
        <VideoPlayer videoId="9fvpglpxs1" playerProps={{ height: '100%', controls: true, muted: false, playing: false }} />      
      </div>
    </Modal>
  );
};
