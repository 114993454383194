import Panel from "@/ui/Panel";
import Select from "@/ui/Select";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

export const CommissionSettings = ({ campaignData = {} }) => {
  return (
    <Panel title="Commission Settings">
      <p className="text-sm text-gray-500">
        This is where you can set the commission for your partners.
      </p>
      <div className="grid gap-4 mt-4">
        <Select
          label="Comission Type"
          items={[
            { label: "Percentage", id: "percentage" },
            { label: "Fixed", id: "fixed" },
          ]}
          flavor={TextInputFlavors.MODERN}
        />
        <TextInput
          label="Comission Amount"
          placeholder="0"
          flavor={TextInputFlavors.MODERN}
          type={TextInputTypes.NUMBER}
        />
      </div>
    </Panel>
  );
};
