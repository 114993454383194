import { gql, DocumentNode } from "@apollo/client";

// get the campaign
export const GET_CAMPAIGN: DocumentNode = gql`
  query getCampaign($id: ID!) {
    campaign(where: { id: $id }) {
      id
      name
      description
    }
  }
`;

// update an object
export const UPDATE_OBJECT: DocumentNode = gql`
  mutation updateObject(
    $id: ID!
    $name: String
    $type: String
    $screenshot: String
    $x: Int
    $y: Int
    $settings: Json
  ) {
    updateObject(
      data: {
        x: $x
        y: $y
        name: $name
        type: $type
        screenshot: $screenshot
        settings: $settings
      }
      where: { id: $id }
    ) {
      id
      type
      x
      y
      settings
    }
  }
`;

// get the objects from the campaign
export const GET_OBJECTS: DocumentNode = gql`
  query getObjects($id: ID!) {
    objects(
      where: {
        OR: [
          { campaign: { id: $id }, deleted: false }
          { campaign: { id: $id }, deleted: null }
        ]
      }
    ) {
      id
      name
      description
      x
      y
      type
      settings
      screenshot
      page {
        id
        type
        slug
        pageSettings
      }
      connectTo {
        id
      }
    }
  }
`;

// get the objects from the campaign
export const GET_OBJECT: DocumentNode = gql`
  query getObject($id: ID!) {
    object(
      where: {
        id: $id
      }
    ) {
      id
      name
      description
      x
      y
      type
      settings
      screenshot
      connectTo {
        id
      }
      campaign { id }
      page { id }
    }
  }
`;

// delete an object
export const DELETE_OBJECT: DocumentNode = gql`
  mutation deleteObject($id: ID!) {
    updateObject(where: { id: $id }, data: { deleted: true }) {
      id
    }
  }
`;

// remove an object connection (connectTo)
export const REMOVE_CONNECTION: DocumentNode = gql`
  mutation removeConnection($id: ID!, $connectTo: ID!) {
    updateObject(
      data: { connectTo: { disconnect: { id: $connectTo } } }
      where: { id: $id }
    ) {
      id
    }
  }
`;

// add an object connection (connectTo)
export const ADD_CONNECTION: DocumentNode = gql`
  mutation addConnection($id: ID!, $connectTo: ID!) {
    updateObject(
      data: { connectTo: { connect: { id: $connectTo } } }
      where: { id: $id }
    ) {
      id
    }
  }
`;

// get page id from object
export const GET_PAGE_ID: DocumentNode = gql`
  query getPageId($id: ID!) {
    object(where: { id: $id }) {
      page {
        id
      }
    }
  }
`;

// Add provided object to the database
export const ADD_OBJECT: DocumentNode = gql`
  mutation addObject(
    $name: String!
    $x: Int!
    $y: Int!
    $campaignId: ID!
    $type: String!
    $screenshot: String
    $settings: Json
  ) {
    createObject(
      data: {
        name: $name
        x: $x
        y: $y
        type: $type
        screenshot: $screenshot
        settings: $settings
        campaign: { connect: { id: $campaignId } }
      }
    ) {
      id
    }
  }
`;
