import { FC } from "react";
import Calout from "@/ui/Calout";
import { Clipboard, Info } from "@phosphor-icons/react";
import InputGroup from "@/ui/InputGroup";
import { AI_DOMAIN, AUTOMATION_DOMAIN } from "@/env";

export const ExternalEvent: FC<{ campaignId: string }> = ({ campaignId, nodeId }) => {
  const url = `${AUTOMATION_DOMAIN}/workflows/trigger/${campaignId}/${nodeId}`;
  return (
    <div className="flex flex-col gap-4 p-4 items-center justify-center">
      <Calout
        icon={<Info />}
        title={`Trigger this campaign from an external event (like a webhook)`}
      />
      <InputGroup
        elements={[
          {
            type: "input",
            leftRounded: true,
            placeholder: url,
            inputType: "text",
            disabled: true,
          },
          {
            type: "icon",
            rightRounded: true,
            icon: (
              <Clipboard
                onClick={async () => {
                  await navigator.clipboard.writeText(url);
                  alert("Copied to clipboard!");
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
