import * as React from "react";
import { DescriptionProps } from "../types";
import styles from "@/ui/CRUD/FormBuilder/styles";
import TextField from "@material-ui/core/TextField";

/**
 * A component for collecting the decription to use for the form field
 */
const Description: React.FC<DescriptionProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  label,
}) => {
  const [caption, setCaption] = React.useState(value)
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   if (caption !== value) onChange(caption);
  // }, [caption])

  return (
    <>
      <TextField
        multiline
        data-testid="FormBuilder-Attributes-Description"
        onChange={(e) => setCaption(e.target.value)}
        onBlur={(e) => onBlur(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        // onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
        label={label || "Description"}
        style={styles.input}
        value={caption}
        variant="filled"
        inputRef={inputRef}
      />
    </>
  );
};

export default Description;
