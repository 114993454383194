import React from "react";
import { Meta, Story } from "@storybook/react";
import { TableView } from "./TableView";
import { DefaultDataProps, TableViewProps } from "../types";
import { BrowserRouter } from "react-router-dom";
import { CaretCircleDown } from "@phosphor-icons/react";

export default {
  title: "Components/DataView/TableView",
  component: TableView,
  tags: ["autodocs"],
} as Meta;

const Template: Story<TableViewProps> = (args) => (
  <BrowserRouter>
    <TableView {...args} />
  </BrowserRouter>
);

export const items: DefaultDataProps[] = [
  {
    id: "1",
    label: "John Doe",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "13" },
      { id: "count", label: "Count", fieldType: "number", value: "1" },
    ],
  },
  {
    id: "2",
    label: "Jane Doe",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "21" },
      { id: "count", label: "Count", fieldType: "number", value: "2" },
    ],
  },
  {
    id: "3",
    label: "Bob Smith",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "33" },
      { id: "count", label: "Count", fieldType: "number", value: "3" },
    ],
  },
  {
    id: "4",
    label: "Alice Johnson",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "24" },
      { id: "count", label: "Count", fieldType: "number", value: "4" },
    ],
  },
  {
    id: "5",
    label: "Tom Wilson",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "15" },
      { id: "count", label: "Count", fieldType: "number", value: "5" },
    ],
  },
  {
    id: "6",
    label: "John Doe",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "62" },
      { id: "count", label: "Count", fieldType: "number", value: "6" },
    ],
  },
  {
    id: "7",
    label: "Jane Doe",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "71" },
      { id: "count", label: "Count", fieldType: "number", value: "7" },
    ],
  },
  {
    id: "8",
    label: "Bob Smith",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "18" },
      { id: "count", label: "Count", fieldType: "number", value: "8" },
    ],
  },
  {
    id: "9",
    label: "Alice Johnson",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "39" },
      { id: "count", label: "Count", fieldType: "number", value: "9" },
    ],
  },
  {
    id: "10",
    label: "Tom Wilson",
    data: [
      { id: "age", label: "Age", fieldType: "number", value: "40" },
      { id: "count", label: "Count", fieldType: "number", value: "10" },
    ],
  },
];

export const Default: Story<TableViewProps> = Template.bind({});
Default.args = {
  items,
};

export const RenderSelectFields: Story<TableViewProps> = Template.bind({});
RenderSelectFields.args = {
  items,
  visibleFields: ["label", "age"],
};

export const RenderMenuItems: Story<TableViewProps> = Template.bind({});
RenderMenuItems.args = {
  items,
  showMenuItems: true,
  menuItems: [
    { id: "edit", label: "Edit" },
    { id: "delete", label: "Delete" },
  ],
  menuItemElement: <CaretCircleDown />,
};

export const RenderPagination: Story<TableViewProps> = Template.bind({});
RenderPagination.args = {
  items,
  showFooterPagination: true,
  paginationSettings: {
    currentPage: 1,
    totalPages: 3,
  },
  onPageChange: (page) => console.log(`Page changed to ${page}`),
};

// export const CustomCellRenderer: Story<TableViewProps> = Template.bind({});
// CustomCellRenderer.args = {
//   items,
//   cellRenderer: (item, field) => {
//     if (field === "name") {
//       return <strong>{item[field]}</strong>;
//     }
//     return item[field];
//   },
// };
