import { useEffect, useState } from "react";

import { TitleCardProps } from "@/ui/TitleCard/types";

import { Buildings, MapPin, Phone, UserCircle } from "@phosphor-icons/react";
import { getContact } from "@/api/crm";
import { getSessionToken } from "@/api/auth";

import TitleCard from "@/ui/TitleCard";
import { ContactWidgetProps } from "./types";

export const ContactTitle: React.FC<ContactWidgetProps> = ({ id }) => {
  const [titleDetails, setTitleDetails] = useState<TitleCardProps>(
    {} as TitleCardProps
  );

  const updateTitleDetails = (data) => {
    console.log("updateTitleDetails data", data);

    setTitleDetails({
      id: data.id,
      title: data.title || `${data.firstName} ${data.lastName}`,
      icon: <UserCircle size={24} />,
      cardChips: [
        ...Boolean(data?.churchName) ? [{ icon: <Buildings />, label: data.churchName }] : [],
        ...Boolean(data?.address) ? [{ icon: <MapPin />, label: data.address }] : [],
        ...Boolean(data?.phone) ? [{ icon: <Phone />, label: data.phone }] : [],
      ],
      // CardActions: <Button type={ButtonTypes.SOFT} label="Open" />,
      // children: <KPISimpleGroup items={kpiItems} />,
    });

    // setPageName(data.title || `${data.firstName} ${data.lastName}`);
  };

  const getData = async () => {
    const token = await getSessionToken();
    const data = await getContact(id, { token });

    try {
      updateTitleDetails({
        ...data,
        title: data?.title || `${data.firstName} ${data.lastName}`,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return <TitleCard {...titleDetails} />;
};
