import { ArrowUUpLeft, ArrowUUpRight } from "@phosphor-icons/react";

export const UndoRedoButtons = ({ doUndoRedo = (p0: string) => {} }, pageId) => {
  return (
    <div className="flex px-3" style={{ marginTop: -3 }}>
      <button
        onClick={() => doUndoRedo("undo", pageId)}
        className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
      >
        <ArrowUUpLeft />
      </button>
      <button
        onClick={() => doUndoRedo("redo", pageId)}
        className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
      >
        <ArrowUUpRight />
      </button>
    </div>
  );
};
