import { activitiesList } from "./sampleData";

// Define the ActivitiesSection component as a functional component
export const ActivitiesSection = () => {
  
  // Define the Activity component as a functional component that takes in props
  const Activity = ({ icon, title, caption }) => (
    // Render a div with a flex layout and a gap of 2
    <div className="flex gap-2">
      {/* Render a div with a fixed height and width, and a rounded border */}
      <div className="h-6 w-6 flex-none rounded-full overflow-hidden">
        {/* Render an image with the source set to the icon prop */}
        <img src={icon} className="object-cover" alt="avatar" />
      </div>
      {/* Render a div that takes up the remaining space */}
      <div className="flex-1">
        {/* Render a paragraph with the title prop, truncated if it overflows */}
        <p className="whitespace-nowrap overflow-hidden text-ellipsis w-[200px] text-black dark:text-white">
          {title}
        </p>
        {/* Render a paragraph with the caption prop, in smaller text */}
        <p className="text-xs text-black/40 dark:text-white/40">{caption}</p>
      </div>
    </div>
  );

  // Render the ActivitiesSection component
  return (
    <div data-testid="ActivitiesSection">
      {/* Render a heading */}
      <h4 className="font-semibold text-black dark:text-white mb-5">
        Activities
      </h4>
      {/* Render a div with a flex column layout and a gap of 4 */}
      <div className="flex flex-col gap-4">
        {/* Map over the activitiesList array and render an Activity component for each activity */}
        {activitiesList.map(({ icon, title, caption }, key) => (
          <Activity key={key} icon={icon} title={title} caption={caption} />
        ))}
      </div>
    </div>
  );
};