import React from "react";
import { BadgeProps } from "./types";

// Define the Badge component as a functional component that takes in props of type BadgeProps
export const Badge: React.FC<BadgeProps> = ({ type, color, text }) => {

  // Define a function that returns the color class based on the color prop
  const renderBadgeColor = () => {
    switch (color) {
      case "default":
        return "lightwhite";
      case "primary":
        return "indigo-300";
      case "secondary":
        return "lightpurple-300";
      case "info":
        return "lightblue-200";
      case "success":
        return "lightgreen-200";
      case "warning":
        return "lightyellow";
      case "error":
        return "lightred";
      default:
        return "";
    }
  };

  // Define a variable className with a default value of "rounded"
  let className = "rounded";

  // If the type prop is "rounded", set className to "rounded-full"
  if (type === "rounded") className = "rounded-full";

  // If the type prop is "glow", set className to a string with the shadow color based on the renderBadgeColor function
  if (type === "glow")
    className = `rounded-full shadow-3xl shadow-${renderBadgeColor()}/50`;

  // If the type prop is "dots", return a div with the appropriate classes and a child div with a background color of the current color
  if (type === "dots")
    return (
      <div
        className={`inline-flex space-x-2.5 items-center text-xs justify-center px-2 py-1 text-${renderBadgeColor()} ${color === "default" && `dark:text-white`}`}
      >
        <div className="h-1.5 w-1.5 rounded-full bg-current"></div>
        <span>{text}</span>
      </div>
    );

  // If the type prop is not "dots", return a div with the appropriate classes and the text prop as its child
  return (
    <div
      className={`inline-flex items-center ${className} text-xs justify-center px-2 py-1 text-black bg-${renderBadgeColor()}`}
      data-testid="Badge"
    >
      {text}
    </div>
  );
};