import {
  EditorCanvas,
  EditorTypes,
  useEditorActions,
} from "@/Apps/Pages/Editor/EditorCanvas";
import { sampleContentData } from "@/Apps/Pages/Editor/Editor.stories";
import { BrowserWrapper } from "@/Apps/Pages/Editor/BrowserWrapper";
import { useEffect, useState } from "react";

export const PageNode = ({
  id,
  data = {
    activeSettingsHandler: () => {},
  },
}) => {
  const campaignId = "clzj3e1he7wiu0811cpbqq07l";
  // const pageId = "clzj3fo3f7wkw0811l2ae5bw7";
  // const token = "ZWM0MTI2YjAtNjk2OS0xMWVmLTk3NDktMjlmNzI3YzMxMjdi";
  //   const [content, setContent] = useState(sampleContentData);

  //   useEffect(() => {
  //     //     console.log("Content Changed", id)
  //     setContent(data?.content);
  //   }, [data?.content]);
  let currentSettings = {};
  let updateSettings = () => {};
  
  try {
    const { getActiveSettings = () => {}, setActiveSettings = () => {} } =
      data?.activeSettingsHandler();

    // Use getActiveSettings() to access the current state
    currentSettings = getActiveSettings();
    // Use setActiveSettings to update the state
    updateSettings = (newSettings = {}) => {
      setActiveSettings(newSettings);
    };

  } catch (e) {
    console.log(e);
  }


  const { content = [] } = data;

  return (
    <div
      style={{
        width: parseInt(1366 / 1.0),
        height: parseInt(1536 / 0.8),
      }}
      className="rounded bg-white/60 dark:bg-black/60 shadow-sm overflow-hidden"
    >
      {JSON.stringify(currentSettings)}
      {/* <BrowserWrapper url={data?.label}> */}
      <EditorCanvas
        variant={EditorTypes.PURE}
        content={content}
        // onLoad={() => {
        //   console.log("Loaded");
        //   setContent(data?.content);
        // }}
        campaignId={campaignId}
        pageId={id}
        // onItemSelect={itm => console.log("onItemSelect", itm)}
        onItemSelect={({ id, settings }) => updateSettings(settings)}

        //   toShow="builder"
        //   token={token}
      />
      {/* </BrowserWrapper> */}
    </div>
  );
};
