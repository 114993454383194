import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { cloneCampaign, getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link, useNavigate } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";

export const Member = ({ name = "" }) => {
  const [membershipSiteList, setMembershipSiteList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // get the membershipSiteList from the server
  const loadMembershipSites = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "member" });
    // const response = await getCampaigns({ token, type: "membershipSite" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((membershipSite: any) => {
          return {
            id: membershipSite.id,
            label: membershipSite.name,
            caption: `Last Updated: ${moment(membershipSite.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setMembershipSiteList(seqs);
    }
    setIsLoading(false);
  };

  // Creates a new membership site based on the membership site funnel template
  const handleCreateMembershipSite = async () => {
    const name = prompt("Enter a name for your membership site");
    const campaignId = "cljywyjj54thd0811978vs8b0"; // The ID of the membership site funnel template
    if (name) {
      const token = getSessionToken();
      const response = await cloneCampaign(campaignId, { token, name });
      const newCampaignId = response?.data?.cloneCampaign?.response?.id;
      if (newCampaignId) {
        // navigate to the new funnel
        navigate(`/member/${newCampaignId}`);
      }
    }
  };

  // load the membershipSiteList when the page loads
  useEffect(() => {
    loadMembershipSites();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Button
            label="+ Create a Membership Site"
            type={ButtonTypes.OUTLINED}
            onClick={handleCreateMembershipSite}
          />
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(membershipSiteList.length) && (
          <EmptyState
            title="No Membership Sites Yet"
            description="Get started by creating a new membership site."
            showImage={false}
          >
            <Link to="/launchpad/member">
              <Button
                label="Create a Membership Site"
                type={ButtonTypes.DEFAULT}
              />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(membershipSiteList.length) && (
          <Grid>
            {/* Can we change to Gallery View - let's me toggle views */}
            {membershipSiteList.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/member/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
