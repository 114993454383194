import { useEffect, useState } from "react";
import { ItemGroup } from "./components/ItemGroup";

import { PopupSizes } from "@/ui/Modal/types";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Tag } from "./components/Tag";
import Modal from "@/ui/Modal";
import { getFontsFromPage } from "@/api/pages";
import { AddPageModal } from "../Funnels/Popups/AddPageModal";
import { Sparkle } from "@phosphor-icons/react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/ui/Layout/Loading";
import { getCatalogItems } from "@/api/catalog";

interface PageItemDetailsProps {
  // pageContent: ISettings[];
  // pageId: string;
  catalogItem: any;
  pageDetails: any;
  image?: string;
  title?: string;
  description?: string;
  categories?: string[];
  fonts?: string[];
  onClose?: () => void;
  forType?: string;
}

export const PageItemDetails: React.FC<PageItemDetailsProps> = ({
  // pageId,
  // pageContent,
  catalogItem = [],
  pageDetails = {},
  image = "https://sandcastleassets.s3.amazonaws.com/templates/pageid/ckxq3zol5904z08119y28wgkw.png",
  title = "Page Template",
  description = "This is a page template that you can use for your site. It's a great starting point for your funnel and you can customize it however you want.",
  categories = ["Sales"],
  fonts = ["Roboto"],
  onClose = () => false,
  forType = "pages",
}) => {
  const [isAddPagePopupOpen, setIsAddPagePopupOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // const pageId = pageDetails?.id;
  const pageContent = pageDetails?.content;
  const pageType = catalogItem?.categories;
  const pageTheme = catalogItem?.tags;

  const { id } = useParams();
  const campaignId = id;

  console.log({ campaignId });

  return (
    <>
      <Modal footer={false} size={PopupSizes.XXXLARGE} onClose={onClose}>
        <div className="grid md:grid-cols-12 gap-7 px-3 w-full">
          {/* <div className={`flex flex-row gap-7`} style={{ marginTop: "-1rem" }}> */}
          <div className="overflow-scroll rounded-lg px-3 flex flex-col md:col-span-8">
            {/* <div className="overflow-scroll px-3 h-[calc(100vh-174px)]"> */}
            <div className="overflow-hidden rounded-lg">
              <img src={image} />
            </div>
          </div>
          <div className="flex flex-col gap-3 md:col-span-4">
            {/* <div className="flex-1 flex-col dark:bg-white/5 rounded-lg p-7"> */}
            <h1 className="text-2xl font-bold mb-4">{title}</h1>

            <div className="mb-7">
              <p className="text-md mb-2">{description}</p>
              <Button
                label="Launch using this template"
                type={ButtonTypes.DEFAULT}
                className="mt-4 w-[212px]"
                onClick={() => setIsAddPagePopupOpen(true)}
              />
              {/* <Button
                label="✨ Launch using A.I."
                type={ButtonTypes.OUTLINED}
                defaultIcon={<Sparkle className="ml-3 text-lg" />}
                className="mt-4 w-[212px]"
                onClick={() => setIsAddPagePopupOpen(true)}
              /> */}
            </div>

            <h2 className="text-lg font-bold mb-2">Categories</h2>
            <div className="mb-7">
              {categories.map((category, key) => (
                <Tag key={key}>{category.toUpperCase()}</Tag>
              ))}
            </div>
            {Boolean(fonts.length) && (
              <>
                <h2 className="text-lg font-bold mb-2">Fonts</h2>
                <div className="mb-7">
                  {fonts.map((font, key) => (
                    <Tag key={key}>{font}</Tag>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      {/* Render the add page modal if it's set to open */}
      {Boolean(isAddPagePopupOpen) && (
        <AddPageModal
          campaignId={campaignId}
          pageTheme={pageTheme}
          pageType={pageType}
          pageContent={pageContent}
          setIsOpen={setIsAddPagePopupOpen}
          // deplurize forType in the unitName if it's plural
          unitName={forType.slice(0, -1)}
          campaignAction={!id ? "create" : null} // if there's no campaign id in the param, then create a new campaign from scratch (otherwise add the page to the provided campaign)
          // pageAction="add" // always add a new page to the campaign, whether you create a new campaign or not - TO DO: Replace
          onSuccess={({ campaignId, pageId }) => {
            setIsAddPagePopupOpen(false);
            onClose();
            // now redirect
            // console.log({ forType, campaignId, pageId });
            if (forType === "pages") navigate(`/pages/${campaignId}`); // if this is for pages, then redirect to the pages dashboard & editor
            else if (id) navigate(`/${forType}/${campaignId}/${pageId}`); // if this is for funnels, then redirect to the funnels dashboard & editor
            else navigate(`/${forType}/${campaignId}/${pageId}`); // redirect to the appropriate dashboard & editor
          }}
        />
      )}
    </>
  );
};

interface PagesGroupProps {
  type?: string;
  showAll?: boolean;
  tagFilter?: string[];
}
export const PagesGroup: React.FC<PagesGroupProps> = ({
  type = "pages",
  showAll = false,
  tagFilter,
}) => {
  const [designCampaigns, setDesignCampaigns] = useState<any[]>([]);
  const [pageItemDetailsIsShowing, setPageItemDetailsIsShowing] =
    useState<boolean>(false);
  const [activeCatalogItem, setActiveCatalogItem] = useState(null);

  const loadCatalogItems = async () => {
    const items = await getCatalogItems({ type: "designs" });
    setDesignCampaigns(items);
  };

  useEffect(() => {
    loadCatalogItems();
  }, []);

  if (!designCampaigns.length)
    return <Loading type="gallery" className="py-8" />;

  const pageContent = activeCatalogItem?.page?.content || [];

  const items = designCampaigns
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) // sort by most recent (createdAt)
    .map((item) => ({
      ...item,
      image:
        item?.image ||
        `https://sandcastleassets.s3.amazonaws.com/templates/pageid/${item?.page?.id}.png`,
    }));

  return (
    <>
      {pageItemDetailsIsShowing && (
        <PageItemDetails
          forType={type}
          catalogItem={activeCatalogItem}
          pageDetails={activeCatalogItem?.page}
          image={activeCatalogItem?.image}
          title={activeCatalogItem?.name}
          description={activeCatalogItem?.description}
          categories={activeCatalogItem?.categories?.split(",") || []}
          fonts={
            Boolean(pageContent.length) ? getFontsFromPage(pageContent) : []
          }
          onClose={() => setPageItemDetailsIsShowing(false)}
        />
      )}
      <ItemGroup
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        {...(!showAll && { amountToShow: 3 })}
        items={items.map((item) => ({
          ...item,
          categories:
            item?.categories &&
            item.categories
              .split(",")
              .map((cat) => `${cat} page`)
              .join(","),
        }))}
        categories={items.filter(itm => itm.categories).reduce((unique, item) => {
          return unique.includes(item?.categories) ? unique : [...unique, item?.categories];
        }, [])}
        withFilter={showAll}
        href={type}
        tagFilter={tagFilter}
        customCardStyles={{
          transition: "background-position 1.5s ease", // Added transition property
        }}
        onItemClick={(item) => {
          setActiveCatalogItem(item);
          setPageItemDetailsIsShowing(true);
        }}
      />
    </>
  );
};
