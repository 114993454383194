import DetailsView from "@/ui/DataView/DetailsView";

interface PartnerDetailProps {
  id: string;
}

export const PartnerDetail: React.FC<PartnerDetailProps> = ({ id }) => {
  const partnerFields = ["status", "joinDate", "referralCount", "commissions"];

  return (
    <DetailsView
      isEditable
      title="Partner Information"
      item={{
        id: "X",
        label: "Partner X",
        data: [
          {
            id: "status",
            label: "Status",
            value: "Active",
            fieldType: "enum",
            options: [
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },              
            ],
          },
          {
            id: "joinDate",
            label: "Join Date",
            value: "2021-01-01",
            fieldType: "date",
          },
          {
            id: "referralCount",
            label: "Referral Count",
            value: "5",
            fieldType: "number",
          },
          {
            id: "commissions",
            label: "Commissions",
            value: "$100.00",
            fieldType: "number",
          },
        ],
      }}
      visibleFields={partnerFields}
    />
  );
};
