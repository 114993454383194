import React from "react";
import { SimpleContainerProps } from "./types";

/**
 * A simple container component that can display child elements with an optional label and display style.
 */
export const SimpleContainer: React.FC<SimpleContainerProps> = ({ children, label, display = null }) => {
  // Render the container with a border, padding, and rounded corners.
  return (
    <div data-testid="SimpleContainer" className="border border-black/10 dark:border-white/10 p-5 rounded-md">
      {/* If a label is provided, render it with a smaller font size and some margin below it. */}
      {Boolean(label) && (
        <div className="mb-5">
          <p className="text-sm font-semibold">{label}</p>
        </div>
      )}

      {/* If a display style of "block" is provided, render the child elements directly. */}
      {display === "block" ? (
        children
      ) : (
        // If a display style of "flex" is provided, render the child elements in a flex container with some spacing between them.
        <div className="flex flex-wrap items-center gap-4">{children}</div>
      )}
    </div>
  );
};