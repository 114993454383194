import { createContext } from "react";

type ContextValueType = {
  darkMode: boolean;
  sidebarOpen: boolean;
  rightSidebarOpen: boolean;
  pluginUrls: string[];
  permissions: string[];
  setDarkMode: () => void;
  setSidebarOpen: () => void;
  setRightSidebarOpen: () => void;
  setPluginUrls: () => void;
};

export const AppContext = createContext<ContextValueType>({
  darkMode: false,
  sidebarOpen: false,
  rightSidebarOpen: false,
  pluginUrls: [],
  permissions: [],
  setPermissions: () => {},
  setDarkMode: () => {},
  setSidebarOpen: () => {},
  setRightSidebarOpen: () => {},
  setPluginUrls: () => [],
});
