import { useEffect, useState } from "react";
import { WizardProps } from "./types";
import Stepper from "../Stepper";

export const Wizard: React.FC<WizardProps> = ({
  steps = [],
  renderStep,
  showProgressIndicator = true,
  showButtons = true,
  currentStep = 1,
  onFinish,
  onCancel,
  onStepChange,
  nextButtonText = "Next",
  previousButtonText = "Previous",
  finishButtonText = "Finish",
  cancelButtonText = "Cancel",
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(currentStep);

  useEffect(() => {
    setCurrentStepIndex(currentStep);
  }, [currentStep]);

  // Handler for the "Next" button
  const handleNextStep = () => {
    setCurrentStepIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      handleStepClick(nextIndex);
      return nextIndex;
    });
  };

  // Handler for the "Previous" button
  const handlePreviousStep = () => {
    setCurrentStepIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      handleStepClick(newIndex);
      return newIndex;
    });
  };

  // Handler for clicking on a step
  const handleStepClick = (index: number) => {
    setCurrentStepIndex(() => {
      onStepChange && onStepChange(index);
      return index;
    });
  };

  // Handler for the "Cancel" button
  const handleCancel = () => {
    onCancel && onCancel();
  };

  // Renders a step
  const renderCurrentStep = (step: any, index: number) => {
    if (renderStep) {
      return renderStep(index, step);
    }

    return (
      <div
        key={index}
        data-testid={`Step-${currentStepIndex}`}
        onClick={() => handleStepClick(index)}
      >
        {step?.label && <h3>{step?.label}</h3>}
        {step?.component}
      </div>
    );
  };

  // Returns the wizard component
  return (
    <div data-testid="Wizard" className="items-center mb-7">
      {/* Renders the progress indicator */}
      {showProgressIndicator && (
        <div data-testid="StepLegend">
          <div className="mx-auto w-4/5 mt-3">
            <Stepper
              activeStep={currentStepIndex}
              numberOfSteps={steps.length}
            />
          </div>
        </div>
      )}

      {/* Renders the current step */}
      {renderCurrentStep(
        steps.find((step) => step.index === currentStepIndex),
        currentStepIndex
      )}

      {/* Renders the buttons */}
      {showButtons && (
        <div className="flex justify-end items-center mt-8 gap-4">
          <button
            type="button"
            // className="btn !bg-lightred !text-white"
            className="bg-black/10 text-black/70"
            onClick={handleCancel}
          >
            {cancelButtonText}
          </button>

          {currentStepIndex > 1 && (
            <button type="button" className="btn" onClick={handlePreviousStep}>
              {previousButtonText}
            </button>
          )}

          {currentStepIndex < steps.length && (
            <button type="button" className="btn" onClick={handleNextStep}>
              {nextButtonText}
            </button>
          )}

          {currentStepIndex === steps.length && (
            <button type="button" className="btn" onClick={onFinish}>
              {finishButtonText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Wizard;
