import Menu from "@/ui/_old/Menu";

import style from "@/Apps/Pages/Properties/style";

export const NiceMenu = (props) => (
  <div
    className="cursor-pointer relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-black/10 dark:border-white/10"
    // style={{ ...style.selectField, cursor: "pointer", ...props.style }}
  >
    <Menu {...props} style={{ width: "100%", textAlign: "left" }}>
      <div style={{ maxHeight: 200, overflowY: "scroll" }}>
        {props.children}
      </div>
    </Menu>
  </div>
);
