import * as React from "react";
import TextField from "@material-ui/core/TextField";

import { ColumnsProps } from "../types";
import styles from "@/ui/CRUD/FormBuilder/styles";
/**
 * A number (1-12) component for collecting the number of
 * columns to use for the form field
 *
 * ToDo: Instead of a text field, give them visual options (1 col, 2 col, 3 col, etc.) in the form of buttons (FancySelector?)
 */
const Columns: React.FC<ColumnsProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  label = "Columns",
}) => {
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 50);
  // }, []);
  return (
    <TextField
      type="number"
      data-testid="FormBuilder-Attributes-Columns"
      onChange={(e) => {
        const { value } = e.target
        // console.log({ isNumber: Number.isInteger(value) })
        // if (Number.isInteger(value)) onBlur(value)
        // else onChange(value);
        // onChange(value)
        onBlur(parseInt(value).toString())
      }}
      onBlur={(e) => onBlur(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
      defaultValue={value}
      value={value}
      style={styles.input}
      variant="filled"
      label={label || "Columns"}
      inputRef={inputRef}
      sx={{
        width: '100%',
        maxWidth: '200px',
        '& .MuiFilledInput-root': {
          backgroundColor: '#f0f0f0',
        },
        '& .MuiFilledInput-root:hover': {
          backgroundColor: '#e0e0e0',
        },
        '& .MuiFilledInput-root.Mui-focused': {
          backgroundColor: '#d0d0d0',
        },
        '& .MuiInputLabel-root': {
          color: '#666',
        },
      }}
    />
  );
};

export default Columns;
