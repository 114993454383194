import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { RightCarrotIcon } from "../../Icons";
import { NavGroupProps } from "./types";

// Define a functional component called NavGroup that takes in several props
export const NavGroup: React.FC<NavGroupProps> = (
  { isOpen = false, href = "#", onClick = () => null, name, icon, items = [] },
  key
) => {
  // Create a ref to the list element
  const listRef = useRef(null);

  // Define a function that checks if the current path matches the href prop
  const getIsActive = () => {
    const currentPath = window.location.pathname;
    const isActive = currentPath === href;
    return isActive;
  };

  // Use an effect to set the height of the parent element based on whether the NavGroup is open or not
  useEffect(() => {
    if (listRef.current) {
      const height = isOpen ? listRef.current.scrollHeight : 0;
      listRef.current.parentElement.style.height = `${height}px`;
    }
  }, [isOpen]);

  // Return a JSX element that renders a list item with several nested elements
  return (
    <li data-testid="NavGroup" key={key} className="menu nav-item">
      <Link
        to={!items.length && href}
        onClick={Boolean(items.length) ? onClick : () => null}
        className={`nav-link group text-black dark:text-white cursor-pointer flex p-1 ${
          Boolean(isOpen) && "active"
        }`}
      >
        <div
          className={`text-black/50 dark:text-white/20 ${"w-4"} h-5 flex items-center justify-center transition-all duration-200 ${
            Boolean(isOpen) && "!rotate-90"
          }`}
        >
          {/* Render a RightCarrotIcon if the NavGroup has items */}
          {items.length ? <RightCarrotIcon /> : null}
        </div>
        <div>
          <span
            // to={href}
            className={`nested-link ${getIsActive() && "active font-semibold"}`}
          >
            <div className="flex items-center">
              {/* Render the icon prop */}
              <span>{icon}</span>
              {/* Render the name prop */}
              <span className="pl-1">{name}</span>
            </div>
          </span>
        </div>
      </Link>

      {/* Render a sub-menu if the NavGroup has items */}
      <ul
        data-testid="NavGroupSubMenu"
        className={`overflow-hidden transition-all duration-300 sub-menu flex flex-col gap-1 text-black dark:text-white/80`}
      >
        <div
          ref={listRef}
          className={`${isOpen ? "opacity-100" : "opacity-0"} grid gap-1`}
        >
          {/* Render each item in the items prop */}
          {Boolean(items.length) &&
            items.map(({ href, label, icon, isActive = false }, key) => (
              <li key={key}>
                <Link to={href} className={isActive ? "active" : ""}>
                  {/* Render the icon prop */}
                  <span>{icon}</span>
                  {/* Render the label prop */}
                  {label}
                </Link>
              </li>
            ))}
        </div>
      </ul>
    </li>
  );
};
