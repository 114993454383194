export const APP_DOMAIN = `https://app.launchos.com`;
export const EDITOR_DOMAIN = `https://app.tenminutefunnels.com`;
// export const EDITOR_DOMAIN = `http://localhost:3000`;
// export const SITE_DOMAIN = `http://localhost:3002`;
export const SITE_DOMAIN = `https://www.launchos.com`;
// export const AI_DOMAIN = `http://localhost:3001`;
export const AI_DOMAIN = `https://ai-engine-liard.vercel.app`; // the next js ai app

// export const AGENTS_DOMAIN = `http://localhost:5000`;
// export const AGENTS_DOMAIN = `https://crsimple-fb0eb39c1a9d.herokuapp.com`;
export const AGENTS_DOMAIN = `https://flowiseai-railway-production-4e06.up.railway.app/api/v1/prediction`;

export const AUTOMATION_DOMAIN = `https://automation-backbone-6163feaa1726.herokuapp.com`;
// export const AUTOMATION_DOMAIN = `http://localhost:3002`;
// export const API_DOMAIN = `https://4d3c-74-192-130-187.ngrok-free.app/v1`;
export const API_DOMAIN = `https://api.tenminutefunnels.com/v1`;
export const GQL_DOMAIN = `https://api.funnels.cc:40000`;
export const SANDBOX_DOMAIN = `https://app.launchos.com:8080`;
export const STORYBOOK_DOMAIN = `https://app.launchos.com:6006`;
