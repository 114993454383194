export enum SectionTypes {
    TYPOGRAPHY = "Typography",
    POSITIONPADDING = "PositionPadding",
    ALIGNMENT = "Alignment",
    ACTIONS = "Interactions",
    SPACING = "Spacing",
    WIDTH = "Width",
    HEIGHT = "Height",
    WIDTHHEIGHT = "WidthHeight",
    BORDERSSHADOW = "BorderShadow",
    // BORDERSSHADOW = "BordersShadow",
    LINEHEIGHT = "LineHeight",
    MARGIN = "Margin",
    PADDING = "Padding",
    POSITION = "Margin",
    BACKGROUND = "Background",
    UPLOADGROUP = "UploadGroup",
    COLORPICKER = "ColorPicker",
    BUTTONSLIDER = "ButtonSliderGroup",
    FANCYSELECTOR = "FancySelector",
    STYLEBUILDER = "StyleBuilder",
    PRESETS = "Presets",
    PREVIEW = "Preview",
    CAPTION = "Caption",
    MOBILETOGGLE = "MobileResponsiveToggle",
    DATATABLE = "DataTable",
    ICONSELECTOR = "IconSelector",
    LISTSORTABLE = "SortableList",
    CUSTOM = "Custom",
    BUTTONGROUP = "ButtonGroup",
    SECTION = "Section",
    LINKDROPDOWN = "LinkDropDown",
    OBJECTPRESETS = "ObjectPresets",
    DROPDOWNCOLLAPSE = "DropDownCollapse",
    INTERACTIONS = "Interactions",
    ICON = "Icon",
    RICHEDITOR = "RichEditor",
    SMARTLOGIC = "SmartLogic",
}
