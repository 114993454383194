// import { AppConfig } from "@launchos/modules/app";

import gql from "graphql-tag";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import Dropzone from "react-dropzone";

import { client } from "@/client";
import FontIcon from "@/ui/_old/FontIcon";
import Section from "../Section";
import { AppContext } from "@/AppContext";

const styles = {
  default: {
    margin: "0 auto",
    fontFamily: "sans-serif",
    fontSize: 11,
    padding: 15,
    textAlign: "center",
  },
  btnIcon: {
    textAlign: "center",
    display: "inline-block",
    padding: 20,
    width: 100,
    height: 100,
    verticalAlign: "middle",
    cursor: "pointer",
    ":hover": { backgroundColor: "#DDD" },
    ":active": { backgroundColor: "#CCC" },
    position: "relative",
    zIndex: 1,
  },
};

class UploadGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };

    this.onDrop = this.onDrop.bind(this);
  }

  static contextType = AppContext;

  async onDrop(files) {
    // const { settings } = this.context;

    this.setState({ files });

    console.log({ files });
    this.props.onChange(files);

    // show preview

    const file = files[0];

    const uploadFileResponse = await client.mutate({
      mutation: gql`
        mutation uploadFile($file: Upload!, $userId: ID!) {
          uploadFile(file: $file, userId: $userId) {
            response
          }
        }
      `,
      variables: {
        file,
        userId: localStorage.userId,
      },
    });

    console.log({ uploadFileResponse });

    const { url } = _.get(uploadFileResponse, "data.uploadFile.response", { url: "" });
    this.props.onChange([{ preview: url }]);

    // remove preview

  }

  render() {
    const { highlighted, style, text, children } = this.props;
    const { files } = this.state;

    const dropStyles = {
      default: {
        border: "none",
        width: "100%",
      },
      activeStyle: {
        border: "1px solid blue",
      },
      acceptStyle: {
        border: "1px solid green",
      },
      rejectStyle: {
        border: "1px solid red",
      },
      disabledStyle: {
        border: "1px solid gray",
      },
    };

    return (
      <Section
        icon="cloud_upload"
        label={`Upload New ${[text[0].toUpperCase(), ...text.slice(1)].join(
          ""
        )}`}
        {...this.props}
      >
        <div style={{ ...styles.default, ...style }}>
          {highlighted ? (
            <div className="divide-x divide-black/10 dark:divide-white/10">
              <div
                className="text-center inline-block p-5 w-[100px] h-[100px] align-middle cursor-pointer hover:bg-white/5 rounded relative z-[1]"
                // style={{ ...styles.btnIcon, borderRight: "1px solid #CCC" }}
              >
                <Dropzone
                  onDrop={this.onDrop}
                  style={dropStyles.default}
                  {...dropStyles}
                  {...this.props}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FontIcon>file_upload</FontIcon>
                      <div>UPLOAD {text.toUpperCase()}</div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div
                className="text-center inline-block p-5 w-[100px] h-[100px] align-middle cursor-pointer hover:bg-white/5 rounded relative z-[1]"
                // style={styles.btnIcon}
                onClick={() => {
                  const url = prompt(
                    `Please enter the URL of your ${text}`,
                    this.props.url || ""
                  );

                  console.log(url);
                  if (url && url.length) {
                    this.props.onChange([
                      {
                        preview: url,
                      },
                    ]);
                  } else if (url === "") {
                    this.props.onChange([{ preview: "" }]);
                  }
                }}
              >
                <FontIcon>link</FontIcon>
                <div>
                  PASTE
                  <br />
                  URL
                </div>
              </div>
            </div>
          ) : (
            // <div>Dropzone is at fault</div>
            <Dropzone
              onDrop={this.onDrop.bind(this)}
              style={dropStyles.default}
              {...dropStyles}
              {...this.props}
            >
              <div
                className="text-center inline-block p-5 w-[100px] h-[100px] align-middle cursor-pointer hover:bg-white/5 rounded relative z-[1]"
                // style={styles.btnIcon}
              >
                {files.length ? (
                  <img
                    alt="SUCCESS!!"
                    style={{ width: 30 }}
                    src="https://www.turningtechnologies.ca/wp-content/uploads/2016/02/CheckMark.png"
                  />
                ) : (
                  <FontIcon>file_upload</FontIcon>
                )}
                <div>
                  UPLOAD
                  <br />
                  {text.toUpperCase()}
                </div>
              </div>
            </Dropzone>
          )}
          {children}
        </div>
      </Section>
    );
  }
}

UploadGroup.propTypes = {
  /** This is the label for the upload button */
  // text: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  /** This event is triggered when the upload button is clicked */
  // onUploadClick: PropTypes.func.isRequired,
  /** This event is triggered when the URL button is clicked */
  // onURLClick: PropTypes.func.isRequired
};

UploadGroup.defaultProps = {
  text: "image",
  highlighted: true,
  isExpanded: true,
};

export default UploadGroup;
