import { AppContext } from "@/AppContext";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Grid from "@/ui/Grid";

interface LoadingProps {
  type?: "default" | "titles" | "gallery" | "list" | "tiny";
  className?: string;
}

export const Loading = ({ type = "default", className = "" }: LoadingProps) => {
  const { darkMode } = useContext(AppContext);

  if (type === "gallery") {
    return (
      <div className={`mb-5 ${className}`}>
        <Grid>
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              className="dark:bg-white/5"
              baseColor={darkMode && "#444"}
              highlightColor={darkMode && "#444"}
              height={100}
              borderRadius="1rem"
            />
          ))}
        </Grid>
      </div>
    );
  }

  if (type === "titles") {
    return (
      <div className={`${className}`}>
        {[...Array(8)].map((_, index) => (
          <Skeleton
            key={index}
            className="dark:bg-white/5 mb-6"
            baseColor={darkMode && "#444"}
            highlightColor={darkMode && "#444"}
            height={135}
            borderRadius="1rem"
          />
        ))}
      </div>
    );
  }

  if (type === "tiny") {
    return (
      <img
        src="https://sandcastleassets.s3.amazonaws.com/images/tiny_loader.gif"
        className="w-7"
      />
    );
  }

  return <div className={`p-8 ${className}`}>Loading...</div>;
};
