import { PenNib } from '@phosphor-icons/react';
import { Content } from './Content';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { ContentEditor } from './ContentEditor';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("content")) return [];
    return [
      {
        label: "Content",
        icon: <PenNib className="text-lg" />,
        href: "/content",
        order: 8,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("content")) return [];
    return [
      {
        path: "/content",
        element: <Content name="Content Library" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Content Library" />,
          },
          {
            path: ":packageId/:objectId",
            element: <ContentEditor />,
          },
        ],
      },
    ];
  }
};


export { Content };