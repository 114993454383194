import { Page, Container } from '../Page';

export const DragDrop: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Drag & Drop" }] }}>
			<Container>
				<p className="text-lg font-semibold">Drag & Drop</p>
			</Container>
		</Page>
	);
};
