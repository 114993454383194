import { Badge } from "../Badge";
import { ListDisplayGroupProps } from "./types";
import { ListDropdown } from "../ListDropdown/ListDropdown";
import KPISimpleGroup from "../TitleCard/KPISimpleGroup";

// Define a new React functional component called ListDisplayGroup
export const ListDisplayGroup: React.FC<ListDisplayGroupProps> = ({
  // Define default props for the component
  // title = "Product Delivery",
  // caption = "Order Details",
  items = [],
  footer = null,
  maxHeight = null,
  menuItems = [],
  menuItemElement,
}) => {
  // Render the list display group
  return (
    <div
      data-testid="ListDisplayGroup"
      // Set the class name for the container div
      className={`grid grid-flow-row gap-0 ${
        maxHeight && `h-[${maxHeight}px] overflow-y-auto`
      }`}
    >
      {/* Map over the items array and render each item */}
      {items.map(
        (
          {
            id,
            image,
            imageStyle = {},
            title,
            caption,
            status,
            actions,
            onClick,
          },
          key
        ) => {
          // Render each item as a div with a click handler
          return (
            <div
              data-testid="ListDisplayItem"
              onClick={onClick}
              key={key}
              className="flex gap-3 group dark:hover:bg-white/5 hover:bg-black/5 rounded cursor-pointer p-3"
            >
              {/* Render the item image if it exists */}
              {typeof image === "string" ? (
                <div
                  className="w-[54px] h-[54px] flex-none overflow-hidden rounded-lg relative"
                  style={{ width: 75, ...imageStyle }}
                >
                  <img
                    data-testid="list-item-image"
                    src={image}
                    style={{}}
                    alt="images"
                  />
                </div>
              ) : (
                image
              )}
              {/* Render the item details */}
              <div className="flex-1">
                <div className="flex justify-between items-center gap-3">
                  {/* Render the item title & caption */}
                  <div className="flex-row justify-between gap-3">
                    <p className="line-clamp-2" data-testid="list-item-label">
                      {title}
                    </p>

                    {/* Render the item caption */}
                    <p className="text-xs text-black/40 dark:text-white/40">
                      {caption}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    {/* Render the item status badge if it exists */}
                    {status && (
                      <Badge
                        type="rounded"
                        color="info"
                        text={status.caption}
                      />
                    )}

                    {actions && actions}

                    {/* Render the item dropdown menu */}
                    {Boolean(menuItems.length) && (
                      <ListDropdown
                        items={menuItems}
                        element={menuItemElement}
                        forId={id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}

      {/* Render the footer if it exists */}
      {footer && (
        <div className="mt-2 flex justify-center rounded-lg bg-lightpurple-100/50 dark:bg-white/5 p-5">
          <div className="space-y-1 text-center">
            <div className="flex text-sm text-gray-600">
              {/* Render the footer caption */}
              <p className="pr-4 pt-1 text-black/40 dark:text-white/40">
                {footer.caption}
              </p>
              {/* Render the footer action component */}
              {footer.actionComponent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
