import React from "react";
import { CloseButtonIcon } from "../Icons";
import {
  PrimaryIcon,
  SecondaryIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
  DangerIcon
} from "../Icons";
import { AlertProps } from "./types";

/**
 * Define the Alert component as a functional component that takes in props of type AlertProps
 * @param param0
 * @returns
 */
export const Alert: React.FC<AlertProps> = ({
  type = "basic", variation = "primary", message, onClose,
}) => {
  // Define a function that returns the appropriate class name based on the type and variation props
  const getClassName = (): string => {
    let className = "rounded p-3 ";
    switch (type) {
      case "basic":
        className += "basic text-black ";
        break;
      case "alertWithCloseButton":
        className += "alertWithCloseButton flex items-center text-black ";
        break;
      case "outlineAlert":
        className += "outlineAlert flex items-center border dark:text-white ";
        break;
      case "alertWithIcon":
        className += "alertWithIcon flex items-center text-black ";
        break;
      default:
        break;
    }

    let bgVsBorder = type === "outlineAlert" ? "border-" : "bg-";

    switch (variation) {
      case "primary":
        className += `primary ${bgVsBorder}indigo-300 `;
        break;
      case "secondary":
        className += `secondary ${bgVsBorder}lightpurple-200 `;
        break;
      case "info":
        className += `info ${bgVsBorder}lightblue-200 `;
        break;
      case "success":
        className += `success ${bgVsBorder}lightgreen-100 `;
        break;
      case "warning":
        className += `warning ${bgVsBorder}lightyellow `;
        break;
      case "danger":
        className += `danger ${bgVsBorder}lightred text-white `;
        break;
      default:
        break;
    }

    return className;
  };

  // Define a function that returns the appropriate icon based on the variation prop
  const getMessageIcon = (): React.ReactNode => {
    switch (variation) {
      case "primary":
        return (
          <PrimaryIcon className="w-5 h-5 mr-2" />
        );
      case "secondary":
        return <SecondaryIcon className="w-5 h-5 mr-2" />;
      case "info":
        return <InfoIcon className="w-5 h-5 mr-2" />;
      case "success":
        return <SuccessIcon className="w-5 h-5 mr-2" />;
      case "warning":
        return <WarningIcon className="w-5 h-5 mr-2" />;
      case "danger":
        return <DangerIcon className="w-5 h-5 mr-2" />;
      default:
        return null;
    }
  };

  // Render the Alert component with the appropriate class name and content based on the props
  return (
    <div data-testid="Alert" className={getClassName()}>
      {/* Render the icon button if the type prop is "alertWithIcon" */}
      {type === "alertWithIcon" && (
        <button
          type="button"
          className="hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300"
        >
          {getMessageIcon()}
        </button>
      )}

      {/* Render the message content */}
      {message}

      {/* Render the close button if the type prop is not "basic" */}
      {type !== "basic" && (
        <div
          className="ml-auto hover:opacity-50 rotate-0 hover:rotate-180 transition-all duration-300 cursor-pointer"
          onClick={onClose}
          data-testid="alert-close"
        >
          <CloseButtonIcon />
        </div>
      )}
    </div>
  );
};