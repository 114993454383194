import { getSessionToken } from "@/api/auth";
import { getContact, updateContact } from "@/api/crm";
import { DetailsView } from "@/ui/DataView/DetailsView";
import { DefaultDataProps } from "@/ui/DataView/types";
import { useEffect, useState } from "react";
import { mapContactResponseToView } from "../scripts";
import { debounce } from "lodash";
import { ContactGroupProps } from "./types";

export const ContactGroup: React.FC<ContactGroupProps> = ({
  id,
  title,
  fields = [],
  children = null,
}) => {
  const [item, setItem] = useState<DefaultDataProps>();

  const loadData = async () => {
    const token = await getSessionToken();
    const data = await getContact(id, { token });
    setItem(mapContactResponseToView(data));
  };

  const updateItem = async (fieldId, data) => {
    const itemField = data.find((item) => item.id === fieldId);
    const token = await getSessionToken();
    await updateContact(id, { [itemField.id]: itemField.value }, { token });
  };

  const debouncedHandleItemChange = debounce(updateItem, 300);

  const handleItemChange = (id, data) => {
    setItem({ ...item, data });
    debouncedHandleItemChange(id, data);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!item) return null;

  return (
    <DetailsView
      isEditable
      onItemChange={handleItemChange}
      title={title}
      item={item}
      visibleFields={fields}
      children={children && children(item)}
    />
  );
};
