import * as React from "react";
import _ from "lodash";
import WebFontLoader from "webfontloader";

import * as Tools from "../../index";

import { GeneratorProps } from "./types";

export const Generator: React.FC<GeneratorProps> = (props) => {
  const { data } = props;
  const { main } = data;
  const { tabs } = main;

  WebFontLoader.load({
    google: {
      families: ["Material Icons"],
    },
  });

  return (
    <Tools.PropertiesWindow
      {...props}
      defaultTab={0}
      tabs={(tabs || [""]).map((itm, key) => ({
        id: key,
        title: itm,
        component: key ? GenerateSecondTab : GenerateFirstTab,
      }))}
    />
  );
};

const GenerateTab = (props) => {
  const { data, tab } = props;
  const { main } = data;
  const { sections } = main;

  const filteredSections =
    tab === ""
      ? sections
      : sections.filter((itm) => _.get(itm, "tab", tab) === tab);

  return (
    // <div style={{ width: "100%" }}>
    <div className="w-full divide-y divide-black/10 dark:divide-white/10 overflow-y-auto">
    
      {filteredSections.map((section, key) => {
        const { type, settings } = section;

        let Component = _.has(Tools, type)
          ? Tools[type]
          : () => (
            <Tools.Section
              key={key}
              icon="code"
              label={`Placeholder for ${type}`}
            />
          );

        // Add Section Wrapper if required
        if (_.has(section, "sectionWrap")) {
          return (
            <Tools.Section key={key} {...props} {...settings}>
              <Component {...props} {...settings} />
            </Tools.Section>
          );
        } else {
          return <Component key={key} {...props} {...settings} />;
        }
      })}
    </div>
  );
};

const GenerateFirstTab = (props) => (
  <GenerateTab {...props} tab={_.get(props, "data.main.tabs[0]", "basic")} />
);

const GenerateSecondTab = (props) => (
  <GenerateTab {...props} tab={_.get(props, "data.main.tabs[1]", "advanced")} />
);

export default Generator;
