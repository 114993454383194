import { QueryBuilder, RuleGroupType } from "react-querybuilder";

import "react-querybuilder/dist/query-builder.scss";
import "./styles.scss";

import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import Dropdown from "@/ui/Dropdown";
import ListDropdown from "@/ui/ListDropdown";

export interface LogicBuilderProps {
  fields?: any[];
  query?: RuleGroupType;
  setQuery?: (query: RuleGroupType) => void;
  className?: string;
  clauseLabel?: string;
}

export const LogicBuilder: React.FC<LogicBuilderProps> = ({
  fields = [],
  // query = {
  //   combinator: "and",
  //   rules: [{ field: "url", operator: "contains", value: "" }],
  // },
  query = { combinator: "and", rules: [{ field: "", operator: "", value: ""}] },
  setQuery,
  className = "",
  clauseLabel = "When",
}) => {
  const handleAddCondition = (e) => {
    setQuery({
      ...query,
      rules: [
        ...query.rules,
        // use the last rule as a template for the new one
        {
          ...query.rules[query.rules.length - 1],
          id: Math.random().toString(36).substr(2, 10),
        },
      ],
    });
  };

  const handleRowAction = (item) => {
    const { id } = item;
    if (id === "remove") {
      setQuery({
        ...query,
        rules: query.rules.slice(0, query.rules.length - 1),
      });
    } else if (id === "duplicate") {
      setQuery({
        ...query,
        rules: [
          ...query.rules,
          {
            ...query.rules[query.rules.findIndex((itm) => itm.id === id)],
            id: Math.random().toString(36).substr(2, 10),
          },
        ],
      });
    }
  };

  const dropdownItems = [{ label: "And" }, { label: "Or" }];

  return (
    <div
      className={`justifiedLayout border rounded dark:border-white/10 p-5 ${className}`}
    >
      <div className="flex gap-3 items-start">
        <div className="ruleGroup">
          <div className="py-2 text-right">{clauseLabel}</div>
          {query.rules.length > 1 && (
            <div className="py-2 w-16">
              <Dropdown
                className="px-2 py-2"
                label={query.combinator === "and" ? "And" : "Or"}
                items={dropdownItems}
                onClick={(k) =>
                  setQuery({
                    ...query,
                    combinator: dropdownItems[k].label.toLowerCase(),
                  })
                }
              />
            </div>
          )}
        </div>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={(q) => setQuery(q)}
        />
        <div className="pt-5">
          {query.rules.map((rule, i) => (
            <div key={i} style={{ height: 48 }} className="">
              <ListDropdown
                onItemSelect={handleRowAction}
                items={[
                  ...[
                    Boolean(query.rules.length > 1) && {
                      id: "remove",
                      label: "Remove",
                    },
                  ],
                  {
                    id: "duplicate",
                    label: "Duplicate",
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
      <Button
        type={ButtonTypes.SOFT}
        label="+ Add another condition"
        onClick={handleAddCondition}
      />
    </div>
  );
};
