import { useState, FC, useEffect } from "react";
import { renderWidget } from "@/Apps/Dashboards/renderWidget";
import { NodeData } from "@/Apps/Funnels/CampaignBuilder/types";
import { getSessionToken } from "@/api/auth";
import { getUserIntegrationSettings } from "@/api/integrations";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { Placeholder } from "@phosphor-icons/react";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

export const Webhook: FC<{ data: NodeData }> = ({ data, onClose = () => {}, onSave = () => {} }) => {
  const { settings } = data;
  const [userFields, setUserFields] = useState([]);
  const [payload, setPayload] = useState(settings?.payload || {});

  useEffect(() => {
    const loadWebhookSettings = async () => {
      const integrationDetails = await getUserIntegrationSettings(
        settings?.id,
        {
          token: getSessionToken(),
        }
      );

      const { setting } = integrationDetails;
      const uf = JSON.parse(setting?.settings) || [];
      setUserFields(uf);
      // setWebhookSettings(settings)
    };

    loadWebhookSettings();
  }, []);

  const handleSettingsChange = (id, value) => {
    console.log("id", id, "value", value);
    setPayload({ ...payload, [id]: value });
  };

  const renderHijack = (widget) => {
    console.log("widget", widget);
    if (widget?.field_type === "text") {
      return (
        <div>
          <TextInput
            flavor={TextInputFlavors.MODERN}
            label={widget?.placeholder}
            name={widget?.id}
            onChange={(e) => handleSettingsChange(widget?.id, e.target.value)}
            value={payload[widget?.id]}
          />
        </div>
      );
    }
    return false;
  };

  // const widgets = userFields.map((field) => field.type);

  // Render the properties for the webhook
  return (
    <div className={"flex flex-col gap-7 h-full p-3"}>
      {userFields.map((widget, key) => {
        return (
          <span key={key} className="">
            {renderHijack(widget) || renderWidget(widget, {})}
          </span>
        );
      })}

      <div className="flex justify-end items-center mt-4 gap-4">
        <Button
          type={ButtonTypes.SOFT}
          label="Cancel"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={onClose}
        />
        <Button
          type={ButtonTypes.SOFT}
          label="OK"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={() => {
            // console.log("payload", payload);
            onSave(payload);
          }}
        />
      </div>
    </div>
  );
};
