import * as React from "react";
import { PresetSelectorProps } from "./types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "@/ui/CRUD/FormBuilder/styles";

// import styles from "@/ui/CRUD/FormBuilder/styles";
// import theme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";

import theme from "@/ui/theme";


/**
 * A component for collecting the page/url the user wants to link to
 */
const PresetSelector: React.FC<PresetSelectorProps> = ({
  onChange = () => null,
  onBlur = () => null,
  label,
  value,
  data = [
    { label: "Testing Content!", type: "content", image: theme.logoIcon },
    { label: "Testing Launch", type: "launch", image: theme.logoIcon },
  ],
}) => {
  return (
    <div data-testid="FormBuilder-Attributes-PresetSelector">
      <FormControl variant="filled" style={styles.input}>
        <InputLabel id="demo-simple-select-label">
          {label || "Select a Preset..."}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            onBlur(e.target.value);
          }}
        >
          {data && data.map((itm, key) => (
            <MenuItem key={key} value={itm.type} style={styles.input}>
              <div style={{ display: "flex" }}>
                <img style={{ height: 30 }} src={itm.image || theme.logoIcon} />
                <div style={{ padding: 8 }}>{itm.label}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PresetSelector;
