import { InputGroupProps } from "./types";

// Define the InputGroup component as a functional component that takes in an array of elements as props
export const InputGroup: React.FC<InputGroupProps> = ({ elements = [] }) => {
  // Render the input group as a div with a flex display and negative horizontal spacing
  return (
    <div data-testid="InputGroup" className="flex -space-x-px mb-4 w-full">
      {/* Map over each element in the elements array */}
      {elements.map((el, index) => {
        // Render a different element based on the type of the current element
        switch (el.type) {
          // If the element is an input, render an input element with the appropriate styles and props
          case "input":
            return (
              <input
                key={index}
                className={`form-input w-full ${
                  el.leftRounded ? "rounded-l-lg" : ""
                } ${
                  el.rightRounded ? "rounded-r-lg" : ""
                } border border-black/10 dark:border-white/10 bg-transparent px-3 py-2.5 placeholder:text-black/60 dark:placeholder:text-white/60 hover:z-10 hover:border-black dark:hover:border-white focus:z-10 focus:border-black dark:focus:border-white`}
                placeholder={el.placeholder}
                type={el.inputType}
                value={el.value}
                disabled={el.disabled}
                onChange={el.onChange}
              />
            );

          // If the element is text, render a div with the appropriate styles and content
          case "text":
            return (
              <div
                key={index}
                className={`flex items-center justify-center ${
                  el.leftRounded ? "rounded-l-lg" : ""
                } ${
                  el.rightRounded ? "rounded-r-lg" : ""
                } border border-black/10 dark:border-white/10 px-3.5`}
              >
                <span>{el.content}</span>
              </div>
            );

          // If the element is a label, render a div with the appropriate styles and text
          case "label":
            return (
              <div
                key={index}
                className="flex items-center justify-center border border-black/10 bg-black/20 px-3.5 text-black"
              >
                <span className="-mt-1">{el.text}</span>
              </div>
            );

          // If the element is a select, render a select element with the appropriate styles and options
          case "select":
            return (
              <select
                key={index}
                className={`form-select ${
                  el.rightRounded
                    ? "w-[200px] rounded-r-full"
                    : "w-16 rounded-l-full"
                } border border-black/10 dark:border-white/10 bg-white dark:bg-black px-3 py-2.5 pr-9 hover:z-10 hover:border-black focus:z-10 focus:border-black`}
                onChange={el.onChange}
              >
                {el.options.map((opt, optIndex) => (
                  <option 
                    key={optIndex}>{opt}</option>
                ))}
              </select>
            );

          // If the element is a button, render a button element with the appropriate styles and text
          case "button":
            return (
              <button
                key={index}
                className={`btn ${el.leftRounded ? "rounded-l-full" : ""} ${
                  el.rightRounded ? "rounded-r-full" : ""
                } bg-black/20 dark:bg-white/20 font-semibold text-black dark:text-white hover:bg-black dark:hover:bg-white focus:bg-black active:bg-black dark:active:bg-white`}
                onClick={el.onClick}
              >
                {el.text}
              </button>
            );

          // If the element is an icon, render a div with the appropriate styles and icon
          case "icon":
            return (
              <div
                key={index}
                className={`cursor-pointer bg-black/80 dark:bg-white/80 flex justify-center items-center ${
                  el.leftRounded ? "rounded-l-md" : ""
                } ${
                  el.rightRounded ? "rounded-r-md" : ""
                } px-3 font-semibold text-white dark:text-black border border-r-0 border-black/10 dark:border-white/10`}
              >
                {el.icon}
              </div>
            );

          // If the element is of an unknown type, return null
          default:
            return null;
        }
      })}
    </div>
  );
};
