import React, { useState } from "react";
import { BigOption } from "./BigOption";
import { BigOptionGroupProps } from "./types";

export const BigOptionGroup: React.FC<BigOptionGroupProps> = ({
  options,
  selectedOption,
  onChange,
  disabled = false,
  className = "",
}) => {
  const [currentSelectedOption, setCurrentSelectedOption] = useState(selectedOption);

  const handleOptionChange = (value: string) => {
    if (!options.find((option) => option.value === value)?.disabled) {
      setCurrentSelectedOption(value);
      if (onChange) {
        onChange(value);
      }
    }
  };

  return (
    <div className={`big-option-group ${className}`} data-testid="BigOptionGroup">
      {options.map((option) => (
        <BigOption
          key={option.value}
          label={option.label}
          checked={Boolean(option.value === currentSelectedOption)}
          disabled={option.disabled || disabled}
          onChange={() => handleOptionChange(option.value)}
          value={option.value}
        />
      ))}
    </div>
  );
};