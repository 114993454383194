import { get } from "lodash";
import React from "react";

import {
  convertToRem,
  convertFromRem,
} from "@/Apps/Pages/MobileResponsiveToggle/style";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";

import {
  ButtonSliderGroup,
  W,
} from "../BuildingBlocks/ButtonSliderGroup/ButtonSliderGroup";

import { GapProps } from "./types";

import { Minus, Plus } from "@phosphor-icons/react";
/**
 * Creates a Button Slider Group to Change the Components Gap
 */

const Gap: React.FC<GapProps> = ({
  onChange = () => null,
  settings = {},
  updateComponentStyle = () => null,
  minValue = 0,
  maxValue = 200,
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
}) => {
  const gap = get(settings, "properties.gap", 0);

  // send raw pixel value to the button slider even if input value is in rem
  const convertedValue = Number(
    convertFromRem(gap, getMobileState()["type"])
  );

  const handleChange = (value, shouldDbUpdate = true, wait = 100) => {
    const gapOffset =
      value === "SHRINK" ? convertedValue - 5 : convertedValue + 5;
    const newGap = Number.isInteger(value) ? value : gapOffset;

    // convert the raw pixel value to rem before saving it the db
    const newValue = useRem
      ? convertToRem(newGap, getMobileState()["type"])
      : newGap;

    const newStyle = {
      gap: newValue,
    };

    updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);

    onChange();
  };

  return (
    <div data-testid="Properties-Gap">
      <ButtonSliderGroup
        onChange={handleChange}
        text="Gap"
        button1={{ icon: <Minus />, value: "SHRINK" }}
        button2={{ icon: <Plus />, value: "GROW" }}
        minValue={minValue}
        maxValue={maxValue}
        value={convertedValue}
      />
    </div>
  );
};

export default Gap;
