import React from "react";
import { SearchIcon } from "../Icons";
import { LegendProps } from "./types";

// This component renders a legend with a search input and a list of items
export const Legend: React.FC<LegendProps> = ({
  items = [],
  onItemClick,
  onSearch,
  showSearch = true,
}) => {
  const groupedItems = items.reduce((acc, item) => {
    const firstLetter = item?.title?.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});

  return (
    <div
      data-testid="ContactList-Legend"
      className={
        "flex-none hidden md:block bg-lightwhite dark:bg-white/5 rounded-2xl py-6 w-[208px]"
      }
    >
      {/* Search input */}
      {showSearch && (
        <div
          className="relative w-full mb-2 px-6"
          data-testid="ContactList-Legend-SearchInput"
        >
          <div className="absolute inset-y-0 left-6 flex items-center pl-[6px] pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            placeholder="Search..."
            className="bg-white/80 dark:bg-black/80 border border-black/10 dark:border-white/10 text-black dark:text-white text-sm rounded-lg block max-w-[160px] w-full pl-[26px] p-1 focus:ring-0 focus:outline-0"
            onChange={(e) => onSearch && onSearch(e.target.value)}
          />
        </div>
      )}

      {/* List of items */}
      <div className="flex flex-col gap-2 h-[calc(100vh-260px)] overflow-y-auto px-6">
        {groupedItems &&
          Object.keys(groupedItems)
            .sort()
            .map((key, index) => (
              <React.Fragment key={index}>
                <div className="p-1">
                  <p className="h-6 w-6 text-xs text-black/40 dark:text-white/40 leading-6 text-center">
                    {key}
                  </p>
                </div>

                {groupedItems[key].map((item, index) => {
                  const { icon = "/assets/images/byewind-avatar.png" } = item;
                  return (
                    <a
                      key={index}
                      data-testid="ContactList-Legend-Item"
                      className="p-1 flex items-center gap-2 bg-transparent rounded-lg hover:bg-black/5 transition-all duration-300 cursor-pointer dark:hover:bg-white/5"
                      id={item.id}
                      onClick={() => onItemClick && onItemClick(item)}
                    >
                      {/* Item icon */}
                      {typeof icon === "string" ? (
                        <img
                          className="w-6 h-6 rounded-full overflow-hidden object-cover p-5"
                          src={String(icon)}
                          alt=""
                        />
                      ) : (
                        icon
                      )}

                      {/* Item label */}
                      <p>{item.title}</p>
                    </a>
                  );
                })}
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};
