import { classNames, blueprintProfiles as items } from "./data";

export const BlueprintList = ({ onSelect }) => {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 border-gray-200 sm:grid-cols-2"
    >
      {items.map((item, itemIdx) => (
        <li
          key={itemIdx}
          className={`flow-root ${item.disabled ? "opacity-40" : ""}`}
          onClick={() => !item.disabled && onSelect(item.id)}
        >
          <div
            className={`relative -m-2 flex items-center space-x-4 rounded-xl p-2 ${
              !item.disabled &&
              "focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50 dark:hover:bg-gray-800"
            }`}
          >
            <BlueprintIcon {...item} />
            <div className="text-left">
              <h3
                className={`text-sm font-medium text-gray-900 ${
                  !item.disabled && "cursor-pointer"
                }`}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                <span className="text-black dark:text-white dark:font-bold">{item.title}</span>
                <span aria-hidden="true"> &rarr;</span>
                {item.tag && (
                  <span className="rounded-full bg-black/10 px-2.5 py-1.5 text-xs text-gray-500 mx-1">
                    {item.tag}
                  </span>
                )}
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-500">
                {item.description}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export const BlueprintIcon = (item) => {
  const { background, icon } = item;
  const Icon = icon;
  return <div
    className={classNames(
      background,
      "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
    )}
  >
    <Icon className="h-6 w-6 text-white" aria-hidden="true" />
  </div>;
}
