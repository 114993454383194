import * as React from "react";
import TextField from "@material-ui/core/TextField";

import { FormItemNameProps } from "../types";
import styles from "@/ui/CRUD/FormBuilder/styles";

/**
 * A component for collecting the "name" to use for the form field
 */
const FormItemName: React.FC<FormItemNameProps> = ({
  onChange = () => null,
  onBlur = () => null,
  value,
  label,
}) => {
  let inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 50);
  // }, []);
  return (
    <TextField
      type="text"
      data-testid="FormBuilder-Attributes-FormItemName"
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onKeyUp={e => e.which === 13 && onBlur(e.target.value)}
      defaultValue={value}
      value={value}
      style={styles.input}
      variant="filled"
      label={label || "Name"}
      inputRef={inputRef}
    />
  );
};

export default FormItemName;
