import { Page, Container } from '../Page';

export const Tables: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Tables" }] }}>
			<Container>
				<p className="text-lg font-semibold">Tables</p>
			</Container>
		</Page>
	);
};
