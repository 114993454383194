import { SearchIcon } from "@/ui/Icons";
import {
  ArrowsDownUp,
  DotsThree,
  // DotsThree,
  FunnelSimple,
  Plus,
} from "@phosphor-icons/react";
import { FilterBarProps } from "./types";
import { LogicBuilder } from "@/Apps/Funnels/CampaignBuilder/Nodes/SmartRouter/LogicBuilder";
import { RuleGroupType } from "react-querybuilder";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ListDropdown from "@/ui/ListDropdown";

const SearchInput = ({ onSearch = () => {} }) => {
  return (
    <form className="md:flex items-center hidden">
      <label htmlFor="voice-search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[6px] pointer-events-none">
          <SearchIcon />
        </div>
        <input
          type="text"
          id="voice-search"
          className="bg-black/5 dark:bg-white/5 border-0 text-sm rounded-lg block max-w-[160px] w-full pl-[26px] p-1 focus:ring-0 focus:outline-0"
          placeholder="Search..."
          required
          onChange={onSearch}
        />
      </div>
    </form>
  );
};

const FilterSelector = ({ fields, onChange }) => {
  const [query, setQuery] = useState<RuleGroupType>();

  const handleSetQuery = (query) => {
    setQuery(query);
    onChange(query);
  };

  return (
    <div className="absolute">
      <LogicBuilder
        fields={fields}
        query={query}
        setQuery={handleSetQuery}
        className="bg-white dark:shadow-3xl dark:bg-black"
        clauseLabel="Where"
      />
    </div>
  );
};

export const FilterBar: React.FC<FilterBarProps> = ({
  withAddButton = true,
  withSearch = true,
  withSort = true,
  withFilter = true,
  withMoreMenu = true,
  // filterItems = [],
  moreItems = [],
  fields = [],
  onFilterChange = () => {},
  onSortChange = () => {},
  onAddClick = () => {},
  onSearch = () => {},
  className = "",
}) => {
  const [isFilterBuilderShowing, setIsFilterBuilderShowing] = useState(false);

  return (
    <div
      className={`p-2 bg-lightwhite dark:bg-white/5 rounded-lg flex gap-2 justify-between mb-2 ${className}`}
    >
      <div className="flex items-center gap-4">
        <div className="flex gap-2 items-center">
          {withAddButton && (
            <button
              type="button"
              className="p-1 rounded-lg bg-transparent hover:bg-black/5 dark:hover:bg-white/5 transition-all duration-300"
              onClick={onAddClick}
            >
              <Plus className="text-lg" />
            </button>
          )}
          {withFilter && (
            <>
              <button
                type="button"
                className="p-1 rounded-lg bg-transparent hover:bg-black/5 dark:hover:bg-white/5 transition-all duration-300"
                onClick={() =>
                  setIsFilterBuilderShowing(!isFilterBuilderShowing)
                }
              >
                <FunnelSimple className="text-lg" />
              </button>
              {isFilterBuilderShowing && (
                <OutsideClickHandler
                  onOutsideClick={() => setIsFilterBuilderShowing(false)}
                >
                  <FilterSelector fields={fields} onChange={onFilterChange} />
                </OutsideClickHandler>
              )}
            </>
          )}
          {withSort && (
            <button
              type="button"
              className="p-1 rounded-lg bg-transparent hover:bg-black/5 dark:hover:bg-white/5 transition-all duration-300"
            >
              <ListDropdown
                element={<ArrowsDownUp className="text-lg" />}
                onItemSelect={onSortChange}
                items={fields.map((field) => ({
                  id: field.name,
                  label: field.label,
                }))}
              />
            </button>
          )}
          {withMoreMenu && (
            <ListDropdown
              element={
                <button
                  type="button"
                  className="p-1 rounded-lg bg-transparent hover:bg-black/5 dark:hover:bg-white/5 transition-all duration-300"
                >
                  <DotsThree className="text-[22px]" />
                </button>
              }
              items={moreItems}
            />
          )}
        </div>
      </div>
      {withSearch && <SearchInput onSearch={onSearch} />}
    </div>
  );
};
