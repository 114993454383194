import { Link } from "react-router-dom";
import { PanelItemProps, PanelProps, PanelTitleProps } from "./types";

export const PanelTitle: React.FC<PanelTitleProps> = ({
  img = "https://images.zapier.com/storage/services/ca28a86dece6f48288518c65c4bf9fbb.128x128.png",
  caption = "Registration Page",
  description = "Craft the perfect registration page for your campaign",
  showLearnMore = true,
  learnMoreHref = "#",
}) => {
  return (
    <div className="flex items-center gap-4">
      {typeof img === "string" && (
        <img src={img} alt="images" className="flex-none w-8 h-8" />
      )}
      {typeof img !== "string" && img}
      <div className="flex-1 flex items-center justify-between gap-4">
        <div>
          <p className="text-sm font-semibold">{caption}</p>
          <p className="text-xs text-black/40 dark:text-white/40">
            {description}{" "}
            {showLearnMore && (
              <a
                href={learnMoreHref}
                target="_blank"
                className="text-lightpurple-300"
              >
                Learn More
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

// This component represents a single item in a panel
export const PanelItem: React.FC<PanelItemProps> = ({
  // Default values for the props
  icon,
  title = "How to lorem ipsum",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc euismod purus vitae metus congue, at aliquet nunc tincidunt",
  className = "",
  onClick = () => null,
  buttonLabel,
  button,
  href = "#",
}) => {
  // The component returns a div with two child elements: a div and a Link
  return (
    <div
      data-testid="PanelItem"
      className={`flex flex-col sm:flex-row items-center flex-wrap w-full gap-3 group ${className}`}
      onClick={onClick}
    >
      {icon && icon}
      <div className="flex-1">
        {/* The title of the item */}
        <h3 className="font-semibold">{title}</h3>
        {/* The description of the item */}
        <p className="text-xs text-black/40  dark:text-white/40">
          {description}
        </p>
      </div>
      {/* The Link that represents the button */}
      {button && button}
      {buttonLabel && (
        <Link
          // onClick={onClick}
          to={href}
          className="btn flex-none text-xs px-2 py-[5px]"
        >
          {buttonLabel}
        </Link>
      )}
    </div>
  );
};

// This component represents a panel that can contain multiple items
export const Panel: React.FC<PanelProps> = ({
  title,
  actionButton,
  children,
  withItems = false,
  isExpanded = true,
  onToggle = () => null,
  items = [],
  className = "",
  footer,
}) => {
  // If withItems is true, wrap the children in a grid
  const chdrn = withItems ? (
    <div className="grid grid-flow-row">{children}</div>
  ) : (
    children
  );

  // The component returns a div with two child elements: a div and the children
  return (
    <div
      data-testid="Panel"
      className={`bg-lightwhite dark:bg-white/5 rounded-2xl p-6 ${className}`}
      onClick={() => onToggle(isExpanded)}
    >
      <div className="panel-header">
        {/* If an actionButton is provided, display it */}
        {actionButton ? (
          <div className="flex flex-wrap items-start justify-between mb-4 gap-3">
            {/* The title of the panel */}
            <h2 className="text-lg font-semibold">{title}</h2>
            {/* The actionButton */}
            {actionButton}
          </div>
        ) : (
          // Otherwise, just display the title
          <div>
            {/* if the title is a string, display the h2 version, otherwise (e.g. it's a react node), just place it */}
            {typeof title === "string" ? (
              <h2 className="text-lg font-semibold mb-4">{title}</h2>
            ) : (
              title
            )}
          </div>
        )}
      </div>

      {/* If isExpanded is true, display the children */}
      {isExpanded && chdrn}

      {/* If withItems is true and there are items, display them */}
      {isExpanded && withItems && items.length > 0 && (
        <ul className="flex flex-col gap-4 items-start cursor-pointer w-full grid grid-flow-row">
          {items.map((item, index) => (
            <PanelItem key={index} {...item} />
          ))}
        </ul>
      )}

      {/* Render the footer if it exists */}
      {footer && (
        <div className="mt-4 flex justify-center rounded-lg bg-lightpurple-100/50 dark:bg-white/5 p-5">
          <div className="space-y-1 text-center">
            <div className="flex text-sm text-gray-600">
              {/* Render the footer caption */}
              <p className="pr-4 pt-1 text-black/40 dark:text-white/40">
                {footer.caption}
              </p>
              {/* Render the footer action component */}
              {footer.actionComponent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
