import { contactsList } from "./sampleData";

// Define the ContactsSection component as a functional component
export const ContactsSection = () => {
  // Define the Contact component as a functional component that takes in props
  function Contact({ name, avatar }) {
    // Render a div with a flex layout and a gap of 2, containing an image and a paragraph with the name prop
    return (
      <div className="flex gap-2 items-center">
        <img
          src={avatar}
          className="h-6 w-6 flex-none rounded-full object-cover"
          alt="avatar"
        />
        <p className="text-black dark:text-white">{name}</p>
      </div>
    );
  }

  // Render the ContactsSection component
  return (
    <div data-testid="ContactsSection">
      {/* Render a heading */}
      <h4 className="font-semibold text-black dark:text-white mb-5">
        Contacts
      </h4>
      {/* Render a div with a flex column layout and a gap of 4 */}
      <div className="flex flex-col gap-4">
        {/* Map over the contactsList array and render a Contact component for each contact */}
        {contactsList.map((contact, index) => (
          <Contact key={index} name={contact.name} avatar={contact.avatar} />
        ))}
      </div>
    </div>
  );
};