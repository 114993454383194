/**
 * The size options for a popup.
 */
export enum PopupSizes {
  /**
   * A small popup size.
   */
  SMALL,
  /**
   * A medium popup size.
   */
  MEDIUM,
  /**
   * A large popup size.
   */
  LARGE,
  /**
   * An extra large popup size.
   */
  XLARGE,
  /**
   * An 2XL large popup size.
   */
  XXLARGE,
  /**
   * An 3XL large popup size.
   */
  XXXLARGE,
  /**
   * A fullscreen popup size.
   */
  FULLSCREEN,
}

/**
 * The position options for a popup.
 */
export enum PopupPosition {
  /**
   * The default position for a popup.
   */
  DEFAULT,
  /**
   * The centered position for a popup.
   */
  CENTERED,

  /**
   * The right sidebar position for a popup.
   */
  RIGHT_SIDEBAR,
}

/**
 * Props for a modal component.
 */
export type ModalProps = {
  /**
   * The title of the modal.
   */
  title?: string | React.ReactNode;
  /**
   * The tabs to display in the modal.
   */
  tabs?: React.ReactNode;
  /**
   * The position of the modal.
   */
  position?: PopupPosition;
  /**
   * The size of the modal.
   */
  size?: PopupSizes;
  /**
   * The icon to display in the modal.
   */
  icon?: React.ReactNode;
  /**
   * A boolean indicating whether you can close the modal by clicking outside of it.
   */
  closeOnClickOutside?: boolean;
  /**
   * The function to call when the modal is closed.
   */
  onClose?: () => void;
  /**
   * The function to call when the modal is successful.
   */
  onSuccess?: () => void;
  /**
   * The content to display in the modal.
   */
  children: React.ReactNode;
  /**
   * The style to apply to the modal.
   */
  childrenStyle?: React.CSSProperties;
  /**
   * Whether to display the footer in the modal.
   */
  footer?: Boolean;
};
