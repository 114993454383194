import { NewspaperClipping } from '@phosphor-icons/react';
import { PlanningBlueprints } from "./Planning";

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";

export const onListItems = (hook: HookObject) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    return [
      {
        label: "Planning",
        icon: <NewspaperClipping className="text-lg" />,
        href: "/planner",
        order: 0,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    return [
      {
        path: "/planner",
        element: <PlanningBlueprints name="Planning Profiles" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Planning" />,
          },
        ],
      },
    ];
  }
};


export { PlanningBlueprints };