import { cloneCampaign, updateCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import Modal, { Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { classNames, blueprintProfiles as items } from "./data";

export const CreateNewBlueprint = ({ type = "company", onClose = () => {} }) => {
  const [newCampaignName, setNewCampaignName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const item = items.find((item) => item.id === type);

  return (
    <Modal
      title={`Create a Planning Profile: ${item?.title}`}
      onClose={onClose}
      onSuccess={async () => {
        setIsLoading(true);
        const token = getSessionToken();
        const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // id of the "blank" campaign template
        const response = await cloneCampaign(campaignId, {
          token,
          name: newCampaignName,
        });

        const newCampaignId = response?.data?.cloneCampaign?.response?.id;

        // update the type of the campaign to be a tracking campaign
        const updateResponse = await updateCampaign(newCampaignId, {
          token,
          settings: { 
            type: "planning",
            settings: {
              type
            }
          },
        });

        console.log({ response, updateResponse });

        if (newCampaignId) {
          // navigate to the new funnel
          navigate(`/planner/${newCampaignId}`);
        }
        onClose();
        setIsLoading(false);
        // loadTrackingCampaigns();
      }}
      size={PopupSizes.LARGE}
      icon={
        <div
          className={classNames(
            item?.background,
            "flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg"
          )}
        >
          <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
        </div>
      }
    >
      {isLoading && (
        <div className="flex justify-center items-center p-50">
          Creating your profile...
        </div>
      )}
      {!isLoading && (
        <div className="px-5">
          {/* <Title>What would you like to name this profile?</Title> */}
          <h2 className="text-lg mb-4">
            What would you like to name this profile?
          </h2>

          <div className="grid grid-flow-row gap-7 mb-37">
            <TextInput
              // label={`What would you like to name this ${unitName}`}
              placeholder={`Enter profile name here`}
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
              flavor={TextInputFlavors.MODERN}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
