import { ActivitiesSection } from "./ActivitiesSection";
import { ContactsSection } from "./ContactsSection";
import { NotificationsSection } from "./NotificationsSection";
import { RightSidebarProps } from "./types";
import { ChatBot } from "@/Apps/AITools";

/**
 * The RightSidebar component displays a fixed right sidebar with notifications, activities, and contacts sections.
 * @param notifications Whether to show notifications section.
 * @param activities Whether to show activities section.
 * @param contacts Whether to show contacts section.
 */
export const RightSidebar: React.FC<RightSidebarProps> = ({
  notifications = false,
  activities = false,
  contacts = false,
  chat = false,
  pageSettings = {},
}) => {
  // Render the component
  return (
    // The main container for the right sidebar
    <div
      data-testid="RightSidebar"
      style={{ width: 500 }}
      className="right-sidebar fixed right-0 bg-white dark:bg-black/80 bottom-0 z-50 w-[280px] border-l border-black/10 dark:border-white/10 transition-all duration-300 backdrop-blur"
    >
      {/* The container for the sections */}
      <div className="flex flex-col gap-9 px-6 py-[22px] h-screen overflow-y-auto overflow-x-hidden">
        {/* Render the notifications section if notifications prop is true */}
        {Boolean(notifications) && <NotificationsSection />}

        {/* Render the activities section if activities prop is true */}
        {Boolean(activities) && <ActivitiesSection />}

        {/* Render the contacts section if contacts prop is true */}
        {Boolean(contacts) && <ContactsSection />}

        {/* Render the chat section if chat prop is true */}
        {Boolean(chat) && <ChatBot pageSettings={pageSettings} />}
      </div>
    </div>
  );
};
