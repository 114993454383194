import Checkbox from "@/ui/Checkbox";
import { FieldData, RenderFieldProps } from "../types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";
import Select from "@/ui/Select";
import TextArea from "@/ui/TextArea";

/**
 * Renders a field based on its fieldType.
 * @param param0
 * @returns
 */
export const RenderField: React.FC<RenderFieldProps> = ({
  data, // The data object containing the field's properties
  isEditable = false, // A boolean indicating whether the field is editable or not
  onItemChange, // A function to handle changes to the field's value
}) => {
  const { id = "", label = "", value = "", fieldType = "string" } = data; // Destructure the data object to get the field's properties

  // Render the field based on its fieldType
  switch (fieldType) {
    case "string":
    case "number":
    case "date":
      return (
        <>
          {/* Render the field label */}
          <p
            key={id}
            data-testid={`DetailsView-Field-${id}`}
            className="text-xs text-black/40 dark:text-white/40"
          >
            {label}
          </p>
          {isEditable ? (
            // Render an input field if isEditable is true
            <span
              key={`${id}-value`}
              data-testid={`DetailsView-EditField-${id}`}
              style={{ marginTop: -7 }}
            >
              <TextInput
                type={
                  (fieldType === "string"
                    ? "text"
                    : fieldType) as TextInputTypes
                }
                value={value ? String(value) : ''}
                placeholder={label}
                onChange={(e) => {
                  onItemChange({ data: { ...data, value: e.target.value } });
                }}
              />
            </span>
          ) : (
            // Otherwise, render the field value as text
            <p key={`${id}-value`} className="text-sm">
              {String(value)}
            </p>
          )}
        </>
      );
    case "longtext":
      return (
        <>
          {/* Render the field label */}
          <p
            key={id}
            data-testid={`DetailsView-Field-${id}`}
            className="text-xs text-black/40 dark:text-white/40"
          >
            {label}
          </p>
          {isEditable ? (
            // Render an input field if isEditable is true
            <span
              key={`${id}-value`}
              data-testid={`DetailsView-EditField-${id}`}
              style={{ marginTop: -7 }}
            >
              <TextArea
                value={value ? String(value) : ''}
                placeholder={label}
                onChange={(e) => {
                  onItemChange({ data: { ...data, value: e.target.value } });
                }}
              />
            </span>
          ) : (
            // Otherwise, render the field value as text
            <p key={`${id}-value`} className="text-sm">
              {String(value)}
            </p>
          )}
        </>
      );
    case "enum":
      return (
        <>
          {/* Render the field label */}
          <p
            key={id}
            data-testid={`DetailsView-Field-${id}`}
            className="text-xs text-black/40 dark:text-white/40"
          >
            {label}
          </p>
          {isEditable ? (
            // Render a select field if isEditable is true and fieldType is enum
            <span
              key={`${id}-value`}
              data-testid={`DetailsView-EditField-${id}`}
              style={{ marginTop: -7 }}
            >
              <Select
                items={data.options}
                value={String(value)}
                onChange={(e) => {
                  onItemChange({ data: { ...data, value: e.target.value } });
                }}
              />
            </span>
          ) : (
            // Otherwise, render the field value as text
            <p className="text-sm" key={`${id}-value`}>
              {String(value)}
            </p>
          )}
        </>
      );
    case "boolean":
      return (
        <>
          {/* Render the field label */}
          <p
            key={id}
            data-testid={`DetailsView-Field-${id}`}
            className="text-xs text-black/40 dark:text-white/40"
          >
            {label}
          </p>
          {isEditable ? (
            // Render a checkbox field if isEditable is true and fieldType is boolean
            <span
              key={`${id}-value`}
              data-testid={`DetailsView-EditField-${id}`}
              onChange={(e) => {
                onItemChange({ data: { ...data, value: e.target.checked } });
              }}
            >
              <Checkbox /*label="Checkbox"*/ checked={Boolean(value)} />
            </span>
          ) : (
            // Otherwise, render the field value as text
            <p className="text-sm" key={`${id}-value`}>
              {value ? "Yes" : "No"}
            </p>
          )}
        </>
      );
    default:
      return null;
  }
};
