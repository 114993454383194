import { getSessionToken } from "@/api/auth";
import { createObject, getCampaign, getObject, initializeBroadcast, updateObject } from "@/api/campaigns";
import { Page } from "@/ui/Layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@/ui/Layout/Page";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { handleContentSave } from "@/Apps/Content/ContentEditor";
import { CreateContentPopup } from "@/Apps/Content/ContentDashboardPage";
import { BroadcastOptions } from "./components/BroadcastOptions";

// export const handleContentSave = async (objectData, campaignId) => {
//   const item = objectData;

//   // console.log("item", item);

//   // save to db
//   const token = getSessionToken();

//   if (!item?.id) {
//     console.log("create object", item?.name, item?.settings);
//     // create object
//     await createObject({
//       name: item?.name,
//       x: 0, // calculate this
//       y: 0,
//       type: "ContentComponent",
//       settings: { name: item?.name, withAI: false, ...item?.settings },
//       campaignId,
//     });
//   } else {
//     const payload = {
//       token,
//       name: item?.name,
//       settings: item?.settings,
//     };
//     console.log({ payload });
//     await updateObject(item?.id, payload);
//   }

//   alert("Saved!");
//   window.location.reload();
// };

export const BroadcastConfirmation = () => {
  // const [packageData, setPackageData] = useState({});
  const [packageName, setPackageName] = useState("Untitled Broadcast");
  const [objectData, setObjectData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [broadcastOptions, setBroadcastOptions] = useState({
    schedule: { type: "draft", options: {} },
  });
  
  const { id, objectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const token = getSessionToken();

      const data = await getCampaign({ token, id });
      const object = await getObject(objectId);
      console.log("object", object);
      // setPackageData(data);
      setPackageName(data?.name);
      // setObjectName(data?.objects?.find((obj: any) => obj.id === objectId)?.name);
      setObjectData(object?.data?.object);
      // setPageId(pgId);

      // setUrl(`https://${data?.domain?.name}/${pgData?.slug}`);
      // set broadcast options
      console.log("Broadcast Options =>", object?.data?.object?.settings?.options);

      setBroadcastOptions(object?.data?.object?.settings?.options);

      // alert("Page Loaded!")
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleBroadcastOptionsSave = async () => {

    // update node settings with broadcast options
    const token = await getSessionToken();
    const objResponse = await updateObject(objectId, {
      token,
      ...objectData,
      settings: { ...objectData?.settings, options: broadcastOptions },
    });

    // Call `/campaign/broadcasts hook on save
    const response = await initializeBroadcast(id, { name: objectData?.name, ...objectData?.settings });
    // const response = await initializeBroadcast(id, broadcastOptions);
    console.log("Initialize Broadcast Reponse", response);

    // navigate(`/crm/broadcasts/${id}`)
  }


  return (
    <Page
      topBar={{
        page: [
          { label: "Email Broadcasts", href: "/crm/broadcasts" },
          { label: packageName, href: `/crm/broadcasts/${id}` },
        ],
        items: (
          <button
            className="btn btn-info"
            // onClick={() => navigate(`/crm/broadcasts/${id}`)}            
            onClick={handleBroadcastOptionsSave}
          >
            Save
          </button>
        ),
      }}
    >
      <Container>
        {isLoading ? (
          "Loading..."
        ) : (
          <>
            <div className="p-3">
              <Button
                label="<- Back to Editor"
                type={ButtonTypes.SOFT}
                // onClick={() => window.history.back()}
                onClick={() => navigate(`/crm/broadcasts/${id}`)}
              />
            </div>

            <BroadcastOptions options={broadcastOptions} onChange={options => setBroadcastOptions(options)} />
            {/* {JSON.stringify(broadcastOptions)} */}
          </>
        )}
      </Container>
    </Page>
  );
}