import { GoogleChromeLogo } from '@phosphor-icons/react';
import { Sites } from './Sites';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { PageEditor } from '../Pages/Editor';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  // Add an item called "Sites" to the App Drawer
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("sites")) return [];
    return [
      {
        label: "Sites",
        icon: <GoogleChromeLogo className="text-lg" />,
        href: "/sites", // this is the path to the "Sites" gallery. Go there when the user clicks on the item
        order: 1,
      },
    ];
  }

  // Add a route for the "Sites" gallery and another for the Sites Dashboard
  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("sites")) return [];
    return [
      {
        path: "/sites",
        element: <Sites name="Sites" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Sites" />,
          },
          {
            path: ":campaignId/:objectId",
            element: <PageEditor name="Sites" homeHref="/sites" />,
          },
        ],
      },
    ];
  }
};


export { Sites };