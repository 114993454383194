import React from "react";

import { Helmet } from "react-helmet";

import { MobileResponsiveToggleProps, MobileState } from "./types";
import { style, getDefaultFontSize } from "./style";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import FontIcon from "@/ui/_old/FontIcon";
// import Button from "@/ui/_old/Button";

/**
 * For Mobile Responsiveness - set the root size that page elements derive their relative sizes from
 */
const SetRootFontSize: React.FC<{ mobileState: MobileState }> = ({
  mobileState,
}) => {
  const fontSize = getDefaultFontSize(mobileState);
  return (
    <Helmet>
      <style type="text/css">{`html { font-size: ${fontSize}px; }`}</style>
    </Helmet>
  );
};

const BtnWrapper: React.FC<{
  isActive?: boolean;
  children: React.ReactNode;
}> = ({ isActive = false, children = <></> }) => {
  return (
    <div
      className={`
        cursor-pointer
        inline-block
        ${isActive ? "bg-gray-200" : "bg-inherit"}
        ${isActive ? "hover:bg-gray-200" : "hover:bg-gray-100"}
      `}
    >
      {children}
    </div>
  );
};


const MobileResponsiveToggle: React.FC<MobileResponsiveToggleProps> = ({
  setMobileState = () => null,
  getMobileState = () => null,
}) => {
  const [showRotate, setShowRotate] = React.useState<boolean>(false);
  const currentMobileState = getMobileState() || MobileState.DESKTOP;

  const handleMobileStateChange = (type, dimensions) => {
    setMobileState(type, dimensions);
    setShowRotate(
      type === MobileState.TABLET || type === MobileState.SMARTPHONE
    );
  };

  const handleRotate = () => {
    const currentMobileState = getMobileState();
    const dimensions = currentMobileState.dimensions;

    handleMobileStateChange(currentMobileState.type, {
      ...dimensions,
      paddingTop: dimensions.paddingLeft,
      paddingBottom: dimensions.paddingRight,
      paddingLeft: dimensions.paddingTop,
      paddingRight: dimensions.paddingBottom,
      width: dimensions.height,
      height: dimensions.width,
    });
  };



  const Btn: React.FC<{
    icon: string;
    type: MobileState;
  }> = ({ icon = "tv", type = MobileState.DESKTOP }) => {
    const mobileState: MobileState = currentMobileState.type;

    return (
      <Button type={ButtonTypes.SOFT} label={<FontIcon>{icon}</FontIcon>} />
    )
    xreturn (
      <BtnWrapper isActive={mobileState === type}>
        <Button
          icon
          style={style.icon}
          onClick={() => handleMobileStateChange(type, style[type])}
        >
          {icon}
        </Button>
      </BtnWrapper>
    );
  };

  // const isFromIframe = window.location !== window.parent.location;

  return (
    // <div style={{ ...style.container, ...isFromIframe ? { bottom: 80, right: 5 } : {} }}>
    <div className="zoom-75 mt-1">
      <SetRootFontSize mobileState={currentMobileState.type} />

      <div>
        <Btn icon="zoom_out_map" type={MobileState.FULLSCREEN} />
        <Btn icon="tv" type={MobileState.DESKTOP} />
        <Btn icon="tablet_android" type={MobileState.TABLET} />
        <Btn icon="smartphone" type={MobileState.SMARTPHONE} />
      </div>

      <div style={style.rotateIcon}>
        {showRotate && (
          <Button icon transparent style={style.icon} onClick={handleRotate}>
            screen_rotation
          </Button>
        )}
      </div>
    </div>
  );
};

export default MobileResponsiveToggle;
