import React from 'react'
import styled from 'styled-components'
import MuiToolTip from "@material-ui/core/Tooltip";

import { HelpCircleIcon } from 'lucide-react';
import { Theme, withStyles } from '@material-ui/core';

type HelpButtonProps = {
  href: string;
  tooltip: string;
  style: React.CSSProperties;
}

export const ToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: 14,
    padding: 5,
  },
}))(MuiToolTip);


const Link = styled.a`
    color: #999;
    transition: 250ms;
    &:hover {
        color: #333;
        transform: scale(1.3);
    }
`

export const HelpButton: React.FC<HelpButtonProps> = ({ href = "", tooltip = "", style = {} }) => {

  return (
    <ToolTip arrow title={
      <div style={{ padding: 10 }}>
        {tooltip}
      </div>
    }>
      <Link target="_blank" href={href} style={style}>
        {/* <Icon style={{ fontSize: '14pt' }} type={IconTypes.ErrorOutline} /> */}
        <HelpCircleIcon />
      </Link>
    </ToolTip>
  )
}

export default HelpButton;