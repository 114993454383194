import { cloneCampaign, updateCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import Modal, { Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const CreateNewCampaign = ({ onClose = () => {} }) => {
  const [newCampaignName, setNewCampaignName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <Modal
      title="Create a Tracking Campaign"
      onClose={onClose}
      onSuccess={async () => {
        setIsLoading(true);
        const token = getSessionToken();
        const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // id of the tracking campaign template
        const response = await cloneCampaign(campaignId, {
          token,
          name: newCampaignName,
        });

        const newCampaignId = response?.data?.cloneCampaign?.response?.id;

        // update the type of the campaign to be a tracking campaign
        await updateCampaign(newCampaignId, {
          token,
          settings: { type: "tracking" },
        });

        if (newCampaignId) {
          // navigate to the new funnel
          navigate(`/tracking/${newCampaignId}`);
        }
        onClose();
        setIsLoading(false);
        // loadTrackingCampaigns();
      }}
      size={PopupSizes.LARGE}
    >
      {isLoading && (
        <div className="flex justify-center items-center p-50">Creating your campaign...</div>
      )}
      {!isLoading && (
        <div className="p-5">
          <Title>What would you like to name this campaign?</Title>

          <div className="grid grid-flow-row gap-7 mb-7">
            <TextInput
              // label={`What would you like to name this ${unitName}`}
              placeholder={`Enter campaign name here`}
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
              flavor={TextInputFlavors.MODERN}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
