import { ProjectDetails } from "./widgets/ProjectDetails";
import { SpendingBreakdown } from "./widgets/SpendingBreakdown";
import { TimelineList } from "./widgets/TimelineList";
import { MediaFiles } from "@/Apps/Media/widgets/MediaFiles";
import { KPIBlock } from "@/Apps/Insights/widgets/KPIBlock";
import { PageList } from "@/Apps/Funnels/widgets/PageList";
import { NameAndDescription } from "./Settings/widgets/NameAndDescription";
import { CampaignDomainName } from "./Settings/widgets/CampaignDomain";
import { ScriptsAndTracking } from "./Settings/widgets/ScriptsAndTracking";
import { DeleteFunnel } from "./Settings/widgets/DeleteFunnel";
import { Widget } from "./types";
import { Customize } from "@/Apps/Funnels/widgets/Customize";
import { AutomationBuilder } from "../Workflows/widgets/AutomationBuilder";
import { ShareURL } from "./Settings/widgets/ShareURL";
// import { Contacts } from "@/Apps/CRM/widgets/ContactList";
import { ContactTitle } from "@/Apps/CRM/widgets/ContactTitle";
import { ContactInfo } from "@/Apps/CRM/widgets/ContactInfo";
import { Notes } from "@/Apps/CRM/widgets/Notes";
import { ContactType } from "@/Apps/PFOC/widgets/ContactType";
import { NextDestinationView } from "@/Apps/PFOC/widgets/NextDestinationView";
import { ScrollStatus } from "@/Apps/PFOC/widgets/ScrollStatus";
import { ChurchInfo } from "@/Apps/PFOC/widgets/ChurchInfo";
import { EnablePasswordProtection } from "@/Apps/Member/widgets/EnablePasswordProtection";
import { ProductDetails } from "@/Apps/Cart/Products/widgets/ProductDetails";
import { OfferDetails } from "../Cart/Offers/widgets/OfferDetails";
import { FinalDestination } from "../Funnels/widgets/FinalDestination";
import { WebinarCTATime } from "../Webinar/widgets/WebinarCTATime";
import { SelectPaymentTypes } from "../Partnerships/widgets/SelectPaymentTypes";
import { CommissionSettings } from "../Partnerships/widgets/CommissionSettings";
import { SessionSchedule } from "../Webinar/widgets/SessionSchedule";
import { WebinarVideoUpload } from "../Webinar/widgets/WebinarVideoUpload";
import { TrackingLinks } from "../Insights/widgets/TrackingLinks";
import ContactList from "@/ui/ContactList";
import { Payouts } from "../Partnerships/widgets/Payouts";
import { PartnerDetail } from "../Partnerships/widgets/PartnerDetail";
import Panel from "@/ui/Panel";
import { ContentDashboardPage } from "../Member/ContentDashboardPage";
import { QRCodeView } from "../PFOC/widgets/QRCodeView";
import { triggerAsyncHook, triggerHook } from "@/plugins/client";
import { HookTriggers, ListItemsHooks } from "@/plugins/types";
import { useContext } from "react";
import { AppContext } from "@/AppContext";
import { ChooseProfile } from "../Planning/widgets/ChooseProfile";
import { UseWithAIToggle } from "../Funnels/widgets/UseWithAIToggle";

export const renderWidget = (widget: Widget, settings: any) => {
  let type = typeof widget === "string" ? widget : widget?.type;

  const contextObj = useContext(AppContext);

  const {
    campaignData,
    setCampaignData,
    unitName = "Campaign",
    panelTitle,
    id,
  } = settings;

  switch (type) {
    // Overview Widgets
    case "details":
      return <ProjectDetails />;
    // case "kpi":
    //   return <KPIBlock {...widget?.settings} />;
    case "pages":
      return (
        <PageList
          campaignData={campaignData}
          unitName={unitName}
          panelTitle={panelTitle}
          {...widget?.settings}
        />
      );
    case "customize":
      return <Customize campaignData={campaignData} />;
    case "timeline":
      return <TimelineList />;
    case "media":
      return <MediaFiles uploadSection />;
    case "spending":
      return <SpendingBreakdown />;
    // case "tracking":
    //   return <TrackingLinks campaignData={campaignData} unitName={unitName} />;

    // Automation Widgets
    case "automation":
      return <AutomationBuilder />;

    // Settings Widgets
    case "share":
      return <ShareURL />;
    case "name":
      return (
        <NameAndDescription
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          unitName={unitName}
          {...widget?.settings}
        />
      );
    case "domain":
      return (
        <CampaignDomainName
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          unitName={unitName}
          {...widget?.settings}
        />
      );
    case "scripts":
      return (
        <ScriptsAndTracking
          campaignData={campaignData}
          unitName={unitName}
          {...widget?.settings}
        />
      );
    case "delete":
      return (
        <DeleteFunnel
          campaignData={campaignData}
          unitName={unitName}
          {...widget?.settings}
        />
      );

    // CRM Widgets
    case "contactList":
      return <ContactList />;
    case "contactTitle":
      return <ContactTitle id={id} />;
    case "contactInfo":
      return <ContactInfo id={id} />;
    case "contactNotes":
      return <Notes id={id} />;

    // Membership Site Widgets
    case "password":
      return <EnablePasswordProtection unitName={unitName} id={id} />;
    case "posts":
      return (
        <Panel title="Posts">
          <ContentDashboardPage campaignData={campaignData} />
        </Panel>
      );

    // Layout Widgets
    case "columns": {
      const { widgets } = widget.settings;
      return (
        <div
          className={`grid grid-cols-1 md:grid-cols-${widgets.length} gap-7 items-start`}
        >
          {widgets.map((widget: string, key) => (
            <span key={key}>{renderWidget(widget, settings)}</span>
          ))}
        </div>
      );
    }
    case "group": {
      const { widgets } = widget.settings;
      return (
        <div className={`grid gap-7`}>
          {widgets.map((widget: string) => renderWidget(widget, settings))}
        </div>
      );
    }

    // Product & Offer Widgets
    case "productDetails":
      return <ProductDetails campaignData={campaignData} unitName="Product" />;
    case "offerDetails":
      return <OfferDetails campaignData={campaignData} unitName="Offer" />;

    case "finalDestination":
      return (
        <FinalDestination campaignData={campaignData} unitName={unitName} />
      );

    // Webinar Widgets
    case "webinarCTATime":
      return <WebinarCTATime campaignData={campaignData} />;
    case "webinarSchedule":
      return <SessionSchedule campaignData={campaignData} />;
    case "webinarVideo":
      return (
        <WebinarVideoUpload campaignData={campaignData} unitName="Webinar" />
      );

    // Affiliate Widgets
    case "affiliateCommissionSettings":
      return <CommissionSettings campaignData={campaignData} />;
    case "affiliatePaymentTypes":
      return <SelectPaymentTypes campaignData={campaignData} />;
    case "affiliatePayouts":
      return <SpendingBreakdown />;
    // return <Payouts />;;
    case "affiliatePartnerDetail":
      return <PartnerDetail id={id} />;

    // PFOC Widgets
    case "pfocContactType":
      return <ContactType id={id} />;
    case "pfocQRCode":
      return <QRCodeView id={id} />;
    case "pfocNextDestination":
      return <NextDestinationView id={id} />;
    case "pfocScrollStatus":
      return <ScrollStatus id={id} />;
    case "pfocChurchInfo":
      return <ChurchInfo id={id} />;


    // Smart Planner Widgets
    case "planner":
      return <ChooseProfile id={id} campaignData={campaignData} unitName={unitName} />; 

    case "withAI":
      return <UseWithAIToggle id={id} campaignData={campaignData} unitName={unitName} />;

    default:
      return triggerHook(
        HookTriggers.onListItems,
        { 
          id: ListItemsHooks.WIDGET,
          type          
        },
        {
          widget,
          settings,
        }, null, contextObj
      );
  }
};
