import { FC } from "react";
import { NodeIconProps } from "./types";
import { StatsIcon } from "./StatsIcon";

export const NodeIcon: FC<NodeIconProps> = ({
  Icon,
  imageSrc,
  color,
  containerStyle = {},
  imageStyle = {},
  iconStyle = { fontSize: "48pt" },
  selected = false,
  showStats = false,
  stats = {},
}) => {

  let container = <></>;
  if (Icon) container = <Icon style={iconStyle} className="mx-auto text-white" />;
  if (!Icon) container = <img style={imageStyle} src={imageSrc} className="mx-auto" />;

  if (showStats)
    container = (
      <StatsIcon style={iconStyle} color={color} data={stats} />
    );

  return (
    <div
      style={{ backgroundColor: color, ...containerStyle }}
      className={`border ${
        selected
          ? "border border-black/50 dark:border-white"
          : "border-black/20 dark:border-white/20"
      } rounded bg-black/5 dark:bg-white/5 flex justify-center items-center h-full`}
    >
      {container}
    </div>
  );
};

export const getNodeIconComponent = (data: NodeIconProps) => (
  <NodeIcon {...data} />
);

