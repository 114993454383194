import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { Container, Page } from "@/ui/Layout/Page";

import { AppContext } from "@/AppContext";
import scrollContactWidgets from "../PFOC/contactsDashboard";
import { Widget } from "../Dashboards/types";
import { DashboardPage } from "../Dashboards";

export const ContactsDashboard = ({ name = "" }) => {
  const [pageName, setPageName] = useState("Contact Record");

  const { id } = useParams();

  const { permissions = [] } = useContext(AppContext);
  const showScroll = permissions.includes("scroll");

  let useTheseWidgets: Widget[] = [
    "contactTitle",
    "contactInfo",
    "contactNotes",
  ];
  if (showScroll) useTheseWidgets = scrollContactWidgets;

  return (
    <Page
      topBar={{
        page: [
          { label: "CRM", href: "/crm" },
          { label: name, href: "/crm/contacts" },
          { label: pageName },
        ],
      }}
    >
      <Container>
        {/* <ContactRecordDashboardPage id={id} /> */}
        <DashboardPage id={id} widgets={useTheseWidgets} />
      </Container>
    </Page>
  );
};
