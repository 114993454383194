import { Loading } from "@/ui/Layout/Loading";
import { PanelItem } from "@/ui/Panel";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { fromAddress, setDefaults } from "react-geocode";

const apiKey = "AIzaSyB6k0U2LcdsHPqzjVK5rxJ-s5q9aXr0gJA";

setDefaults({ key: apiKey });

const NextDestinationView = ({ id }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  // const { navigate } = useNavigate();

  useEffect(() => {
    console.log("NextDestinationView");
    // (async () => {
    //   // fetch details from http://localhost:8100/next/{id}
    //   const resp = await fetch(
    //     `https://pfoc-server-1165efc8841d.herokuapp.com/next/${id}`
    //   );
    //   const data = await resp.json();
    //   setData(data);
    //   console.log("next destination data", data);
    //   setLoading(false);
    // })();
  }, []);

  const { churchName, churchLocation, pastorName } = data;

  return (
    <div>
      {loading ? (
        <p className="py-4 text-black">Loading Next Church Location...</p>
      ) : (
        <PanelItem
          title={`${churchName} in ${churchLocation}`}
          description={`Pastor ${pastorName}`}
          buttonLabel="Open Church Record"
        />
      )}
    </div>
  );
};


const MapContainer = ({
  google,
  // markers = [],
  addresses = [],
  width = "100%",
  height = 300,
}) => {
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeMarkerDetails, setActiveMarkerDetails] = useState({});

  const fetchAddress = async (addresses) => {
    try {
      let markerList = [];

      console.log({ addresses });
      addresses.forEach(async ({ address }) => {
        // console.log({ address });
        if (!address || isEmpty(address)) return;

        const response = await fromAddress(address);
        const { lat, lng } = response.results[0].geometry.location;

        if (
          !markerList.some((marker) => marker.lat === lat && marker.lng === lng)
        ) {
          markerList = [...markerList, { lat, lng }];
        }
        // console.log({ markerList })
        setMarkers(markerList);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const calculateCenter = (providedMarkers) => {
    // calculate the center of the map using the provided list of lat/lng pairs
    if (providedMarkers.length === 1) return providedMarkers[0];

    const latSum = providedMarkers.reduce((acc, marker) => acc + marker.lat, 0);
    const lngSum = providedMarkers.reduce((acc, marker) => acc + marker.lng, 0);
    const latCenter = latSum / providedMarkers.length;
    const lngCenter = lngSum / providedMarkers.length;
    return { lat: latCenter, lng: lngCenter };
  };

  useEffect(() => {
    fetchAddress(addresses);
  }, [addresses]);

  if (!markers.length) {
    return <Loading />;
  }

  const isFromIframe = window.location !== window.parent.location;

  return (
    <Map
      google={google}
      zoom={6}
      containerStyle={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      style={{
        width,
        height,
      }}
      initialCenter={calculateCenter(markers)}
    >
      {markers.map((marker, index) => {
        // console.log({ marker, address: addresses[index] });
        const {
          // icon = "https://cdn-icons-png.flaticon.com/512/4334/4334376.png",
          icon = "https://cdn-icons-png.flaticon.com/512/3448/3448400.png",
          address,
        } = addresses[index];
        return (
          <Marker
            key={index}
            id={index}
            position={marker}
            // onClick={() => console.log("You clicked me!", address)}
            onClick={(props, marker, e) => {
              setActiveMarker(marker);
              setActiveMarkerDetails(addresses[index]);
            }}
            // onClick={() => console.log(addresses[index])}
            icon={{
              // url: "https://cdn-icons-png.flaticon.com/512/4334/4334376.png",
              // url: "https://cdn-icons-png.flaticon.com/512/1045/1045148.png",
              // url: "https://cdn-icons-png.flaticon.com/512/8357/8357017.png",
              url: icon,
              scaledSize: new google.maps.Size(30, 30),
            }}
          ></Marker>
        );
      })}

      <InfoWindow visible marker={activeMarker}>
        <a href={isFromIframe ? '#' : `/crm/contacts/${activeMarkerDetails?.id}`}>
          <h1 className="text-black font-bold">{activeMarkerDetails?.churchName}</h1>
          <br />
          <p className="text-black">Pastor {activeMarkerDetails?.pastorName}</p>
          <p className="text-black">{activeMarkerDetails?.churchLocation}</p>
        </a>
        {/* <NextDestinationView id={activeMarkerDetails?.id} /> */}
      </InfoWindow>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey,
})(MapContainer);
