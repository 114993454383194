import React from "react";
import {
  DeltaUpIcon,
  DeltaDownIcon
} from "../Icons";
import { KPIWidgetProps } from "./types";

// Define the KPIWidget component as a functional component that takes in props of type KPIWidgetProps
export const KPIWidget: React.FC<KPIWidgetProps> = ({
  label, number, delta, deltaSign, color = "lightblue", icon,
}) => {
  // Render the component with the given props
  return (
    // Apply a background color and rounded corners to the component
    <div data-testid="KPIWidget" className={`bg-${color}-100 rounded-2xl p-6`}>
      {/* Display the label and icon of the KPI */}
      <div className="flex items-center gap-2 justify-between mb-2 text-black">
        <p className="text-sm font-semibold">{label}</p>
        {icon}
      </div>
      {/* Display the number and delta of the KPI */}
      <div className="flex items-center justify-between">
        <h2 className="text-2xl leading-9 font-semibold text-black">
          {number}
        </h2>
        {/* Display the delta and delta sign of the KPI */}
        <div className="flex items-center gap-1">
          <p className="text-xs leading-[18px] text-black">{delta}</p>
          {deltaSign === "POSITIVE" && <span data-testid="DeltaUpIcon"><DeltaUpIcon /></span>}
          {deltaSign === "NEGATIVE" && <span data-testid="DeltaDownIcon"><DeltaDownIcon /></span>}
        </div>
      </div>
    </div>
  );
};