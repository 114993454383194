import ReactPlayer from "react-player";
import { videoLookup } from "./videos";

export const VideoPlayer = ({
  videoId,
  url,
  wrapperStyles = {},
  playerProps = {},
  autoPlay = true,
}) => {
  const videoUrl = url || videoLookup(videoId);
  // console.log("videoUrl", videoUrl);

  return (
    <div style={{ ...wrapperStyles }}>
      <ReactPlayer
        playing={autoPlay}
        muted
        loop
        url={videoUrl}
        width="100%"
        {...playerProps}
      />
    </div>
  );
}
