import { useState, useEffect, useContext } from "react";

import { ButtonTypes, Variants } from "@/ui/types";
import {
  TextInputFlavors,
  TextInputTypes,
} from "@/ui/TextInput/types";
import { TextInput } from "@/ui/TextInput";
import { Button } from "@/ui/Button";

import { getSessionToken, logOutUser, loginUser } from "../api/auth";
import Alert from "@/ui/Alert";
import { AppContext } from "@/AppContext";
import { getCampaigns } from "@/api/campaigns";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { setSidebarOpen, setRightSidebarOpen } = useContext(AppContext);

  const handleSubmit = async () => {
    // event.preventDefault();
    setIsLoading(true);
    // alert("test")
    const data = await loginUser({ email, password });
    if (data) {
      if (data.error) {
        setErrorMessage(data.error);
      } else {
        console.log("the data", data);
        if (data?.token) {
          localStorage.setItem("token", data.token);

          // are there any profiles? if not, redirect to onboarding
          const token = getSessionToken();
          const response = await getCampaigns({ token, type: "planning" });
          if (
            response &&
            response.filter(({ deleted }) => !deleted).length === 0
          ) {
            window.location.href = "/planner";
          } else {
            // otherwise, redirect to the dashboard
            window.location.href = "/";
          }

          // console.log("response", response)
        } else {
          setErrorMessage("Unable to login. Please try again.");
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // no sidebars
    setSidebarOpen(false);
    setRightSidebarOpen(false);

    // Log out the user
    const token = localStorage.getItem("token");
    const data = logOutUser({ token });
    if (data) {
      if (data.error) {
        setErrorMessage(data.error);
      } else {
        localStorage.removeItem("token"); // log out (delete the token locally)
      }
    }
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center justify-center h-screen">
        <div className="max-w-[680px] flex-none w-full bg-white dark:bg-white/5 p-4 sm:p-10 lg:px-[146px] lg:py-[107px] rounded-2xl loginform">
          {/* <Logo className="mb-3 max-w-[288px] mx-auto" /> */}
          <div className="mb-8">
            <h1 className="text-2xl font-semibold text-left mb-2">
              Welcome!
            </h1>
            <p className="mb-3">Please sign in to continue...</p>
            {/* Error Message Goes Here */}
            {errorMessage && (
              <Alert
                message={errorMessage}
                onClose={() => {}}
                type="basic"
                variation="danger"
              />
            )}
          </div>

          <form className="mb-4" onSubmit={handleSubmit}>
            <TextInput
              flavor={TextInputFlavors.CLASSIC}
              type={TextInputTypes.EMAIL}
              placeholder="Email"
              className="mb-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") handleSubmit();
              }}
            />

            <TextInput
              flavor={TextInputFlavors.CLASSIC}
              type={TextInputTypes.PASSWORD}
              placeholder="Password"
              className="mb-4"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") handleSubmit();
              }}
            />

            {/* <div className="mb-7 text-right">
              <Link to="forgot-password.html" className="text-lightpurple-300">
                Forgot Password?
              </Link>
            </div> */}

            <Button
              type={isLoading ? ButtonTypes.DISABLED : ButtonTypes.DEFAULT}
              variant={Variants.SECONDARY}
              label={isLoading ? "Loading..." : `Sign In`}
              className="w-full py-2 px-4 text-lg font-semibold"
              onClick={handleSubmit}
            />
          </form>
          <p className="text-center text-black/40 dark:text-white/40">
            Not a Member yet?{" "}
            <a
              href="https://www.launchos.com/"
              className="text-lightpurple-300"
            >
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
