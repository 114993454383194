import { useEffect, useState } from "react";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
// import { publishCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { ArrowSquareOut, SealCheck } from "@phosphor-icons/react";
import InputGroup from "@/ui/InputGroup";

// import { PopupSizes } from "@/ui/Modal/types";
import { publishPage } from "@/api/pages";

export const PublishPageModal = ({ setIsOpen, pageId, unitName = "Page" }) => {
  // const icon = <SuccessAlertIcon className="transform scale-50" />
  const [isPublishing, setIsPublishing] = useState(true);
  const [pageURL, setPageURL] = useState("");

  const doPublish = async () => {
    // call the api to publish the page
    const token = getSessionToken();
    const response = await publishPage(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Page is Published");
        setIsPublishing(false);
        setPageURL(response.data.publishPage.webSiteUrl);
        console.log(response);
      }
    }
  };

  useEffect(() => {
    // publish the page now
    doPublish();
  }, []);

  return (
    <Modal
      // icon={icon}
      title={`Your ${unitName} is ${isPublishing ? "Publishing..." : "Live!!"}`}
      size={PopupSizes.LARGE}
      onClose={() => setIsOpen(false)}
      onSuccess={() => setIsOpen(false)}
      footer={!isPublishing}
    >
      <div className="flex items-center p-5">
        {isPublishing ? (
          <Title>
            Your {unitName.toLowerCase()} is being published. This may take up
            to 30 seconds.
          </Title>
        ) : (
          <div>
            <div className="flex items-center justify-center">
              <SealCheck size={64} />
            </div>
            <Title>
              Good news! Your {unitName.toLowerCase()} is live and ready to
              share with the world!
            </Title>
            <InputGroup
              elements={[
                {
                  type: "input",
                  leftRounded: true,
                  placeholder: pageURL,
                  inputType: "text",
                  disabled: true,
                },
                {
                  type: "icon",
                  rightRounded: true,
                  icon: (
                    <a href={pageURL} target="_blank">
                      <ArrowSquareOut />
                    </a>
                  ),
                },
              ]}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
