import { useEffect, useState } from "react";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import FileInput from "@/ui/CommunicationView/ChatConversation/FileInput";
import { useCompletion } from "ai/react";
import { AI_DOMAIN } from "@/env";
import { executeFunnelVision } from "@/Apps/Sketchpad/Sketchpad";
import { Loading } from "@/ui/Layout/Loading";
import { Scribble } from "@phosphor-icons/react";

export const FunnelVisionModal = ({
  setIsOpen,
  campaignData = {},
  unitName = "Funnel",
}) => {
  const [imageData, setImageData] = useState(null);

  const { complete, isLoading } = useCompletion({
    api: `${AI_DOMAIN}/api/completion`,
    body: { type: "vision" },
  });

  const handleImageUpload = async (files) => {
    const file = files[0];

    // console.log("file", file);

    if (file && file.type.match("image.*")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // The result contains the data URL as a string
        console.log(e.target.result);
        const base64data = e.target.result;
        setImageData(base64data);

        executeFunnelVision({ base64data, campaignData, complete }, () => {
          // Switch Tab to Funnel Builder
          window.location.href = `/funnels/${campaignData?.id}#builder`;
          window.location.reload();

        });
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      title="Funnel Vision"
      size={PopupSizes.LARGE}
      footer={null}
      onClose={() => setIsOpen(false)}
      // onSuccess={() => setIsOpen(false)}
    >
      <div className="flex items-center pb-5 px-5">
        <div>
          <Title>
            Upload a photo or sketch of your {unitName.toLowerCase()}.
          </Title>
          <div className="my-4">
            {!isLoading && <FileInput onChange={handleImageUpload} />}
            {isLoading && imageData && (
              <>
                {/* <img
                  src={imageData}
                  alt="Uploaded Image"
                  className="w-full h-auto absolute z-0"
                /> */}
                <div className="p-2 px-4">
                  <Loading type="gallery" />
                </div>
              </>
            )}
          </div>
          <div className="text-center text-gray-500">
            Ready to bring your vision to life? Upload a photo of your
            hand-drawn funnel sketch here. Funnel Vision's A.I. will transform
            it into a fully functional, profit-ready marketing campaign in
            seconds. Just snap, upload, and launch!
          </div>
        </div>
      </div>
    </Modal>
  );
};

