import { Barcode, Receipt, ShoppingCartSimple } from '@phosphor-icons/react';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Products } from "./Products";
import { Dashboard as ProductDashboard } from './Products/Dashboard';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("f$-internal")) return [];

    return [
      {
        icon: <Barcode className="text-lg" />,
        label: "Products",
        href: "/products",
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("f$-internal")) return [];

    return [
      {
        label: "Products",
        path: "/products",
        element: <Products name="Products" />,
        children: [
          {
            path: ":id",
            element: <ProductDashboard name="Products" />,
          },
        ],
      },     
    ];
  }
};