import {
  BugIcon,
  PersonIcon,
  SignalIcon
} from "@/ui/Icons";

interface NotificationItem {
  title: string;
  icon: JSX.Element;
  caption: string;
}

interface ContactItem {
  name: string;
  avatar: string;
}

interface ActivityItem {
  icon: string;
  title: string;
  caption: string;
}

// Define an array of notification objects
export const notificationsList: NotificationItem[] = [
  {
    title: "You have a bug that needs to be fixed.",
    icon: <BugIcon />,
    caption: "5m ago",
  },
  {
    title: "New user registered",
    icon: <PersonIcon />,
    caption: "1:23 AM",
  },
  {
    title: "You have a bug that needs to be fixed.",
    icon: <BugIcon />,
    caption: "0:32 AM",
  },
  {
    title: "Another notification",
    icon: <SignalIcon />,
    caption: "Some caption",
  },
];

// Define an array of contact objects
export const contactsList: ContactItem[] = [
  {
    name: "Natali Craig",
    avatar: "/assets/images/avatar-6.png",
  },
  {
    name: "Drew Cano",
    avatar: "/assets/images/avatar-7.png",
  },
  {
    name: "Orlando Diggs",
    avatar: "/assets/images/avatar-8.png",
  },
  {
    name: "Andi Lane",
    avatar: "/assets/images/avatar-9.png",
  },
  {
    name: "Kate Morrison",
    avatar: "/assets/images/avatar-10.png",
  },
  {
    name: "Koray Okumus",
    avatar: "/assets/images/avatar-11.png",
  },
];

// Define an array of activity objects
export const activitiesList: ActivityItem[] = [
  {
    icon: "/assets/images/avatar-1.png",
    title: "Edited the details of Project X",
    caption: "5m ago",
  },
  {
    icon: "/assets/images/avatar-2.png",
    title: "Released a new version",
    caption: "1:23 AM",
  },
  {
    icon: "/assets/images/avatar-3.png",
    title: "Posted a bug",
    caption: "Yesterday 12:39 AM",
  },
  {
    icon: "/assets/images/avatar-4.png",
    title: "Modified A data in Page X",
    caption: "Last Thursday 3:34 AM",
  },
  {
    icon: "/assets/images/avatar-5.png",
    title: "Deleted a page in Project X",
    caption: "Aug 11",
  },
];