import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign, initializeWorkflow } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { CampaignBuilder } from "../Funnels";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "builder");
  const [workflowData, setWorkflowData] = useState<{ name?: string }>({
    name: "",
  });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);

  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setWorkflowData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/workflows" },
      { label: workflowData.name || "" },
    ],
    items: (
      <Button
        label="Publish Workflow"
        onClick={() => initializeWorkflow(id)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };


  return (
    <Page topBar={topBarSettings}>
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Visual Workflow Builder", value: "builder" },
              // { label: "Activity", value: "crm" },
              { label: "Contacts", value: "contacts" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "builder" && (
            <div className="h-[calc(100vh-174px)]">
              <CampaignBuilder
                id={id}
                campaignData={workflowData}
                direction="vertical"
              />
            </div>
          )}
          {activeTab === "crm" && (
            <DashboardPage
              campaignData={workflowData}
              unitName="workflow"
              widgets={[
                {
                  type: "kpi",
                  settings: {
                    items: [
                      { label: "Clicks", number: 30 },
                      { label: "Sales", number: 5 },
                      { label: "Commissions", number: "$110.00" },
                    ],
                  },
                },
                "spending",
              ]}
            />
          )}
          {activeTab === "contacts" && (
            <DashboardPage
              campaignData={workflowData}
              unitName="workflow"
              widgets={["contactList"]}
            />
          )}
          {activeTab === "settings" && (
            <Settings
              campaignData={workflowData}
              unitName="Workflow"
              widgets={["name", "delete"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
