import { RadioProps } from "./types";

// Define the Radio component as a functional component that takes in props of type RadioProps
export const Radio: React.FC<RadioProps> = ({
  className,
  label,
  checked,
  disabled,
  value,
  name,
  onChange,
}) => {
  // Render the component
  return (
    // Use a label element to wrap the radio button and its label
    <label data-testid="Radio" className={`flex items-center ${className}`}>
      {/* Render the radio button as an input element */}
      <input
        type="checkbox"
        className="form-radio"
        disabled={disabled}
        checked={checked}
        value={value}
        name={name}
        onChange={onChange}
      />
      {/* Render the label text */}
      <span>{label}</span>
    </label>
  );
};
