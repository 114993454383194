import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { useRef, useState } from "react";
import { TableView } from "@/ui/DataView/TableView";
// import { items } from "@/ui/DataView/TableView/TableView.stories";
import { DefaultDataProps } from "@/ui/DataView/types";
import { Loading } from "@/ui/Layout/Loading";
import { ButtonTypes } from "@/ui/types";
import { EmptyState } from "@/ui/Layout";
import Button from "@/ui/Button";
import { FilterBar } from "@/ui/DataView/components/FilterBar/FilterBar";
import { CalendarBlank, FilePdf } from "@phosphor-icons/react";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

const items: DefaultDataProps[] = [...Array(4)].map((_, i) => ({
  id: "header",
  label: "Project tech requirements.pdf",
  icon: (
    <div className="w-6 h-6 p-1 bg-lightpurple-100 flex-none rounded-lg text-black text-center mr-2">
      <FilePdf width="16" height="16" />
    </div>
  ),
  data: [
    { id: "size", label: "File Size", fieldType: "number", value: "5.6 MB" },
    {
      id: "type",
      label: "Uploader",
      fieldType: "string",
      value: "Karina Clark",
    },
    {
      id: "date",
      label: "Upload Time",
      fieldType: "date",
      icon: (
        <CalendarBlank width="16" height="16" className="dark:text-white/40" />
      ),
      value: "Today, 19:40",
    },
  ],
}));

export const UploadItemButton = (props) => {
  const fileInput = useRef(null);

  const handleButtonClick = () => {
    // trigger the click event of the file input
    fileInput.current.click();
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0]); // log the selected file
  };

  return (
    <div>
      <Button
        onClick={handleButtonClick}
        label="+ Upload a File"
        type={ButtonTypes.OUTLINED}
        {...props}
      />
      <input
        type="file"
        ref={fileInput}
        onChange={handleFileChange}
        style={{ display: "none" }} // hide the default file input
      />
    </div>
  );
};

export const Media = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "all");
  const [mediaItems, setMediaItems] = useState<DefaultDataProps[]>(items);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Page topBar={{ page: [{ label: name }], items: <UploadItemButton /> }}>
      <div className={`p-6 w-full h-full`}>
        <div className="tabs flex flex-col h-full">
          <TabsComponent
            tabs={[
              { label: "All Media", value: "all" },
              { label: "Images", value: "images" },
              { label: "Videos", value: "videos" },
              { label: "Documents", value: "documents" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />

          {isLoading && <Loading type="list" />}
          {!isLoading && !Boolean(mediaItems.length) && (
            <EmptyState
              title="No Items Yet"
              description="Upload your first item to get started."
              showImage={false}
            >
              <Button label="Upload" type={ButtonTypes.DEFAULT} />
            </EmptyState>
          )}

          {!isLoading && Boolean(mediaItems.length) && activeTab === "all" && (
            <div>
              <FilterBar
                fields={[]}
                onAddClick={() => console.log("on Add Click")}
              />
              <TableView
                items={mediaItems}
                showFooterPagination
                showMenuItems
                menuItems={[
                  { id: "download", label: "Download File" },
                  { id: "delete", label: "Delete" },
                ]}
                onCellClick={console.log}
                onCellDoubleClick={console.log}
                onMenuItemClick={(item) => console.log(item)}
                onNewItemClick={() => console.log("New item clicked")}
              />
            </div>
          )}
          {!isLoading &&
            Boolean(mediaItems.length) &&
            activeTab === "images" && <div>Images</div>}
          {!isLoading &&
            Boolean(mediaItems.length) &&
            activeTab === "videos" && <div>Videos</div>}
          {!isLoading &&
            Boolean(mediaItems.length) &&
            activeTab === "documents" && <div>Documents</div>}
        </div>
      </div>
    </Page>
  );
};
