import { TitleCardProps } from "./types";

export const TitleCard: React.FC<TitleCardProps> = ({
  title,
  children,
  cardChips,
  CardActions,
  icon = "/assets/images/byewind-avatar.png",
  // sharedWith,
  className,
  onClick,
}) => {
  return (
    <div
      data-testid="TitleCard"
      className={`bg-lightwhite dark:bg-white/5 rounded-2xl p-3 sm:p-6 ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-wrap items-center justify-between gap-3 mb-6">
        {title &&
          (typeof title === "string" ? (
            <div className="flex items-center gap-4 cursor-pointer">
              {typeof icon === "string" ? (
                <img
                  className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                  src={String(icon)}
                  alt=""
                />
              ) : (
                icon
              )}
              <p className="text-lg font-semibold text-black dark:text-white">
                {title}
              </p>
            </div>
          ) : (
            title
          ))}
        {CardActions && (
          <div data-testid="CardActions" className="flex gap-2 items-center">
            {CardActions}
          </div>
        )}
      </div>

      {cardChips && (
        <ul
          data-testid="ChipList"
          className="flex flex-wrap gap-4 items-center mb-4"
        >
          {cardChips.map((chip, index) => (
            <a
              key={index}
              className="flex items-center gap-1 text-xs text-black/40 dark:text-white/40"
              href={chip.href}
            >
              {chip.icon}
              <p>{chip.label}</p>
            </a>
          ))}
        </ul>
      )}

      {/* {sharedWith && (
        <div data-testid="SharedWith" className="shared-with">
          {sharedWith.map((avatar, index) => (
            <div key={index}>{avatar}</div>
          ))}
        </div>
      )} */}
      {children}
    </div>
  );
};

export default TitleCard;
