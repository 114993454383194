import { CSSProperties } from "react";
import { fieldset } from "@/ui/theme";

const style: {
  fieldset: CSSProperties;
  switchLabel: CSSProperties;
  btnContainer: CSSProperties;
  sliderContainer: CSSProperties;
  label: CSSProperties;
} = {
  fieldset,
  switchLabel: { position: "relative", padding: 0, top: 0 },
  btnContainer: { width: 30 },
  sliderContainer: { width: 190, margin: 10, marginLeft: 15 },
  label: {
    display: "block",
    margin: "11px 0",
    backgroundColor: "#EEE",
    borderRadius: 5,
    height: "-webkit-fill-available",
    padding: "5px 10px",
    fontSize: "9pt",
    textShadow: "1px 1px white",
    color: "#666",
    fontWeight: "bold",
  },
};

export default style;
