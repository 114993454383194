import { CSSProperties } from "react";

export const labelStyle: CSSProperties = {
  width: "100%",
  padding: 3,
  backgroundColor: "transparent",
  textAlign: "center",
  border: "none",
  overflow: "hidden",
  resize: "none",
  outline: "none",
  fontSize: 11,
  lineHeight: 1.3,
  height: "auto",
};
