import { Page, Container } from '../Page';

export const Charts: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Charts" }] }}>
			<Container>
				<p className="text-lg font-semibold">Charts</p>
			</Container>
		</Page>
	);
};
