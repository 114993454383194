// const { VITE_API_ENDPOINT } = import.meta.env;

import { API_DOMAIN } from "@/env";

export default {
  serverUrl: `${API_DOMAIN}:48081`,
  // serverUrl: `http://localhost:8081`,
  prospectUrl: {
    limelight: "/api/limelightProspect",
    mailChimp: "/api/mailChimpProspect",
    getresponse: "/api/getresponseProspect",
    icontact: "/api/icontactProspect",
    sendreach: "/api/sendreachProspect",
    activecampaign: "/api/activecampaignProspect",
    convertkit: "/api/convertkitProspect",
    drip: "/api/dripProspect",
    ontraport: "/api/ontraportProspect"
  },
  orderUrl: {
    limelight: "/api/limelightOrder",
    stripe: "/api/stripeOrder",
    authorize: "/api/authorizeOrder",
    paypal: "/api/paypalOrder"
  },
  upsellUrl: {
    limelight: "/api/limelightUpsell",
    stripe: "/api/stripeUpsell",
    authorize: "/api/authorizeUpsell",
    paypal: "/api/paypalUpsell"
  }
};

// export default module.exports;