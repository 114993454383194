import * as React from "react";
import { get } from "lodash";
import styled from "styled-components";

// import { Inpt } from "./TextField";
import { SProps } from "../../LiveFormField/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";

const Inpt = styled.input<SProps>`
  &::placeholder {
    color: ${(p) => get(p.inputStyle, "color", "inherit")};
  }
  box-sizing: border-box;
`;

/**
 * A component that shows a LIVE text input field or tools for configuring the text input field's look, feel, and behavior
 */
export function LiveTextField(props) {
  const {
    onBlur,
    setValue = () => null,
    handleChange = () => null,
    onChange = () => null,
    styles = {}
  } = props;

  const {
    labelStyle = {},
    descriptionStyle = {},
    iconStyle = {},
    inputStyle = {},
    containerStyle = {}
  } = styles;
  // 
  return (<div data-testid="FormBuilder-FormFields-TextField-LIVE">
    {/* {JSON.stringify(props)} */}
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {props.icon && <span style={{
      position: "absolute",
      paddingTop: get(iconStyle, 'padding', 0)
    }} ref={props.ref}>
      <Icon type={props.icon} style={{
        ...iconStyle,
        marginTop: 5,
        marginLeft: 1,
        paddingTop: 0
      }} />
    </span>}
    <Inpt id={`${props.id}_input`} type={props.formFieldType} name={props.name} placeholder={props.placeholder} style={{
      ...inputStyle,
      ...(props.icon ? {
        padding: `${props.inputPadding} ${props.inputPadding} ${props.inputPadding} ${get(props.iconDimensions, 'width')}px`
      } : {})
    }} value={props.defaultValue || props.value} disabled={props.disabled} onChange={e => {
      setValue(e.target.value);
      handleChange(e);
      onChange(e);
    }} onBlur={onBlur} />
  </div>);
}

export default LiveTextField;