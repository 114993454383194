import { useState } from 'react';
import { useParams } from "react-router-dom";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from '@/ui/TabsComponent';
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

export const MediaView = ({ name = ""}) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "general");
  const { id } = useParams();
  
  return (
    <Page
      topBar={{
        page: [{ label: name, href: "/media" }, { label: "Media Item" }],
      }}
    >
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[{ label: "General Info", value: "general" }]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "general" && <div>Your Content</div>}
        </div>
      </div>
    </Page>
  );
};
