import { pages } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";
import { getIconData } from "../utils";

export const shoppingCartNodes = ({
  position,
  campaignData,
  nodes,
  setNodes,
}) => [
  {
    label: "Shopping Cart",
    items: pages
      .filter(({ type }) => type === "cart")
      .map((page) => {
        const { Icon, imageSrc, color } = getIconData(
          { ...page, page: { type: page?.pageType } },
          false
        );
        return {
          label: getNodeMenuLabelComponent(page.name),
          icon: getNodeIconComponent({
            // Icon,
            imageSrc,
            imageStyle: { width: 235 / 5, height: 176 / 5 },
            color,
            // iconStyle: { fontSize: "10pt" },
            containerStyle: { padding: 0 },
          }),
          onClick: () => {
            handleCreateNewPageFromTemplate({
              position,
              campaignData,
              page,
              setNodes,
              nodes,
            });
          },
        };
      }),
  },
];
