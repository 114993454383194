import { Panel } from "@/ui/Panel";
import { Calout } from "@/ui/Calout";

export const EnablePasswordProtection = ({
  unitName = "Funnel",
  campaignData = {},
}) => {
  
  return (
    <>
      <Panel
        title={`Enable password protection?`}        
      >
        <div className="flex items-start w-full mb-5">
          <Calout
            title={`Password protection enabled`}
            caption={`This will require your contacts to enter their password before they can access your ${unitName.toLowerCase()}.`}
          />
        </div>

        <div className="flex items-start pl-1">
          <input
            id="deleteBox"
            type="checkbox"
            // checked={isDeleteCheckboxChecked}
            // onChange={() =>
              // setIsDeleteCheckboxChecked(!isDeleteCheckboxChecked)
            // }
            className="w-[18px] h-[18px] text-black bg-white border-black/20 rounded dark:bg-transparent dark:border-white/20 dark:text-white/20 focus:ring-0 focus:outline-0 focus:outline-offset-0 focus:ring-offset-0"
          />
          <label htmlFor="deleteBox" className="ml-3 cursor-pointer">
            Yes, enable password protection throughout this {unitName.toLowerCase()}
          </label>
        </div>
      </Panel>
    </>
  );
};
