import { Page, Container } from '../Page';

import { Button } from "@/ui/Button";
import { SimpleContainer } from "@/ui/SimpleContainer";
import { ProjectWidget } from "@/ui/Card/ProjectWidget";

import {
	ButtonTypes,
	Status,
	Variants,
} from "@/ui/types";
import { Panel } from '@/ui/Panel';
import { ListDisplayGroup } from '@/ui/ListDisplayGroup';
import { Grid } from '@/ui/Grid';
import { Card } from '@/ui/Card';
import { ListDropdownItem } from '@/ui/ListDropdown/types';

export const Components: React.FC = () => {

	const image = [
		<img
			className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
			src="/assets/images/dropbox.png"
			alt=""
		/>,
		<img
			className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
			src="/assets/images/badoo.png"
			alt=""
		/>,
		<img
			className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
			src="/assets/images/invision.png"
			alt=""
		/>,
	];

	const logo = (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
				fill="currentcolor"
			/>
		</svg>
	);

	const dropdownItems:ListDropdownItem[] = [
		{ label: "Edit Page", href: "#", onClick: console.log },
		{ label: "View Published Page", href: "#", onClick: console.log },
		{ label: "Rename Page", href: "#", onClick: console.log },
		{ type: "divider" },
		{ label: "Move Page Up", href: "#", onClick: console.log },
		{ label: "Move Page Down", href: "#", onClick: console.log },
		{ type: "divider" },
		{ label: "Make a Copy", href: "#", onClick: console.log },
		{ label: "Change the Page URL (Slug)", href: "#", onClick: console.log },
		{ type: "divider" },
		{ label: "Make this my Homepage", href: "#", onClick: console.log },
		{ label: "Make this my 404 (Error) Page", href: "#", onClick: console.log },
		{ type: "divider" },
		{ label: "Delete Page", href: "#", onClick: console.log },
	];

	const listDisplayGroupItems = [
		{
			id: "123",
			image: "/assets/images/product-1.png",
			title:
				"Hanes Women’s Perfect-T Long Sleeve T-shirt Long Sleeve T-shirt",
			caption: "To: Jason Bourne",
			status: { caption: "In Progress", type: Variants.PRIMARY },
		},
		{
			id: "456",
			image: "/assets/images/product-2.png",
			title:
				"Timeson Women's V Neck Short Sleeve Curved Hem Sheer Chiffon Blouse Shirts Tops",
			caption: "To: Marie Durant",
			status: { caption: "Pending", type: Variants.SECONDARY },
		},
		{
			id: "789",
			image: "/assets/images/product-3.png",
			title:
				"Timeson Women's V Neck Short Sleeve Curved Hem Sheer Chiffon Blouse Shirts Tops",
			caption: "To: Dan Wilson",
			status: { caption: "Approved", type: Variants.SUCCESS },
		},
		{
			id: "101112",
			image: "/assets/images/product-4.png",
			title:
				"SIVVAN Scrubs for Women - Long Sleeve Comfort Underscrub Tee",
			caption: "To: Jason Bourne",
			status: { caption: "In Progress", type: Variants.INFO },
		},
	]

	const listDisplayGroupFooter = {
		caption: "Click to add a new page",
		actionComponent: (
			<Button
				onClick={() => null}
				type={ButtonTypes.SOFT}
				variant={Variants.PRIMARY}
				label="+ Add Page"
			/>
		),
	}

	return (
    <Page topbar={{ page: [{ label: "Components" }] }}>
      <Container>
        <p className="text-lg font-semibold">Components</p>

        <div className="grid gap-3">
          {/* Project Label */}
          <a name="projects" />
          <SimpleContainer label="Project Widget">
            <Grid>
              <Card
                type="Task"
                label="Poster illustration design"
                caption="Due Date: Nov 10, 2022"
                image={image[0]}
                status={Status.INPROGRESS}
                sharedWith={logo}
                taskDetails={{
                  completedNum: 13,
                  totalNum: 49,
                }}
              />
              <Card
                type="Task"
                label="Coffee detail page - Main Page"
                caption="Due Date: June 20, 2022"
                image={image[1]}
                status={Status.PENDING}
                sharedWith={logo}
                taskDetails={{
                  completedNum: 56,
                  totalNum: 56,
                }}
              />
              <Card
                type="Task"
                label="Drinking bottle graphics"
                caption="Due Date: Nov 10, 2022"
                image={image[2]}
                status={Status.COMPLETED}
                sharedWith={logo}
                taskDetails={{
                  completedNum: 16,
                  totalNum: 65,
                }}
              />
            </Grid>
          </SimpleContainer>

          {/* List Display */}
          <a name="list_display" />
          <SimpleContainer label="List Display">
            <Panel title="Pages in this funnel">
              <ListDisplayGroup
                label="Pages in this funnel"
                caption="Funnel Settings"
                items={listDisplayGroupItems}
                footer={listDisplayGroupFooter}
                menuItems={dropdownItems}
              />
            </Panel>
          </SimpleContainer>
        </div>
      </Container>
    </Page>
  );
};
