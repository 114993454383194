import { useEffect, useState } from "react";
import { ContactList } from "@/ui/ContactList";
import { LegendDetailsProps } from "@/ui/ContactList/types";
import { TitleCardProps } from "@/ui/TitleCard/types";
import { Buildings, MapPin, Phone, UserCircle } from "@phosphor-icons/react";
import { Button } from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Field } from "react-querybuilder";
import { createContact, deleteContact, getContacts, getContactsCount } from "@/api/crm";
import { getSessionToken } from "@/api/auth";
import { getPlainEnglishOperators } from "../Funnels/CampaignBuilder/Nodes/SmartRouter/fields";
import fieldNames from "./fieldNames";
import { translateFieldType } from "./CRM";

export const ContactsGallery = ({ defaultFilter = {} }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<TitleCardProps[]>([]);
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState<number>();
  const [fields, setFields] = useState<Field[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentFilter, setCurrentFilter] = useState(defaultFilter);

  const fetchData = async (filter = defaultFilter) => {
    const startIndex = (currentPage - 1) * maxItemsPerPage;
    const endIndex = startIndex + maxItemsPerPage - 1;

    console.log("fetching data", { startIndex, endIndex, filter });
    setCurrentFilter(filter);

    try {
      const token = getSessionToken();
      const data =
        (await getContacts({
          startIndex,
          endIndex,
          filter,
          token,
        })) || [];

      // console.log("data returned", data);

      if (!data) console.log("there was an error", data);

      const transformedData = data.map((item) => {
        return {
          id: item.id,
          title: item.title || `${item.firstName} ${item.lastName}`,
          icon: <UserCircle size={24} />,
          // cardChips: [
          //   { icon: <Buildings />, label: item.churchName },
          //   { icon: <MapPin />, label: item.address },
          //   { icon: <Phone />, label: item.phone },            
          // ],
          cardChips: [
            ...Boolean(item?.churchName) ? [{ icon: <Buildings />, label: item.churchName }] : [],
            ...Boolean(item?.address) ? [{ icon: <MapPin />, label: item.address }] : [],
            ...Boolean(item?.phone) ? [{ icon: <Phone />, label: item.phone }] : [],
          ],
          CardActions: (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  // navigate(`/crm/contacts/${item.id}`);
                  window.location.href = `/crm/contacts/${item.id}`;
                }}
                type={ButtonTypes.SOFT}
                label="Open"
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(item);
                }}
                type={ButtonTypes.SOFT}
                label="Delete"
              />
            </>
          ),
          // children: <KPISimpleGroup items={kpiItems} />,
        };
      });

      console.log("transformedData", transformedData);
      setData(transformedData);

      // get the total number of items
      const count = await getContactsCount({ token, filter });

      // calculate the total number of pages
      const totalPages = Math.ceil(count / maxItemsPerPage);
      setTotalPages(totalPages);

      // get the fields
      const defaultFields = fieldNames
        .filter((field) => !field.label.includes("Credit"))
        .map((field) => {
          const { label, fieldType, attributes } = field;
          return {
            name: attributes.name,
            label,
            inputType: translateFieldType(fieldType),
            operators: getPlainEnglishOperators(translateFieldType(fieldType)),
          };
        });

      setFields(defaultFields);
    } catch (error) {
      console.log("error", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(defaultFilter);
  }, [currentPage]);

  const getCurrentFilterKeyValuePairs = () => { 
    if (currentFilter?.rules?.length) {
      // [To Do] filter out any rules that you don't want

      return currentFilter.rules.reduce((acc, rule) => {
        acc[rule.field] = rule.value;
        return acc;
      }, {});
    }

    return {} 
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleLegendItemClick = (item: LegendDetailsProps) => {
    console.log(`Clicked`, item);
    window.location.href = `/crm/contacts/${item.id}`;
  };

  const handleItemClick = (item: TitleCardProps) => {
    // console.log(`Clicked`, item);
    window.location.href = `/crm/contacts/${item.id}`;
  };

  const handleDelete = async (item) => {
    // ask if they're sure?
    const confirm = window.confirm(
      `Are you sure you want to delete ${item.title || 'this contact'}?`
    );

    // delete from db if they say yes
    if (confirm) {
      const token = await getSessionToken();
      await deleteContact(item.id, { token });

      // refresh data
      await fetchData();
    }
  };

  const handleCreate = async () => {
    // if (onCreate) return onCreate();

    // const { id } = await createData();
    const token = getSessionToken();
    let contact = {
      // title: "New Contact",
      firstName: "New",
      lastName: "Contact",
      ...getCurrentFilterKeyValuePairs(),
    };

    // modify for hidden tags (so that its value is comma separated)
    if (contact?.hiddenTags) contact.hiddenTags = `["${contact.hiddenTags}"]`;

    console.log("contact", contact);

    const { id } = await createContact(contact, { token });
    console.log("id", id);
    // handleItemClick({ id });
    if (id) {
      window.location.href = `/crm/contacts/${id}`;
    } else {
      alert("There was an error creating the contact");
    }

  };

  return (
    <div>
      <ContactList
        showLegend
        legendDetails={data.map(({ title, icon, id }) => ({ title, icon, id }))}
        onLegendItemClick={handleLegendItemClick}
        // onSearch={handleSearch}
        items={data}
        onItemClick={handleItemClick}
        paginationDetails={{
          showFirstLastButtons: true,
          showPrevNextButtons: true,
          currentPage,
          totalPages,
          onPageChange: handlePageChange,
        }}
        withFilterBar
        filterBarSettings={{
          withAddButton: true,
          withSearch: false,
          withSort: false,
          withFilter: true,
          withMoreMenu: true,
          moreItems: [
            {
              id: "import",
              label: "Import Contacts",
              onClick: () => alert("This feature is coming soon!  Please contact support for help importing your data (free of charge)."),
            },
            {
              id: "export",
              label: "Export Contacts",
              onClick: () => alert("This feature is coming soon!  Please contact support for help exporting your data (free of charge)."),
            },                
          ],
          onAddClick: handleCreate,
          fields,
          onFilterChange: async (filter) => {
            console.log("filter", filter);
            fetchData(filter);
          },
        }}
        isLoading={isLoading}
      />
    </div>
  );
};
