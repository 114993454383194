import { getSessionToken } from "@/api/auth";
import { createObject, getCampaign, getObject, updateObject } from "@/api/campaigns";
import { Page } from "@/ui/Layout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CreateContentPopup } from "../Content/ContentDashboardPage";
import { Container } from "@/ui/Layout/Page";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

export const handleContentSave = async (objectData, campaignId) => {
  const item = objectData;

  // console.log("item", item);

  // save to db
  const token = getSessionToken();

  if (!item?.id) {
    console.log("create object", item?.name, item?.settings);
    // create object
    await createObject({
      name: item?.name,
      x: 0, // calculate this
      y: 0,
      type: "ContentComponent",
      settings: { name: item?.name, withAI: false, ...item?.settings },
      campaignId,
    });
  } else {
    const payload = {
      token,
      name: item?.name,
      settings: item?.settings,
    };
    console.log({ payload });
    await updateObject(item?.id, payload);
  }

  alert("Saved!");
  window.location.reload();
};

export const ContentEditor = () => {
  // const [packageData, setPackageData] = useState({});
  const [packageName, setPackageName] = useState("•••");
  const [objectData, setObjectData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const { packageId, objectId } = useParams();

  useEffect(() => {
    const loadData = async () => {
      const token = getSessionToken();

      const data = await getCampaign({ token, id: packageId });

      // const pgData = data?.objects?.find(
      //   (obj: any) => obj.id === objectId
      // )?.page;
      // const pgId = pgData?.id;

      const object = await getObject(objectId);
      console.log("object", object);
      // setPackageData(data);
      setPackageName(data?.name);
      // setObjectName(data?.objects?.find((obj: any) => obj.id === objectId)?.name);
      setObjectData(object?.data?.object);
      // setPageId(pgId);

      // setUrl(`https://${data?.domain?.name}/${pgData?.slug}`);

      // alert("Page Loaded!")
      setIsLoading(false);
    };

    loadData();
  }, []);

  return (
    <Page
      topBar={{
        page: [
          { label: "Content Packages", href: "/content" },
          { label: packageName, href: `/content/${packageId}` },
          {
            label: objectData?.name,
            href: `/content/${packageId}/${objectId}`,
          },
        ],
        items: (
          <button className="btn btn-info" onClick={() => handleContentSave(objectData, packageId)}>
            Save
          </button>
        ),
      }}
    >
      <Container>
        {isLoading ? (
          "Loading..."
        ) : (
          <>
            <div className="p-3">  
            <Button
              label="<- Back to Dashboard"
              type={ButtonTypes.SOFT}
              onClick={() => window.history.back()}
            />
            </div>
            <CreateContentPopup
              activeObject={objectData}
              campaignId={packageId}
              setIsCreateContentPopupOpen={() => {}}
              unitName="Post"
              withModal={false}
              onChange={setObjectData}              
            />
          </>
        )}
      </Container>
    </Page>
  );
}