import { PartnerPortals } from "./PartnerPortals";
import { Dashboard } from "./Dashboard";
import { Article } from "@phosphor-icons/react";

import { HookObject, ListItemsHooks } from "@/plugins/types";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("partnerPortal")) return [];
    
    return [
      {
        label: "Partner Portal",
        icon: <Article className="text-lg" />,
        href: "/portals",
        order: 9,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("partnerPortal")) return [];

    return [
      {
        path: "/portals",
        element: <PartnerPortals name="Partner Portal" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Partner Portal" />,
          },
        ],
      },
    ];
  }
};

export { PartnerPortals };
