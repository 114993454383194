import React from "react";
import { get } from "lodash";

import {
  convertToRem,
  convertFromRem,
} from "@/Apps/Pages/MobileResponsiveToggle/style";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";

import { ButtonSliderGroup, W } from "../BuildingBlocks/ButtonSliderGroup/ButtonSliderGroup";

import { MarginProps } from "./types";
// import { ComponentTypes, ISettings } from "../../WebComponent/types";

import { Minus, Plus } from "@phosphor-icons/react";
import { ComponentTypes } from "../../Editor/types";
/**
 * Creates a Button Slider Group to Change the Components Margin
 */

const Margin: React.FC<MarginProps> = ({
  onChange = () => null,
  settings = { id: "", type: ComponentTypes.UNKNOWN, properties: {} },
  minValue = -250,
  maxValue = 250,
  updateComponentStyle = () => null,
  getMobileState = () => ({
    type: MobileState.FULLSCREEN,
  }),
  useRem = true,
}) => {
  const currentMarginTop = get(settings, "properties.marginTop", 0);
  // const currentPositionTop = get(settings, "properties.top", 0);

  // send raw pixel value to the button slider even if input value is in rem
  const convertedValue = convertFromRem(
    currentMarginTop,
    getMobileState()["type"]
  );

  const handleChange = (value, shouldDbUpdate = true, wait = 100) => {
    const mTop = Number(convertedValue);

    const mTopOffset = value === "UP" ? mTop - 10 : mTop + 10;
    const newMarginTop = Number.isInteger(value) ? value : mTopOffset;

    // convert the raw pixel value to rem before saving it the db
    const newValue = useRem
      ? convertToRem(newMarginTop, getMobileState()["type"])
      : newMarginTop;

    const newStyle = {
      marginTop: newValue,
    };

    updateComponentStyle(settings.id, newStyle, shouldDbUpdate, wait);

    onChange();
  };

  return (
    <div data-testid="Properties-Margin">
      <ButtonSliderGroup
        onChange={handleChange}
        text="Position"
        button1={{ icon: <Minus />, value: "SHRINK" }}
        button2={{ icon: <Plus />, value: "GROW" }}
        minValue={minValue}
        maxValue={maxValue}
        value={convertedValue}
      />
    </div>
  );
};

export default Margin;
