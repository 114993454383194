import PropTypes from "prop-types";
import { get } from "lodash";
import styled from "styled-components";
import ExpansionPanel from "@/ui/_old/ExpansionPanel";
import SectionWrapper from "./components/sections/SectionWrapper";

const SBG = styled.div`
  background-color: #eee;
  position: absolute;
  width: 350px;
  margin: 5px;
  height: 65px;
  z-index: 0;
  cursor: pointer;
`;

const Section = (props) => {
  const { settings, label } = props;

  const sessionKey = get(settings, "type", false)
    ? `section_expansion_${label
      .toLowerCase()
      .replace(/ /g, "")}_${settings.type.toLowerCase()}`
    : false;

  const handleExpandToggle = (isExpanded) => {
    if (sessionKey) {
      sessionStorage.setItem(sessionKey, isExpanded);
    }
  };

  let isExpanded = false;
  const sessionStorageValue = sessionStorage.getItem(sessionKey);
  if (sessionStorageValue) {
    isExpanded = sessionStorageValue === "true";
  } else {
    isExpanded = props.isExpanded;
  }

  // return (
  //   <div style={{ width: "100%" }}>
  //     {props.hideBg || props.hideLabel ? null : <SBG />}
  //     {!props.hideLabel ? (
  //       <ExpansionPanel
  //         footer={null}
  //         {...props}
  //         style={{ ...props.style, width: "100%" }}
  //         isExpanded={isExpanded}
  //         onExpandToggle={handleExpandToggle}
  //       >
  //         {props.children}
  //       </ExpansionPanel>
  //     ) : (
  //       props.children
  //     )}
  //   </div>
  // );

  return (
    <div className="w-full">
      {!props.hideLabel ? (
        <SectionWrapper
          isExpanded={isExpanded}
          onExpand={() => handleExpandToggle(true)}
          onCollapse={() => handleExpandToggle(false)}
          label={label}
        >
          {props.children}
        </SectionWrapper>
      ) : (
        props.children
      )}
    </div>
  );
};

Section.propTypes = {
  /** Which component to show/hide when button is toggled */
  // title: PropTypes.string.isRequired,
  /** Bool if the title and collapse should be shown or not. */
  showTitle: PropTypes.bool.isRequired,
  /** Bool if the section should be collapsed or expanded */
  isExpanded: PropTypes.bool.isRequired,
};

Section.defaultProps = {
  // title: false,
  showTitle: true,
  isExpanded: false,
  hideBg: false,
  hideLabel: false,
};

export default Section;
