import Panel from "@/ui/Panel"
import { BlueprintIcon, BlueprintList } from "../BlueprintList"
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSessionToken } from "@/api/auth";
import { getCampaign, getCampaigns, updateCampaign } from "@/api/campaigns";
import Calout from "@/ui/Calout";
import { Switch } from "@/ui/Switch";
import { blueprintProfiles } from "../data";

export const ChooseProfile = ({id, campaignData = {}, unitName = "project"}) => {

  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  useEffect(() => {
    const loadProfiles = async () => {
      const token = getSessionToken();
      const profiles = await getCampaigns({ token, type: "planning" });
      setAllProfiles(profiles);

      const plannerProfiles = campaignData?.settings?.plannerProfiles || [];
      setSelectedProfiles(plannerProfiles);
    }

    loadProfiles();

  }, []);
  
  const handleProfileToggle = async (id, checked) => {
    const campaignId = campaignData?.id;
    console.log({ campaignId, campaignData, id, checked });

    const token = getSessionToken();
    const plannerProfiles = checked
      ? [...selectedProfiles, id]
      : selectedProfiles.filter((profileId) => profileId !== id);

    setSelectedProfiles(plannerProfiles);
    console.log({ plannerProfiles });

    const response = await updateCampaign(campaignId, {
      token,
      settings: {
        settings: {
          ...campaignData?.settings || {},
          plannerProfiles,
        }
      },
    });

    console.log("response", response);
  }

  return (
    <Panel
      title="Smart Planner Profiles"
      actionButton={
        <Link to="/planner">
          <Button
            // onClick={() => setIsDomainPopupOpen(true)}
            type={ButtonTypes.SOFT}
            label="+ Create a new profile"
          />
        </Link>
      }
    >
      <Calout
        title={`Choose the Smart Planner Profiles you want to use for this ${unitName.toLowerCase()}.`}
      />
      <div className="mt-7" />
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3"
      >
        {allProfiles.filter(({deleted}) => !deleted).map((profile, idx) => {
          const isActive = selectedProfiles.includes(profile.id);

          let Icon = (
            <img
              className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
              src="https://flowbite.com/docs/images/logo.svg"
              alt=""
            />
          );
          if (profile?.settings?.type) {
            const iconData = blueprintProfiles.filter((itm) => itm.id === profile?.settings?.type)[0];
            Icon = <BlueprintIcon {...iconData} />;
          }

          return (
            <li 
              key={idx} 
              className="flex gap-4" 
              title={profile.description}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleProfileToggle(profile.id, !isActive);
              }}
            >
              <div
                className={`relative flex items-center space-x-4 rounded-xl p-3 hover:bg-gray-100 dark:hover:bg-gray-800 w-full h-full cursor-pointer
              `}
              >
                {Icon}
                <div className="flex-1 flex items-center justify-between gap-4">
                  <div>
                    <p className="text-sm font-semibold">{profile.name}</p>
                    <p className="text-xs text-black/40 dark:text-white/40">
                      {profile?.description?.slice(0, 120)}
                      {profile?.description?.length > 120 && "..."}
                    </p>
                  </div>
                  <div className="togglebutton inline-block flex gap-3">
                    <Switch
                      htmlFor={`toggle${profile?.id}`}
                      checked={isActive}
                    />
                  </div>
                </div>
              </div>
            </li>
          );})}
      </ul>
    </Panel>
  );
}