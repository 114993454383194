import * as React from "react";
import { get, map, filter, head } from "lodash";

// import { Cell, Grid, ListItem, Menu } from "@launchos/components/ui";

// import propStyles from "@launchos/modules/editor/Builder/Properties/style";
import propStyles from "@/Apps/Pages/Properties/style";

// import theme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";
import Grid, { Cell } from "@/ui/_old/Grid";
import ListItem from "@/ui/_old/ListItem";
import Menu from "@/ui/_old/Menu";

// import objects from "@launchos/modules/campaign/shared/objects";
import objects from "@/Apps/Funnels/objects";

import theme from "@/ui/theme";

interface NiceMenuProps {
  style: React.CSSProperties;
  children: React.ReactNode;
  label: string;
}

const NiceMenu: React.FC<NiceMenuProps> = ({ style, label, children }) => (
  <div style={{ 
    // ...propStyles.selectField, 
    cursor: "pointer", 
    ...style 
  }}>
    <Menu label={<div className="p-1">{label}</div>} style={{ width: "100%", textAlign: "left" }}>
      <div style={{ maxHeight: 200, overflowY: "scroll" }}>{children}</div>
    </Menu>
  </div>
);

type ActionType = {
  payload: {
    urlPageName?: string;
  };
};

interface GoToUrlProps {
  data: any[];

  /**
   * The current action settings for this specific interaction
   */

  action: ActionType;

  /**
   * Triggers when the GoToUrl settings have been set
   */
  onChange?: () => void;
  updateAction?: (
    action: ActionType,
    payload: {
      url: string;
      urlPageName: string;
      useTag?: boolean;
    },
    linkPayload: {
      href: string;
    }
  ) => void;
}

/**
 * Provides a list of options for linking to another pag.
 * (shows dropdown menu of pages, with option to enter url)
 *
 * For example: A custom url, the next page in the funnel, or one of the other pages in this particular funnel
 */
const GoToUrl: React.FC<GoToUrlProps> = ({
  updateAction,
  action,
  data = [],
}) => {
  const { payload } = action;

  return (
    <NiceMenu
      style={{ marginTop: 10, zIndex: 300 }}
      label={get(payload, "urlPageName") || "Choose a page to link to..."}
    >
      <ListItem
        icon="link"
        onClick={() => {
          const urlPrompt = window.prompt(
            "Enter the url you want to link to",
            get(payload, "url", "http://")
          );

          const url =
            urlPrompt.indexOf("http") > -1 ? urlPrompt : `http://${urlPrompt}`;

          updateAction(
            action,
            {
              url,
              urlPageName: url,
              useTag: false,
            },
            { href: url }
          );
        }}
      >
        Enter a Custom URL
      </ListItem>
      {/* <ListItem
            icon="label"
            onClick={() => {
              const url = window.prompt(
                "Enter the name of the tag you want to use",
                get(payload, "url", "")
              );
              updateAction(action, { url, urlPageName: url, useTag: true });
              updateComponentSettings(
                props.settings.id,
                {
                  ...props.settings,
                  href: url
                },
                true,
                false
              );
            }}
          >
            Get Url From Tag Value
          </ListItem> */}

      <ListItem
        icon="skip_next"
        onClick={() => {
          updateAction(
            action,
            {
              url: "{next_page}",
              urlPageName: "Go to the next page",
              useTag: false,
            },
            { href: "{next_page}" }
          );
        }}
      >
        Go to the next page
      </ListItem>
      <div style={propStyles.divider} />

      {map(data, (page, key) => {
        const thumbnail = head(
          filter(get(objects, "pages", []), (itm) => itm.pageType === page.type)
        );

        return (
          <ListItem
            key={key}
            onClick={() => {
              updateAction(
                action,
                {
                  url: `/${page.slug}/`,
                  urlPageName: page.name,
                  useTag: false,
                },
                {
                  href: `/${page.slug}/`,
                }
              );
            }}
          >
            <Grid>
              <Cell style={{ width: 50, height: 45, textAlign: "center" }}>
                <img
                  alt="Screenshot"
                  src={get(thumbnail, "screenshot", theme.logoIcon)}
                  style={{
                    height: "100%",
                    marginTop: 5,
                    border: "5px solid transparent",
                  }}
                />
              </Cell>
              <Cell>
                <div style={{ fontSize: 14, padding: "20px 10px" }}>
                  {page.name.length > 23
                    ? `${page.name.substring(0, 20)}...`
                    : page.name}
                </div>
              </Cell>
            </Grid>
          </ListItem>
        );
      })}
    </NiceMenu>
  );
};

export default GoToUrl;
