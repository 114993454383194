// import { Calout } from "@/ui/Alerts";
import { useState } from "react";
import { Panel } from "@/ui/Panel";
import { TextInput } from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";

import { updatePage } from "@/api/pages";
import { updateObject } from "@/api/campaigns";
import { FooterButtons } from "@/Settings/DomainManagement/Popups";
import { getSessionToken } from "@/api/auth";

export const Properties = ({
  nodeData,
  nodes,
  setNodes,
  setEditDrawerOpen,
}) => {
  const [url, setUrl] = useState(nodeData?.data?.settings?.url || "");

  const pageScript = `
    <script type="text/javascript">
      window.location.href = "${url}";
    </script>
  `;

  const saveSettings = async () => {
    // save the settings
    const nodeId = nodeData?.id;
    const pageId = nodeData?.data?.page?.id;
    const currentPageSettings = nodeData?.data?.page?.pageSettings || {};
    console.log(pageScript, { nodeData, pageId });

    const token = getSessionToken();
    const pageSettings = {
      ...currentPageSettings,
      tracking: {
        ...currentPageSettings?.tracking,
        head: pageScript,
      },
    };

    // update the page with the new settings
    const pageResponse = await updatePage(pageId, {
      token,
      settings: { pageSettings },
    });
    console.log({ pageResponse });

    // now update the node data with the new settings

    const nodeResponse = await updateObject(nodeId, {
      token,
      settings: {
        url,
      },
    });

    console.log({ nodeResponse });

    const newNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          settings: {
            ...node.settings,
            url,
          },
          data: {
            ...node.data,
            page: {
              ...node.data.page,
              pageSettings,
            },
          },
        };
      }
      return node;
    });

    setNodes(newNodes);

    alert("Your changes have been saved");
  };

  return (
    <div className="grid grid-flow-row gap-7">
      <Panel title="What Website Address (URL) should visitors automatically be re-routed to?">
        <div className="grid grid-flow-row gap-7">
          <TextInput
            flavor={TextInputFlavors.MODERN}
            // label="First Name"
            value={url}
            placeholder={`(e.g. http://www.your.destination.url.here.com/)`}
            name="url"
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      </Panel>

      {/* <Calout
        title="Don't forget to configure the destination URL"
        caption="Make sure to configure your shopping cart's destination URL to points to the next page in this funnel"
      /> */}
      {/* <button onClick={saveSettings}>Save</button> */}
      <FooterButtons
        showBack={false}
        showNext={false}
        showSave
        onCancel={() => setEditDrawerOpen(false)}
        onSave={saveSettings}
      />
    </div>
  );
};
