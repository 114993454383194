import FontIcon from "@/ui/_old/FontIcon";
import Grid, { Cell } from "@/ui/_old/Grid";
import _ from "lodash";

import styled, { css } from "styled-components";

const styles = {
  fancySelector: {
    fontFamily: "sans-serif",
    textAlign: "center",
    // margin: "0 auto",
    display: "flex",
    // width: 300,
    placeContent: 'center',
    marginBottom: 20
  },
  iconStyle: {},
  fontIcon: {
    border: "1px solid #CCC",
    padding: 10,
    width: 50,
    height: 50,
    backgroundColor: "white",
  },
  caption: { fontSize: 11, marginTop: 10 },
};

// const IconWrapper = styled.div`
//   display: inline-block;
//   border: 1px solid transparent;
//   cursor: pointer;
//   width: 80px;
//   padding: 15px;
//   background-color: ${({ highlighted = false }) => (highlighted ? `#ddd` : "inherit")}
//   &:hover {
//     ${({ highlighted = false }) =>
//       highlighted
//         ? css`
//             background-color: #ddd;
//           `
//         : css`
//             background-color: #eee;
//           `}
//     border: 1px solid #ddd;
//   }
//   &:active {
//     background-color: #ccc;
//   }
// `;

// let Icon = ({ iconStyle = {}, onSelect = () => {}, highlighted = false, caption = false, icon = "not_interested" }) => (
//   <IconWrapper onClick={onSelect} highlighted={highlighted}>
//     <FontIcon style={{ ...styles.fontIcon, ...iconStyle }}>{icon}</FontIcon>
//     {caption ? <div style={styles.caption}>{caption.toUpperCase()}</div> : null}
//   </IconWrapper>
// );

let Icon = ({
  iconStyle = {},
  onSelect = () => {},
  highlighted = false,
  caption = false,
  icon = "not_interested",
}) => (
  <div
    onClick={onSelect}
    // className={`
    //   inline-block border border-transparent cursor-pointer w-20 p-4
    //   ${highlighted ? "bg-gray-200" : "bg-inherit"}
    //   hover:${highlighted ? "bg-gray-200" : "bg-gray-100"} hover:border-gray-200
    //   active:bg-gray-300
    // `}
    // <FontIcon style={{ ...styles.fontIcon, ...iconStyle }}>{icon}</FontIcon>
    className={`
      inline-block btn bg-black/10 text-black/70 
      mx-1 w-[80px] p-2
      ${highlighted && `bg-black/5`} 
      hover:bg-transparent cursor-pointer
      active:bg-black-200 rounded
    `}
  >
    <FontIcon className="h-[50px] w-[50px]">{icon}</FontIcon>
    {caption ? <div style={styles.caption}>{caption.toUpperCase()}</div> : null}
  </div>
);

const FancySelector = (props) => {
  const { items = [], style = {}, onChange = () => {} } = props;
  return (
    <Grid style={{ ...styles.fancySelector, ...style }} wrap>
      {items.map(({ id, label, component }) => {
        const Component = component;
        return (
          <Cell key={id}>
            <Component {...props} onSelect={() => onChange(id)} />
            {label && <div style={{ fontSize: 12 }}>{label}</div>}
          </Cell>
        );
      })}
    </Grid>
  );
};

export { Icon };
export default FancySelector;
