import { DatePicker, TimePicker } from "@/Apps/CRM/Broadcasts/components/BroadcastOptions";
import Button from "@/ui/Button";
import Calout from "@/ui/Calout";
import { ButtonTypes } from "@/ui/types";
import { Info } from "@phosphor-icons/react";
import { useState } from "react";

export const Date = ({ objectData, setEditDrawerOpen, onSave = () => {} }) => {
  const { settings = {} } = objectData?.data;
  const [dateTimePayload, setDateTimePayload] = useState(settings?.payload);

  const handleDateChange = (e) => {
    const newDate = { ...dateTimePayload, triggerDate: e.target.value }
    setDateTimePayload(newDate)
  };
  const handleTimeChange = (e) => {
    const newTime = { ...dateTimePayload, triggerTime: e.target.value };
    setDateTimePayload(newTime);
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <Calout
        icon={<Info />}
        title={`Choose the date and time you'd like this campaign to begin`}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
        <DatePicker
          name="trigger-date"
          label="Trigger Date"
          onChange={handleDateChange}
          value={dateTimePayload?.triggerDate}
        />
        <TimePicker
          name="trigger-time"
          label="Trigger Time"
          onChange={handleTimeChange}
          value={dateTimePayload?.triggerTime}
        />
      </div>
      {/* {JSON.stringify(dateTimePayload)} */}
      <div className="flex justify-end items-center mt-4 gap-4">
        <Button
          type={ButtonTypes.SOFT}
          label="Cancel"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={() => setEditDrawerOpen(false)}
        />
        <Button
          type={ButtonTypes.SOFT}
          label="OK"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={() => {
            // console.log("payload", payload);
            onSave(dateTimePayload);
          }}
        />
      </div>
    </div>
  );
};
