import Select from "@/ui/Select"
import SectionWrapper from "../SectionWrapper"
import { TextInputFlavors } from "@/ui/TextInput/types"
import Gap from "../../../BasicProperties/Gap"

export const Layout = (props) => {
    return (
        <SectionWrapper label="Layout" isExpanded={true}>
            <div className="flex flex-col gap-4">
                <Select
                    label="Layout Style"
                    flavor={TextInputFlavors.MODERN}
                    onChange={(selectedItem) => console.log(selectedItem.target.value)}
                    items={[
                        { label: "Stack Vertically", id: 'vertical' },
                        { label: "Stack Horizontally", id: 'horizontal' },
                    ]}
                />

                <Gap {...props} />
            </div>
        </SectionWrapper>
    )
}