import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BlueprintList } from "./BlueprintList";

export const CreateProfileButton = ({ onSelect }) => {
  return (
    <Popover className="relative">
      <Popover.Button 
        className="btn text-black dark:text-white border hover:text-white hover:bg-black bg-transparent">
        <span>+ Create a Profile</span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-2 flex w-screen max-w-max">
          <div className="w-screen flex-auto overflow-hidden rounded-2xl bg-white dark:bg-black/80 backdrop-blur dark:border-white/5 border text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
            <div className="p-8">
              <BlueprintList onSelect={onSelect} />
            </div>
            <div className="bg-gray-50 px-8 py-6 dark:bg-white/5">
              <div className="flex items-center gap-x-3">
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300">
                  Choose a Planning Blueprint
                </h3>
                <p className="rounded-full bg-indigo-600/10 px-2.5 py-1.5 text-xs font-semibold text-indigo-600">
                  New
                </p>
              </div>
              <p className="mt-2 text-sm leading-6 text-gray-600 dark:text-gray-200">
                These blueprints will be used to intelligently craft your
                marketing campaigns.
              </p>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
