import { useState } from 'react';

import { Page, Container } from '../Page';

import { Modal } from "@/ui/Modal";
import { Button } from "@/ui/Button";
import { SimpleContainer } from "@/ui/SimpleContainer";

import {
	DeltaUpIcon,
	DeltaDownIcon,
	ProjectIcon,
	FinanceIcon,
	ClientsIcon,
	RightCarrotIcon,
	HomeIcon,
	FlashIcon,
	PrimaryIcon,
	SuccessIcon,
	WarningIcon,
	DangerIcon,
	DownCarrotIcon,
	// the rest
	SidebarToggleIcon,
	FavoriteIcon,
	SearchIcon,
	MicrophoneIcon,
	DarkToggleIcon,
	LightToggleIcon,
	NotificationIcon,
	SignoutIcon,
	SupportIcon,
	MessagesIcon,
	SettingsIcon,
	ProfileIcon,
	DashboardIcon,
	AppsIcon,
	ComponentsIcon,
	ElementsIcon,
	ChartsIcon,
	WidgetsIcon,
	FontIcon,
	DragDropIcon,
	TableIcon,
	FormsIcon,
	UsersIcon,
	PagesIcon,
	AuthenticationIcon,
	DocumentationIcon,
	CloseButtonIcon,
} from "../../Icons";

import {
	PopupPosition,
	PopupSizes,
} from "@/ui/Modal/types";

const AllTheModals = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [size, setSize] = useState(PopupSizes.MEDIUM);
	const [position, setPosition] = useState(PopupPosition.DEFAULT);

	return (
		<SimpleContainer label="Modals">
			{isOpen && (
				<Modal
					title="Basic"
					setIsOpen={setIsOpen}
					size={size}
					position={position}
					onClose={() => setIsOpen(false)}
				>
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s, when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged.
					</p>
				</Modal>
			)}

			<Button
				label="Launch Modal (Default)"
				onClick={() => {
					setPosition(PopupPosition.DEFAULT);
					setIsOpen(true);
				}}
			/>
			<Button
				label="Launch Modal (Centered)"
				onClick={() => {
					setPosition(PopupPosition.CENTERED);
					setIsOpen(true);
				}}
			/>

			<Button
				label="Launch Modal (Small)"
				onClick={() => {
					setSize(PopupSizes.SMALL);
					setIsOpen(true);
				}}
			/>
			<Button
				label="Launch Modal (Medium)"
				onClick={() => {
					setSize(PopupSizes.MEDIUM);
					setIsOpen(true);
				}}
			/>
			<Button
				label="Launch Modal (Large)"
				onClick={() => {
					setSize(PopupSizes.LARGE);
					setIsOpen(true);
				}}
			/>
			<Button
				label="Launch Modal (Extra Large)"
				onClick={() => {
					setSize(PopupSizes.XLARGE);
					setIsOpen(true);
				}}
			/>
		</SimpleContainer>
	);
};

export const Icons: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Icons" }] }}>
			<Container>
				<p className="text-lg font-semibold">Icons & Modals</p>
				<a name="modals" />
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-7 mt-7">
					<SimpleContainer label="Icons">
						<div>
							<SidebarToggleIcon />
							SidebarToggleIcon
						</div>
						<div>
							<FavoriteIcon />
							FavoriteIcon
						</div>
						<div>
							<SearchIcon />
							SearchIcon
						</div>
						<div>
							<MicrophoneIcon />
							MicrophoneIcon
						</div>
						<div>
							<DarkToggleIcon />
							DarkToggleIcon
						</div>
						<div>
							<LightToggleIcon />
							LightToggleIcon
						</div>
						<div>
							<NotificationIcon />
							NotificationIcon
						</div>
						<div>
							<DownCarrotIcon />
							DownCarrotIcon
						</div>
						<div>
							<SignoutIcon />
							SignoutIcon
						</div>
						<div>
							<SupportIcon />
							SupportIcon
						</div>
						<div>
							<MessagesIcon />
							MessagesIcon
						</div>
						<div>
							<SettingsIcon />
							SettingsIcon
						</div>
						<div>
							<ProfileIcon />
							ProfileIcon
						</div>
						<div>
							<DashboardIcon />
							DashboardIcon
						</div>
						<div>
							<AppsIcon />
							AppsIcon
						</div>
						<div>
							<ComponentsIcon />
							ComponentsIcon
						</div>
						<div>
							<ElementsIcon />
							ElementsIcon
						</div>
						<div>
							<ChartsIcon />
							ChartsIcon
						</div>
						<div>
							<WidgetsIcon />
							WidgetsIcon
						</div>
						<div>
							<FontIcon />
							FontIcon
						</div>
						<div>
							<DragDropIcon />
							DragDropIcon
						</div>
						<div>
							<TableIcon />
							TableIcon
						</div>
						<div>
							<FormsIcon />
							FormsIcon
						</div>
						<div>
							<UsersIcon />
							UsersIcon
						</div>
						<div>
							<PagesIcon />
							PagesIcon
						</div>
						<div>
							<AuthenticationIcon />
							AuthenticationIcon
						</div>
						<div>
							<DocumentationIcon />
							DocumentationIcon
						</div>
						<div>
							<RightCarrotIcon />
							RightCarrotIcon
						</div>
						<div>
							<DeltaUpIcon />
							DeltaUpIcon
						</div>
						<div>
							<DeltaDownIcon />
							DeltaDownIcon
						</div>
						<div>
							<ProjectIcon />
							ProjectIcon
						</div>
						<div>
							<FinanceIcon />
							FinanceIcon
						</div>
						<div>
							<ClientsIcon />
							ClientsIcon
						</div>
						<div>
							<HomeIcon />
							HomeIcon
						</div>
						<div>
							<FlashIcon />
							FlashIcon
						</div>
						<div>
							<PrimaryIcon />
							PrimaryIcon
						</div>
						<div>
							<SuccessIcon />
							SuccessIcon
						</div>
						<div>
							<WarningIcon />
							WarningIcon
						</div>
						<div>
							<DangerIcon />
							DangerIcon
						</div>
						<div>
							<CloseButtonIcon />
							CloseButtonIcon
						</div>
					</SimpleContainer>
					<AllTheModals />
				</div>
			</Container>
		</Page>
	);
};
