import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";
import { AutomationBuilder } from "../Workflows/widgets/AutomationBuilder";

import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { getFilterObject } from "@/Apps/CRM/scripts";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [wildfireCampaignData, setWildfireCampaignData] = useState<{
    name?: string;
  }>({ name: "" });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  // const [activePageId, setActivePageId] = useState(null);
  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setWildfireCampaignData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/wildfire" },
      { label: wildfireCampaignData.name || "" },
    ],
    items: (
      <Button
        label="Publish Campaign"
        onClick={() => setIsPublishPopupOpen(true)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          unitName="Campaign"
        />
      )}
      <div className={`p-6 w-full h-full`}>
        <div className="tabs flex flex-col h-full">
          <TabsComponent
            tabs={[
              { label: "Dashboard", value: "overview" },
              // { label: "Scheduling", value: "scheduling" },
              { label: "Customize", value: "customize" },
              { label: "Automation", value: "automation" },
              { label: "Activity", value: "activity" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "overview" && (
            <DashboardPage
              campaignData={wildfireCampaignData}
              unitName="wildfire"
              panelTitle="Pages in this campaign"
              widgets={[
                "kpi",
                "share",
                {
                  type: "columns",
                  settings: {
                    columns: 3,
                    widgets: ["pages", "tracking", "affiliatePayouts"],
                  },
                },
              ]}
            />
          )}
          {activeTab === "customize" && (
            <DashboardPage
              campaignData={wildfireCampaignData}
              unitName="Campaign"
              widgets={["customize"]}
            />
          )}
          {activeTab === "activity" && (
            <DashboardPage
              campaignData={wildfireCampaignData}
              unitName="Campaign"
              widgets={["contactList"]}
            />
          )}
          {activeTab === "automation" && <AutomationBuilder />}
          {activeTab === "crm" && (
            <ContactsGallery
              onCreate={() => {}}
              defaultFilter={getFilterObject({
                field: "hiddenTags",
                operator: "contains",
                value: id,
              })}
            />
          )}
          {activeTab === "settings" && (
            <Settings campaignData={wildfireCampaignData} unitName="Campaign" />
          )}
        </div>
      </div>
    </Page>
  );
};
