import { getSessionToken } from "@/api/auth";
import { getContact, updateContact } from "@/api/crm";
import { DetailsView } from "@/ui/DataView/DetailsView";
import { DefaultDataProps } from "@/ui/DataView/types";
import { useEffect, useState } from "react";
import { mapContactResponseToView } from "@/Apps/CRM/scripts";
import { debounce } from "lodash";
import { ContactWidgetProps } from "@/Apps/CRM/widgets/types";

export const ContactType: React.FC<ContactWidgetProps> = ({ id }) => {
  const [item, setItem] = useState<DefaultDataProps>();

  const loadData = async () => {
    const token = await getSessionToken();
    const data = await getContact(id, { token });
    setItem(mapContactResponseToView(data));
  };

  const updateItem = async (fieldId, data) => {
    const itemField = data.find((item) => item.id === fieldId);
    const token = await getSessionToken();
    await updateContact(id, { [itemField.id]: itemField.value }, { token });
  };

  const debouncedHandleItemChange = debounce(updateItem, 300);

  const handleItemChange = (id, data) => {
    setItem({ ...item, data });
    debouncedHandleItemChange(id, data);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!item) return null;

  return (
    <DetailsView
      isEditable
      onItemChange={handleItemChange}
      title="Type"
      item={{
        ...item,
        data: item.data.map((res) => {
          const { id, value } = res;
          if (id !== "type") return res;
          return {
            id,
            label: "Type of contact",
            value,
            fieldType: "enum",
            options: [
              {
                label: "Participant",
                value: "Participant",
              },
              {
                label: "Registrant",
                value: "Registrant",
              },
              {
                label: "Nominee",
                value: "Nominee",
              },
            ],
          };
        }),
      }}
      visibleFields={["type"]}
    />
  );
};
