import { useContext, useState } from "react";

import { Page, Container } from "@/ui/Layout/Page";

import { Button } from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { TabsComponent } from "@/ui/TabsComponent";

import { createContact } from "@/api/crm";
import { getSessionToken } from "@/api/auth";
import { FormFieldTypes } from "./types";
import { AppContext } from "@/AppContext";
import { Scroll } from "../PFOC/Scroll";
import { ContactsGallery } from "./ContactsGallery";
import { getFilterObject } from "./scripts";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

export const translateFieldType = (fieldType) => {
  switch (fieldType) {
    case FormFieldTypes.TEXT:
      return "string";
    case FormFieldTypes.PASSWORD:
      return "string";
    case FormFieldTypes.CONTENT:
      return "string";
    case FormFieldTypes.DATE:
      return "date";
    case FormFieldTypes.NUMBER:
      return "number";
    // add for multiple choice, check boxes, dropdown, currency, etc.
    default:
      return "string";
  }
};

export const CRM = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState("contacts");
  // const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "contacts");


  const handleCreate = async () => {
    // const { id } = await createData();
    const token = getSessionToken();
    const contact = {
      // title: "New Contact",
      firstName: "New",
      lastName: "Contact",
    };
    const { id } = await createContact(contact, { token });
    console.log("id", id);
    // handleItemClick({ id });
    if (id) {
      window.location.href = `/crm/contacts/${id}`;
    } else {
      alert("There was an error creating the contact");
    }
  };

  const { permissions = [] } = useContext(AppContext);

  // const getDefaultFilter = (type) => {
  //   return {
  //     combinator: "and",
  //     rules: [
  //       {
  //         field: "type",
  //         operator: "=",
  //         value: type,
  //       },
  //     ],
  //   }
  // }

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <>
            <Button
              label="+ Create a Contact"
              type={ButtonTypes.OUTLINED}
              variant={Variants.INFO}
              onClick={handleCreate}
            />
          </>
        ),
      }}
    >
      <Container fullWidth>
        {/* Add a create button */}
        {permissions.includes("scroll") && (
          <TabsComponent
            tabs={[
              { label: "All Contacts", value: "contacts" },
              ...(permissions.includes("scroll")
                ? [
                    { label: "Participants", value: "participants" },
                    { label: "Registrants", value: "registrants" },
                    { label: "Nominees", value: "nominees" },
                    { label: "Live Scroll Map", value: "scroll" },
                  ]
                : []),
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
        )}

        {activeTab === "contacts" && (
          <ContactsGallery />
        )}
        {activeTab === "participants" && (
          <ContactsGallery
            defaultFilter={getFilterObject({
              field: "type",
              operator: "=",
              value: "Participant",
            })}
          />
        )}
        {activeTab === "registrants" && (
          <ContactsGallery
            defaultFilter={getFilterObject({
              field: "type",
              operator: "=",
              value: "Registrant",
            })}
          />
        )}
        {activeTab === "nominees" && (
          <ContactsGallery
            defaultFilter={getFilterObject({
              field: "type",
              operator: "=",
              value: "Nominee",
            })}
          />
        )}
        {activeTab === "scroll" && <Scroll />}
      </Container>
    </Page>
  );
};
