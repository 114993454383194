import { style } from "./style";
import { ActionBehaviors, ActionTypes } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/types";
import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

const settings: IComponentSettings = {
  id: ComponentTypes.BUTTON,
  name: "Button",
  description: "",
  thumbnail: "comp_buttons.png",
  default: {
    type: ComponentTypes.BUTTON,
    properties: style.BUTTON.properties,
    // properties: { display: "inline-block" },
    // hoverStyle: { backgroundColor: theme.highlightColor },
    hoverStyle: {},
    caption1: "Add to Cart",
    caption1style: style.BUTTON.caption1,
    // caption2: "Thank You For The Discount!",
    caption2style: style.BUTTON.caption2,
    actions: [
      {
        type: ActionTypes.TRIGGER_NEXT_NODE,
        name: "Go to the next page",
        payload: {
          url: "{next_page}",
        },
        behavior: ActionBehaviors.CLICK,
      },
    ],
  },
  properties: {
    main: {
      tabs: ["Basic", "Advanced"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "custom_caption",
          sectionWrap: true,
          settings: {
            label: "Button Text",
            icon: "format_size",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Button Actions",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "custom_color_picker",
          sectionWrap: true,
          settings: {
            label: "Button Color",
            icon: "colorize",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ICONSELECTOR,
          sectionWrap: true,
          settings: {
            label: "Button Icon",
            icon: "insert_emoticon",
            containerStyle: { maxHeight: 500, overflowY: "scroll" },
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Button Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Button Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.BORDERSSHADOW,
          settings: {
            label: "Borders & Shadow",
            isExpanded: true,
          },
        },
      ],
    },
  },
};

export default settings;
