import { API_DOMAIN } from "@/env";

/**
 * Retrieves the user's integrations from the API.
 * @param {string} token - The user's authentication token.
 * @returns {Promise<any>} - A promise that resolves to the integration data.
 */
export const getUserIntegrations = async ({ token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(`${API_DOMAIN}/integrations`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      const { data } = await response.json();
      // console.log("integration data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Retrieves the settings for a specific integration.
 * @param {string} integrationId - The ID of the integration.
 * @param {string} token - The user's authentication token.
 * @returns {Promise<any>} - A promise that resolves to the integration settings.
 */
export const getUserIntegrationSettings = async (integrationId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(
        `${API_DOMAIN}/integrations/${integrationId}`,
        {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        }
      );
      const { data } = await response.json();
      // console.log("integration data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Updates the settings for a specific integration.
 * @param {string} integrationId - The ID of the integration.
 * @param {string} token - The user's authentication token.
 * @param {object} settings - The new settings for the integration.
 * @returns {Promise<any>} - A promise that resolves to the updated integration data.
 */
export const updateUserIntegrationSettings = async (
  integrationId,
  { token, settings }
) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    try {
      const response = await fetch(
        `${API_DOMAIN}/integrations/${integrationId}`,
        {
          method: "PATCH",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify(settings),
        }
      );
      const { data } = await response.json();
      console.log("integration data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Creates a new integration for the user.
 * @param {Object} options - The options for creating the integration.
 * @param {string} options.token - The authorization token.
 * @param {Object} options.settings - The integration settings.
 * @returns {Promise<Object>} - A promise that resolves to the created integration data.
 */
export const createUserIntegration = async ({ token, settings }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    try {
      const response = await fetch(`${API_DOMAIN}/integrations`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(settings),
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Deletes an integration profile for the user.
 * @param {string} integrationId - The ID of the integration to delete.
 * @param {Object} options - The options for deleting the integration.
 * @param {string} options.token - The authorization token.
 * @returns {Promise<Object>} - A promise that resolves to the deleted integration data.
 */
export const deleteUserIntegration = async (integrationId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(
        `${API_DOMAIN}/integrations/${integrationId}`,
        {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};
