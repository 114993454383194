import { gql } from '@apollo/client';

export const GET_ALL_INTEGRATIONS = gql`
  query allIntegrationQuery($userId: ID!) {
    integrations(where: { user: { id: $userId } }) {
      id
      type
      setting
      createdAt
      updatedAt
      user {
        id
      }
    }
  }
`;