import React from "react";
import Slider from "@material-ui/core/Slider";
import { IButton } from "./types";
import style from "./style";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import FontIcon from "@/ui/_old/FontIcon";

export const ButtonSlider: React.FC<{
  button1: IButton;
  button2: IButton;
  doManualUpdate: () => void;
  doChange: (e: any, value: any) => void;
  doFinalChange: (e: any, value: any) => void;
  sliderValue: string;
  minValue: React.ReactText;
  maxValue: React.ReactText;
  sliderContainerStyle: React.CSSProperties;
}> = ({
  button1,
  button2,
  doManualUpdate,
  doChange,
  doFinalChange,
  sliderValue,
  minValue,
  maxValue,
  sliderContainerStyle = {},
  label = null
}) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {label && <span style={style.label}>{label}</span>}
      {/* <div style={{ display: "flex", ...sliderContainerStyle }}> */}
      <div className="w-full" style={{ display: "flex", ...sliderContainerStyle }}>
        {/* <div style={style.btnContainer}> */}
        <div>
          <Button
            type={ButtonTypes.SOFT}
            className="px-3"
            onClick={() => {
              doFinalChange(false, button1.value);
            }}
            icon={<CaretUp />}            
            // style={{ color: theme.primaryColor, ...button2.style }}
            {...button1.events}
            transparent
            label={<FontIcon style={{ fontSize: "1rem", marginTop: 3, ...button1.style}}>{button1.icon}</FontIcon>}
          >
            {/* {button1.icon} */}
          </Button>
        </div>
        {/* <div style={style.sliderContainer} onDoubleClick={doManualUpdate}> */}
        <div className="w-full px-5 py-1" onDoubleClick={doManualUpdate}>
          <Slider
            color="secondary"
            data-testid="ButtonSliderGroup-slider"
            onChange={doChange}
            onChangeCommitted={doFinalChange}
            style={{ width: "100%" }}
            value={parseInt(sliderValue, 10)}
            valueLabelDisplay={Number.isInteger(sliderValue) ? "auto" : "off"}
            min={parseInt(minValue, 10)}
            max={parseInt(maxValue, 10)}
          />
        </div>
        {/* <div style={style.btnContainer}> */}
        <div>
          <Button
            type={ButtonTypes.SOFT}
            className="px-3"
            onClick={() => {
              doFinalChange(false, button2.value);
            }}
            icon={<CaretDown />}            
            // style={{ color: theme.primaryColor, ...button2.style }}
            {...button2.events}
            transparent
            label={<FontIcon style={{ fontSize: "1rem", marginTop: 3, ...button2.style}}>{button2.icon}</FontIcon>}
          >
            {/* {button2.icon} */}
          </Button>
        </div>
      </div>
    </div>
  );
