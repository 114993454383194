import { uniqueId } from "lodash";
// import layoutTheme from "@launchos/plugins/misc/v2/blocks/weblayouts/theme";
import { LinkDisplayTypes, LinkLayoutStyles } from "./types";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

const layoutTheme = { fontFamily: 'Open Sans' }

const style = {
  LISTITEM: {
    style: {
      fontFamily: layoutTheme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      borderRadius: "100%",
      padding: 10,
      // color: layoutTheme.backgroundColor,
    },
    contentStyle: {
      padding: 10,
    },
  },
  NAVIGATION: {
    linkStyle: {
      listStyle: "none",
      display: "inline-block",
      padding: "5px 10px",
      fontFamily: "Roboto",
    },
  },
};

const settings: IComponentSettings = {
  id: ComponentTypes.NAVIGATION,
  name: "Links",
  thumbnail: "comp_navigation.png",
  description: "",
  default: {
    type: ComponentTypes.NAVIGATION,
    canHaveChildren: false,
    linkStyle: style.NAVIGATION.linkStyle,
    layoutStyle: LinkLayoutStyles.HORIZONTAL,
    properties: {
      padding: 20,
      textAlign: "center",
      display: "inline-block",
    },
    data: [
      {
        id: uniqueId(),
        caption: "Home",
        linkDisplayType: LinkDisplayTypes.TEXT,
      },
      {
        id: uniqueId(),
        caption: "Features",
        linkDisplayType: LinkDisplayTypes.TEXT,
      },
      {
        id: uniqueId(),
        caption: "Blog",
        linkDisplayType: LinkDisplayTypes.TEXT,
      },
      {
        id: uniqueId(),
        caption: "About Us",
        linkDisplayType: LinkDisplayTypes.TEXT,
      },
      {
        id: uniqueId(),
        caption: "Contact Us",
        linkDisplayType: LinkDisplayTypes.TEXT,
      },
    ],
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "LinkBuilderSettings",
          sectionWrap: true,
          settings: {
            label: "Link Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "LinkLayout",
          sectionWrap: true,
          settings: {
            label: "Layout Style",
            icon: "rotate_right",
            isExpanded: false,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.TYPOGRAPHY,
          settings: {
            label: "Typography",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        // {
        //   tab: "Basic",
        //   type: SectionTypes.WIDTH,
        //   sectionWrap: true,
        //   settings: {
        //     label: "Width",
        //     text: "Width",
        //     icon: "swap_horiz",
        //   },
        // },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
