import React, { useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import { ThemeProvider } from "@material-ui/core/styles";

import { AppContext } from "@/AppContext";
import { AppRoutes } from "@/Routes";
import { EDITOR_DOMAIN } from "./env";
// import { getSessionToken } from "./api/auth";
import { getUserPermissions } from "./api/users";

import { initGA, isSystemDarkMode, logPageView, syncSystemDarkMode } from "./utils";
// import { OnboardingVideo } from "./OnboardingVideo";
import { muiThemeDark, muiThemeLight } from "./ui/theme";

const App: React.FC = () => {
  // Set up state for the sidebar and right sidebar
  const [darkMode, setDarkMode] = useState<boolean>(
    isSystemDarkMode()
    // Boolean(localStorage.getItem("darkMode") === "true")
  );
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    Boolean(localStorage.getItem("sidebarOpen") === "true")
  );
  const [rightSidebarOpen, setRightSidebarOpen] = useState<boolean>(
    Boolean(localStorage.getItem("rightSidebarOpen") === "true")
  );

  const [permissions, setPermissions] = useState<string[]>([]);

  const [pluginUrls, setPluginUrls] = useState<string[]>([
    // "http://localhost:3000/onListItems.js",
    // "http://localhost:3000/bundle.js",
    // "http://localhost:61931/bundle.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/testProjectId-bundle.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/example1.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/example2.js",
  ]);

  // const [routes, setRoutes] = useState<any>()

  const menu = "";
  const layout = "";

  const contextValue = {
    darkMode,
    sidebarOpen,
    rightSidebarOpen,
    pluginUrls,
    permissions,
    setPermissions,
    setDarkMode,
    setSidebarOpen,
    setRightSidebarOpen,
    setPluginUrls,
    disableRightSidebar: false,
  };

  const getIframeMessage = () => ({
    type: "onLoad",
    darkMode,
    pageWrapStyle: {
      backgroundColor: darkMode ? `rgba(22, 22, 22)` : "#F7F7F7", // rgba(22, 22, 22, 0.8)
      width: "100%",
    },
  });

  // Detect when dark mode is changed and set local storage
  useEffect(() => {
    // console.log("Dark Mode Changed:", darkMode);
    localStorage.setItem("darkMode", darkMode ? "true" : "false");
    initGA("G-CMMH8E7PSL"); logPageView();

    // send message to iframe if one exists
    const iframes = document.getElementsByClassName("page-editor");
    Array.from(iframes).forEach((iframe) => {
      if (iframe instanceof HTMLIFrameElement) {
        iframe.contentWindow?.postMessage(getIframeMessage(), "*");
      }
    });
  }, [darkMode]);


  // Listen for messages from the client iframe
  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        // Check the origin of the message for security
        if (event.origin !== EDITOR_DOMAIN) return;

        // console.log("In Launch OS App - Data from sandcastle iframe", event.data);

        // Check the message type
        if (event.data.type === "getState") {
          const iframes = document.getElementsByClassName("page-editor");
          Array.from(iframes).forEach((iframe) => {
            if (iframe instanceof HTMLIFrameElement) {
              iframe.contentWindow?.postMessage(getIframeMessage(), "*");
            }
          });
        }
      },
      false
    );

    (async () => {
      // get user permissions
      const token = localStorage.getItem("token");
      const permissions = await getUserPermissions({ token });
      // console.log("permissions", permissions);
      if (permissions) setPermissions(permissions);
    })();

    // Clean up
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  // listen for dark mode changes
  useEffect(() => {
    syncSystemDarkMode(setDarkMode)
  }, [])

  const toggleLeftSidebar = () => {
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  const toggleRightSidebar = () => {
    localStorage.setItem("rightSidebarOpen", !rightSidebarOpen);
    setRightSidebarOpen(!rightSidebarOpen);
  };

  return (
    <AppContext.Provider value={contextValue}>
      <ThemeProvider theme={darkMode ? muiThemeDark : muiThemeLight}>
        <div className={darkMode ? "dark" : "light"}>
          <div
            className={`antialiased relative font-inter bg-white dark:bg-black text-black dark:text-white text-sm font-normal overflow-x-hidden vertical ${
              sidebarOpen ? "toggle-sidebar" : ""
            } 
            ${rightSidebarOpen ? "right-sidebar" : ""} 
            ${menu} ${layout} body min-h-screen`}
          >
            {/* Start Menu Sidebar Overlay */}
            <div
              className={`fixed inset-0 bg-[black]/60 z-40 ${
                !sidebarOpen ? "hidden" : "lg:hidden"
              }`}
              onClick={toggleLeftSidebar}
            ></div>
            {/* End Menu Sidebar Overlay */}

            {/* Start Right Sidebar Overlay */}
            {!contextValue.disableRightSidebar && (
              <div
                className={`fixed inset-0 bg-[black]/60 z-50 ${
                  !rightSidebarOpen ? "hidden" : "2xl:hidden"
                }`}
                onClick={toggleRightSidebar}
              ></div>
            )}
            {/* End Right Sidebar Overlay */}

            {/* Start Main Content */}
            <div className={`main-container navbar-sticky flex`}>
              <AppRoutes />
            </div>
            {/* End Main Content */}
          </div>
        </div>
      </ThemeProvider>
      <Analytics />
    </AppContext.Provider>
  );
};

export default App;
