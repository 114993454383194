import { TextInputFlavors } from "../TextInput/types";
import { SelectProps } from "./types";

export const Select: React.FC<SelectProps> = ({ multiple, flavor, label, name, value, onChange, className = "", items = [] }) => {
  // If the `multiple` prop is true, render a multi-select element
  if (multiple) {
    return (
      <select
        data-testid="Select"
        // Set the size of the select element to 4
        size={4}
        // Allow multiple options to be selected
        multiple
        // Set the class name of the select element
        className={`form-multiselect !bg-none py-2.5 px-4 w-full text-black dark:text-white border border-black/10 dark:border-white/10 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-black dark:focus:border-white/10 focus:ring-0 focus:shadow-none ${className}`}
        // Add an onChange event listener that calls the onChange prop when the select element is changed
        onChange={onChange}
        // Set the value of the select element to the value prop
        value={value}
        // Set the name of the select element to the name prop
        name={name}
      >
        {/* Map over the `items` prop and render an option element for each item */}
        {items.map(({ label, id }, key) => (
          <option key={key} value={id}>{label}</option>
        ))}
      </select>
    );
  }

  if (flavor === TextInputFlavors.MODERN) {
    return (
      <div className={`relative bg-white dark:bg-white/5 py-4 px-5 rounded-lg border border-black/10 dark:border-white/10 ${className}`}>
        <label className="block text-xs text-black/40 dark:text-white/40 mb-1">
          {label}
        </label>
        <select
          data-testid="Select"
          // Add an onChange event listener that calls the onChange prop when the select element is changed
          onChange={onChange}
          // Set the value of the select element to the value prop
          value={value}
          // Set the name of the select element to the name prop
          name={name}
          // Set the class name of the select element
          className="form-select"
        >
          {/* Map over the `items` prop and render an option element for each item */}
          {items.map(({ label, id }, key) => (
            <option key={key} value={id}>{label}</option>
          ))}
        </select>
      </div>
    );
  }

  // If the `multiple` prop is false, render a single-select element
  return (
    <select
      data-testid="Select"
      // Set the class name of the select element
      className={`form-select py-2.5 px-4 w-full text-black dark:text-white border border-black/10 dark:border-white/10 rounded-lg placeholder:text-black/20 dark:placeholder:text-white/20 focus:border-black dark:focus:border-white/10 focus:ring-0 focus:shadow-none ${className}`}
      // Add an onChange event listener that calls the onChange prop when the select element is changed
      onChange={onChange}
      // Set the value of the select element to the value prop
      value={value}
      // Set the name of the select element to the name prop
      name={name}
    >
      {/* Map over the `items` prop and render an option element for each item */}
      {items.map(({ label, id }, key) => (
        <option key={key} value={id}>{label}</option>
      ))}
    </select>
  );
};