import { Status } from "../types";
import { TaskBarProps } from "./types";

/**
 * Renders a task bar that displays the number of completed tasks out of the total number of tasks.
 * @param param0 
 * @returns 
 */
export const TaskBar: React.FC<TaskBarProps> = ({
  completedNum = 13, 
  totalNum = 49, 
  label = "Total Tasks", 
  status = Status.INPROGRESS,
}) => {

  // Determine the color of the task bar based on the status prop
  let color = "bg-[#8A8CD9]";
  if (status == Status.INPROGRESS) color = "bg-[#8A8CD9]";
  if (status == Status.COMPLETED) color = "bg-lightgreen-200";
  if (status == Status.PENDING) color = "bg-lightblue-200";

  // Calculate the percentage of completed tasks
  const percentage = ((completedNum / totalNum) * 100).toFixed(0);

  // Render the task bar and completion percentage
  return (
    <>
      <div data-testid="TaskBar" className="mt-1 mb-2 h-1 relative w-full rounded-full overflow-hidden">

        {/* Render the background of the task bar */}
        <div className="w-full h-full bg-lightblue-300/20 dark:bg-white/5 absolute" />

        {/* Render the foreground of the task bar */}
        <div
          className={`${status} h-full ${color} absolute`}
          style={{ width: `${percentage}%` }}
        />
      </div>

      {/* Render the completed and total task counts, as well as the label */}
      <div className="flex items-center gap-4 justify-between text-xs">
        <p>
          <span>{completedNum}</span>{" "}
          <span className="text-black/40 dark:text-white/40">/</span> <span>{totalNum}</span>{" "}
          <span className="text-black/40 dark:text-white/40">{label}</span>
        </p>

        {/* Render the completion percentage */}
        <p>{percentage}%</p>
      </div>
    </>
  );
};
