import { Blogs } from "./Blogs";
import { Dashboard } from "./Dashboard";
import { Article } from "@phosphor-icons/react";

import { HookObject, ListItemsHooks } from "@/plugins/types";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("blogs")) return [];
    
    return [
      {
        label: "Blogs",
        icon: <Article className="text-lg" />,
        href: "/blogs",
        order: 9,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("blogs")) return [];

    return [
      {
        path: "/blogs",
        element: <Blogs name="Blogs" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Blogs" />,
          },
        ],
      },
    ];
  }
};

export { Blogs };
