import * as React from "react";
import { EditorComponentProps, EditorMode } from "@/Apps/Pages/Editor/types";

import { IconSource } from "../Icon/types";

export enum ButtonPresets {
  BUTTON01 = "Bold Look",
  BUTTON02 = "Transparent",
  // BUTTON03 = "Button 03",
}
export interface ButtonProps {
  mode?: EditorMode;
  id?: string;
  children?: React.ReactNode | string;
  /**
   * Caption of the first line of the button
   *
   * @params
   **/
  style?: React.CSSProperties;
  properties?: React.CSSProperties;
  icon?: React.ReactNode | any;
  iconSource?: IconSource;
  disabled?: boolean;
  iconStyle?: any;
  caption1?: string;
  caption1style?: any;
  caption2?: string;
  caption2style?: any;
  hoverStyle?: any;
  onClick?: () => any;
  href?: string;
  actions?: any[];
  getComponentSettings?: (id?: string) => any;
  updateComponentSettings?: (
    id: string,
    settings: any,
    dbUpdate?: boolean,
    debounceTimeout?: boolean | number
  ) => void;
}

export interface PluginProps extends EditorComponentProps {
  settings: ButtonProps;
}
