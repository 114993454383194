import ProgressBar from "../../ProgressBar";
import { KPISimpleProps } from "../types";

export const KPISimple: React.FC<KPISimpleProps> = ({
  label,
  type = "text",
  barSettings,
  number,
  href,
  className,
  format,
}) => {
  let content = number ?? "N/A";
  if (typeof number === "number" && format) {
    switch (format) {
      case "number":
        content = number.toLocaleString(undefined, {
          style: "decimal",
          minimumFractionDigits: 0,
        });
        break;
      case "percentage":
        content = number.toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 2,
        });
        break;
      case "currency":
        content = number.toLocaleString(undefined, {
          style: "currency",
          currency: "USD",
        });
        break;
      default:
        break;
    }
  }
  return (
    <div data-testid="KPISimple" className={`${className}`}>
      {label && <p className="">{label}</p>}
      {href && type !== "bar" && (
        <a href={href}>
          <p className="text-lg font-semibold text-black dark:text-white">
            {content}
          </p>
        </a>
      )}
      {!href && type !== "bar" && (
        <p className="text-lg font-semibold text-black dark:text-white">
          {content}
        </p>
      )}

      {type === "bar" && (
        <ProgressBar {...barSettings} number={number || barSettings?.number} />
      )}
    </div>
  );
};

export default KPISimple;
