import { SettingsProps } from "./types";
import { renderWidget } from "../renderWidget";

export const Settings: React.FC<SettingsProps> = ({
  campaignData = {},
  setCampaignData,
  unitName = "Funnel",
  widgets = ["name", "planner", "domain", "scripts", "delete"],
}) => {
  return (
    <div className="flex flex-col gap-7">
      {widgets.map((widget, key) => {
        return (
          <span key={key}>
            {renderWidget(widget, { campaignData, setCampaignData, unitName })}
          </span>
        );
      })}
    </div>
  );
};
