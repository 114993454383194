import { Panel } from "@/ui/Panel";
import { NameAndDescriptionProps } from "../types";
import { useEffect, useState } from "react";
import { getSessionToken } from "@/api/auth";
import { updateCampaign } from "@/api/campaigns";
import { debounce } from "lodash";
import { TextInputFlavors } from "@/ui/TextInput/types";
import TextInput from "@/ui/TextInput";
import TextArea from "@/ui/TextArea";

export const NameAndDescription = ({
  campaignData = {},
  setCampaignData = () => {},
  unitName = "Funnel",
}: {
  campaignData: any;
  setCampaignData: any;
  unitName?: string;
}) => {
  const [nameAndDescription, setNameAndDescription] = useState({
    name: campaignData?.name,
    description: campaignData?.description,
  });

  const saveNameAndDescriptionChange = async ({ name, description }) => {
    console.log(
      "saveNameAndDescriptionChange",
      name,
      description,
      campaignData
    );
    // Perform your database save operation here
    const token = getSessionToken();

    if (!token) return;

    setCampaignData({
      ...campaignData,
      name,
      description,
    });

    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: { name, description },
    });
    console.log("saveNameAndDescriptionChange saved", response);
  };

  const debouncedSaveNameAndDescriptionChange = debounce(
    saveNameAndDescriptionChange,
    500
  );

  useEffect(() => {
    // if (nameAndDescription?.name === campaignData?.name) return;
    // if (nameAndDescription?.description === campaignData?.description) return;

    debouncedSaveNameAndDescriptionChange(nameAndDescription);

    return () => {
      // Cleanup the debounce function when the component unmounts
      debouncedSaveNameAndDescriptionChange.cancel();
    };
  }, [nameAndDescription]);

  const handleNameDescriptionChange = ({ name, description }) => {
    setNameAndDescription({ name, description });
  };

  return (
    <NameAndDescriptionPure
      name={nameAndDescription?.name}
      description={nameAndDescription?.description}
      onChange={handleNameDescriptionChange}
      unitName={unitName}
    />
  );
};

const Name: React.FC<{
  name: string;
  unitName?: string;
  onChange?: NameAndDescriptionProps["onChange"];
}> = ({ name = "", unitName = "Funnel", onChange = () => {} }) => {
  return (
    <TextInput
      flavor={TextInputFlavors.MODERN}
      label={`${unitName} Name`}
      value={name}
      placeholder={`${unitName} Name`}
      name="name"
      onChange={onChange}
    />
  );
};

const Description = ({
  description = "",
  unitName = "Funnel",
  onChange = () => {},
}) => {
  return (
    <TextArea
      flavor={TextInputFlavors.MODERN}
      label={`${unitName} Description`}
      value={description}
      placeholder={` `}
      name="description"
      onChange={onChange}
    />
  );
};

export const LaunchDate = () => (
  <div className="relative">
    <div className="relative">
      <div
        htmlFor="due-date"
        className="absolute inset-y-0 left-0 flex items-center pt-[20px] pl-4 pointer-events-none"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.125 2.5V1.875C13.125 1.52982 13.4048 1.25 13.75 1.25C14.0952 1.25 14.375 1.52982 14.375 1.875V2.5H16.25C16.25 2.5 16.7678 2.5 17.1339 2.86612C17.1339 2.86612 17.5 3.23223 17.5 3.75V16.25C17.5 16.25 17.5 16.7678 17.1339 17.1339C17.1339 17.1339 16.7678 17.5 16.25 17.5H3.75C3.75 17.5 3.23223 17.5 2.86612 17.1339C2.86612 17.1339 2.5 16.7678 2.5 16.25V3.75C2.5 3.75 2.5 3.23223 2.86612 2.86612C2.86612 2.86612 3.23223 2.5 3.75 2.5H5.625V1.875C5.625 1.52982 5.90482 1.25 6.25 1.25C6.59518 1.25 6.875 1.52982 6.875 1.875V2.5H13.125ZM3.75 7.5V16.25H16.25V7.5H3.75ZM16.25 6.25H3.75V3.75H5.625V4.375C5.625 4.72018 5.90482 5 6.25 5C6.59518 5 6.875 4.72018 6.875 4.375V3.75H13.125V4.375C13.125 4.72018 13.4048 5 13.75 5C14.0952 5 14.375 4.72018 14.375 4.375V3.75H16.25V6.25Z"
            fill="currentColor"
            fill-opacity="0.2"
          />
        </svg>
      </div>
      <input
        type="date"
        value=""
        id="due-date"
        className="block rounded-lg px-10 pb-4 pt-[38px] w-full text-sm text-black dark:text-white dark:bg-white/5 bg-white border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer"
        placeholder="pick a date"
      />
    </div>
    <label className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2">
      Launch Date
    </label>
  </div>
);

export const NameAndDescriptionPure = ({
  name,
  description,
  unitName = "Funnel",
  onChange = () => {},
}: NameAndDescriptionProps) => {
  return (
    <Panel title={`${unitName} Details`}>
      <div className="grid grid-flow-row gap-7">
        <Name
          unitName={unitName}
          name={name}
          onChange={(e) => {
            onChange({
              name: e.target.value,
              description,
            });
          }}
        ></Name>
        <Description
          unitName={unitName}
          description={description}
          onChange={(e) => {
            onChange({
              name,
              description: e.target.value,
            });
          }}
        />
        {/* <LaunchDate /> */}
      </div>
    </Panel>
  );
};
