import { useEffect, useState } from "react";
import { getCatalogItem, getCatalogItems } from "@/api/catalog";
import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import SectionWrapper from "../SectionWrapper";
import DropdownGroup from "../../groups/DropdownGroup";
import { DEFAULT_PAGE_ID } from "@/Apps/Pages/Editor/types";

export const Appearance = ({ filter = {} }) => {
  const [templates, setTemplates] = useState([]);
  const { updateContent } = useEditorActions();

  const fetchTemplates = async () => {
    const items = await getCatalogItems({ type: "designs", ...filter });
    console.log({ items });
    setTemplates(
      items.map(({ name, id, image }) => ({
        label: name,
        id,
        icon: (
          <div className="w-12 h-12 mr-2 overflow-hidden rounded">
            <img src={image} className="w-full" />
          </div>
        ),
      })) || []
    );
  };

  const handleTemplateChange = async (key) => {
    const template = templates[key];
    // console.log({ template })
    const id = template.id;

    // get the page content
    const templateData = await getCatalogItem(id);
    console.log({ template, templateData });

    const { page } = templateData;
    const { content } = page;

    // update the page with the content
    updateContent(content, DEFAULT_PAGE_ID);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const themes = [
    {
      label: "Ocean Breeze",
      id: "ocean-breeze",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-blue-500 mr-1" />
          <div className="w-3 h-3 bg-teal-400 mr-1" />
          <div className="w-3 h-3 bg-cyan-300 mr-1" />
          <div className="w-3 h-3 bg-sky-200 mr-1" />
        </div>
      ),
    },
    {
      label: "Sunset Glow",
      id: "sunset-glow",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-orange-500 mr-1" />
          <div className="w-3 h-3 bg-amber-400 mr-1" />
          <div className="w-3 h-3 bg-yellow-300 mr-1" />
          <div className="w-3 h-3 bg-red-400 mr-1" />
        </div>
      ),
    },
    {
      label: "Forest Mist",
      id: "forest-mist",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-emerald-600 mr-1" />
          <div className="w-3 h-3 bg-green-500 mr-1" />
          <div className="w-3 h-3 bg-lime-400 mr-1" />
          <div className="w-3 h-3 bg-teal-300 mr-1" />
        </div>
      ),
    },
    {
      label: "Lavender Dreams",
      id: "lavender-dreams",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-purple-600 mr-1" />
          <div className="w-3 h-3 bg-violet-500 mr-1" />
          <div className="w-3 h-3 bg-indigo-400 mr-1" />
          <div className="w-3 h-3 bg-fuchsia-300 mr-1" />
        </div>
      ),
    },
    {
      label: "Autumn Harvest",
      id: "autumn-harvest",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-amber-600 mr-1" />
          <div className="w-3 h-3 bg-orange-500 mr-1" />
          <div className="w-3 h-3 bg-yellow-400 mr-1" />
          <div className="w-3 h-3 bg-red-500 mr-1" />
        </div>
      ),
    },
    {
      label: "Arctic Frost",
      id: "arctic-frost",
      icon: (
        <div className="flex mr-1">
          <div className="w-3 h-3 bg-blue-600 mr-1" />
          <div className="w-3 h-3 bg-cyan-400 mr-1" />
          <div className="w-3 h-3 bg-slate-300 mr-1" />
          <div className="w-3 h-3 bg-gray-200 mr-1" />
        </div>
      ),
    },
  ];

  return (
    <SectionWrapper label="Appearance">
      <DropdownGroup
        label="Template"
        onChange={handleTemplateChange}
        items={templates}
      />
      <DropdownGroup
        label="Theme"
        onChange={handleTemplateChange}
        items={themes}
      />
    </SectionWrapper>
  );
};