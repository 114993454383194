import { collection } from "../objects";
import {
  getNodeMenuLabelComponent,
} from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { handleCreateNewPageFromTemplate } from "@/api/campaigns";

// import { workflowNodes } from "@/Apps/Workflows/nodes/workflowNodes";

export const collectionNodes = ({ position, campaignData, nodes, setNodes }) => [
  {
    label: "Internal Sources",
    items: collection
      .filter(({ group }) => group !== "filter")
      .map((page) => {
        return {
          label: getNodeMenuLabelComponent(page.name),
          icon: getNodeIconComponent({
            Icon: page?.icon,
            color: page?.iconColor,
            iconStyle: { fontSize: "18pt" },
            containerStyle: { padding: "5px 10px" },
          }),
          onClick: () => {
            handleCreateNewPageFromTemplate({
              position,
              campaignData,
              page: {
                ...page,
                settings: { type: page?.name },
              },
              setNodes,
              nodes,
            });
          },
        };
      }),
  },
  {
    label: "Join & Filter",
    items: collection
      .filter(({ group }) => group === "filter")
      .map((page) => {
        return {
          label: getNodeMenuLabelComponent(page.name),
          icon: getNodeIconComponent({
            Icon: page?.icon,
            color: page?.iconColor,
            iconStyle: { fontSize: "18pt" },
            containerStyle: { padding: "5px 10px" },
          }),
          onClick: () => {
            handleCreateNewPageFromTemplate({
              position,
              campaignData,
              page: {
                ...page,
                settings: { type: page?.name },
              },
              setNodes,
              nodes,
            });
          },
        };
      }),
  },
  // ...workflowNodes({ position, campaignData, nodes, setNodes }),
];
