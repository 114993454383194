import { FormFieldTypes } from "./types";

import { FieldNamePresets, FieldNameTypes, NameValuePair } from "./types";

export const selectOptions: {
  country: NameValuePair[];
  state: NameValuePair[];
  cardType: NameValuePair[];
  expirationMonth: NameValuePair[];
  expirationYear: NameValuePair[];
  shippingMethod: NameValuePair[];
  billingModel: NameValuePair[];
  defaultOptions: NameValuePair[];
} = {
  country: [
    {
      name: false,
      value: "Choose a Country...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "US",
      value: "United States of America",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AD",
      value: "Andorra",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AF",
      value: "Afghanistan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AG",
      value: "Antigua and Barbuda",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AI",
      value: "Anguilla",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AL",
      value: "Albania",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AM",
      value: "Armenia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AO",
      value: "Angola",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AQ",
      value: "Antarctica",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AR",
      value: "Argentina",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AS",
      value: "American Samoa",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AT",
      value: "Austria",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AU",
      value: "Australia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AW",
      value: "Aruba",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AX",
      value: "Aland Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AZ",
      value: "Azerbaijan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BA",
      value: "Bosnia and Herzegovina",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BB",
      value: "Barbados",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BD",
      value: "Bangladesh",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BE",
      value: "Belgium",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BF",
      value: "Burkina Faso",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BG",
      value: "Bulgaria",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BH",
      value: "Bahrain",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BI",
      value: "Burundi",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BJ",
      value: "Benin",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BL",
      value: "Saint Barthélemy",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BM",
      value: "Bermuda",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BN",
      value: "Brunei Darussalam",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BO",
      value: "Bolivia, Plurinational State of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BQ",
      value: "Bonaire, Sint Eustatius and Saba",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BR",
      value: "Brazil",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BS",
      value: "Bahamas",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BT",
      value: "Bhutan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BV",
      value: "Bouvet Island",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BW",
      value: "Botswana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BY",
      value: "Belarus",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "BZ",
      value: "Belize",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CA",
      value: "Canada",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CC",
      value: "Cocos (Keeling) Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CD",
      value: "Congo, the Democratic Republic of the",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CF",
      value: "Central African Republic",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CG",
      value: "Congo",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CH",
      value: "Switzerland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CI",
      value: "Côte d'Ivoire",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CK",
      value: "Cook Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CL",
      value: "Chile",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CM",
      value: "Cameroon",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CN",
      value: "China",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CO",
      value: "Colombia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CR",
      value: "Costa Rica",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CU",
      value: "Cuba",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CV",
      value: "Cabo Verde",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CW",
      value: "Curaçao",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CX",
      value: "Christmas Island",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CY",
      value: "Cyprus",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CZ",
      value: "Czechia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DE",
      value: "Germany",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DJ",
      value: "Djibouti",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DK",
      value: "Denmark",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DM",
      value: "Dominica",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DO",
      value: "Dominican Republic",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DZ",
      value: "Algeria",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "EC",
      value: "Ecuador",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "EE",
      value: "Estonia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "EG",
      value: "Egypt",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "EH",
      value: "Western Sahara",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ER",
      value: "Eritrea",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ES",
      value: "Spain",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ET",
      value: "Ethiopia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FI",
      value: "Finland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FJ",
      value: "Fiji",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FK",
      value: "Falkland Islands (Malvinas)",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FM",
      value: "Micronesia, Federated States of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FO",
      value: "Faroe Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FR",
      value: "France",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GA",
      value: "Gabon",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GB",
      value: "United Kingdom of Great Britain and Northern Ireland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GD",
      value: "Grenada",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GE",
      value: "Georgia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GF",
      value: "French Guiana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GG",
      value: "Guernsey",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GH",
      value: "Ghana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GI",
      value: "Gibraltar",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GL",
      value: "Greenland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GM",
      value: "Gambia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GN",
      value: "Guinea",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GP",
      value: "Guadeloupe",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GQ",
      value: "Equatorial Guinea",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GR",
      value: "Greece",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GS",
      value: "South Georgia and the South Sandwich Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GT",
      value: "Guatemala",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GU",
      value: "Guam",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GW",
      value: "Guinea-Bissau",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GY",
      value: "Guyana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HK",
      value: "Hong Kong",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HM",
      value: "Heard Island and McDonald Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HN",
      value: "Honduras",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HR",
      value: "Croatia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HT",
      value: "Haiti",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HU",
      value: "Hungary",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ID",
      value: "Indonesia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IE",
      value: "Ireland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IL",
      value: "Israel",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IM",
      value: "Isle of Man",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IN",
      value: "India",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IO",
      value: "British Indian Ocean Territory",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IQ",
      value: "Iraq",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IR",
      value: "Iran, Islamic Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IS",
      value: "Iceland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IT",
      value: "Italy",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "JE",
      value: "Jersey",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "JM",
      value: "Jamaica",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "JO",
      value: "Jordan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "JP",
      value: "Japan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KE",
      value: "Kenya",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KG",
      value: "Kyrgyzstan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KH",
      value: "Cambodia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KI",
      value: "Kiribati",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KM",
      value: "Comoros",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KN",
      value: "Saint Kitts and Nevis",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KP",
      value: "Korea, Democratic People's Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KR",
      value: "Korea, Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KW",
      value: "Kuwait",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KY",
      value: "Cayman Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KZ",
      value: "Kazakhstan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LA",
      value: "Lao People's Democratic Republic",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LB",
      value: "Lebanon",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LC",
      value: "Saint Lucia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LI",
      value: "Liechtenstein",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LK",
      value: "Sri Lanka",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LR",
      value: "Liberia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LS",
      value: "Lesotho",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LT",
      value: "Lithuania",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LU",
      value: "Luxembourg",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LV",
      value: "Latvia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LY",
      value: "Libya",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MA",
      value: "Morocco",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MC",
      value: "Monaco",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MD",
      value: "Moldova, Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ME",
      value: "Montenegro",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MF",
      value: "Saint Martin (French part)",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MG",
      value: "Madagascar",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MH",
      value: "Marshall Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MK",
      value: "Macedonia, the former Yugoslav Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ML",
      value: "Mali",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MM",
      value: "Myanmar",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MN",
      value: "Mongolia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MO",
      value: "Macao",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MP",
      value: "Northern Mariana Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MQ",
      value: "Martinique",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MR",
      value: "Mauritania",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MS",
      value: "Montserrat",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MT",
      value: "Malta",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MU",
      value: "Mauritius",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MV",
      value: "Maldives",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MW",
      value: "Malawi",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MX",
      value: "Mexico",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MY",
      value: "Malaysia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MZ",
      value: "Mozambique",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NA",
      value: "Namibia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NC",
      value: "New Caledonia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NE",
      value: "Niger",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NF",
      value: "Norfolk Island",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NG",
      value: "Nigeria",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NI",
      value: "Nicaragua",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NL",
      value: "Netherlands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NO",
      value: "Norway",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NP",
      value: "Nepal",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NR",
      value: "Nauru",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NU",
      value: "Niue",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NZ",
      value: "New Zealand",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "OM",
      value: "Oman",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PA",
      value: "Panama",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PE",
      value: "Peru",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PF",
      value: "French Polynesia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PG",
      value: "Papua New Guinea",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PH",
      value: "Philippines",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PK",
      value: "Pakistan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PL",
      value: "Poland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PM",
      value: "Saint Pierre and Miquelon",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PN",
      value: "Pitcairn",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PR",
      value: "Puerto Rico",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PS",
      value: "Palestine, State of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PT",
      value: "Portugal",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PW",
      value: "Palau",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PY",
      value: "Paraguay",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "QA",
      value: "Qatar",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RE",
      value: "Réunion",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RO",
      value: "Romania",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RS",
      value: "Serbia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RU",
      value: "Russian Federation",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RW",
      value: "Rwanda",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SA",
      value: "Saudi Arabia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SB",
      value: "Solomon Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SC",
      value: "Seychelles",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SD",
      value: "Sudan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SE",
      value: "Sweden",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SG",
      value: "Singapore",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SH",
      value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SI",
      value: "Slovenia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SJ",
      value: "Svalbard and Jan Mayen",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SK",
      value: "Slovakia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SL",
      value: "Sierra Leone",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SM",
      value: "San Marino",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SN",
      value: "Senegal",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SO",
      value: "Somalia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SR",
      value: "Suriname",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SS",
      value: "South Sudan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ST",
      value: "Sao Tome and Principe",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SV",
      value: "El Salvador",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SX",
      value: "Sint Maarten (Dutch part)",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SY",
      value: "Syrian Arab Republic",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SZ",
      value: "Eswatini",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TC",
      value: "Turks and Caicos Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TD",
      value: "Chad",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TF",
      value: "French Southern Territories",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TG",
      value: "Togo",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TH",
      value: "Thailand",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TJ",
      value: "Tajikistan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TK",
      value: "Tokelau",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TL",
      value: "Timor-Leste",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TM",
      value: "Turkmenistan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TN",
      value: "Tunisia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TO",
      value: "Tonga",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TR",
      value: "Turkey",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TT",
      value: "Trinidad and Tobago",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TV",
      value: "Tuvalu",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TW",
      value: "Taiwan, Province of China",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TZ",
      value: "Tanzania, United Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UA",
      value: "Ukraine",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UG",
      value: "Uganda",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AE",
      value: "United Arab Emirates",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UM",
      value: "United States Minor Outlying Islands",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UY",
      value: "Uruguay",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UZ",
      value: "Uzbekistan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VA",
      value: "Holy See",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VC",
      value: "Saint Vincent and the Grenadines",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VE",
      value: "Venezuela, Bolivarian Republic of",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VG",
      value: "Virgin Islands, British",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VI",
      value: "Virgin Islands, U.S.",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VN",
      value: "Viet Nam",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VU",
      value: "Vanuatu",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WF",
      value: "Wallis and Futuna",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WS",
      value: "Samoa",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "YE",
      value: "Yemen",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "YT",
      value: "Mayotte",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ZA",
      value: "South Africa",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ZM",
      value: "Zambia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ZW",
      value: "Zimbabwe",
    },
  ],
  state: [
    {
      name: false,
      value: "State...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AL",
      value: "Alabama",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AK",
      value: "Alaska",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AZ",
      value: "Arizona",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "AR",
      value: "Arkansas",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CA",
      value: "California",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CO",
      value: "Colorado",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "CT",
      value: "Connecticut",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DE",
      value: "Delaware",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "DC",
      value: "District of Columbia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "FL",
      value: "Florida",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "GA",
      value: "Georgia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "HI",
      value: "Hawaii",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ID",
      value: "Idaho",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IL",
      value: "Illinois",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IN",
      value: "Indiana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "IA",
      value: "Iowa",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KS",
      value: "Kansas",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "KY",
      value: "Kentucky",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "LA",
      value: "Louisiana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ME",
      value: "Maine",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MD",
      value: "Maryland",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MA",
      value: "Massachusetts",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MI",
      value: "Michigan",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MN",
      value: "Minnesota",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MS",
      value: "Mississippi",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MO",
      value: "Missouri",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "MT",
      value: "Montana",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NE",
      value: "Nebraska",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NV",
      value: "Nevada",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NH",
      value: "New Hampshire",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NJ",
      value: "New Jersey",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NM",
      value: "New Mexico",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NY",
      value: "New York",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "NC",
      value: "North Carolina",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "ND",
      value: "North Dakota",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "OH",
      value: "Ohio",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "OK",
      value: "Oklahoma",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "OR",
      value: "Oregon",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "PA",
      value: "Pennsylvania",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "RI",
      value: "Rhode Island",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SC",
      value: "South Carolina",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "SD",
      value: "South Dakota",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TN",
      value: "Tennessee",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "TX",
      value: "Texas",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "UT",
      value: "Utah",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VT",
      value: "Vermont",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "VA",
      value: "Virginia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WA",
      value: "Washington",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WV",
      value: "West Virginia",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WI",
      value: "Wisconsin",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "WY",
      value: "Wyoming",
    },
  ],
  cardType: [
    {
      name: false,
      value: "Choose...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "visa",
      value: "Visa",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "amex",
      value: "American Express",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "master",
      value: "MasterCard",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "discover",
      value: "Discover",
    },
  ],
  expirationMonth: [
    {
      name: false,
      value: "Choose...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "01",
      value: "January",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "02",
      value: "February",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "03",
      value: "March",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "04",
      value: "April",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "05",
      value: "May",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "06",
      value: "June",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "07",
      value: "July",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "08",
      value: "August",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "09",
      value: "September",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "10",
      value: "October",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "11",
      value: "November",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "12",
      value: "December",
    },
  ],
  expirationYear: [
    {
      name: false,
      value: "Choose...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "21",
      value: "2021",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "22",
      value: "2022",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "23",
      value: "2023",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "24",
      value: "2024",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "25",
      value: "2025",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "26",
      value: "2026",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "27",
      value: "2027",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "28",
      value: "2028",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "29",
      value: "2029",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "30",
      value: "2030",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "31",
      value: "2031",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "32",
      value: "2032",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "33",
      value: "2033",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "34",
      value: "2034",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "35",
      value: "2035",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "36",
      value: "2036",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "37",
      value: "2037",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "38",
      value: "2038",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "39",
      value: "2039",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "40",
      value: "2040",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "41",
      value: "2041",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "42",
      value: "2042",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "43",
      value: "2043",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "44",
      value: "2044",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "45",
      value: "2045",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "46",
      value: "2046",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "47",
      value: "2047",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "48",
      value: "2048",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "49",
      value: "2049",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "50",
      value: "2050",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "51",
      value: "2051",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "52",
      value: "2052",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "53",
      value: "2053",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "54",
      value: "2054",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "55",
      value: "2055",
    },
  ],
  shippingMethod: [
    {
      id: Math.random().toString(36).slice(2),
      name: "a",
      value: "Shipping Method",
    },
  ],
  billingModel: [
    {
      id: Math.random().toString(36).slice(2),
      name: "a",
      value: "Billing Model",
    },
  ],
  defaultOptions: [
    {
      name: false,
      value: "Choose...",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "01",
      value: "Option One",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "02",
      value: "Option Two",
    },
    {
      id: Math.random().toString(36).slice(2),
      name: "03",
      value: "Option Three",
    },
  ],
};

export const fieldNames: FieldNameTypes[] = [
  {
    label: "Name",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.NAME,
      placeholder: "Name",
    },
  },
  {
    label: "Email",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.EMAIL,
      placeholder: "Email",
    },
  },
  {
    label: "Password",
    fieldType: FormFieldTypes.PASSWORD,
    attributes: {
      name: FieldNamePresets.PASSWORD,
      placeholder: "Password",
    },
  },
  {
    label: "Username",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.USERNAME,
      placeholder: "Username",
    },
  },
  {
    label: "Company",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.COMPANY,
      placeholder: "Company",
    },
  },
  {
    label: "First Name",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.FIRSTNAME,
      placeholder: "First Name",
    },
  },
  {
    label: "Last Name",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.LASTNAME,
      placeholder: "Last Name",
    },
  },
  {
    label: "Address Line 1",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.ADDRESS,
      placeholder: "Address Line 1",
    },
  },
  {
    label: "Address Line 2",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.ADDRESS2,
      placeholder: "Address Line 2",
    },
  },
  {
    label: "City",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.CITY,
      placeholder: "City",
    },
  },
  {
    label: "State",
    fieldType: FormFieldTypes.DROPDOWN,
    attributes: {
      name: FieldNamePresets.STATE,
      placeholder: "State",
      items: selectOptions.state,
    },
  },
  {
    label: "Country",
    fieldType: FormFieldTypes.DROPDOWN,
    attributes: {
      name: FieldNamePresets.COUNTRY,
      placeholder: "Country",
      items: selectOptions.country,
    },
  },
  {
    label: "Zip/Postal Code",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.ZIP,
      placeholder: "Zip/Postal Code",
    },
  },
  {
    label: "Phone",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.PHONE,
      placeholder: "Phone",
    },
  },
  {
    label: "Credit Card CVV",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.CVV,
      placeholder: "CVV",
    },
  },
  {
    label: "Credit Card Type",
    fieldType: FormFieldTypes.DROPDOWN,
    attributes: {
      name: FieldNamePresets.CARDTYPE,
      placeholder: "Card Type",
      items: selectOptions.cardType,
    },
  },
  {
    label: "Credit Card Number",
    fieldType: FormFieldTypes.TEXT,
    attributes: {
      name: FieldNamePresets.CARDNUMBER,
      placeholder: "•••• •••• •••• ••••",
    },
  },
  {
    label: "Credit Card Expiration Month",
    fieldType: FormFieldTypes.DROPDOWN,
    attributes: {
      name: FieldNamePresets.EXPIRATIONMONTH,
      placeholder: "Expiration Month",
      items: selectOptions.expirationMonth,
    },
  },
  {
    label: "Credit Card Expiration Year",
    fieldType: FormFieldTypes.DROPDOWN,
    attributes: {
      name: FieldNamePresets.EXPIRATIONYEAR,
      placeholder: "Expiration Year",
      items: selectOptions.expirationYear,
    },
  },
  {
    label: "Credit Card Expiration Date",
    fieldType: FormFieldTypes.DATE,
    attributes: {
      name: FieldNamePresets.EXPIRATIONDATE,
      placeholder: "Expiration Date",
    },
  },
];

export default fieldNames;
