import { Page, Container } from '../Page';

import { SimpleContainer } from "@/ui/SimpleContainer";

import AccountInfoPage from "./AccountInfoPage";

export const Pages: React.FC = () => {
	return (
		<Page topbar={{ page: [{ label: "Pages" }] }}>
			<Container>
				<p className="text-lg font-semibold">Pages</p>

				{/* Account Info Page */}
				<a name="account_info" />
				<div className="mt-7">
					<SimpleContainer label="Account Info Page">
						<AccountInfoPage />
					</SimpleContainer>
				</div>
			</Container>
		</Page>
	);
};
