import { notificationsList } from "./sampleData";

// Define the NotificationsSection component as a functional component
export const NotificationsSection = () => {
  // Define the Notification component as a functional component that takes in props
  const Notification = ({ title, icon, caption }) => {
    // Render a div with a flex layout and a gap of 2
    return (
      <div className="flex gap-2">
        {/* Render a div with a fixed height and width, a rounded border, and a background color */}
        <div
          className={`h-6 w-6 flex-none p-1 text-black bg-lightblue-100 rounded-lg`}
        >
          {/* Render the icon prop */}
          {icon}
        </div>
        {/* Render a div that takes up the remaining space */}
        <div className="flex-1">
          {/* Render a paragraph with the title prop, truncated if it overflows */}
          <p className="whitespace-nowrap overflow-hidden text-ellipsis w-[200px] text-black dark:text-white">
            {title}
          </p>
          {/* Render a paragraph with the caption prop, in smaller text */}
          <p className="text-xs text-black/40 dark:text-white/40">{caption}</p>
        </div>
      </div>
    );
  };

  // Render the NotificationsSection component
  return (
    <div data-testid="NotificationsSection">
      {/* Render a heading */}
      <h4 className="font-semibold text-black dark:text-white mb-5">
        Notifications
      </h4>
      {/* Render a div with a flex column layout and a gap of 4 */}
      <div className="flex flex-col gap-4">
        {/* Map over the notificationsList array and render a Notification component for each notification */}
        {notificationsList.map(({ title, icon, caption }, index) => (
          <Notification
            key={index}
            title={title}
            icon={icon}
            caption={caption}
          />
        ))}
      </div>
    </div>
  );
};