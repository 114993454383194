import React, { useContext, useEffect } from "react";

import { AppContext } from "@/AppContext";
import { Breadcrumbs } from "@/ui/Breadcrumbs";

import { SidebarToggleIcon, DarkToggleIcon, LightToggleIcon } from "@/ui/Icons";
import { Link } from "react-router-dom";
import { truncate } from "lodash";
import { TopbarProps } from "./types";
import UniversalSearch from "@/ui/UniversalSearch";
import { Sparkle } from "@phosphor-icons/react";

export const Topbar: React.FC<TopbarProps> = ({
  page = [{ label: "Default", href: "/" }],
  items = null,
  leftItems = null,
}) => {
  // Get the necessary context from the AppContext
  const {
    darkMode,
    sidebarOpen,
    rightSidebarOpen,
    setDarkMode,
    setSidebarOpen,
    setRightSidebarOpen,
    disableRightSidebar,
  } = useContext(AppContext);

  // Toggle the left sidebar
  const toggleLeftSidebar = () => {
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  // Toggle the right sidebar
  const toggleRightSidebar = () => {
    localStorage.setItem("rightSidebarOpen", !rightSidebarOpen);
    setRightSidebarOpen(!rightSidebarOpen);
  };

  // useEffect(() => {
  //   // Set the current page settings in local storage
  //   localStorage.setItem("currentPageSettings", JSON.stringify(page));
  // }, []);

  // Render the top bar
  return (
    <div
      data-testid="Topbar"
      className="border-b border-black/10 dark:border-white/10 py-[18px] px-7 flex items-center justify-between"
      style={{ maxHeight: 65 }}
    >
      <div className="flex items-center gap-2">
        {/* Toggle button for the left sidebar */}
        <button
          type="button"
          className="text-black dark:text-white"
          onClick={toggleLeftSidebar}
          data-testid="Topbar-LeftSidebarToggle"
        >
          <SidebarToggleIcon />
        </button>

        {/* Breadcrumbs */}
        <Breadcrumbs
          items={page.map((item) => ({
            label: truncate(item.label, { length: 30 }),
            href: item.href,
            icon: item.icon,
          }))}
          type="Slash"
        />
        {leftItems}
      </div>

      <div className="flex items-center gap-5">
        {/* Universal search */}
        {/* <UniversalSearch /> */}

        {/* Action buttons */}
        {items}

        <div
          data-testid="Topbar-DarkModeToggle"
          className="flex items-center gap-2"
        >
          {/* Toggle button for the right sidebar */}
          {!disableRightSidebar && (
            <button
              data-testid="Topbar-RightSidebarToggle"
              type="button"
              className="text-black dark:text-white rotate-180"
              onClick={toggleRightSidebar}
            >
              {/* <SidebarToggleIcon /> */}
              <Sparkle className="text-lg" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
