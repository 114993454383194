import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";

export const PartnerPortals = ({ name = "" }) => {
  const [portalList, setPortalList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // get the portalList from the server
  const loadPortals = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "portal" });
    // const response = await getCampaigns({ token, type: "portal" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((portal: any) => {
          return {
            id: portal.id,
            label: portal.name,
            caption: `Last Updated: ${moment(portal.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setPortalList(seqs);
    }
    setIsLoading(false);
  };

  // load the portalList when the page loads
  useEffect(() => {
    loadPortals();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        // items: (
        //   <Link to="/launchpad/portals" className="justify-end">
        //     <Button label="+ Create a Blog" type={ButtonTypes.OUTLINED} />
        //   </Link>
        // ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(portalList.length) && (
          <EmptyState
            title="No Partner Programs Yet"
            // description="Get started by creating a new portal."
            showImage={false}
          >
            {/* <Link to="/launchpad/portals">
              <Button label="Create a Blog" type={ButtonTypes.DEFAULT} />
            </Link> */}
          </EmptyState>
        )}
        {!isLoading && Boolean(portalList.length) && (
          <Grid>
            {/* Can we change to Gallery View - let's me toggle views */}
            {portalList.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/portals/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
