import * as React from "react";
import { CaretDown, CaretUp } from "@phosphor-icons/react";

interface SectionWrapperProps {

  /**
   * The title to show as the label of the group
   */
  label?: string

  /**
   * The icon to show as part of the label of the group
   */
  icon?: string;

  /**
   * An optional button element to be displayed alongside the label
   */
  button?: JSX.Element;

  /**
   * Whether or not the background of the section is showing.  
   * Useful for when you want to disguise the fact that you're 
   * in a group
   */
  backgroundIsShowing?: Boolean

  /**
   * Whether or not the label is showing
   */
  labelIsShowing?: Boolean

  /**
   * The custom style to apply to this section
   */
  style?: object

  /**
   * Whether or not the group should be expanded by default
   */
  isExpanded?: Boolean

  /**
   * The component to use instead of the default that wraps each group of properties
   */
  sectionWrapperComponent?: React.ReactNode

  /**
   * The children elements to be rendered inside the section wrapper
   */
  children?: JSX.Element | JSX.Element[];

  /**
   * Triggers when the section should expand
   */
  onExpand?: () => void

  /**
   * Triggers when the section should collapse
   */
  onCollapse?: () => void
}

/**
 * A convenient way to wrap your properties into functional groups 
 * (e.g. [Text], [Image Settings], etc.). 
 * 
 * Groups are collapsable so that the amount of controls do not overwhelm the user
 */
const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  button,
  label = "Settings",
  onCollapse = () => { }, 
  onExpand = () => { }, 
  isExpanded = true 
}) => {

  const [isExpandedState, setIsExpandedState] = React.useState(isExpanded);

  // Update the isExpandedState when the isExpanded prop changes
  React.useEffect(() => {
    setIsExpandedState(isExpanded);
  }, [isExpanded]);

  return (
    <div className="p-5">
      <div
        className="flex items-center justify-between p-3 rounded-lg dark:hover:bg-white/5 hover:bg-black/5 cursor-pointer"
        onClick={() => {
          if (isExpandedState) onCollapse();
          else onExpand();

          setIsExpandedState(!isExpandedState);
          // onClick();
        }}
      >
        <h3 className="text-sm font-medium">{label}</h3>
        {button ? button : isExpandedState ? <CaretUp /> : <CaretDown />}
      </div>
      {isExpandedState && <div className="pt-2">{children}</div>}
    </div>
  );
}

export default SectionWrapper;
