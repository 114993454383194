import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
// import { Link } from "react-router-dom";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { CreateNewBlueprint } from "./CreateNewBlueprint";

import { CreateProfileButton } from "./CreateProfileButton";
import { BlueprintIcon, BlueprintList } from "./BlueprintList";
import { blueprintProfiles } from "./data";

export const PlanningBlueprints = ({ name = "" }) => {
  const [planningBlueprints, setPlanningBlueprints] = useState([]);
  const [createBlueprintIsShowing, setCreateBlueprintIsShowing] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blueprintId, setBlueprintId] = useState<string>(null);

  // open the create new blueprint popup
  const openCreateNewBlueprintPopup = (id: string) => {
    setBlueprintId(id);
    setCreateBlueprintIsShowing(true);
  };

  // get the planningBlueprints from the server
  const loadPlanningBlueprints = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "planning" });

    console.log("response", response)

    // const response = await getCampaigns({ token, type: "campaign" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((campaign: any) => {

          let Icon = (
            <img
              className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
              src="https://flowbite.com/docs/images/logo.svg"
              alt=""
            />
          );
          if (campaign?.settings?.type) {
            const iconData = blueprintProfiles.filter(itm => itm.id === campaign?.settings?.type)[0]
            Icon = <BlueprintIcon {...iconData} />;
          }

          return {
            id: campaign.id,
            label: campaign.name,
            caption: `Last Updated: ${moment(campaign.updatedAt).fromNow()}`,
            img: Icon, // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setPlanningBlueprints(seqs);
    }
    setIsLoading(false);
  };

  // load the planningBlueprints when the page loads
  useEffect(() => {
    loadPlanningBlueprints();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: <CreateProfileButton onSelect={openCreateNewBlueprintPopup} />,
      }}
    >
      {createBlueprintIsShowing && (
        <CreateNewBlueprint
          type={blueprintId}
          onClose={() => setCreateBlueprintIsShowing(false)}
        />
      )}
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(planningBlueprints.length) && (
          <EmptyState
            title="You Haven't Created a Profile Yet"
            description="Choose a Planning Blueprint to Get Started."
            showImage={false}
          >
            <div className="mx-auto max-w-3xl px-4 py-8 "> {/*border-b border-t*/}
              <BlueprintList onSelect={openCreateNewBlueprintPopup} />
            </div>
          </EmptyState>
        )}
        {!isLoading && Boolean(planningBlueprints.length) && (
          <Grid>
            {/* Can we change to Gallery View - let's me toggle views */}
            {planningBlueprints.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/planner/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
