import { BigCheck } from "@/ui/BigCheck"
import Grid from "@/ui/Grid"
import Panel from "@/ui/Panel"
import Select from "@/ui/Select";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";

export const FinalDestination = ({ campaignData = {}, unitName }) => {
  return (
    <Panel title="Final Destination">
      <p className="text-sm text-gray-500">
        This is where your visitors will be sent after they complete the funnel.
      </p>
      <Grid cols={3} smCols={1} lgCols={3} className="mt-7">
        <BigCheck
          label="Default Thank You Page"
          caption="This will take your visitors to a default thank you page"
        />
        <BigCheck
          label="Send to a URL"
          caption="This will send your visitors to a url you choose"
        />
        <BigCheck
          label="Send to another page"
          caption="This will send your visitors to one of your pre-built pages"
        />
      </Grid>
      <Select
        label="Select a Campaign"
        items={[
          { label: "Campaign 1", id: "campaign1" },
          { label: "Campaign 2", id: "campaign2" },
          { label: "Campaign 3", id: "campaign3" },
        ]}
        className="mt-7"
        flavor={TextInputFlavors.MODERN}
      />
      <Select
        label="Select a Page"
        items={[
          { label: "Page 1", id: "page1" },
          { label: "Page 2", id: "page2" },
          { label: "Page 3", id: "page3" },
        ]}
        className="mt-7"
        flavor={TextInputFlavors.MODERN}
      />
      <TextInput
        label="Enter a URL"
        placeholder="https://"
        className="mt-7"
        flavor={TextInputFlavors.MODERN}
      />
    </Panel>
  );
};