import * as BlogsApp from "./Blogs";
import * as BotBuilderApp from "./BotBuilder";
import * as CartApp from "./Cart";
import * as F$Internal from "./F$";
import * as CopyApp from "./Copy";
import * as CRMApp from "./CRM";
import * as EnterpriseApp from "./Enterprise";
import * as FunnelsApp from "./Funnels";
import * as InsightsApp from "./Insights";
import * as MediaApp from "./Media";
import * as MemberApp from "./Member";
import * as CollectionsApp from "./Collections";
import * as ContentApp from "./Content";
import * as OutreachApp from "./Outreach";
import * as PagesApp from "./Pages";
import * as FormsApp from "./SmartForms";
import * as PlanningApp from "./Planning";
import * as SitesApp from "./Sites";
import * as SocialApp from "./Social";
import * as WebinarApp from "./Webinar";
import * as WildfireApp from "./Wildfire";
import * as PartnershipsApp from "./Partnerships";
import * as PartnerPortalApp from "./PartnerPortals";
import * as Workflows from "./Workflows";

export default [
  PlanningApp,
  BlogsApp,
  // BotBuilderApp,
  CartApp,
  // CopyApp,
  CRMApp,
  F$Internal,
  PartnershipsApp,
  PartnerPortalApp,
  // EnterpriseApp,
  FunnelsApp,
  InsightsApp,
  MediaApp,
  MemberApp,
  ContentApp,
  CollectionsApp,
  // OutreachApp,
  PagesApp,
  FormsApp,
  SitesApp,
  SocialApp,
  WebinarApp,
  WildfireApp,
  Workflows,
];
