import { getSessionToken } from "@/api/auth";
import { createCampaign, createObject, getCampaign, getObject, updateCampaign, updateObject } from "@/api/campaigns";
import { Page } from "@/ui/Layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@/ui/Layout/Page";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { handleContentSave } from "@/Apps/Content/ContentEditor";
import { CreateContentPopup } from "@/Apps/Content/ContentDashboardPage";
import { BroadcastOptions } from "./components/BroadcastOptions";

// export const handleContentSave = async (objectData, campaignId) => {
//   const item = objectData;

//   // console.log("item", item);

//   // save to db
//   const token = getSessionToken();

//   if (!item?.id) {
//     console.log("create object", item?.name, item?.settings);
//     // create object
//     await createObject({
//       name: item?.name,
//       x: 0, // calculate this
//       y: 0,
//       type: "ContentComponent",
//       settings: { name: item?.name, withAI: false, ...item?.settings },
//       campaignId,
//     });
//   } else {
//     const payload = {
//       token,
//       name: item?.name,
//       settings: item?.settings,
//     };
//     console.log({ payload });
//     await updateObject(item?.id, payload);
//   }

//   alert("Saved!");
//   window.location.reload();
// };

export const BroadcastEditor = () => {
  // const [packageData, setPackageData] = useState({});
  const [broadcastData, setBroadcastData] = useState({name: ""});
  const [packageName, setPackageName] = useState("");
  const [objectData, setObjectData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const { id, objectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getSessionToken();
    
    (async () => {
      setIsLoading(true);

      const data = await getCampaign({ token, id });
      setBroadcastData(data);

      // set email data
      setObjectData(data?.objects[0]);

      setIsLoading(false);
    })();
  }, []);

  const handleSave = async () => {
    // if id is new
    const token = await getSessionToken();
    if (!broadcastData?.id) {
      // create a campaign with the chosen name and settings
      const response = await createCampaign({
        token,
        name: packageName,
        type: "broadcast",
        // settings: objectData?.settings || {},
      });

      const id = response?.id;

      // Create an object
      const object = await createObject({
        name: packageName,
        x: 0,
        y: 0,
        type: "send_email",
        settings: { withAI: false, ...objectData?.settings },
        campaignId: id,
      });

      // route to schedule page
      window.location.href = `/crm/broadcasts/${id}/${object?.data?.object?.id}/confirmation`;
    } else {
      // alert("Saving!");
      // Update Campaign with Title
      const settings = {
        ...broadcastData?.settings,
        name: packageName,
      };
      const response = await updateCampaign(broadcastData.id, {
        token,
        settings,
      });
      // console.log({ response, settings })

      // Update Object with Settings
      const objResponse = await updateObject(objectId, {
        token,
        ...objectData,
        name: packageName,
        settings: objectData?.settings
      });

      console.log({ objResponse });

      // redirect to dashboard
      navigate(`/crm/broadcasts/${id}`);
    }
  }

  return (
    <Page
      topBar={{
        page: [
          { label: "Email Broadcasts", href: "/crm/broadcasts" },
          { label: packageName.length ? packageName : "Untitled Broadcast" },
        ],
        items: (
          <button className="btn btn-info" onClick={handleSave}>
            Save
          </button>
        ),
      }}
    >
      <Container>
        {isLoading ? (
          "Loading..."
        ) : (
          <>
            <div className="p-3">  
            <Button
              label="<- Back to Dashboard"
              type={ButtonTypes.SOFT}
              onClick={() => window.history.back()}
            />
            </div>
            {/* {JSON.stringify(objectData)} */}
            <CreateContentPopup
              activeObject={objectData}
              campaignId={""}
              unitName="Email"
              withModal={false}
              onChange={({ settings = {}, name = "" }) => {
                const { content = "" } = settings;
                setPackageName(name);
                setObjectData({...objectData, settings: { ...objectData?.settings, ...settings }})
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}