import { Component } from "react";

import style from "./style";

import { cancelActions } from "../utils";
import { triggerHook } from "@/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@/plugins/types";
import Grid from "@/ui/_old/Grid";
import Tabs, { Tab } from "@/ui/_old/Tabs";
import TabsComponent from "@/ui/TabsComponent";

const width = 400;

const Drawer = ({ style: stl, onClose = () => {}, children = <></> }) => (
  <div style={stl}>{children}</div>
);

const TabProperties = (props) => {
  const { activeTabObject, tabs = [], activeTab, switchTab, title } = props;
  return (
    <div>
      <div style={{ ...style.tabHeader }}>
        {/* <h2 style={{ padding: 30 }}>
          {title || `${activeTabObject.title} Settings`}
          {tabs.length <= 1 && triggerHook(HookTriggers.onComponentRender, { id: ComponentRenderHooks.PROPERTIES_TITLE, type: props.type }, props)}
        </h2> */}
        {/* {tabs.length > 1 ? (
          <Tabs transparent>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                active={activeTab === tab.id}
                onClick={() => switchTab(tab.id)}
              >
                {tab.title}
              </Tab>
            ))}
          </Tabs>
        ) : null} */}

        {tabs.length && (
          <div className="tabs flex flex-col" style={{ padding: "25px 25px 0"}}>
            <TabsComponent
              tabs={tabs.map(({ id, title }) => ({ label: title, value: id }))}
              activeTab={activeTab}
              setActiveTab={switchTab}
              actionSection={false}
            />
          </div>
        )}

        {tabs.length > 1 &&
          triggerHook(
            HookTriggers.onComponentRender,
            { id: ComponentRenderHooks.PROPERTIES_TITLE, type: props.type },
            props
          )}
      </div>

      {tabs.map((tab, key) => {
        const TabContent = tab.component;
        if (activeTab === tab.id) {
          return (
            <div key={key} style={{ position: "relative" }}>
              <div
                style={{
                  width,
                  ...style.tabContent,
                  // top: tabs.length > 1 ? 150 : 110,
                }}
              >
                <Grid wrap justify="start">
                  <TabContent {...props} />
                </Grid>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

/**
 * This is a sidebar that slides out to reveal all the various
 * items/widgets that can be dragged to the canvas
 * @param {object} props The props
 * @returns {function} The component
 */
class PropertiesWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.defaultTab,
    };
    this.doClose = this.doClose.bind(this);
    this.switchTab = this.switchTab.bind(this);
  }

  doClose() {
    this.props.hideProperties();
    if (this.props.onClose) this.props.onClose();
  }

  switchTab(activeTab) {
    this.setState({ activeTab });
  }

  render() {
    const { tabs } = this.props;
    const { activeTab } = this.state;

    let activeTabObject = false;

    if (tabs) {
      let activeTabKey = activeTab
        ? tabs.findIndex((itm) => itm.id === activeTab)
        : 0;
      if (activeTabKey === -1) activeTabKey = 0;
      activeTabObject = tabs[activeTabKey];
    }

    return (
      <div
        // style={{ width: 0 }}
        onClick={cancelActions}
        onMouseDown={cancelActions}
        onContextMenu={cancelActions}
        onDrag={cancelActions}
        onDragStart={cancelActions}
        // onClick={(e) => e.stopPropagation()}
        // onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); return false }}
      >
        {/* <Drawer
          {...this.props}
          hidden={false}
          overlay={false}
          style={{ width, ...this.props.style, zIndex: 301 }}
          onClose={this.doClose}
        // position={DrawerPosition.LEFT}
        > */}
        {tabs ? (
          <TabProperties
            {...this.props}
            activeTabObject={activeTabObject}
            activeTab={activeTab}
            switchTab={this.switchTab}
          />
        ) : (
          this.props.children
        )}
        {/* </Drawer> */}
      </div>
    );
  }
}

PropertiesWindow.defaultProps = {
  tab: "BASIC",
};

export default PropertiesWindow;
