import { API_DOMAIN } from "../env";
import { collectionExists, createCollection } from "./crm";

/**
 * Logs in a user with the provided email and password.
 * @param {Object} credentials - The user's login credentials.
 * @param {string} credentials.email - The user's email.
 * @param {string} credentials.password - The user's password.
 * @returns {Promise<Object|string>} - A promise that resolves to the user data if successful, or an error message if unsuccessful.
 */
export const loginUser = async ({ email, password }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    email,
    password,
  });

  try {
    const response = await fetch(`${API_DOMAIN}/sessions`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });

    const data = await response.json();

    if (data.error) {
      return data.error;
    } else {
      // check if the user has a crm table
      const { token } = data;
      const collectionId = "crm";
      const resp = await collectionExists(collectionId, { token });

      if (!resp) {
        // create the collection
        await createCollection(collectionId, { token, useSchema: "crm" });
      }

      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

/**
 * Logs out the user with the provided token.
 * @param {Object} options - The logout options.
 * @param {string} options.token - The user's authentication token.
 * @returns {Promise<Object>} - A promise that resolves to the logout response data.
 */
export const logOutUser = async ({ token }) => {
  if (token) {
    // remove the token from the server
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${API_DOMAIN}/sessions`, {
      method: "DELETE",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    });

    const data = await response.json();
    console.log(data);

    return data;
  }
};

/**
 * Retrieves information about the user with the provided token.
 * @param {string|null} token - The user's authentication token.
 * @returns {Promise<Object|string>} - A promise that resolves to the user information if successful, or an error message if unsuccessful.
 */
export const getTokenInfo = async (token: string | null) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const resp = await fetch(`${API_DOMAIN}/sessions/${token}`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      const response = await resp.json();
      // console.log('getTokenInfo', response);

      return response;
    } catch (error) {
      return error;
    }
  }
};

export const getSessionToken = ():string => {
  const token = localStorage.getItem("token");

  // redirect to login if no token
  if (!token) {
    window.location.href = "/login";
  }

  return token;
};
