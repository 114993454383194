import { GroupProps } from "./types";

// Define a functional component that takes in GroupProps as its props
export const Group: React.FC<GroupProps> = ({ label = "", children = null }) => {
  // Render a div with a border, padding, and rounded corners
  return (
    <div data-testid="Group" className="border border-black/10 dark:border-white/10 p-5 rounded-md">
      {/* Render a div with margin bottom */}
      <div className="mb-5">
        {/* Render a paragraph with small text and semibold font */}
        <p className="text-sm font-semibold">{label}</p>
      </div>
      {/* Render a grid with one column and gap between children */}
      <div className="grid grid-cols-1 gap-5">{children}</div>
    </div>
  );
};