import Button from "@/ui/Button";
import Calout from "@/ui/Calout";
import InputGroup from "@/ui/InputGroup";
import { ButtonTypes } from "@/ui/types";
import { Info } from "@phosphor-icons/react";
import { useState } from "react";

export const Wait = ({ objectData, setEditDrawerOpen, onSave = () => {} }) => {
  const { settings = {} } = objectData?.data;
  const [waitPayload, setWaitPayload] = useState(settings?.payload);

  return (
    <div className="flex flex-col gap-4 p-4">
      <Calout
        icon={<Info />}
        title={`How much time would you like to pass before triggering the next task in this sequence?`}
      />
      <InputGroup
        elements={[
          {
            type: "input",
            leftRounded: true,
            placeholder: "0",
            inputType: "number",
            onChange: (e) => {
              setWaitPayload({ ...waitPayload, waitAmt: e.target.value });
            },
          },
          {
            type: "select",
            options: ["Minutes", "Hours", "Days"],
            rightRounded: true,
            onChange: (e) => {
              setWaitPayload({ ...waitPayload, waitUnit: e.target.value });
            },
          },
        ]}
      />
      {/* {JSON.stringify(waitPayload)} */}
      <div className="flex justify-end items-center mt-4 gap-4">
        <Button
          type={ButtonTypes.SOFT}
          label="Cancel"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={() => setEditDrawerOpen(false)}
        />
        <Button
          type={ButtonTypes.SOFT}
          label="OK"
          style={{ padding: "10px 15px", fontSize: "11pt" }}
          onClick={() => {
            // console.log("payload", payload);
            onSave(waitPayload);
          }}
        />
      </div>
    </div>
  );
};
