import React from "react";
import { SmallInfoIcon } from "../Icons";
import { CaloutProps } from "./types";

// Define a functional component called Calout that takes in props of type CaloutProps
export const Calout: React.FC<CaloutProps> = ({
  // Destructure the props object to get the icon, title, caption, and children props
  icon = <SmallInfoIcon />,
  title,
  caption,
  children,
}) => {
  // Return a div element with the following classes and children
  return (
    <div
      data-testid="Calout"
      className="bg-lightpurple-100/50 dark:bg-white/5 rounded-lg p-4 flex items-start gap-1 w-full"
    >
      {/* Render the icon prop */}
      {icon}
      <div>
        {/* Render the title prop */}
        {title && <h3 className="text-sm">{title}</h3>}
        {/* Render the caption prop */}
        {caption && (
          <p className="text-xs text-black/40 dark:text-white/40">{caption}</p>
        )}
      </div>
      {/* Render the children prop */}
      {children}
    </div>
  );
};
