import { KPISimple } from "./KPISimple";
import { KPISimpleGroupProps } from "./types";

export const KPISimpleGroup: React.FC<KPISimpleGroupProps> = ({
  items,
  className = "",
}) => {
  return (
    <div
      data-testid="KPISimpleGroup"
      className={`flex divide-x divide-[#000000]/10 dark:divide-white/10 ${className}`}
      // className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-0 lg:flex lg:divide-x divide-[#000000]/10 dark:divide-white/10"
    >
      {items.map((item, index) => {
        let c = "px-3 sm:px-7";
        if (index === 0) c = "pr-3 sm:pr-7";
        if (item?.type === "bar") c = "sm:pr-7 shrink-0";
        if (index === items.length - 1) c = "pl-3 sm:pl-7";
        return (
          <div key={index} className={c}>
            <KPISimple
              key={index}
              label={item?.label}
              number={item?.number}
              type={item?.type}
              href={item?.href}
              className={item?.className}
              format={item?.format}
            />
          </div>
        );
      })}
    </div>
  );
};

export default KPISimpleGroup;