import { GridProps } from "./types";

// Define a functional component called Grid that takes in GridProps as its props.
export const Grid: React.FC<GridProps> = ({
  // Destructure the children prop from the props object.
  children,
  // Set default values for cols, smCols, lgCols, and gap if they are not provided.
  cols = 1,
  smCols = 2,
  lgCols = 3,
  gap = 7,
  className = "",
}) => {
  // Render a div with a class of "grid" and dynamic classes based on the provided props.
  return (
    <div
      data-testid="Grid"
      className={`grid grid-cols-${cols} sm:grid-cols-${smCols} lg:grid-cols-${lgCols} gap-${gap} ${className}`}
    >
      {/* Render the child elements passed to the component */}
      {children}
    </div>
  );
};