import React from "react";
import { Link } from "react-router-dom";

import { Group } from "../Group";

import { Alert } from "./Alert";
import { Calout } from "../Calout";

export const AllAlerts: React.FC = () => {
  const caption = (
    <>
      Your payment was declined. To start using tools, please{" "}
      <Link to="#" className="text-lightpurple-300">
        Add Payment Method.
      </Link>
    </>
  );
  return (
    <>
      <Group label="Calout">
        <Calout title="We need your attention!!" caption={caption} />
      </Group>

      <Group label="Basic Alert">
        <Alert
          type="basic"
          variation="primary"
          message="A simple primary alert—check it out!"
        />
        <Alert
          type="basic"
          variation="secondary"
          message="A simple Secondary alert—check it out!"
        />
        <Alert
          type="basic"
          variation="info"
          message="A simple Info alert—check it out!"
        />
        <Alert
          type="basic"
          variation="success"
          message="A simple Success alert—check it out!"
        />
        <Alert
          type="basic"
          variation="warning"
          message="A simple Warning alert—check it out!"
        />
        <Alert
          type="basic"
          variation="danger"
          message="A simple Danger alert—check it out!"
        />
      </Group>

      <Group label="Alert with Close button">
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="primary"
          message="A simple primary alert—check it out!"
        />
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="secondary"
          message="A simple Secondary alert—check it out!"
        />
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="info"
          message="A simple Info alert—check it out!"
        />
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="success"
          message="A simple Success alert—check it out!"
        />
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="warning"
          message="A simple Warning alert—check it out!"
        />
        <Alert
          onClose={() => alert("Close!")}
          type="alertWithCloseButton"
          variation="danger"
          message="A simple Danger alert—check it out!"
        />
      </Group>

      <Group label="Outline Alerts">
        <Alert
          type="outlineAlert"
          variation="primary"
          message="A simple primary alert—check it out!"
        />
        <Alert
          type="outlineAlert"
          variation="secondary"
          message="A simple Secondary alert—check it out!"
        />
        <Alert
          type="outlineAlert"
          variation="info"
          message="A simple Info alert—check it out!"
        />
        <Alert
          type="outlineAlert"
          variation="success"
          message="A simple Success alert—check it out!"
        />
        <Alert
          type="outlineAlert"
          variation="warning"
          message="A simple Warning alert—check it out!"
        />
        <Alert
          type="outlineAlert"
          variation="danger"
          message="A simple Danger alert—check it out!"
        />
      </Group>

      <Group label="Alert with Icon">
        <Alert
          type="alertWithIcon"
          variation="primary"
          message="A simple primary alert—check it out!"
        />
        <Alert
          type="alertWithIcon"
          variation="secondary"
          message="A simple Secondary alert—check it out!"
        />
        <Alert
          type="alertWithIcon"
          variation="info"
          message="A simple Info alert—check it out!"
        />
        <Alert
          type="alertWithIcon"
          variation="success"
          message="A simple Success alert—check it out!"
        />
        <Alert
          type="alertWithIcon"
          variation="warning"
          message="A simple Warning alert—check it out!"
        />
        <Alert
          type="alertWithIcon"
          variation="danger"
          message="A simple Danger alert—check it out!"
        />
      </Group>
    </>
  );
};
