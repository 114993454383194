import React, { useContext } from "react";
import { Menu, Item, Separator, Submenu } from "react-contexify";

import { AppContext } from "@/AppContext";

import { ContextMenuProps, ItmProps } from "./types";
import "./styles.css";

export const Itm: React.FC<ItmProps> = ({ children }) => {
  // This component is a simple wrapper around the children passed to it
  // It adds a class name to the div that wraps the children
  return (
    <div data-testid="ContextMenuItem" className="text-[13px] flex">
      {children}
    </div>
  );
};

// This component takes an array of items as a prop and renders them as a dropdown menu
export const RenderItems = ({ items = [] }) => {
  // If there are no items, return null
  if (!items.length) return null;

  // Otherwise, render the items
  return (
    <>
      {items?.map((item, index) => {
        // Destructure the item object to get its properties
        const {
          icon = null,
          component = null,
          type,
          disabled,
          items,
          onClick,
          label,
        } = item;

        // If the item type is "component", render the component
        if (type === "component") {
          return component;
        }
        // If the item type is "separator", render a separator
        else if (type === "separator") {
          return <Separator key={index} />;
        }
        // If the item is disabled, render a disabled item
        else if (disabled) {
          return (
            <Item disabled key={index}>
              <Itm>{item?.label}</Itm>
            </Item>
          );
        }
        // If the item has sub-items, render a submenu
        else if (items) {
          return (
            <Submenu label={item?.label} key={index}>
              {/* <div style={{ top: 250 }}> */}
              <RenderItems items={item?.items} />
              {/* </div> */}
            </Submenu>
          );
        }
        // Otherwise, render a regular item
        else {
          return (
            <Item onClick={onClick} key={index}>
              <Itm>
                {icon}
                {label}
              </Itm>
            </Item>
          );
        }
      })}
    </>
  );
};

/**
 * The Menu component is a dropdown menu that is a wrapper around
 * react-contexify. It allows users to display a list of options when
 * they click on a specific element. The component provides a flexible
 * and customizable way to create context menus and dropdown menus in
 * your React application.
 * @param React.FC
 * @returns
 */
export const ContextMenu: React.FC<ContextMenuProps> = ({ items = [], id }) => {
  // This component is the main component that renders the dropdown menu
  // It takes an array of items and an id as props
  // It uses the useState hook to manage the visibility of the menu
  // It renders a div that wraps the Menu component and sets its visibility based on the state
  // It renders the Menu component with the id and a RenderItems component as children
  const [isVisible, setIsVisible] = React.useState(true);
  const { darkMode } = useContext(AppContext);

  return (
    <div
      data-testid="ContextMenu"
      style={{ visibility: isVisible ? "visible" : "hidden" }}
    >
      <Menu
        animation="none"
        onVisibilityChange={setIsVisible}
        theme={darkMode ? "dark" : ""}
        id={id}
      >
        <RenderItems items={items} />
      </Menu>
    </div>
  );
};
