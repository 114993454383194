import { getSessionToken } from "@/api/auth";
import { getCampaigns } from "@/api/campaigns";
import { getOnboardingData } from "@/api/entities";
import moment from "moment";

export const getPromptForAlternativeWidgetCopy = (type, html, flavor = "improve") => {
  return `Act as a worldclass marketing copy expert.  Using the context provided about my business in your system prompt, your task is to ${flavor === "improve" ? "improve the provided content" : "craft a powerful " + type + " for a web page"} and return your response using similar html formating that was in the original content.

  ${flavor === "create" && "If the current content is about a business completely different than mine (and what you see in the system prompt), create brand new, completely different content about my business instead."}

  ${flavor === "create" && "If the current content is about a product or service completely different than mine (and what you see in the system prompt), create brand new, completely different content about my product or service instead."}

  ${flavor === "create" && "If the current content is about a feature or benefit that does not apply to my business, brand story, or product (and what you see in the system prompt), create brand new, completely different content about a feature or benefit associated with my product or service instead."}

  ${flavor === "create" && "If the current content is about a solution to a problem that does not apply to my business, brand story, or product (and what you see in the system prompt), create brand new, completely different content about a feature, benefit, or solution associated with my product or service instead."}

In other words, start by determining the plain version of the provided html snippet?  Then come up with an improvement to the message using a similar length of words, then finally, reinsert the improved version back into the original html so that the styling is the same but the words are different?

Keep in mind that this is a ${type} object and you should follow best practices for ${type} writing.

Here is the original html snippet:
'''
${html}
'''

In your response, do not show the plain version, alternative version.  Just return the html (and ONLY that) version, and I will take care of the rest.  Do not prefix your response with anything, just return the html and that's it.
`;
};

// export const getPromptForAlternativePageCopy = (content) => {
//   return `You are a worldclass marketing copy expert, and you are tasked with updating the content of a landing page.

// I have attached an json array of web page elements, which represents the landing page.  Focusing on just the TEXT, PARAGRAPH, and HEADLINE elements, please update the html content in each one.  You'll find the content in the \`html\` key of the element.  You'll know the type of object by observing the \`type\` key of the element.  

// For example, an object representing a HEADLINE element with content saying "The Headline's HTML Content" looks like this:

// '''
// {
//    "id": "x0h22wacp3q",
//    "parent": "z1zayuowrt",
//    "type": "Headline",
//    "html": "The Headline's <strong>HTML Content</strong>",
//    "properties": { display: "block", marginTop: "1.5rem" }
// }
// '''

// You'll find that many of the TEXT, PARAGRAPH, and HEADLINE elements are formatted using simple HTML code.

// When you come up with updated content, first decide what the plain text version of the content should be, then reinsert that content back into the original html in a way that mirrors the same html formating and styling of the original version.

// For example, if the original html key is:

// '''
// <p style="line-height: 3rem;"><span style="color: #ffffff; font-size: 3.2rem;">THE</span></p>
// <p style="line-height: 3rem;"><span style="color: #3399ff; font-size: 6.2rem;"><strong>Headline's</strong></span></p>
// <p style="line-height: 2.5rem;"><strong><span style="color: #3399ff; font-size: 3.2rem;">HTML</span></strong></p>
// <p style="line-height: 2.5rem;"><span style="color: #ffffff; font-size: 3.2rem;">Content</span></p>
// '''

// and you determine that the updated text should be "A Brand New Day!", following the instruction that you should use the same html format and styling, the new version should be:

// '''
// <p style="line-height: 3rem;"><span style="color: #ffffff; font-size: 3.2rem;">A</span></p>
// <p style="line-height: 3rem;"><span style="color: #3399ff; font-size: 6.2rem;"><strong>Brand</strong></span></p>
// <p style="line-height: 2.5rem;"><strong><span style="color: #3399ff; font-size: 3.2rem;">New</span></strong></p>
// <p style="line-height: 2.5rem;"><span style="color: #ffffff; font-size: 3.2rem;">Day!</span></p>
// '''

// Respond with a new, updated json array.

// VERY IMPORTANT: It should be the exact same json array that was originally provided, with the only thing different being the updated html keys of the TEXT, PARAGRAPH, and HEADLINE elements. 

// In your response, just return the updated json object, and I will take care of the rest.  Do not prefix your response with anything, just return the updated object.

// Here is the original json array:

// '''
// ${JSON.stringify(content, null, 2)}
// '''
// `;
// };

export const getSystemPrompt = async ({
  pageSettings,
  userDetails,
  assistant,
  profileData,
  type = "chat",
}) => {
  // let's get avatar answers
  // first lets get the token
  const token = getSessionToken();
  // const profileData = {};
  const profileDataToUse = profileData || await getOnboardingData(token, null, null);

  // remove the token and the id from the user details
  if (userDetails) delete userDetails.id;
  if (userDetails) delete userDetails.token;
  if (userDetails) delete userDetails.deleted;
  if (userDetails) delete userDetails.permissions;
  if (userDetails) delete userDetails.appId;
  if (userDetails) delete userDetails.host;

  let mainStartPrompt = `You are a friendly, worldclass marketing expert.  
  
  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

  let profileContextPrompt = `Here is some context about the user and their business (in JSON format for your convenience).  Please provide a response to the user's question (but make no reference to the fact that you got this information from JSON context.  The fact is you got it from the information they entered in the Launch OS Profile and the Smart Planner). 

  Here is the context about the user:

  '''
  ${JSON.stringify(userDetails, null, 2)}
  '''
  
  Here is the context about their business: 
    
  '''
  ${JSON.stringify(profileDataToUse, null, 2)}
  '''
  `;

  let endPrompt = `If the user asks a question somewhat unrelated to marketing, try to answer from a marketing perspective, but don't be too strict about this.  Only decline to answer if it's completely unrelated to marketing.  Instead, politely ask them to ask a marketing related question.  If they ask a marketing related question, or they ask anything associated with the context provided, answer it.  If you don't know the answer, politely tell them that you don't know the answer.  If you need more information, ask them for it.  If you need to clarify something, ask them for clarification.  Render your response using markdown`;

  if (type !== "chat") endPrompt = "";

  let currentContextPrompt = "";

  // TODO: put this in the gptContext of pageSettings
  if (pageSettings[0]?.label === "Funnels" && !pageSettings[1]) {
    const funnelList = await getCampaigns({ token, type: "" });
    currentContextPrompt = `Here are the funnels the user has created so far:
    
    '''

    ${JSON.stringify(
      funnelList.map((itm) => ({
        id: itm.id,
        name: itm.name,
        lastUpdated: moment(itm.updatedAt).fromNow(),
      })),
      null,
      2
    )}

    '''

    If the user asks anything about any of these funnels, answer to the best of your ability.  If you're unable to derive the answer from this list, prompt the user to open the funnel, and then ask the question again.  Render your response using markdown.

    If you render the name of any funnel, use the funnel's name as a link to the funnel.  For example, if the funnel's name is "My First Funnel" with an id of "123", you should render it as [My First Funnel](/funnels/123).`;
  }

  if (pageSettings[2]?.context) {
    currentContextPrompt = pageSettings[2].gptContext?.content;
  }

  // let systemPrompt = mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;



  if (assistant.id === "marketResearch") {
    mainStartPrompt = `You are a worldclass marketing research expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to market research, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or market research related question.  Render your response using markdown`;

    // systemPrompt = mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  if (assistant.id === "trafficSEO") {
    mainStartPrompt = `You are a worldclass marketing traffic and SEO expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to traffic or SEO, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or traffic/SEO related question.  Render your response using markdown`;

    // systemPrompt =
    //   mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  if (assistant.id === "copywriting") {
    mainStartPrompt = `You are a worldclass marketing copy expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to copywriting, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or copywriting related question.  Render your response using markdown`;

    // systemPrompt =
      // mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  // console.log({ profileData, pageSettings, userDetails, systemPrompt });
  // console.log({ assistant });

  return { mainStartPrompt, profileContextPrompt, currentContextPrompt, endPrompt };
};

export const getFunnelVisionPrompt = () => {
  return `This is a picture of a funnel marketing sequence.  
  
  Please create a json array for each funnel object, with the following properties for each array item: id: uuid(), name (label), x position, y position, type, subType, and an array of the other items it connects to (e.g. connectTo: [{id1}, {id2}].
  
  For example, a funnel with two objects {A -> B}, would have a json array like this:
  [ { id: "a1", name: "A", x: 0, y: 0, type: "TrackingSource", subType: "traffic_source_email", connectTo: ["b1"] }, { id: "b1", name: "B", x: 200, y: 0, type: "PageComponent", subType: "lead_page", connectTo: [] }]
  
  Please only return the JSON array.  Do not under any circumstances return anything else.  No introduction sentence or summaries.  The JSON array MUST adhere to the provided schema.  Do not add, change, or remove any other properties than the ones requested and provided in the example. 
  
  For the type and subType properties, please use the following values:
  
  === New Type: TrackingSource ===
  
  Name: "TrackingSource": 
  Description: This is often (not always) the first object(s) in a funnel.  They're usually found to the left, and they represent the source of the traffic flowing into the funnel.  For example an email, a social media post, a paid ad, etc.
  
  Here are the subType values for "TrackingSource" objects:
  - "traffic_source_facebook"
  - "traffic_source_adwords"
  - "traffic_source_email"
  - "traffic_source_blog"
  - "traffic_source_twitter"
  - "traffic_source_youtube"
  - "traffic_source_webinar"
  - "traffic_source_affiliate"
  - "traffic_source_organic"
  - "traffic_source_redirect"
  - "traffic_source_smartroute"
  - "traffic_source_other"
  
  === /End of TrackingSource Type ===
  
  === New Type: ABSplitComponent ===
  
  Name: "ABSplitComponent": 
  Description: Creates a link that randomly redirects its visitors between two or more destinations.  This is often used to test different landing pages, or to split traffic between two different offers.
  
  There are no subType values for "ABSplitComponent" objects.
  
  === /End of ABSplitComponent Type ===
  
  === New Type: AnotherFunnelComponent ===
  
  Name: "AnotherFunnelComponent": 
  Description: Creates a link that automatically routes its visitors to a page in another funnel.  This is often used to route traffic to a different funnel after a visitor has completed a specific action in the current funnel.
  
  There are no subType values for "AnotherFunnelComponent" objects.
  
  === /End of AnotherFunnelComponent Type ===
  
  === New Type: PageComponent ===
  
  Name: "PageComponent": 
  Description: These objects represent web pages.  They're sometimes found as destinations after a tracking source component.  For example a lead capture page, a sales page, a thank you page, etc.
  
  Here are the subType values for "PageComponent" objects:
  - "blank_page"
  - "content_page"
  - "home_page"
  - "content_page"
  - "lead_squeeze"
  - "legal_page"
  - "live_event_page"
  - "precart_check"
  - "order_page"
  - "sales_page"
  - "sales_page"
  - "thankyou_page"
  - "webinar_page"
  
  === /End of PageComponent Type ===
  
  === New Type: cart: ===
  
  Name: "cart": 
  Description: This object represents a shopping cart.  It's often found as a destination after a sales page.  There are many types of shopping carts, such as ClickBank, Keap, PayPal, SamCart, Shopify, etc.
  
  Here are the subType values for "Cart" objects:
  - "shopping_cart_1shoppingcart"
  - "shopping_cart_amazon"
  - "shopping_cart_clickbank"
  - "shopping_cart_infusionsoft"
  - "shopping_cart_jvzoo"
  - "shopping_cart_nanacast"
  - "shopping_cart_paypal"
  - "shopping_cart_samcart"
  - "shopping_cart_shopify"
  - "shopping_cart_ultracart"
  - "shopping_cart_zaxaa"
  - "shopping_cart_another"
  
  === /End of cart Type ===
  
  === New Type: ContentComponent ===
  
  Name: "ContentComponent": 
  Description: These objects represent content. For example a blog post, social media post, advertisement, an email, etc.
  
  Here are the subType values for "ContentComponent" objects:
  - "blog_post"
  - "facebook_post"
  - "twitterX_post"
  - "instagram_post"
  - "linkedIn_post"
  - "advertisement"
  - "email_content"
  - "content_post"
  
  === /End of ContentComponent Type ===
  
  === New Type: Trigger ===
  
  Name: "Trigger": 
  Description: These objects represent triggers.  When activated, they're used to trigger a task.  For example, a visitor clicking a link, a visitor submitting a form & becoming a new contact, a visitor making a purchase, a specific date, etc.
  
  Here are the subType values for "Trigger" objects:
  - "external_event"
  - "tag"
  - "new_contact"
  - "trigger_webhook"
  - "date"
  
  === /End of Trigger Type ===
  
  === New Type: Task: ===
  
  Name: "Task": 
  Description: These objects represent tasks.  They're often used to send an email, send a text message, send a postcard, etc.
  
  Here are the subType values for "Task" objects:
  - "send_email"
  - "trigger_webhook"
  - "wait"
  
  === /End of Task Type ===
  
  If unsure what type or subType to use, just choose the one that best fits the object.
  
  If the image requested is NOT a funnel marketing sequence, please respond with "This does not appear to be a funnel marketing sequence."
`;
}