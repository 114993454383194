import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import { Page } from "@/ui/Layout";
import { getCampaign, getObject, updateObject } from "@/api/campaigns";
import { useEffect, useState } from "react";
// import { DotsThree } from "@phosphor-icons/react";
import { ListDropdown } from "@/ui/ListDropdown";
import { Button } from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";

import { useCompletion } from "ai/react";

import { getPage, openPublishedPage } from "@/api/pages";
import { DeletePageModal } from "@/Apps/Funnels/Popups/DeletePageModal";
import { EditorCanvas } from "./EditorCanvas";

import { useEditorActions } from "./EditorCanvas";
import {
  getPromptForAlternativeWidgetCopy,
  getSystemPrompt,
} from "@/Apps/AITools/ChatBot/prompts";
import { DropDownItem, ISettings, ComponentTypes } from "./types";
import { AI_DOMAIN, AGENTS_DOMAIN } from "@/env";
import { getSessionToken, getTokenInfo } from "@/api/auth";
import { BrowserWrapper } from "./BrowserWrapper";
// import { DeleteFunnel, NameAndDescription } from "@/Apps/Dashboards/Settings";
// import { AboutYou } from "@/Settings/Profile";
import { PublishPageModal } from "../Popups/PublishPageModal";
import { DownCarrotIcon } from "@/ui/Icons";
import _ from "lodash";
import { Loading } from "@/ui/Layout/Loading";
import { Sparkle } from "@phosphor-icons/react";
import useWindowSize from "react-use/lib/useWindowSize";
import { CreateProfileButton } from "@/Apps/Planning/CreateProfileButton";
import { blueprintProfiles as items } from "@/Apps/Planning/data";

import BeautifulDropdown from "@/ui/BeautifulDropdown";
// import { ConnectedAccounts } from "@/Settings/IntegrationManagement/Integrations";

const shouldPaymentSettingsShow = (content = []) => {
  // is there a credit card form on the page?
  const showMerchantSettings =
    content.findIndex((itm) => itm.name === "cardNumber") > -1 ||
    content
      .filter((itm) => itm.hasOwnProperty("actions"))
      .findIndex(
        (i) =>
          i.actions.findIndex(
            (o) => o.type === "TRIGGER_UPSELL" || o.type === "TRIGGER_BOLTPAY"
          ) > -1
      ) > -1;

  return showMerchantSettings;
};

export const complete = async (prompt, systemPrompt = "") => {
  const response = await fetch(
    `${AGENTS_DOMAIN}/6542d707-c94e-41d6-af99-eb574f7d0904`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        question: `
        ${systemPrompt}

        The Question: ${prompt}
        `,
      }),
    }
  );
  const result = await response.json();
  return result?.text;
};

export const PageEditor = ({ 
  name = "Funnels", 
  homeHref = "/funnels", 
  withChrome = true,
  selected = false,
  campaignId,
  objectId,
}) => {
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [campaignData, setCampaignData] = useState({});
  const [toShow, setToShow] = useState("builder");
  const [campaignName, setCampaignName] = useState("•••");
  const [pageName, setPageName] = useState("•••");
  const [pageData, setPageData] = useState({});
  const [pageId, setPageId] = useState("");
  const [pageIsLoaded, setPageIsLoaded] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState("");
  const [confettiIsShowing, setConfettiIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [url, setUrl] = useState("•••");

  const params = useParams();
  // let { campaignId, objectId, destination = "builder", type } = params;
  let { destination = "builder", type } = params;
  if (!campaignId) campaignId = params.campaignId;
  if (!objectId) objectId = params.objectId;

  const navigate = useNavigate();

  const { updateContent, updateComponent } = useEditorActions();

  // const { complete, isLoading } = useCompletion({
  //   api: `${AI_DOMAIN}/api/completion`,
  //   body: {
  //     type: "chat",
  //     systemPrompt,      
  //   },
  // });
  

  /**
   * Handles the smart copy request by getting new content from the AI API.
   * @param {Object} params - The parameters for the smart copy request.
   * @param {string} params.content - The content for the smart copy request.
   * @param {SmartCopySettings} params.settings - The settings for the smart copy request.
   */
  const handleGetSmartCopyRequest = async ({
    content,
    settings,
    flavor = "improve",
  }: {
    content: string;
    settings: ISettings;
    flavor: "improve" | "create";
  }) => {
    // get the new content from the AI API

    console.log("System Prompt", systemPrompt);

    const prompt = getPromptForAlternativeWidgetCopy(
      settings.type,
      settings.html,
      flavor,
    );

    setIsLoading(true)
    const response = await complete(prompt, systemPrompt);
    setIsLoading(false);
    console.log("Prompt Response", response);

    updateComponent(settings.id, {
      ...settings,
      html: response,
    }, pageId);
  };

  const handleAlternativePageCopyLocally = async () => {
    // get page content
    const token = getSessionToken();
    const { data } = await getPage(pageId, { token });
    const { content } = data;

    // get text content objects
    // console.log(content.filter((itm) => itm.type === "Headline"));
    const textObjects = content.filter(settings => 
      settings?.type === ComponentTypes.TEXT ||
      settings?.type === ComponentTypes.PARAGRAPH ||
      settings?.type === ComponentTypes.HEADLINE ||
      settings?.type === ComponentTypes.V1BUTTON
    );

    // loop through them (async loop)
    for (const itm of textObjects) {
      // use AI to generate alternative content for it (handleGetSmartCopyRequest)
      console.log(itm);
      await handleGetSmartCopyRequest({
        content: itm.html,
        settings: itm,
        flavor: "create",
      });
    }

    // use AI to generate alternative content for it (handleGetSmartCopyRequest)
    // alert("done!  show confetti!");
    setConfettiIsShowing(true);
  };

  /**
   * This function is used to override the default drop-down items for some of the widgets in the editor.
   * This function is called by the EditorCanvas component every time a drop-down is opened.
   * @param items The default drop-down items
   * @param settings The settings of the widget
   * @returns The updated/modified list of drop-down items
   */
  const handleGetDropDownItems = (
    items: DropDownItem[],
    settings: ISettings
  ): DropDownItem[] => {
    if (
      settings?.type === ComponentTypes.TEXT ||
      settings?.type === ComponentTypes.PARAGRAPH ||
      settings?.type === ComponentTypes.HEADLINE ||
      settings?.type === ComponentTypes.V1BUTTON
    ) {
      return [
        {
          id: "gpt_copy",
          text: "Suggest Alternative Copy",
          icon: "auto_awesome",
          onClick: handleGetSmartCopyRequest,
        },
        { id: "divider" },
        ...items,
      ];
    }

    return items;
  };

  useEffect(() => {
    const loadData = async () => {
      const token = getSessionToken();
      const user = await getTokenInfo(token);

      const data = await getCampaign({ token, id: campaignId });
      // console.log({ data })
      const pgData = data?.objects?.find(
        (obj: any) => obj.id === objectId
      )?.page;
      const pgId = pgData?.id;

      setCampaignData(data);
      setCampaignName(data?.name);
      setPageName(data?.objects?.find((obj: any) => obj.id === objectId)?.name);
      setPageData(pgData);
      setPageId(pgId);

      setUrl(`https://${data?.domain?.name}/${pgData?.slug}`);

      // alert("Page Loaded!")

      // Set the System Prompt

      // @ts-ignore
      const theSystemPrompt = await getSystemPrompt({
        pageSettings: getPageSettings(),
        userDetails: user,
        assistant: { id: "copywriting" },
        type: "completion",
      });

      setSystemPrompt(
        Object.keys(theSystemPrompt)
          .map((key) => theSystemPrompt[key])
          .join("\n\n")
      );

    };

    loadData();
  }, []);

  useEffect(() => {
    if (type) setToShow(`${destination}/${type}`);
    else setToShow(destination);
  }, [destination]);

  const pageInfo = _.get(pageData, 'content', [])
    .filter(
      (settings) =>
        settings?.type === ComponentTypes.TEXT ||
        settings?.type === ComponentTypes.PARAGRAPH ||
        settings?.type === ComponentTypes.HEADLINE ||
        settings?.type === ComponentTypes.V1BUTTON ||
        settings?.type === ComponentTypes.PICTURE
    )
    .map((itm) => {
      let obj = itm;
      delete obj.properties;
      return obj;
    });

  const pageSettings = [
    { label: name, href: homeHref },
    { label: campaignName, href: `${homeHref}/${campaignId}` },
    {
      label: pageName,
      canRename: true,
      onChange: console.log,
      context: {
        type: "The Page's Data",
        pageId: pageId,
        content: `This page's name is ${pageName}.  This page's id is ${pageId}.  This page's campaign id is ${campaignId}.  This page's campaign name is ${campaignName}. 
              
              This is a JSON representation of what's currently on the page:

              '''

              ${JSON.stringify(pageInfo, null, 2)}

              '''              
              `,
      },
    }, // To Do: Get Page name & make this a dropdown showing all funnel pages
  ];

  const getPageSettings = () => pageSettings;

  const rewritePageWithAlternativeContent = () => {
    const proceed = window.confirm(
      "This will generate new content for this page.  Are you sure you want to rewrite this page?"
    );
    if (proceed) handleAlternativePageCopyLocally();
  };

  const rewriteIconButton = (pageIsLoaded && !isLoading)
    ? { icon: <Sparkle />, onClick: rewritePageWithAlternativeContent }
    : { icon: <span style={{ zoom: 0.5 }}><Loading type="tiny" /></span> };
  
    const { width, height } = useWindowSize();

  const editorCanvas = (
    <BrowserWrapper
      url={url}
      selected={selected}
      withUndoRedo
      withCustomButtons
      customButtonIcons={[rewriteIconButton]}
    >
      {confettiIsShowing && <Confetti width={width} height={height} recycle={false} onConfettiComplete={() => setConfettiIsShowing(false)} />}

      <EditorCanvas
        campaignId={campaignId}
        pageId={pageId}
        toShow={toShow}
        token={getSessionToken()}
        getDropDownItems={handleGetDropDownItems}
        onLoad={async () => {
          // after one second, check if the object settings has the withAI flag
          // if it does, then call the handleAlternativePageCopyLocally function
          // then remove the withAI flag from the object settings
          const token = getSessionToken();
          const { data } = await getObject(objectId);
          console.log("The Editor has loaded!", data?.object);
          setPageIsLoaded(true);

          if (data?.object?.settings?.withAI) {
            await handleAlternativePageCopyLocally();
            const r = await updateObject(objectId, {
              token,
              ...data?.object?.settings,
              settings: {
                ...data?.object?.settings?.settings,
                withAI: false,
              },
            });
            console.log("updateObject Response", r);
          }
        }}
      />
    </BrowserWrapper>
  );

  if (!withChrome && campaignId !== "" && pageId !== "") return editorCanvas;
  if (pageId === "") return null;

  return (
    <Page
      topBar={{
        page: pageSettings,
        items: (
          <>
            {isLoading ? (
              <span>
                <Loading type="tiny" />
              </span>
            ) : (
              <>
                {pageIsLoaded && (
                  <Button
                    onClick={rewritePageWithAlternativeContent}
                    label="Rewrite Page Content"
                    variant={Variants.INFO}
                  />
                )}
              </>
            )}
            {/* <BeautifulDropdown 
              label="+ Add Item"
              footer={false}
              items={items}
              onSelect={console.log}
            /> */}
            <ListDropdown
              element={
                <Button
                  label={
                    <div className="flex gap-1">
                      <span className="">Actions</span>
                      <DownCarrotIcon className="pt-1" />
                    </div>
                  }
                  type={ButtonTypes.OUTLINED}
                  variant={Variants.INFO}
                />
              }
              items={[
                {
                  id: "view",
                  label: "Publish this Page",
                  onClick: () => setIsPublishPopupOpen(true),
                },
                { id: "divider", type: "divider" },
                {
                  id: "view",
                  label: "View Published Page",
                  onClick: () => openPublishedPage({ campaignData, pageData }),
                },
                {
                  id: "preview",
                  label: "Preview Draft",
                  onClick: () => setToShow(`builder/preview`),
                },
                { id: "divider", type: "divider" },
                {
                  id: "builder",
                  label: "Page Editor",
                  onClick: () => setToShow(`builder`),
                },
                {
                  id: "templates",
                  label: "Page Templates",
                  onClick: () => {
                    // setToShow("random");
                    // setToShow(`templates`);
                    window.location.href = `/funnels/${campaignId}/${objectId}/templates`;
                  },
                },
                { id: "divider", type: "divider" },
                {
                  id: "seo",
                  label: "SEO Settings",
                  onClick: () => setToShow(`seo`),
                },
                {
                  id: "campaign",
                  label: "Campaign Settings",
                  onClick: () => setToShow(`email`),
                },
                ...(shouldPaymentSettingsShow(pageData?.content || [])
                  ? [
                      {
                        id: "campaign",
                        label: "Payment Settings",
                        onClick: () => setToShow(`merchant`),
                      },
                    ]
                  : []),
                {
                  id: "tracking",
                  label: "Tracking Settings",
                  onClick: () => setToShow(`tracking`),
                },
                { id: "divider", type: "divider" },
                {
                  id: "exit",
                  label: "Exit Intent Settings",
                  onClick: () => setToShow(`exit`),
                },
                {
                  id: "css",
                  label: "CSS Settings",
                  onClick: () => setToShow(`css`),
                },
                { id: "divider", type: "divider" },
                {
                  id: "delete",
                  label: "Delete this Page",
                  onClick: () => setIsDeletePopupOpen(true),
                },
              ]}
            />
          </>
        ),
      }}
    >
      {isPublishPopupOpen && (
        <PublishPageModal setIsOpen={setIsPublishPopupOpen} pageId={pageId} />
      )}
      {isDeletePopupOpen && (
        <DeletePageModal
          setIsOpen={setIsDeletePopupOpen}
          campaignId={campaignId}
          pageId={pageId}
        />
      )}

      {/* editor iframe here */}
      {campaignId !== "" && pageId !== "" && (
        <div className="p-7 w-full h-full flex">{editorCanvas}</div>
      )}
    </Page>
  );
};