import React from "react";
import { generatePlansFromPrompt } from "./api/planning";
import { getSessionToken } from "./api/auth";

const Test = () => {
  const [prompt, setPrompt] = React.useState(
    "My name is Kyle Graham (kjgraham@gmail.com)!  Please help me generate a marketing plan for my business.  It's called 10 Minute Pages. (tenminutepages.com) and we're in Leander, TX."
  );
  const [isLoading, setIsLoading] = React.useState(false);
  
  const handleSubmit = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    console.log(prompt);
    const thePlans = await generatePlansFromPrompt({ prompt, token })
    setIsLoading(false);
    console.log("the plans to build", thePlans);
  }
  return (
    <div className="w-6/12 border p-5 rounded m-5 text-center">
      <span className="text-white">Hi....</span>
      <textarea  className="w-full m-2 rounded text-black" style={{ height: 100}} value={prompt} onChange={e => setPrompt(e.target.value)} />

      <button type="submit" className="w-32 bg-white text-black p-1" onClick={handleSubmit}>{isLoading ? 'Loading...' : 'Go'}</button>

    </div>
  );
}

export default Test;
