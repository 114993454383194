import React from "react";

import { ButtonSliderGroupProps } from "./types";

import style from "./style";
import { MaxCheck } from "./MaxCheck";
import { AutoCheck } from "./AutoCheck";
import { ButtonSlider } from "./ButtonSlider";

// export const W = ({ children = <></> }) => <div className="mt-3">{children}</div>

export const ButtonSliderGroup: React.FC<ButtonSliderGroupProps> = ({
  toolTip = "",
  button1 = { value: 0, events: {}, style: {}, icon: null },
  onChange = () => null,
  showSlider = true,
  button2 = { value: 0, events: {}, style: {}, icon: null },
  text,
  minValue = 0,
  value = 0,
  maxValue = 100,
  showMaxCheck = false,
  showAutoCheck = false,
  maxCheckValue = 0,
  autoCheckValue = 0,
  originalValue = 0,
  sliderContainerStyle = { },
}) => {
  const [sliderValue, setSliderValue] = React.useState<string>(
    parseInt(value, 10)
  );
  const [curMaxCheckValue, setMaxCheckValue] = React.useState<number | boolean>(
    maxCheckValue | false
  );
  const [curAutoCheckValue, setAutoCheckValue] = React.useState<
    number | boolean
  >(autoCheckValue | false);

  // For instant changes (while mouse button is down)
  const doChange = (e, value) => {
    setSliderValue(value);
    onChange(value, false);
  };

  // For the final change (mouse button is released)
  const doFinalChange = (e, value) => {
    onChange(value, true);
  };

  // For when a manual change is made (e.g. double click popup window)
  const doManualUpdate = () => {
    const newValue = prompt(
      `Enter a number (${parseInt(minValue, 10)} - ${parseInt(maxValue, 10)})`,
      parseInt(sliderValue, 10)
    );
    // setSliderValue(newValue);

    onChange(parseInt(newValue, 10), true);
    // setTimeout(() => doFinalChange(false, parseInt(newValue, 10)), 300);
  };

  React.useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <fieldset
      style={style.fieldset}
      title={toolTip}
      data-testid="ButtonSliderGroup"
    >
      <div className="flex">
        <legend className="py-2 px-3 w-1/3 text-black/50 dark:text-white/50">{text}</legend>
        <div className="w-2/3">
          {showSlider && !curMaxCheckValue && !curAutoCheckValue && (
            <ButtonSlider
              button1={button1}
              button2={button2}
              doManualUpdate={doManualUpdate}
              doChange={doChange}
              doFinalChange={doFinalChange}
              sliderValue={sliderValue}
              minValue={minValue}
              maxValue={maxValue}
              sliderContainerStyle={sliderContainerStyle}
            />
          )}

          {showMaxCheck && (
            <MaxCheck
              curMaxCheckValue={curMaxCheckValue}
              setMaxCheckValue={setMaxCheckValue}
              maxValue={maxValue}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              doFinalChange={doFinalChange}
            />
          )}

          {showAutoCheck && (
            <AutoCheck
              autoCheckValue={autoCheckValue}
              setAutoCheckValue={setAutoCheckValue}
              curAutoCheckValue={curAutoCheckValue}
              doFinalChange={doFinalChange}
              originalValue={originalValue}
            />
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default ButtonSliderGroup;
