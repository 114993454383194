export const ProjectDetails = () => {
  return (
    <div className="bg-lightwhite dark:bg-white/5 rounded-2xl p-6">
      <div className="flex items-start justify-between gap-4 mb-[2px]">
        <h2 className="text-lg font-semibold">Coffee detail page</h2>
        <img
          className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
          src="/assets/images/marvel.png"
          alt=""
        />
      </div>
      <div className="flex items-end gap-4 justify-between">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-0 lg:flex lg:divide-x divide-[#000000]/10 dark:divide-white/10">
          <div className="lg:pr-7 shrink-0">
            <p className="mb-1">Status</p>
            <div className="w-full bg-[#000]/5 dark:bg-white/20 rounded-lg overflow-hidden">
              <div
                className="bg-lightpurple-200 whitespace-nowrap text-center px-3 p-1 text-sm font-semibold text-black"
                style={{
                  width: "51%",
                }}
              >
                In Progress / 51%
              </div>
            </div>
          </div>
          <div className="lg:px-7">
            <p className="mb-1">Total Tasks</p>
            <p className="text-lg font-semibold">15 / 48</p>
          </div>
          <div className="lg:px-7">
            <p className="mb-1">Due Date</p>
            <p className="text-lg font-semibold">29 Jan, 2022</p>
          </div>
          <div className="lg:pl-7">
            <p className="mb-1">Budget Spent</p>
            <p className="text-lg font-semibold">$15,000</p>
          </div>
        </div>
        <div className="flex items-center justify-start flex-none -space-x-1.5">
          <img
            className="w-6 h-6 rounded-full overflow-hidden object-cover ring-2 ring-white dark:ring-black"
            src="/assets/images/avatar-13.png"
            alt=""
          />
          <img
            className="w-6 h-6 rounded-full overflow-hidden object-cover ring-2 ring-white dark:ring-black"
            src="/assets/images/avatar-10.png"
            alt=""
          />
          <span className="flex justify-center items-center w-6 h-6 text-center rounded-full object-cover text-black bg-lightpurple-100 dark:bg-white text-xs ring-2 ring-white dark:ring-black">
            +3
          </span>
        </div>
      </div>
    </div>
  );
};
