import { useEffect, useState } from "react";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { publishCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { ArrowSquareOut, SealCheck } from "@phosphor-icons/react";
import InputGroup from "@/ui/InputGroup";

export const PublishCampaignModal = ({
  setIsOpen,
  campaignId,
  unitName = "Campaign",
}) => {
  // const icon = <SuccessAlertIcon className="transform scale-50" />
  const [isPublishing, setIsPublishing] = useState(true);
  const [url, setUrl] = useState(
    "https://www.tenminutefunnels.com/page_name_here"
  );

  const doPublish = async () => {
    // call the api to publish the campaign
    const token = getSessionToken();
    const response = await publishCampaign(campaignId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Campaign Published");
        setIsPublishing(false);
        const { webSiteUrl } = response;
        setUrl(webSiteUrl);
        console.log(response, webSiteUrl);
      }
    }
  };

  useEffect(() => {
    // publish the campaign now
    doPublish();
  }, []);

  return (
    <Modal
      // icon={icon}
      title={`Your ${unitName} is ${isPublishing ? "Publishing..." : "Live!"}`}
      size={PopupSizes.LARGE}
      onClose={() => setIsOpen(false)}
      onSuccess={() => setIsOpen(false)}
      footer={!isPublishing}
    >
      <div className="flex items-center p-5">
        {isPublishing ? (
          <Title>
            Your {unitName.toLowerCase()} is being published. This may take up
            to 30 seconds.
          </Title>
        ) : (
          <div>
            <div className="flex items-center justify-center">
              <SealCheck size={64} />
            </div>
            <Title>
              Good news! Your {unitName.toLowerCase()} is live and ready to
              share with the world!
            </Title>
            <InputGroup
              elements={[
                {
                  type: "input",
                  leftRounded: true,
                  placeholder: url,
                  inputType: "text",
                  disabled: true,
                },
                {
                  type: "icon",
                  rightRounded: true,
                  icon: (
                    <a href={url} target="_blank">
                      <ArrowSquareOut />
                    </a>
                  ),
                },
              ]}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

