// import { Funnels } from "@/Funnels/Funnels";
import { TreeStructure } from "@phosphor-icons/react";
import CampaignBuilder from "@/Apps/Funnels/CampaignBuilder";
import { FunnelGallery } from "@/Apps/Funnels/Funnels";
import { FunnelDashboard } from "./Dashboard";

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { PageEditor } from "../Pages/Editor";
import { useEffect } from "react";

const Reroute = () => {
  useEffect(() => {
    window.location.href = "/crm";
  }, []);
  return null;
};

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (permissions.includes("scroll")) return [];

    // if (!permissions.includes("funnels")) return [];
    return [
      {
        label: "Funnels",
        icon: <TreeStructure className="text-lg" />,
        href: "/funnels",
        order: 2,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    // if (!permissions.includes("funnels")) return [];
    if (permissions.includes("scroll")) return [{ path: "/funnels", element: <Reroute /> }];

    return [
      {
        path: "/funnels",
        element: <FunnelGallery name="Funnels" />,
        children: [
          {
            path: ":id",
            element: <FunnelDashboard name="Funnels" />,
          },
          {
            path: ":campaignId/:objectId",
            element: <PageEditor />,
          },
          {
            path: ":campaignId/:objectId/:destination",
            element: <PageEditor />,
          },
          {
            path: ":campaignId/:objectId/:destination/:type",
            element: <PageEditor />,
          },
        ],
      },
    ];
  }
};

export { FunnelGallery, FunnelDashboard, CampaignBuilder };
