import { gql } from '@apollo/client';

export const GET_CATALOG_ITEMS = gql`
  query allCatalogItems {
    catalogItems(orderBy: rating_DESC) {
      id
      user {
        id
      }
      type
      image
      name
      video
      color
      skill
      isNew
      description
      link
      rating
      campaign {
        id
        name
        screenshot
        objects {
          id
          name
          screenshot
          type
          x
          y
          settings
        }
      }
      page {
        id
        name
        content
      }
      earnings
      isRecommended
      isSpotlight
      tags
      categories
      createdAt
      updatedAt
    }
  }
`;

export const GET_CATALOG_ITEM = gql`
  query getCatalogItem($id: ID!) {
    catalogItem(where: { id: $id }) {
      id
      user {
        id
      }
      type
      image
      name
      video
      color
      skill
      isNew
      description
      link
      rating
      campaign {
        id
        name
        screenshot
        objects {
          id
          name
          screenshot
          type
          x
          y
          settings
        }
      }
      page {
        id
        name
        content
      }
      earnings
      isRecommended
      isSpotlight
      tags
      categories
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CATALOG_ITEM = gql`
  mutation createCatalogItem(
    $userId: ID
    $name: String!
    $type: String
    $image: String
    $video: String
    $color: String
    $skill: String
    $partner: String
    $isNew: Boolean
    $description: String
    $link: String
    $rating: Float
    $campaignId: ID
    $earnings: Float
    $isRecommended: Boolean
    $isSpotlight: Boolean
    $tags: String
    $categories: String
  ) {
    createCatalogItem(
      data: {
        user: { connect: { id: $userId } }
        name: $name
        type: $type
        image: $image
        video: $video
        color: $color
        skill: $skill
        partner: $partner
        isNew: $isNew
        description: $description
        link: $link
        rating: $rating
        campaign: { connect: { id: $campaignId } }
        earnings: $earnings
        isRecommended: $isRecommended
        isSpotlight: $isSpotlight
        tags: $tags
        categories: $categories
      }
    ) {
      id
      name
    }
  }
`;

export const DELETE_CATALOG_ITEM = gql`
  mutation deleteCatalogItem($id: ID!) {
    deleteCatalogItem(where: { id: $id }) {
      id
    }
  }
`;

export const DEPLOY_CATALOG_ITEM = gql`
  mutation deployCatalogItem($id: ID!, $destination: String!, $userId: ID!) {
    deployCatalogItem(id: $id, destination: $destination, userId: $userId) {
      response
      statusCode
      message
    }
  }
`;

export const UPDATE_CATALOG_ITEM = gql`
  mutation updateCatalogItem(
    $id: ID!
    $name: String!
    $type: String
    $image: String
    $video: String
    $color: String
    $skill: String
    $partner: String
    $isNew: Boolean
    $description: String
    $link: String
    $rating: Float
    $campaignId: ID
    $earnings: Float
    $isRecommended: Boolean
    $isSpotlight: Boolean
    $tags: String
    $categories: String
  ) {
    updateCatalogItem(
      where: { id: $id }
      data: {
        name: $name
        type: $type
        image: $image
        video: $video
        color: $color
        skill: $skill
        partner: $partner
        isNew: $isNew
        description: $description
        link: $link
        rating: $rating
        campaign: { connect: { id: $campaignId } }
        earnings: $earnings
        isRecommended: $isRecommended
        isSpotlight: $isSpotlight
        tags: $tags
        categories: $categories
      }
    ) {
      id
      name
    }
  }
`;

export const GET_CATALOG_DESIGN_ITEMS = gql`
  query allCatalogDesignItems {
    catalogItems(where: { type: "designs" }, orderBy: isSpotlight_DESC) {
      id
      user {
        id
      }
      type
      image
      name
      video
      color
      skill
      isNew
      description
      link
      rating
      page {
        id
        content
        type
      }
      earnings
      isRecommended
      isSpotlight
      tags
      categories
      createdAt
      updatedAt
    }
  }
`;
