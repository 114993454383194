import { get, has } from "lodash";
import TextInput from "@/ui/_old/Inputs/TextInput";

export const ToggleAddRemoveCart = (props) => {
    const { action, updateAction, id } = props;
    const { payload } = action;

    return (
        <div>
            <TextInput
                placeholder="Name of Product"
                style={{ marginTop: 10 }}
                value={get(payload, 'name', '')}
                onBlur={(e) => updateAction(action, { ...payload, id, name: e.target.value, quantity: 1 })}
                focusOnMount
                focusOnClick
            />
            <TextInput
                iconLeft="attach_money"
                placeholder="Price of Product"
                style={{ marginTop: 10 }}
                value={parseFloat(get(payload, 'price', '0').replace(/[^0-9.]/g, "")).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                format="currency"
                onBlur={(e) => updateAction(action, { ...payload, id, price: e.target.value.replace(/,/g, ""), quantity: 1 })}
                focusOnClick
            />
            {has(payload, 'price') && (
                <p style={{ color: "#666", textAlign: "center", fontSize: '10pt', padding: 10 }}>
                    We will charge your buyers an additional ${get(payload, 'price', '0')} if this order bump is checked when they complete their purchase.
                </p>
            )}
        </div>
    )
}
