import { useEffect, useContext, useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { has } from "lodash";

import { Topbar, LeftSidebar, RightSidebar } from "..";

import { getTokenInfo } from "@/api/auth";
import { AppContext } from "@/AppContext";
import { ContainerProps, PageProps } from "./types";
import { triggerAsyncHook } from "@/plugins/client";
import { HookTriggers, ListItemsHooks } from "@/plugins/types";
import { NavGroupItem } from "../LeftSidebar/types";

import { getSessionToken } from "@/api/auth";

export const Container: React.FC<ContainerProps> = ({
  children = <></>,
  className = "",
  fullWidth = false,
}) => {
  return (
    // Start Container
    <div
      className={`overflow-y-auto overflow-x-hidden p-7 h-full ${className}`}
    >
      {/* Start Max Width Container */}
      {!fullWidth ? (
        <div className="mx-auto max-w-6xl h-full">{children}</div>
      ) : (
        children
      )}
      {/* End Max Width Container */}
    </div>
    // End Container
  );
};

export const Page: React.FC<PageProps> = ({
  topBar = {},
  children = <></>,
}) => {
  // Define state for the menu items
  const [menuItems, setMenuItems] = useState<NavGroupItem[]>([]);
  const [userInfo, setUserInfo] = useState<any>({});

  // Get the app context
  const contextObj = useContext(AppContext);
  const { disableRightSidebar } = contextObj;

  // Check if logged in (check for a token)
  const navigate = useNavigate();

  // Function to check if the token is valid
  const checkToken = async () => {
    // Get the token from local storage
    const token = getSessionToken();
    // Call the API to check if the token is valid
    const response = await getTokenInfo(token);
    // Set the user info state
    setUserInfo(response);

    // If the response has an error property, redirect to login page with error message
    if (has(response, "error")) {
      navigate("/login", { state: { error: response.error } });
    }
  };

  // Function to load in all menu items
  const loadInAllMenuItems = async () => {
    // Trigger the onListItems hook to get the menu items
    const menuItemsFromPlugin = await triggerAsyncHook(
      HookTriggers.onListItems,
      {
        id: ListItemsHooks.APP_DRAWER,
        position: "top",
      },
      null,
      null,
      contextObj
    );

    // Set the menu items state
    setMenuItems(menuItemsFromPlugin);
  };

  // Call checkToken function on component mount
  useEffect(() => {
    checkToken();
    // console.log("context obj", { contextObj });
    loadInAllMenuItems();
  }, [contextObj]);

  const { width, height } = useWindowSize();

  return (
    <>
      {/* <Confetti width={width} height={height} recycle={false} /> */}

      {/* Render the Sidebar */}
      <LeftSidebar menuItems={menuItems} avatarSettings={userInfo} />

      {/* Render the Page Content */}
      <div
        data-testid="Page"
        className="main-content flex-1"
        style={disableRightSidebar ? { marginRight: 0 } : {}}
      >
        {/* Render the top bar component */}
        <Topbar {...topBar} />

        {/* Start Content */}
        <div
          data-testid="Page-Content"
          className="h-[calc(100vh-73px)] overflow-y-auto overflow-x-hidden"
        >
          {/* Render the child components */}
          {children}

          {/* <Footer /> */}
        </div>
        {/* End Content */}
      </div>

      {/* Render the Right Sidebar if not disabled */}
      {!disableRightSidebar && (
        <RightSidebar
          notifications={false}
          activities={false}
          contacts={false}
          chat={true}
          pageSettings={topBar?.page}
        />
      )}
    </>
  );
};
