import { useContext, useEffect, useState } from "react";
import { useChat } from "ai/react";

import { marked } from "marked";

import Avatar from "@/ui/Avatar";

import ChatConversation from "@/ui/CommunicationView/ChatConversation";
import {
  CaretCircleDown,
  Clipboard,
  DotsThreeCircle,
  DotsThreeVertical,
  Sparkle,
} from "@phosphor-icons/react";
import { AI_DOMAIN } from "@/env";
import { getSessionToken, getTokenInfo } from "@/api/auth";
import { CloseButtonIcon } from "@/ui/Icons";
import { AppContext } from "@/AppContext";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import ListDropdown from "@/ui/ListDropdown";
import { ListDropdownItem } from "@/ui/ListDropdown/types";
import { getSystemPrompt } from "./prompts";
import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import { getPage } from "@/api/pages";
import { createObject } from "@/api/campaigns";

const listOfAssistants = [
  { id: "copilot", label: "Launch OS Copilot" },
  { id: "marketResearch", label: "Market Research" },
  // { id: "launchAdvisor", label: "Launch Advisor" },
  // { id: "kb", label: "Knowledge Base" },
  { id: "trafficSEO", label: "Traffic & SEO" },
  { id: "copywriting", label: "Copywriting" },
];

interface AssistantsDropdownProps {
  onChange: (item: ListDropdownItem) => void;
}

const AssistantsDropdown: React.FC<AssistantsDropdownProps> = ({
  onChange = () => null,
}) => {
  return (
    <ListDropdown
      element={<CaretCircleDown size={20} />}
      onItemSelect={(item) => {
        onChange(item);
      }}
      items={listOfAssistants.map((field) => ({
        id: field.id,
        label: field.label,
      }))}
      style={{ position: "absolute", bottom: 50, left: 0 }}
    />
  );
};

export const ChatBot = ({ pageSettings = {} }) => {
  const [systemPrompt, setSystemPrompt] = useState<string>("");
  const [userDetails, setUserDetails] = useState<any>({});
  const [autoScrollingIsEnabled, setAutoScrollingIsEnabled] = useState(true);
  const [assistant, setAssistant] = useState<ListDropdownItem>({
    id: "asst_1xmZDDC2m1Dhg6gqVsTTXeq4",
    label: "Launch OS Copilot",
  });

  const { messages, input, handleInputChange, handleSubmit } = useChat({
    api: `${AI_DOMAIN}/api/chat`,
    // api: `${AI_DOMAIN}/chat`,
    body: { type: "chat", systemPrompt, assistantId: assistant.id },
    onResponse: () => {
      setAutoScrollingIsEnabled(true);
    },
  });

  const { setRightSidebarOpen, rightSidebarOpen } = useContext(AppContext);

  const { updateContent } = useEditorActions();

  useEffect(() => {
    const wn = document.getElementById("chat-conversation-container");
    if (wn && autoScrollingIsEnabled) {
      wn.scrollTo({ top: wn.scrollHeight, behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    retrieveSystemPrompt();
    console.log("Changed: ", { assistant });
  }, [assistant]);

  const retrieveSystemPrompt = async () => {
    let profileData;

    console.log("The page settings", pageSettings);

    if (pageSettings[1]?.context?.profileData) {
      profileData = pageSettings[1]?.context?.profileData;
    }

    const systemPrompt = await getSystemPrompt({
      pageSettings,
      userDetails,
      assistant,
      profileData,
    });

    // flatten the values of each key in the object into a string
    setSystemPrompt(
      Object.keys(systemPrompt)
        .map((key) => systemPrompt[key])
        .join("\n\n")
    );
  };

  const suggestions = [];
  // const suggestions = messages.length
  //   ? []
  //   : [
  //       {
  //         label: "Write an ad",
  //       },
  //       {
  //         label: "Help me brainstorm a subject line",
  //       },
  //       {
  //         label: "Write a blog post",
  //       },
  //     ];

  const chatMessages = messages.map((msg) => {
    const icon =
      msg.role === "user" ? (
        <div className="mr-4">
          <Avatar
            type="Rounded"
            email={userDetails?.email}
            height="8"
            width="8"
          />
        </div>
      ) : (
        <div
          className="h-8 w-8 text-sm rounded-full p-2 mr-4 bg-indigo-300 text-black"
          style={{ padding: 6 }}
        >
          {/* <Robot size={24} /> */}
          <Sparkle size={18} />
        </div>
      );
    return {
      id: msg.id,
      type: "text",
      caption: msg.content,
      from: msg.role === "user" ? "User" : "Bot",
      icon,
    };
  });

  const addContentToPage = async (item: any) => {
    const token = getSessionToken();
    const pageId = pageSettings[2]?.context?.pageId;
    const { data } = await getPage(pageId, { token });
    const { content } = data;

    // look for an active element
    // look for the last text element
    // add the new content to the last text element
    updateContent([...content, item], pageId);

    console.log("The Page", content);
  };

  const createContentPost = async (caption: string) => {
    console.log("Create Content Post", caption, pageSettings);

    const packageId = pageSettings[1]?.id;

    const variables = {
      name: "New Post",
      x: 0,
      y: 0,
      type: "ContentComponent",
      campaignId: packageId,
      settings: {
        name: "New Post",
        type: "Post",
        content: caption,
      },
    };
    console.log("The variables", variables);

    const response = await createObject(variables);

    // go to the page
    console.log("The response", response);
    window.location.href = `/content/${packageId}/${response?.data?.createObject?.id}`;
  };

  let feedbackItems = [];

  // console.log("Check", pageSettings[1].href);
  if (pageSettings[0]?.label === "Funnels") {
    // feedbackItems.push(
    //   {
    //     id: "generatePage",
    //     label: "Generate Page with Content",
    //     onClick: () => alert("Coming soon (within days)!"),
    //   }
    // );

    if (pageSettings[1]?.href.includes("/funnels") && pageSettings.length > 2) {
      feedbackItems.push({
        id: "generateContent",
        label: "Add Content to Page",
        // onClick: () => addContentToPage(obj),
      });
    }
  }

  if (pageSettings[0]?.href === "/content") {
    // alert("Hey Yo!");

    feedbackItems.push({
      id: "createContent",
      label: "Generate Post with Content",
      // onClick: (obj) => createContentPost(obj),
    });
  }

  // feedbackItems.push(
  //   {
  //     id: "addToProfile",
  //     label: "Add to Avatar Profile",
  //     // onClick: () => alert("Coming soon (within days)!"),
  //   },
  // );

  const feedbackButtons = [
    {
      onClick: async (str) => {
        await navigator.clipboard.writeText(str);
        alert("Copied!");
      },
      icon: (caption) => <Clipboard size={20} />,
    },
    {
      onClick: () => {},
      icon: (caption) => (
        <>
          <ListDropdown
            element={
              <div className="btn mt-2">
                <DotsThreeVertical size={20} />
              </div>
            }
            onItemSelect={(obj) => {
              if (obj.id === "generateContent") {
                const obj = {
                  id: Math.random().toString(36).substring(7),
                  type: "Text",
                  html: marked.parse(caption),
                  state: "normal",
                  parent: 2540,
                  properties: {
                    display: "inline-block",
                    padding: 5,
                    textAlign: "left",
                  },
                };

                console.log("The caption", caption);

                addContentToPage(obj);
              }

              if (obj.id === "createContent") {
                createContentPost(caption);
              }
            }}
            items={feedbackItems}
            style={{ position: "absolute", bottom: 50, left: 0 }}
          />
        </>
      ),
      // className: "btn",
    },
  ];

  const xfeedbackButtons = [
    ...(pageSettings[0]?.href?.includes("/funnels") ||
    pageSettings[0]?.href?.includes("/pages")
      ? [
          {
            onClick: () => {},
            icon: (
              <Button
                type={ButtonTypes.SOFT}
                label="Generate Page with Content"
                className="mr-2 mt-4"
              />
            ),
          },
          {
            onClick: () => {},
            icon: (
              <Button
                type={ButtonTypes.SOFT}
                label="Add Content to Page"
                className="mr-2 mt-4"
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    retrieveSystemPrompt();
    (async () => {
      const token = getSessionToken();
      const user = await getTokenInfo(token);
      setUserDetails(user);
      // console.log({ user });
    })();
  }, [pageSettings]);

  // Toggle the right sidebar
  const toggleRightSidebar = () => {
    localStorage.setItem("rightSidebarOpen", !rightSidebarOpen);
    setRightSidebarOpen(!rightSidebarOpen);
  };

  return (
    <>
      <div className="relative mb-3">
        <h1 className="text-lg text-black dark:text-white absolute">
          {assistant.label}
        </h1>
        <button
          type="button"
          className="text-black/40 dark:text-white/40 hover:text-black dark:hover:text-white right-0 absolute"
          onClick={toggleRightSidebar}
          // onClick={() => setRightSidebarOpen(false)}
        >
          <CloseButtonIcon />
        </button>
      </div>

      <ChatConversation
        containerSettings={{
          messages: chatMessages,
          withFeedbackButtons: true,
          feedbackButtons,
        }}
        inputSettings={{
          type: "Rounded",
          value: input,
          withButtons: true,
          buttonSettings: [
            {
              position: "inside",
              location: "beforeInput",
              // icon: <CaretCircleDown size={20} />,
              icon: <AssistantsDropdown onChange={setAssistant} />,
              // onClick: () => console.log("Button clicked"),
            },
          ],
          onChange: handleInputChange,
          onKeyDown: (e) => {
            if (e.key === "@") {
              console.log("Show suggestions");
            }
          },
          onSubmit: handleSubmit,
          // onSubmit: submitMessage,
          suggestions,
          // onSuggestionClick: (suggestion) => {
          //   handleInputChange({ target: { value: suggestion } });
          // },
        }}
      />
    </>
  );
};
