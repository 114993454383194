import { GraduationCap } from "@phosphor-icons/react";
import { ComponentsIcon, WidgetsIcon } from "../../Icons";
import { NavGroup } from "./NavGroup";
import { NavSection } from "./NavSection";
import { SupportSectionNavGroupsProps } from "./types";

export const SupportSectionNavGroups: React.FC<
  SupportSectionNavGroupsProps
> = ({ doToggle = () => null, openNavGroups = {} }) => {
  return (
    <div data-testid="SupportSectionNavGroups">
      {/* Render the "Support" section */}
      <NavSection>Support</NavSection>

      {/* Render the "Launch Pad" menu item */}
      <NavGroup
        key="Launchpad"
        name="Launch Pad"
        href="/launchpad"
        icon={<WidgetsIcon />}
      />

      {/* Render the "University" menu item */}
      <NavGroup
        key="university"
        name="University"
        href="https://university.tenminutefunnels.com"
        icon={<GraduationCap className="text-[22px]" />}
      />

      {/* Render the "Sandcastle" menu item */}
      {/* <NavGroup
        key="sandcastle"
        name="Sandcastle"
        href="/sandcastle"
        icon={<ComponentsIcon />} /> */}

      {/* Render the "User Interface" menu item */}
      {/* <NavGroup
        key="ui"
        name="User Interface"
        icon={<WidgetsIcon />}
        isOpen={openNavGroups["ui"]}
        onClick={() => doToggle("ui")}
        items={[
          { href: "/ui/components", label: "Components" },
          { href: "/ui/elements", label: "Elements" },
          { href: "/ui/charts", label: "Charts" },
          { href: "/ui/widgets", label: "Widgets" },
          { href: "/ui/icons", label: "Icons & Modals" },
          { href: "/ui/dragdrop", label: "Drag & Drop" },
          { href: "/ui/tables", label: "Tables" },
          { href: "/ui/forms", label: "Forms" },
          { href: "/ui/pages", label: "Pages" },
        ]}
      /> */}
    </div>
  );
};
