/**
 * Enum for different flavors of text input.
 */
export enum TextInputFlavors {
  CLASSIC, // Classic flavor
  DEFAULT, // Default flavor
  MODERN, // Modern flavor
  ROUNDED, // Rounded flavor
}

/**
 * Enum for different types of text input.
 */
export enum TextInputTypes {
  TEXT = "text", // Text input type
  PASSWORD = "password", // Password input type
  EMAIL = "email", // Email input type
  URL = "url", // URL input type
  SEARCH = "search", // Search input type
  TELEPHONE = "telephone", // Telephone input type
  DATE = "date", // Date input type
  NUMBER = "number", // Date input type
  TIME = "time", // Date input type
  // CURRENCY = "currency", // Currency input type
}

/**
 * Enum for different types of validation for text input.
 */
export enum TextInputValidationTypes {
  NONE, // No validation
  ERROR, // Validation error
  SUCCESS, // Validation success
}

/**
 * Props for the TextInput component.
 */
export type TextInputProps = {
  /**
   * Label for the text input.
   */
  label?: string;
  /**
   * Value of the text input.
   */
  value?: string;
  /**
   * Placeholder text for the text input.
   */
  placeholder?: string;
  /**
   * Whether the text input is required or not.
   */
  required?: boolean;
  /**
   * Flavor of the text input.
   */
  flavor?: TextInputFlavors;
  /**
   * Type of the text input.
   */
  type?: TextInputTypes;
  /**
   * Name of the text input.
   */
  name?: string;
  /**
   * Type of validation for the text input.
   */
  validationType?: TextInputValidationTypes;
  /**
   * Validation message for the text input.
   */
  validationMessage?: string;
  /**
   * Class name for the text input.
   */
  className?: string;
  /**
   * Function to handle onChange event of the text input.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Function to handle onKeyUp event of the text input.
   */
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};