
export const SpendingBreakdown = () => {
  return (
    <div className="p-6 bg-lightwhite dark:bg-white/5 rounded-2xl">
      <div className="flex items-center justify-between gap-4">
        <h2 className="text-sm font-semibold mb-4">Project Spendings</h2>
      </div>
      <div className="grid grid-cols-1">
        <div className="table-responsive">
          <table className="table-hover">
            <thead>
              <tr>
                <th>Manager</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="whitespace-nowrap min-w-[150px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover ring-2 ring-white"
                      src="/assets/images/avatar-11.png"
                      alt="" />
                    <p className="flex-1">Koray Okumus</p>
                  </div>
                </td>
                <td className="whitespace-nowrap">Jun 24, 2022</td>
                <td>$942.00</td>
                <td className="whitespace-nowrap">
                  <div className="flex items-center text-xs text-[#8A8CD9]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                        fill="currentcolor" />
                    </svg>
                    <p>In Progress</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="whitespace-nowrap min-w-[150px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover ring-2 ring-white"
                      src="/assets/images/avatar-6.png"
                      alt="" />
                    <p className="flex-1">Natali Craig</p>
                  </div>
                </td>
                <td className="whitespace-nowrap">Mar 10, 2022</td>
                <td>$881.00</td>
                <td className="whitespace-nowrap">
                  <div className="flex items-center text-xs text-black/40 dark:text-white/40">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                        fill="currentcolor" />
                    </svg>
                    <p>Rejected</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="whitespace-nowrap min-w-[150px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover ring-2 ring-white"
                      src="/assets/images/avatar-23.png"
                      alt="" />
                    <p className="flex-1">Olivia Rhye</p>
                  </div>
                </td>
                <td className="whitespace-nowrap">Nov 10, 2022</td>
                <td>$409.00</td>
                <td className="whitespace-nowrap">
                  <div className="flex items-center text-xs text-[#4AA785]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                        fill="currentcolor" />
                    </svg>
                    <p>Completed</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="whitespace-nowrap min-w-[150px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover ring-2 ring-white"
                      src="/assets/images/avatar-8.png"
                      alt="" />
                    <p className="flex-1">Orlando Diggs</p>
                  </div>
                </td>
                <td className="whitespace-nowrap">Dec 20, 2022</td>
                <td>$953.00</td>
                <td className="whitespace-nowrap">
                  <div className="flex items-center text-xs text-[#FFC555]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                        fill="currentcolor" />
                    </svg>
                    <p>Approved</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="whitespace-nowrap min-w-[150px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="w-6 h-6 flex-none rounded-full overflow-hidden object-cover ring-2 ring-white"
                      src="/assets/images/avatar-10.png"
                      alt="" />
                    <p className="flex-1">Kate Morrison</p>
                  </div>
                </td>
                <td className="whitespace-nowrap">Jul 25, 2022</td>
                <td>$907.00</td>
                <td className="whitespace-nowrap">
                  <div className="flex items-center text-xs text-[#59A8D4]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                        fill="currentcolor" />
                    </svg>
                    <p>Pending</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
