import { Calout } from "@/ui/Calout";
import { Modal } from "@/ui/Modal";
import { deletePage } from "@/api/pages";
import { getSessionToken } from "@/api/auth";

export const DeletePageModal = ({
  setIsOpen,
  campaignId,
  pageId,
  unitName,
}) => {
  const handleDeletePage = async () => {
    const token = getSessionToken();
    const response = await deletePage(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Page Deleted");
        setIsOpen(false);

        // load the funnel builder again
        window.location.href = `/funnels/${campaignId}`;
        console.log(response);
      }
    }
  };

  return (
    <Modal
      title={`Are you sure you want to delete this ${unitName}?`}
      onClose={() => setIsOpen(false)}
      onSuccess={handleDeletePage}
    >
      <Calout title="You won't be able to retrieve this once deleted" />
    </Modal>
  );
};
