import { getCampaignSales, getCurrentVisitors, getKPIs } from "@/api/tracking";
import { KPIWidget } from "@/ui/KPIWidget";
import { getTotalByKey } from "@/utils";
import { useEffect, useState } from "react";



export const KPIBlock: React.FC<{ campaignData?: any; items?: string[] }> = ({
  campaignData = {},
  items = ["views", "visitors", /*"realtime",*/ "leads", "sales"],
}) => {

  const [views, setViews] = useState(0);
  const [currentVisitors, setCurrentVisitors] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [leads, setLeads] = useState(0);
  const [sales, setSales] = useState(0);
  
  const loadCurrentVisitors = async () => {
    const campaignId = campaignData?.id;    
    const curVis = await getCurrentVisitors(campaignId);
    console.log({curVis});
    setCurrentVisitors(curVis);
  }

  useEffect(() => {
    let t;
    (async () => {
      // console.log("Testing");
      const campaignId = campaignData?.id;
      const traffic = await getKPIs(campaignId);
      const leads = await getKPIs(campaignId, null, "lead");
      const sales = await getCampaignSales(campaignId);
      console.log({traffic, leads, sales});
      
      // loadCurrentVisitors();
      
      // repeat every 10 seconds
      // t = setInterval(loadCurrentVisitors, 10000);

      // setCurrentVisitors(curVis);
      setVisitors(getTotalByKey(traffic?.data, "visits"));
      setViews(getTotalByKey(traffic?.data, "pageviews"));
      setLeads(getTotalByKey(leads?.data, "visits"));
      setSales(Number(sales));
    })();

    return () => {
      clearInterval(t);
    }
  }, []);

  return (
    <div className={`grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-${items.length} gap-7`}>
      {items.includes("views") && (
        <KPIWidget
          color="lightblue"
          key="views"
          label="Views"
          // number="721K"
          number={String(views)}
          // delta="+11.01%"
          delta="+0%"
          deltaSign="POSITIVE"
        />
      )}

      {items.includes("realtime") && (       
        <KPIWidget
          color="lightpurple"
          key="online"
          label="Realtime"
          // number="721K"
          number={String(currentVisitors)}
          // delta="+11.01%"
          delta="+0%"
          deltaSign="POSITIVE"
        /> 
      )}

      {items.includes("visitors") && (
        <KPIWidget
          color="lightpurple"
          key="visitors"
          label="Visitors"
          // number="367K"
          number={String(visitors)}
          // delta="+9.15%"
          delta="+0%"
          deltaSign="POSITIVE"
        />
      )}

      {items.includes("leads") && (
        <KPIWidget
          color="lightblue"
          key="leads"
          label="Leads"
          // number="1,156"
          number={String(leads)}
          // delta="+0.56%"
          delta="+0%"
          deltaSign="POSITIVE"
        />
      )}

      {items.includes("sales") && (
        <KPIWidget
          color="lightpurple"
          key="sales"
          label="Sales"
          // number="$39K"
          // number="$0.00"
          number={`$${String(sales)}`}
          // delta="-1.48%"
          delta="-0%"
          deltaSign="NEGATIVE"
        />
      )}

      {items
        .filter(
          (itm) =>
            itm !== "views" &&
            itm !== "visitors" &&
            itm !== "realtime" &&
            itm !== "leads" &&
            itm !== "sales"
        )
        .map(
          (
            {
              id,
              label = "",
              number = "0",
              delta = "+0%",
              deltaSign = "POSITIVE",
            },
            key
          ) => {
            return (
              <KPIWidget
                key={key}
                color="lightblue"
                label={label}
                number={number}
                delta={delta}
                deltaSign={deltaSign}
              />
            );
          }
        )}
    </div>
  );
};
