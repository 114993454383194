import Grid from "@/ui/Grid";
import Panel from "@/ui/Panel"
import TextInput from "@/ui/TextInput";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

export const WebinarCTATime = ({ campaignData = {} }) => {
  return (
    <Panel title="CTA Link Timestamp">
      <p className="text-sm text-gray-500">
        In the webinar, the CTA button will be displayed at this time.
      </p>
      <Grid cols={3} smCols={1} lgCols={3} className="mt-7">
        <TextInput
          label="Hours"
          placeholder="0"
          flavor={TextInputFlavors.MODERN}
          type={TextInputTypes.NUMBER}
        />
        <TextInput
          label="Minutes"
          placeholder="0"
          flavor={TextInputFlavors.MODERN}
          type={TextInputTypes.NUMBER}
        />
        <TextInput
          label="Seconds"
          placeholder="0"
          flavor={TextInputFlavors.MODERN}
          type={TextInputTypes.NUMBER}
        />
      </Grid>
    </Panel>
  );
};