import React, { useEffect } from "react";
import moment from "moment";

import { ButtonTypes, Status } from "@/ui/types";
import { getCampaigns } from "@/api/campaigns";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { EmptyState, Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";

import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { Loading } from "@/ui/Layout/Loading";

const logo = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
      fill="currentcolor"
    />
  </svg>
);

// a dashboard that lists all the campaigns
export const FunnelGallery = ({ name }) => {
  const [funnels, setFunnels] = React.useState([]); // [funnel1, funnel2, funnel3
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // get the funnels from the server
  const loadFunnels = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "" });
    if (response) {
      const funnels = response
        ?.filter(({ deleted }) => !deleted)
        .map((funnel: any) => {
          return {
            id: funnel.id,
            label: funnel.name,
            caption: `Last Updated: ${moment(funnel.updatedAt).fromNow()}`,
            img: funnel.screenshot ? (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                // src={funnel.screenshot}
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ) : (
              <></>
            ),
            status: Status.INPROGRESS,
            sharedWith: logo,
            // taskDetails: {
            // 	completedNum: 13,
            // 	totalNum: 49
            // }
          };
        });

      console.log("funnels", funnels);
      setFunnels(funnels);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadFunnels();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Link to="/launchpad/funnels" className="justify-end">
            <Button label="+ Create a Funnel" type={ButtonTypes.OUTLINED} />
          </Link>
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(funnels.length) && (
          <EmptyState
            title="No Funnels Yet"
            description="Get started by creating a new funnel."
            showImage={false}
          >
            <Link to="/launchpad/funnels">
              <Button label="Create a Funnel" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(funnels.length) && (
          <Grid>
            {funnels.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <>
                    <Card
                      key={key}
                      label={label}
                      caption={caption}
                      image={img}
                      href={`/funnels/${id}`}
                      status={status}
                      // sharedWith={sharedWith}
                      taskDetails={taskDetails}
                    />
                  </>
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
