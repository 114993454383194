import { API_DOMAIN } from "@/env";
import { getTokenInfo } from "@/api/auth";

/**
 * Updates user data for a given user ID.
 * @param {string} userId - The ID of the user.
 * @param {Object} options - The options for updating user data.
 * @param {string} options.token - The authorization token.
 * @param {Object} options.data - The data to update.
 * @returns {Promise<Object>} - A promise that resolves to the updated user data.
 */
export const updateUserData = async (userId, { token, data }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(data);

    try {
      const response = await fetch(`${API_DOMAIN}/users/${userId}`, {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

export const getUserPermissions = async ({ token }) => {
  const data = await getTokenInfo(token);
  if (!data) return false;
  
  const { permissions } = data;
  return permissions;
};
