import { EmptyState } from "@/ui/Layout";

export const AutomationBuilder = () => {
  return (
    <div className="h-full">
      <EmptyState
        title="Nothing to Show"
        description="This feature is coming soon."
        showImage={false}
      />
    </div>
  );
};
