
export const TimelineList = () => {
  return (
    <div className="p-6 bg-lightwhite dark:bg-white/5 rounded-2xl">
      <h2 className="text-sm font-semibold mb-4">What's on the road?</h2>
      <div className="flex flex-nowrap justify-between overflow-auto gap-8 mb-5">
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            Su
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            01
          </p>
        </div>
        <div className="cursor-pointer bg-black dark:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-white dark:text-black text-xs">Mo</p>
          <p className="font-semibold text-white dark:text-black">02</p>
        </div>
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            Tu
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            03
          </p>
        </div>
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            We
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            04
          </p>
        </div>
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            Th
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            05
          </p>
        </div>
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            Fr
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            06
          </p>
        </div>
        <div className="cursor-pointer group hover:bg-black dark:hover:bg-lightpurple-200 p-1.5 duration-150 rounded-md text-center">
          <p className="text-black/40 group-hover:text-white dark:text-white/40 dark:group-hover:text-black text-xs">
            Sa
          </p>
          <p className="font-semibold group-hover:text-white dark:group-hover:text-black">
            07
          </p>
        </div>
      </div>
      <ol className="relative border-l border-black/10 dark:border-white/10 ml-3">
        <li className="mb-4 ml-6">
          <img
            className="absolute flex items-center justify-center w-6 h-6 rounded-full overflow-hidden object-cover -left-3 ring-8 ring-lightwhite dark:ring-[#282828]"
            src="/assets/images/avatar-22.png"
            alt="" />
          <h3 className="flex items-center">Edited the details of Project X</h3>
          <p className="mb-4 text-xs leading-[18px] text-black/40 dark:text-white/40">
            Email@gmail.com
          </p>
        </li>
        <li className="mb-4 ml-6">
          <img
            className="absolute flex items-center justify-center w-6 h-6 rounded-full overflow-hidden object-cover -left-3 ring-8 ring-lightwhite dark:ring-[#282828]"
            src="/assets/images/avatar-2.png"
            alt="" />
          <h3 className="flex items-center">Stevens</h3>
          <p className="mb-4 text-xs leading-[18px] text-black/40 dark:text-white/40">
            1:32 AM
          </p>
        </li>
        <li className="mb-4 ml-6">
          <img
            className="absolute flex items-center justify-center w-6 h-6 rounded-full overflow-hidden object-cover -left-3 ring-8 ring-lightwhite dark:ring-[#282828]"
            src="/assets/images/avatar-14.png"
            alt="" />
          <h3 className="flex items-center">Submitted a bug</h3>
          <p className="mb-4 text-xs leading-[18px] text-black/40 dark:text-white/40">
            Yesterday 12:39 AM
          </p>
        </li>
        <li className="mb-4 ml-6">
          <img
            className="absolute flex items-center justify-center w-6 h-6 rounded-full overflow-hidden object-cover -left-3 ring-8 ring-lightwhite dark:ring-[#282828]"
            src="/assets/images/avatar-10.png"
            alt="" />
          <h3 className="flex items-center">Modified A data in Page X</h3>
          <p className="mb-4 text-xs leading-[18px] text-black/40 dark:text-white/40">
            Last Thursday 3:34 AM
          </p>
        </li>
        <li className="mb-4 ml-6">
          <img
            className="absolute flex items-center justify-center w-6 h-6 rounded-full overflow-hidden object-cover -left-3 ring-8 ring-lightwhite dark:ring-[#282828]"
            src="/assets/images/avatar-15.png"
            alt="" />
          <h3 className="flex items-center">Deleted a page in Project X</h3>
          <p className="mb-4 text-xs leading-[18px] text-black/40 dark:text-white/40">
            Aug 11
          </p>
        </li>
      </ol>
    </div>
  );
};
