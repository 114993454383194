import { Pagination } from "../Pagination";
import { ContactListProps } from "./types";
import { TitleCard } from "../TitleCard";
import { TitleCardProps } from "../TitleCard/types";
import { Legend } from "./Legend";
import { FilterBar } from "../DataView/components/FilterBar/FilterBar";
import { EmptyState } from "../Layout";
import Button from "../Button";
import { ButtonTypes } from "../types";
import { Loading } from "../Layout/Loading";

// Define the ContactList component as a functional component that takes in props
export const ContactList: React.FC<ContactListProps> = ({
  items = [], // An array of items to be displayed in the list
  paginationDetails: { currentPage, totalPages, onPageChange } = {}, // Pagination details object with current page, total pages, and a function to handle page changes
  showLegend = false, // A boolean to determine whether or not to show the legend
  legendDetails = [], // An array of legend items to be displayed
  onItemClick, // A function to handle item clicks
  onLegendItemClick, // A function to handle legend item clicks
  onSearch, // A function to handle search queries
  withFilterBar = true, // A boolean to determine whether or not to show the filter bar
  filterBarSettings = {}, // An object of settings for the filter bar
  className, // A string to add a custom class name to the component
  isLoading = false, // A boolean to determine whether or not the contacts are loading
}) => {
  // Return the ContactList component with the following JSX
  // if (!items.length) return <div>Nothing to show...</div>;
  return (
    <div
      data-testid="ContactList"
      className={`flex flex-row gap-7 ${className}`}
    >
      {/* If showLegend is true, render the Legend component with the legendDetails array and the onLegendItemClick and onSearch functions */}
      {showLegend && (
        <Legend
          items={Boolean(items.length) ? legendDetails : []}
          onItemClick={onLegendItemClick}
          onSearch={onSearch}
          showSearch={onSearch ? true : false}
        />
      )}
      <div className="flex-1">
        {withFilterBar && <FilterBar {...filterBarSettings} className="mb-7" />}
        <div
          className={`${!Boolean(items.length) && 'h-full'} overflow-y-auto grid grid-flow-row gap-7 items-start ${className}`}
        >
          {isLoading && <Loading type="titles" />}

          {!isLoading && !Boolean(items.length) && (
            <EmptyState
              title="No Contacts Yet"
              description="Get started by creating a new contact."
              showImage={false}
            />
          )}

          {!isLoading && Boolean(items.length) && (
            <>
              {/* Map over the items array and render a TitleCard component for each item */}
              {items.map((itm: TitleCardProps, key: number) => (
                <TitleCard
                  key={key}
                  onClick={() => onItemClick && onItemClick(itm)}
                  {...itm}
                />
              ))}
              {/* Render the Pagination component with the current page, total pages, and onPageChange function */}
              {Boolean(totalPages > 1) && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactList;
