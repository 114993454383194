import React, { useEffect, useState } from "react";
import Panel from "@/ui/Panel";
import Calout from "@/ui/Calout";

import { RenderField } from "./RenderField";
import { DetailsViewProps } from "../types";

export const DetailsView: React.FC<DetailsViewProps> = ({
  item = {},
  title = "",
  children,
  footer,
  visibleFields = [],
  isEditable = false,
  onItemChange = () => {},
}) => {
  const { banner, data = [] } = item;
  const [editedData, setEditedData] = useState(data);

  useEffect(() => {
    setEditedData(data.filter(({ id }) => !["id", "title", "banner"].includes(id)));
  }, [data]);

  // Render the DetailsView component
  return (
    <div data-testid="DetailsView">
      <Panel title={<h3 className="text-sm font-semibold">{title}</h3>}>
        {/* Placeholder for rendering a banner as an image or custom component */}
        {banner && typeof banner === "string" ? (
          // Render an image if banner is a string
          <img data-testid="DetailsView-Banner" src={banner} alt="Banner" />
        ) : (
          // Otherwise, render banner as a custom component
          <div data-testid="DetailsView-Banner">{banner}</div>
        )}

          {/* Render each field */}
        <div className="max-w-md grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 pt-5">
          {visibleFields.map((fieldId, key) => {
            const fieldData = editedData.find(({ id }) => id === fieldId);
            // console.log(title, "fieldData", fieldData);
            if (!fieldData) return null;

            return (
              <RenderField
                key={key}
                data={fieldData}
                isEditable={isEditable}
                onItemChange={({ data }) => {
                  const newData = editedData.map(d => d.id === data.id ? data : d);
                  setEditedData(newData)
                  onItemChange?.(data.id, newData);
                }}
                // editedData={editedData}
                // setEditedData={setEditedData}
              />
            );
          })}
          {/* {editedData.map((fieldData, key) => {            

            // If visibleFields is defined and doesn't include the current field, don't render it
            if (visibleFields.length > 0 && !visibleFields.includes(fieldData.id)) {
              return null;
            }

            // Render the field
            return (
              <RenderField
                key={key}
                data={fieldData}
                isEditable={isEditable}
                onItemChange={({ data }) => {
                  const newData = editedData.map(d => d.id === data.id ? data : d);
                  setEditedData(newData)
                  onItemChange?.(data.id, newData);
                }}
                // editedData={editedData}
                // setEditedData={setEditedData}
              />
            );
          })} */}
        </div>

        {children}

        {/* Render the footer if it exists */}
        {footer && (
          <div className="DetailsView-Footer" data-testid="DetailsView-Footer">
            <Calout
              icon={footer?.icon}
              title={footer?.title}
              caption={footer?.caption}
            />
          </div>
        )}
      </Panel>
    </div>
  );
};

export default DetailsView;